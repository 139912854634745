import { userType } from "../../DTO";
import { Api } from "../api";

export const EditUser = (
    user: userType,
    userId: number,
    firstName: string,
    lastName: string,
    phone: string,
) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `users/admin/edit/${userId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                phone,
            }),
        });

        return api
            .call()
            .then((data: any) => resolve({ data: data }))
            .catch((err) => reject(err.data.message));
    });
};
