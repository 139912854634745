import {
    DriverType,
    PaginationType,
    PictureType,
    UserAppType,
    WalletType,
    userType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToWalletTypeList } from "../payment/wallet";
import { convertToVehicle } from "../vehicle/get_list";

export const FetchList = (
    user: userType,
    current_page: number,
    phone?: string,
) => {
    return new Promise<{ data: DriverType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/driver/get",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    phone,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToDriverList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToDriverList(data: any[]): DriverType[] {
    const driverList = data.map((item: any) => {
        return convertToDriver(item);
    });

    return driverList;
}

export function convertToDriver(data: any): DriverType {
    const driverOB = {
        id: data?.id,
        vehicle_id: data?.vehicle_id,
        user_id: data?.user_id,
        phone: data?.phone,
        licence_id: data?.licence_id,
        passport_id: data?.passport_id,
        verified: data?.verified,
        status: data?.status,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
        plate_number: data?.plate_number,
        commission: data?.commission,
        commissionVip: data?.commission_vip,
        user: convertToUser(data?.user),
        vehicle: data?.vehicle ? convertToVehicle(data?.vehicle) : undefined,
    };

    return driverOB;
}

export function convertToPictureListType(data: any): PictureType[] {
    const pictureList = data.map((item: any) => {
        return convertToPictureType(item);
    });
    return pictureList;
}

export function convertToPictureType(item: any): PictureType {
    const pictureOb = {
        id: item.id,
        type: item.type,
        url: item.url,
        user_id: item.user_id,
        deleted_at: item.deleted_at,
        created_at: item.created_at,
        updated_at: item.updated_at,
    };
    return pictureOb;
}

export function convertToUser(data: any): UserAppType {
    const userOB = {
        id: data?.id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone: data?.phone,
        email: data?.email,
        code: data?.code,
        status: data?.status,
        avatar: data?.avatar,
        sheba_num: data?.sheba_num,
        bank_cart: data?.bank_cart,
        identity_code: data?.identity_code,
        last_login: data?.last_login,
        phone_verified_at: data?.phone_verified_at,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
        wallets: data?.wallets ? convertToWalletTypeList(data?.wallets) : [],
        attachments: data?.attachments,
        picture: data?.picture ? convertToPictureListType(data?.picture) : [],
    };
    return userOB;
}

// export function convertToWalletList(data: any): WalletType[] {
//     const walletList = data?.map((item: any) => {
//         return convertToWalletType(item);
//     });

//     return walletList;
// }

// export function convertToWalletType(item: any): WalletType {
//     const walletOB = {
//         id: item?.id,
//         user_id: item?.user_id,
//         balance: item?.balance,
//         status: item?.status,
//         amount: item?.amount,
//         type: item?.type,
//         description: item?.description,
//         transaction_id: item?.transaction_id,
//         currency: item?.currency,
//         deleted_at: item?.deleted_at,
//         created_at: item?.created_at,
//         updated_at: item?.updated_at,
//     };

//     return walletOB;
// }
