import { DiscountType, userType } from "../../DTO";
import { Api } from "../api";
import { convertDiscountCode } from "./edit";

export const ShowDiscount = (user: userType, id: string) => {
    return new Promise<{ discountCode: DiscountType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `coupon/show/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    discountCode: convertDiscountCode(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
