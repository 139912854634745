import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { CustomBreadCrumb, Loading, StaticMap } from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { AddressType } from "../../../DTO";
import { AddressApi } from "../../../api";

export const HotelAddressShow = () => {
    const [address, setAddress] = useState<AddressType>();
    const [loading, setLoading] = useState(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();
    const { id } = useParams();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("addressList")), href: "/definition/address/list" },
        { name: `${t("info")} ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchAddressOB();
    }, [id]);

    const fetchAddressOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.ShowAddress(user, Number(id))
                .then((data) => {
                    setAddress(data.address);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err,
                    });
                });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("addressInfo")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="my-2">{t("AddressInfo")}</h4>
                            <Link
                                to={`/definition/address/edit/${id}`}
                                className="btn btn-warning "
                            >
                                {t("edit")}
                            </Link>
                        </div>
                        {address && (
                            <div className="d-flex flex-row flex-wrap">
                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t("id")}:</span>
                                    <span>{address?.id} </span>
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t(t("title"))}:</span>
                                    {address?.title}
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t("detail")}:</span>
                                    {address?.detail}
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t("postalCode")}</span>
                                    {address.postalCode}
                                </div>

                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t("provinceName")}:</span>
                                    {address.city?.name}
                                </div>
                                <div className="col-12 col-md-6 mb-2">
                                    <span>{t("enProvinceName")}:</span>
                                    {address.city?.name_en}
                                </div>
                                <div className="col-12 col-md-6 mb-2 p-2">
                                    <StaticMap
                                        location={{
                                            lat: Number(address.latitude),
                                            lng: Number(address.longitude),
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
