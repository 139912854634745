import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";

import "./style.scss";
import { Form, Table } from "react-bootstrap";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { PaginationType, TripType } from "../../../../DTO";
import { useAppPagination } from "../../../../Router/hook";
import { TripApi } from "../../../../api";
import { useTranslation } from "react-i18next";

export const VehicleTripList = () => {
    const { t, i18n } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: "Vehicle Trip", href: "#" },
    ];

    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [tripList, setTripList] = useState<TripType[]>([]);

    useEffect(() => {
        fetchTripList(pagination.current_page);
    }, []);

    const fetchTripList = (page: number) => {
        setLoading(true);
        TripApi.FetchTripList(user, page)
            .then((data) => {
                setTripList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("trip")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading && <Loading />}
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">
                            {t("trip")} {t("")}
                        </h4>
                        <div className="d-flex flex-row ">
                            <Link
                                to={"/vehicle/trip/new"}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                +{t("create")} {t("trip")}
                            </Link>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("origin")}</th>
                                    <th>{t("destination")}</th>
                                    <th>{t("time")}</th>
                                    {/* <th>Capacity</th> */}
                                    <th>{t("carType")}</th>
                                    <th>{t("actions")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tripList.map((item) => (
                                    <tr key={item?.id}>
                                        <td>{item?.id}</td>
                                        <td>{item?.price}</td>
                                        <td>{item?.model}</td>
                                        <td>{item?.route?.city_in?.name}</td>
                                        <td>{item?.route?.city_out?.name}</td>
                                        <td>
                                            {item?.time?.slice(0, 10)}{" "}
                                            {item?.time?.slice(11, 19)}
                                        </td>
                                        <td>{item?.vehicle?.model}</td>
                                        <td>
                                            <Link
                                                to={`/vehicle/trip/info/${item?.id}`}
                                                className="btn btn-info btn-sm"
                                            >
                                                {t("info")}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/vehicle/trip/edit/${item?.id}`}
                                                className="btn btn-warning btn-sm"
                                            >
                                                {t("edit")}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/vehicle/driver/info/${item.driver?.id}`}
                                                className="btn btn-light btn-sm"
                                            >
                                                {t("showDriver")}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/vehicle/trip/list?current_page=${pageNum}`,
                                );
                                fetchTripList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
