import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import DatePicker from "react-multi-date-picker";
import gregorian_en from "react-date-object/locales/gregorian_en";
import gregorian from "react-date-object/calendars/gregorian";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";

export const DiscountCodeFormCreate = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        setDate,
        date,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="discount-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="discount-code"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("code")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="code"
                            className="h3"
                            type="string"
                            name="code"
                            placeholder={String(t("codePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.code}
                            onChange={handleChange}
                            isInvalid={touched.code && !!errors.code}
                        />
                        <Form.Text className="text-danger">
                            {errors.code}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="discount-amount"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("amount")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="amount"
                            className="h3"
                            type="string"
                            name="amount"
                            placeholder={String("amountPlaceholder")}
                            onBlur={handleBlur}
                            value={values.amount}
                            onChange={handleChange}
                            isInvalid={touched.amount && !!errors.amount}
                        />
                        <Form.Text className="text-danger">
                            {errors.amount}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="discount-amount"
                        className="position-relative d-flex flex-column col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("expireDate")}</Form.Label>
                        <DatePicker
                            value={date}
                            onChange={(array: any) => {
                                setDate(array.toString?.());
                            }}
                            calendar={gregorian}
                            locale={gregorian_en}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={date.length === 0}
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
