import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelForm } from "../../../form";
import { HotelApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { AccommodationRoomRatePlanType } from "../../../DTO";
import { useTranslation } from "react-i18next";

export const HotelRatePlanEdit: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [ratePlan, setRatePlan] = useState<AccommodationRoomRatePlanType>();

    const { id, roomId, ratePlanId } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/list`,
        },
        {
            name: t("ratePlan"),
            href: `/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/list`,
        },
        { name: `Edit`, href: "#" },
    ];

    const fetchSingleRatePlan = () => {
        setLoading(true);

        HotelApi.RatePlanApi.ShowRatePlan(
            user,
            Number(id),
            Number(roomId),
            Number(ratePlanId),
        )
            .then((data) => {
                setRatePlan(data.ratePlan);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchSingleRatePlan();
    }, [id, roomId, ratePlanId]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("ratePlan")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("create")}</h4>
                        {ratePlan && (
                            <Formik<HotelForm.RatePlanForm.FormValues>
                                validationSchema={HotelForm.RatePlanForm.ValidationSchema(
                                    t,
                                )}
                                initialValues={{
                                    name: ratePlan.name,
                                    description: ratePlan.description,
                                    code: ratePlan.code,
                                    discount: String(ratePlan.discount),
                                    price: String(ratePlan.price),
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    HotelApi.RatePlanApi.EditRatePlan(
                                        user,
                                        Number(id),
                                        Number(roomId),
                                        Number(ratePlanId),
                                        event.name,
                                        event.code,
                                        Number(event.price),
                                        event.description,
                                        Number(event.discount),
                                    )
                                        .then(() => {
                                            setLoading(false);
                                            navigate(
                                                `/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/list`,
                                            );
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <HotelForm.RatePlanForm.HotelRatePlan
                                        submitTxt={t("submit")}
                                        {...props}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
