import { WalletType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToWalletType } from "./list";

export const DecreaseWallet = (
    user: userType,
    userId: number,
    amount: number,
    description: string,
) => {
    return new Promise<{ wallet: WalletType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/wallets/decrease",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: userId,
                amount,
                description,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({ wallet: convertToWalletType(data.data) });
            })
            .catch((error) => reject(error.data.message));
    });
};
