import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDiagramSuccessor,
    faDollar,
    faUserCircle,
    faUserFriends,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { OrderApi } from "../../../api";
import Swal from "sweetalert2";
import { OrderChartType } from "../../../DTO";
import { faFirstOrder } from "@fortawesome/free-brands-svg-icons";

export const Home = () => {
    const user = useAppSelector(selectUser);
    const [order, setOrder] = useState<OrderChartType>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        OrderApi.OrderChart(user)
            .then((data) => {
                setOrder(data.chart);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    }, []);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "#" },
    ];

    const seriesLine = [
        {
            name: "Sales",
            data: order && order.date,
        },
    ];

    const optionsLine: ApexOptions = {
        chart: {
            height: 350,
            type: "line",
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    customIcons: [],
                },
            },
        },
        stroke: {
            width: 5,
            curve: "smooth",
        },
        xaxis: {
            type: "datetime",
            categories: order && order.amount,
            tickAmount: 10,
            labels: {
                formatter: function (value, timestamp: number, opts) {
                    return opts.dateFormatter(
                        new Date(timestamp),
                        "dd MMM yyyy",
                    );
                },
            },
        },
        title: {
            text: "Site Activity",
            align: "left",
            style: {
                fontSize: "16px",
                color: "#666",
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                gradientToColors: ["#FDD835"],
                shadeIntensity: 1,
                type: "horizontal",
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100],
            },
        },
        yaxis: {
            min: -10,
            max: 40,
        },
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("dashboard")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="p-2  col-12 col-lg-4 align-items-start align-self-start ">
                    <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row justify-content-between position-relative">
                        <FontAwesomeIcon
                            className="mx-2 userImageSiteLayout"
                            size="4x"
                            icon={faUserCircle}
                        />
                        <div className="d-flex flex-column align-self-center">
                            <strong className=" ">{user.username}</strong>
                            <div>
                                <span>{t("email")}: </span>
                                <strong className=" mx-2">{user.email}</strong>
                            </div>
                            {/* <div>
                                <span>Username:</span>
                                <strong className="mx-2">
                                    {user.username}
                                </strong>
                            </div> */}

                            <div>
                                <span>{t("phoneNumber")}:</span>
                                <strong className="mx-2">{user.phone}</strong>
                            </div>
                            {/* <div>
                                <span>registery date :</span>
                                <strong className="mx-2">
                                    {user.created_at}
                                </strong>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex flex-row flex-wrap rounded px-3'>
          <div className='col-12  col-sm-6 p-2'>
            <div className='rounded p-3 mb-2 shadow bg-custom-white h-100 d-flex flex-row justify-content-between  align-items-center'>
              <span>city:</span>
              <Form className='col-10 position-relative'>
                <Form.Control  autoComplete="off"                 size='sm'
                  placeholder='city'
                  aria-label='city'
                  aria-describedby='basic-addon1'
                  value={city}
                  onChange={(e: any) => {
                    setCity(e.target.value)
                  }}
                />
                {city.length > 2 && (
                  <div
                    className='bg-custom-light position-absolute col-12'
                    style={{ height: '150px', zIndex: 20 }}
                  ></div>
                )}
              </Form>
            </div>
          </div>
          <div className='col-12  col-sm-6 p-2'>
            <div className='rounded p-3 mb-2 shadow bg-custom-white h-100 dashboard-top-part align-items-center'>
              <span>Date:</span>
              <DatePicker
                onChange={(array: any) => {
                  if (array != null) {
                    let gregorian1 = new DateObject(array[0])
                      .convert(gregorian, gregorian_en)
                      .format()

                    let gregorian2 = new DateObject(array[1])
                      .convert(gregorian, gregorian_en)
                      .format()
                  }
                }}
                range
                rangeHover
                plugins={[<DatePanel position='left' />, weekends()]}
                calendar={gregorian}
                locale={gregorian_en}
              />
            </div>
          </div>
        </div> */}
                <div className="d-flex flex-row flex-wrap rounded">
                    <div className="col-12 col-lg-3 p-1">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row align-items-center justify-content-between position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="3x"
                                icon={faUsers}
                            />
                            <div>
                                <span className="text-center m-2">
                                    {t("totalUser")}:
                                </span>
                                <span className="text-center m-2">
                                    {order && order.totalUser}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3 p-1">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="3x"
                                icon={faUserFriends}
                            />
                            <div>
                                <span className="text-center m-2">
                                    {t("totalDriver")}:
                                </span>
                                <span className="text-center m-2">
                                    {order && order.totalDriver}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3 p-1">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row  position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="3x"
                                icon={faDollar}
                            />
                            <div>
                                <span className="text-center m-2">
                                    {t("totalOrder")}:
                                </span>
                                <span className="text-center m-2">
                                    {order && order.totalOrder}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-3 p-1">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex align-items-center justify-content-between flex-row  position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="3x"
                                icon={faDiagramSuccessor}
                            />
                            <div>
                                <span className="text-center m-2">
                                    {t("totalSuccess")}:
                                </span>
                                <span className="text-center m-2">
                                    {order && order.totalSuccess}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-3">
                        <div className="d-flex flex-row flex-wrap">
                            <div className=" col-12 col-sm-6 col-lg-12 p-2 mb-2 h-100 "> */}
                    {/* <div className='rounded d-flex flex-row bg-custom-white p-3 shadow w-100 dashboard-part'>
                  <div className='d-flex flex-column'>
                    <span>All Sell </span>
                    <span>0</span>
                  </div>
                  <FontAwesomeIcon size='2x' icon={faBagShopping} />
                </div> */}
                </div>
                {/* <div className=' col-12 col-sm-6 col-lg-12 p-2 mb-2 h-100 '>
                <div className='rounded d-flex flex-row bg-custom-white p-3 shadow w-100 dashboard-part'>
                  <div className='d-flex flex-column'>
                    <span>All SellPrice </span>
                    <span>0</span>
                  </div>
                  <FontAwesomeIcon size='2x' icon={faDollar} />
                </div>
              </div> */}
                {/* <div className=' col-12 col-sm-6 col-lg-12 p-2 mb-2 h-100 '>
                <div className='rounded d-flex flex-row bg-custom-white p-3 shadow w-100 dashboard-part'>
                  <div className='d-flex flex-column'>
                    <span>All User </span>
                    <span>0</span>
                  </div>
                  <FontAwesomeIcon size='2x' icon={faUsers} />
                </div>
              </div>
              <div className=' col-12 col-sm-6 col-lg-12 p-2 mb-2 h-100 '>
                <div className='rounded d-flex flex-row bg-custom-white p-3 shadow w-100 dashboard-part'>
                  <div className='d-flex flex-column'>
                    <span>All driver </span>
                    <span>0</span>
                  </div>
                  <FontAwesomeIcon size='2x' icon={faUserFriends} />
                </div>
              </div> */}

                {/* <div className=' col-12 col-sm-6 col-lg-12 p-2 mb-2 h-100 '>
                <div className='rounded d-flex flex-row bg-custom-white p-3 shadow w-100 dashboard-part'>
                  <div className='d-flex flex-column'>
                    <span>Cancel Order </span>
                    <span>0</span>
                  </div>
                  <FontAwesomeIcon size='2x' icon={faCancel} />
                </div>
              </div> */}
                {/* </div>
                    </div> */}

                {/*<div className="col-12 col-lg-12 p-2">*/}
                {/*    <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column flex-sm-row">*/}
                {/*        <div className=" w-100" style={{ direction: "ltr" }}>*/}
                {/*            <ApexCharts*/}
                {/*                options={optionsLine}*/}
                {/*                // @ts-ignore*/}
                {/*                series={seriesLine}*/}
                {/*                // @ts-ignore*/}
                {/*                type="line"*/}
                {/*                height={450}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/* </div> */}
        </>
    );
};
