import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SeatModelType } from "../../../../DTO";
import { Loading, SeatShow } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { VehicleApi } from "../../../../api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const SeatList = () => {
    const [seatList, setSeatList] = useState<SeatModelType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const fetchSeatList = () => {
        setLoading(true);
        VehicleApi.SeatApi.SeatList(user)
            .then((data) => {
                setSeatList(data.seatModel);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchSeatList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("cars")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <div className="d-flex flex-wrap">
                    {seatList.length > 0 &&
                        seatList.map((seat: SeatModelType, index: number) => (
                            <SeatShow seatData={seat} key={index} />
                        ))}
                </div>
            )}
        </div>
    );
};
