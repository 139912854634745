import React from "react";
import { Pagination } from "react-bootstrap";

import "./style.scss";

export const CustomPagination: React.FC<{
    total: number;
    perPage: number;
    currentPage: number;
    changePage: Function;
}> = ({ total, perPage, currentPage, changePage }) => {
    let maxSize = Math.ceil(total / perPage);
    return (
        <div className="d-flex flex-row justify-content-center mt-3">
            <Pagination>
                <Pagination.Prev
                    onClick={() => {
                        if (currentPage > 1) {
                            changePage(currentPage - 1);
                        }
                    }}
                />
                {currentPage != 1 && (
                    <Pagination.Item
                        onClick={() => {
                            changePage(1);
                        }}
                    >
                        {1}
                    </Pagination.Item>
                )}
                {3 < currentPage && <Pagination.Ellipsis disabled />}
                {currentPage - 1 > 1 && (
                    <Pagination.Item
                        onClick={() => {
                            changePage(currentPage - 1);
                        }}
                    >
                        {currentPage - 1}
                    </Pagination.Item>
                )}
                <Pagination.Item active>{currentPage}</Pagination.Item>
                {currentPage + 1 < maxSize && (
                    <Pagination.Item
                        onClick={() => {
                            changePage(currentPage + 1);
                        }}
                    >
                        {currentPage + 1}
                    </Pagination.Item>
                )}
                {currentPage + 2 < maxSize && <Pagination.Ellipsis disabled />}
                {maxSize > 1 && currentPage != maxSize && (
                    <Pagination.Item
                        onClick={() => {
                            changePage(maxSize);
                        }}
                    >
                        {maxSize}
                    </Pagination.Item>
                )}
                <Pagination.Next
                    onClick={() => {
                        if (currentPage < maxSize) {
                            changePage(currentPage + 1);
                        }
                    }}
                />
            </Pagination>
        </div>
    );
};
