import React, { useState } from "react";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { AdminForm } from "../../../form";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { AdminApi } from "../../../api";
import { Loading, CustomBreadCrumb } from "../../../components";
import { useAppNavigate } from "../../../Router/hook";

export const NewAdmin: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("adminList")), href: "/admin-user" },
        { name: String(t("new")), href: "#" },
    ];

    const createAdmin = (
        firstName: string,
        lastName: string,
        roleId: number,
        username: string,
        email: string,
        phone: string,
        password: string,
    ) => {
        setLoading(true);
        AdminApi.CreateAdmin(
            user,
            firstName,
            lastName,
            username,
            email,
            phone,
            password,
            roleId,
        )
            .then((data) => {
                navigate("/admin-user");
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("adminNew")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<AdminForm.FormValues>
                        validationSchema={AdminForm.ValidationSchema(t)}
                        initialValues={{
                            username: "",
                            phoneNumber: "",
                            email: "",
                            password: "",
                            firstName: "",
                            lastName: "",
                            roleId: 0,
                        }}
                        onSubmit={(event) => {
                            createAdmin(
                                event.firstName,
                                event.lastName,
                                event.roleId,
                                event.username,
                                event.email,
                                event.phoneNumber,
                                String(event.password),
                            );
                        }}
                        component={(props: any) => (
                            <AdminForm.AdminFormCreate
                                submitTxt={String(t("submit"))}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
