import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import "./style.scss";
import { CustomPagination, Loading, SeatShow } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { PaginationType, VehicleModelType } from "../../../../DTO";
import { VehicleApi } from "../../../../api";
import { useAppPagination } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const VehicleCarList = () => {
    const { t } = useTranslation();
    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("riding"), href: "#" },
    ];
    const [seatModelId, setSeatModelId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [vehicleList, setVehicleList] = useState<VehicleModelType[]>([]);
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        fetchVehicleList(
            // pagination.current_page
            title,
        );
    }, []);

    const fetchVehicleList = (
        // page: number
        title: string,
    ) => {
        setLoading(true);
        VehicleApi.FetchList(
            user,
            // ,
            // page
            title,
        )
            .then((data) => {
                setVehicleList(data.data);
                // setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const findSeatModel = vehicleList.find((item) => {
        if (item.model && item.vehicle) {
            return Number(item?.vehicle?.id) == seatModelId;
        }
    });

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("cars")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("carTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showAdminSearchInput"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <Link
                                to={"/vehicle/car/new"}
                                className="btn btn-success "
                            >
                                {t("createCar")} +
                            </Link>
                            <Link
                                to={"/vehicle/car/create-seat"}
                                className="btn btn-success mx-1"
                            >
                                {t("createSeat")} +
                            </Link>
                            <Link
                                to={"/vehicle/car/seat-list"}
                                className="btn btn-info mx-1"
                            >
                                {t("seatList")}
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="showAdminSearchInput"></input>
                    <div className="adminSearchBox p-2  flex-wrap flex-sm-nowrap justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                {/* <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">Id</Form.Label>
                                    <Form.Control  autoComplete="off"                                       size="sm"
                                        type="number"
                                        placeholder="Enter id"
                                    />
                                </Form.Group> */}
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("title")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                        type="text"
                                        placeholder={t("titlePlaceholder")}
                                    />
                                </Form.Group>
                                {/* <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        Phone
                                    </Form.Label>
                                    <Form.Control  autoComplete="off"                                       size="sm"
                                        type="number"
                                        placeholder="Enter Phone"
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        Plate
                                    </Form.Label>
                                    <Form.Control  autoComplete="off"                                       size="sm"
                                        type="text"
                                        placeholder="Enter Plate"
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        License code
                                    </Form.Label>
                                    <Form.Control  autoComplete="off"                                       size="sm"
                                        type="text"
                                        placeholder="Enter License code"
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        Status
                                    </Form.Label>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select example"
                                        style={{ maxWidth: "230px" }}
                                    >
                                        <option>select status</option>
                                        <option value="1">Active</option>
                                        <option value="2">InActive</option>
                                    </Form.Select>
                                </Form.Group> */}
                            </Form>
                        </div>
                        <button
                            className="btn btn-sm btn-success mx-1 text-nowrap"
                            onClick={() => fetchVehicleList(title)}
                        >
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("title")}</th>
                                    <th>{t("model")}</th>
                                    <th>{t("status")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleList.map((vehicleItem, index) => (
                                    <tr key={index}>
                                        {vehicleItem.vehicle &&
                                            vehicleItem.model && (
                                                <>
                                                    <td>
                                                        {vehicleItem.vehicle.id}
                                                    </td>
                                                    <td>
                                                        {
                                                            vehicleItem.vehicle
                                                                .title
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            vehicleItem.vehicle
                                                                .model
                                                        }
                                                    </td>
                                                    <td>
                                                        <strong
                                                            className={
                                                                vehicleItem
                                                                    .vehicle
                                                                    .status ===
                                                                "Pending"
                                                                    ? "badge bg-warning"
                                                                    : "badge bg-success"
                                                            }
                                                        >
                                                            {
                                                                vehicleItem
                                                                    .vehicle
                                                                    .status
                                                            }
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-sm btn-info"
                                                            onClick={() => {
                                                                setShowModal(
                                                                    true,
                                                                );
                                                                setSeatModelId(
                                                                    Number(
                                                                        vehicleItem
                                                                            .vehicle
                                                                            ?.id,
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            {t("info")}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    text: `
                                                                        ${t(
                                                                            "alertDelete",
                                                                        )}
                                                                    ${vehicleItem
                                                                        .vehicle
                                                                        ?.title}"?`,
                                                                    icon: "warning",
                                                                    showCancelButton:
                                                                        true,
                                                                    confirmButtonColor:
                                                                        "#3085d6",
                                                                    cancelButtonColor:
                                                                        "#d33",
                                                                    cancelButtonText:
                                                                        String(
                                                                            t(
                                                                                "cancel",
                                                                            ),
                                                                        ),
                                                                    confirmButtonText:
                                                                        String(
                                                                            t(
                                                                                "yesDelete",
                                                                            ),
                                                                        ),
                                                                }).then(
                                                                    (
                                                                        result,
                                                                    ) => {
                                                                        if (
                                                                            result.isConfirmed
                                                                        ) {
                                                                            VehicleApi.DeleteVehicle(
                                                                                user,
                                                                                Number(
                                                                                    vehicleItem.vehicle &&
                                                                                        vehicleItem
                                                                                            .vehicle
                                                                                            .id,
                                                                                ),
                                                                            )
                                                                                .then(
                                                                                    (
                                                                                        data,
                                                                                    ) => {
                                                                                        setLoading(
                                                                                            false,
                                                                                        );
                                                                                        fetchVehicleList(
                                                                                            title,
                                                                                        );
                                                                                    },
                                                                                )
                                                                                .catch(
                                                                                    (
                                                                                        err,
                                                                                    ) => {
                                                                                        setLoading(
                                                                                            false,
                                                                                        );
                                                                                    },
                                                                                );
                                                                        }
                                                                    },
                                                                );
                                                            }}
                                                            className="btn btn-sm btn-danger"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                            />
                                                        </button>
                                                    </td>
                                                </>
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/vehicle/driver/list?current_page=${pageNum}`,
                                );
                                fetchVehicleList(
                                    // pageNum
                                    )
                                    ;
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("seatModel")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {findSeatModel?.vehicle && findSeatModel.model && (
                        <SeatShow
                            seatData={{
                                name: findSeatModel?.vehicle?.title,
                                model: findSeatModel?.model,
                            }}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};
