import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb, Loading } from "../../../../components";
import { TripForm } from "../../../../form";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { TripApi } from "../../../../api";
import { TripType } from "../../../../DTO";
import { useAppNavigate } from "../../../../Router/hook";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { date } from "yup";

export const VehicleTripEdit: FC = () => {
    const [trip, setTrip] = useState<TripType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");

    const { id } = useParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("trip"), href: "/vehicle/trip/list" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];

    const fetchTripById = () => {
        setLoading(true);
        TripApi.ShowTrip(user, Number(id))
            .then((data) => {
                setTrip(data.trip);
                setDate(String(data?.trip?.time));
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    };

    useEffect(() => {
        fetchTripById();
    }, []);

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("edit")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <CustomBreadCrumb breadCrumbList={crumbs} />
            <div className="d-flex flex-column ">
                {trip?.id && trip.id > 0 && (
                    <Formik<TripForm.FormValues>
                        validationSchema={TripForm.ValidationSchema(t)}
                        initialValues={{
                            driverId: Number(trip?.driver?.id),
                            routeId: Number(trip?.route?.id),
                            driverName: `${trip.driver?.user?.first_name} ${trip.driver?.user?.last_name} <---> ${trip.driver?.phone}`,
                            routeName: `${trip.route?.city_in?.name} ---> ${trip.route?.city_out?.name}`,
                            price: String(trip?.price),
                            time: date,
                            duration: String(trip.duration),
                            portInId: Number(trip.port_in_id),
                            portInName: String(trip.portIn.name),
                            portOutId: Number(trip.port_out_id),
                            portOutName: String(trip.portOut.name),
                            status: trip.status,
                            priceFront: String(trip.price_front),
                        }}
                        onSubmit={(event) => {
                            setLoading(true);

                            TripApi.EditTrip(
                                user,
                                Number(id),
                                Number(event.price),
                                Number(event.duration),
                                Number(event.portInId),
                                Number(event.portOutId),
                                event.status,
                                Number(event.priceFront),
                                date,
                            )
                                .then(() => {
                                    navigate("/vehicle/trip/list");
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    Swal.fire({
                                        icon: "error",
                                        text: error.data.message,
                                    });
                                });
                        }}
                        component={(props: any) => (
                            <TripForm.TripFormCreate
                                submitTxt={t("submit")}
                                setDate={setDate}
                                date={date}
                                routeList={Array(trip?.route)}
                                driverList={Array(trip?.driver)}
                                {...props}
                            />
                        )}
                    />
                )}
            </div>
        </div>
    );
};
