import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
    faAdd,
    faEdit,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { TripApi } from "../../../../api";
import { PaginationType, RouteType } from "../../../../DTO";
import { useAppNavigate, useAppPagination } from "../../../../Router/hook";
import "./style.scss";
import { useTranslation } from "react-i18next";

export const RouteList = () => {
    const [route, setRoute] = useState<RouteType[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [routeName, setRouteName] = useState<string>("");

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("route"), href: "#" },
    ];

    useEffect(() => {
        fetchRouteList(pagination.current_page);
    }, []);

    const fetchRouteList = (page: number) => {
        setLoading(true);
        TripApi.RouteApi.RouteList(user, page, routeName)
            .then((data) => {
                setRouteName("");
                setRoute(data.route);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const deleteRoute = (id: number) => {
        setLoading(true);
        TripApi.RouteApi.DeleteRoute(user, id)
            .then(() => {
                Swal.fire(
                    String(t("delete")),
                    String(t("deleteMessage")),
                    "success",
                );
                fetchRouteList(pagination.current_page);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("route")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("routeTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showRouteSearchInput"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("routeSearch")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <Link
                                to={"/definition/route/new"}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                <span>{t("addRoute")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faAdd}
                                />
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="showRouteSearchInput"></input>
                    <div className="routeSearchBox  p-2 flex -row justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("name")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        onChange={(e) =>
                                            setRouteName(e.target.value)
                                        }
                                        size="sm"
                                        value={routeName}
                                        type="string"
                                        placeholder={String(
                                            t("placeholderName"),
                                        )}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button
                            className="btn btn-sm btn-success mx-1 text-nowrap"
                            onClick={() =>
                                fetchRouteList(pagination.current_page)
                            }
                        >
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("cityInName")}</th>
                                    <th>{t("cityOutName")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {route.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.city_in?.name}</td>
                                        <td>{item.city_out?.name}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item.status === "Active"
                                                        ? "badge bg-success"
                                                        : "badge bg-danger"
                                                }
                                            >
                                                {item?.status}
                                            </strong>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/definition/route/edit/${item?.id}`}
                                                    className="btn btn-sm btn-warning"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                    />
                                                </Link>
                                                <span
                                                    onClick={() =>
                                                        deleteRoute(
                                                            Number(item.id),
                                                        )
                                                    }
                                                    className="btn btn-sm btn-danger "
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/definition/route/list?current_page=${pageNum}`,
                                );
                                fetchRouteList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
