import { PaginationType, RoleType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";
import { convertPermissionList } from "../permission/list";

export const ListRole = (user: userType) => {
    return new Promise<{
        role: RoleType[];
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/role/get",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    role: convertRoleList(data.data),
                });
            })
            .catch((error) => {
                return reject(error);
            });
    });
};

export const convertRoleList = (data: any[]): RoleType[] => {
    return data.map((element) => convertRole(element));
};

export const convertRole = (data: any): RoleType => {
    let out: RoleType = {
        id: data?.id,
        name: data?.name,
        status: data?.status,
        guardName: data?.guard_name,
        createdAt: data?.created_at ? data?.created_at : null,
        updatedAt: data?.created_at ? data?.updated_at : null,
        permissions: convertPermissionList(data?.permissions),
    };

    return out;
};
