import { seatType, userType } from "../../../DTO";
import { Api } from "../../api";

type createSeat = {
    capacity: number;
    name: string;
    type: string;
    model: seatType[];
};

export const CreateSeatModel = (user: userType, seatModel: createSeat) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/seat/model/store",
            body: JSON.stringify([
                {
                    capacity: seatModel.capacity,
                    name: seatModel.name,
                    type: seatModel.type,
                    model: seatModel.model,
                },
            ]),
            header: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.message);
            });
    });
};
