import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb, Loading } from "../../../../components";
import { RouteForm } from "../../../../form";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { TripApi } from "../../../../api";
import { useAppNavigate } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const AddressRouteNew: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("route"), href: "/definition/route/list" },
        { name: t("new"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("new")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <CustomBreadCrumb breadCrumbList={crumbs} />
            <div className="d-flex flex-column ">
                <Formik<RouteForm.FormValues>
                    validationSchema={RouteForm.ValidationSchema(t)}
                    initialValues={{
                        cityInId: 0,
                        cityInName: "",
                        cityOutId: 0,
                        cityOutName: "",
                        status: "Active",
                    }}
                    onSubmit={(event) => {
                        setLoading(true);
                        TripApi.RouteApi.CreateRoute(
                            user,
                            event.cityInId,
                            event.cityOutId,
                        )
                            .then(() => {
                                setLoading(false);
                                navigate("/definition/route/list");
                            })
                            .catch((err) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    text: err.data.message,
                                });
                            });
                    }}
                    component={(props: any) => (
                        <RouteForm.RouteFormCreateEdit
                            submitTxt={t("submit")}
                            {...props}
                        />
                    )}
                />
            </div>
        </div>
    );
};
