import { WithdrawalType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToWithdrawalTypeList } from "../../payment/withdrawal";

export const ListWithdrawal = (user: userType, accommodationId: number) => {
    return new Promise<{ withdrawal: WithdrawalType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/withdrawal/accommodation`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                resolve({
                    withdrawal: convertToWithdrawalTypeList(data.data.data),
                });
            })
            .catch((err) => reject(err.data.message));
    });
};
