import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./state";
import { loaderStoreType } from "./type";

export const LoaderSlice = createSlice({
    initialState: initialState,
    name: "Loader",
    reducers: {
        setLoader: (state: loaderStoreType, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const { setLoader } = LoaderSlice.actions;
export const LoaderReducer = LoaderSlice.reducer;
