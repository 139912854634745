import {
    AccommodationRoomRatePlanType,
    PaginationType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";

export const ListRatePlan = (
    user: userType,
    accommodationId: number,
    roomId: number,
    currentPage: number,
    ratePlanSearch?: string,
) => {
    return new Promise<{
        ratePlan: AccommodationRoomRatePlanType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/accommodation/${accommodationId}/room-type/${roomId}/rate-plan`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
                search: ratePlanSearch,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    ratePlan: convertToAccommodationRoomRatePlanTypeList(
                        data.data,
                    ),
                    pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};

export const convertToAccommodationRoomRatePlanTypeList = (
    data: any[],
): AccommodationRoomRatePlanType[] => {
    return data.map((element) =>
        convertToAccommodationRoomRatePlanType(element),
    );
};

export const convertToAccommodationRoomRatePlanType = (
    data: any,
): AccommodationRoomRatePlanType => {
    let out: AccommodationRoomRatePlanType = {
        code: data?.code,
        description: data?.description,
        discount: data?.discount,
        id: data?.id,
        name: data?.name,
        price: data?.price,
        priceDollar: data?.price_dollar,
        roomTypeId: data?.room_type_id,
    };

    return out;
};
