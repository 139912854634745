import { userType } from "../../DTO";
import { Api } from "../api";

export const CreateDiscount = (
    user: userType,
    code: number,
    name: string,
    expireTime: string,
    amount: number,
) => {
    return new Promise<{}>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "coupon/store",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                code,
                name,
                expire_time: expireTime,
                amount,
            },
        });

        return api
            .call()
            .then((data) => {
                return resolve({ data });
            })
            .catch((error) => reject(error));
    });
};
