import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";

import { Loading } from "../../../components";
import { useAppNavigate } from "../../../Router/hook";
import { PortApi } from "../../../api";
import { PortForm } from "../../../form";
import { useParams } from "react-router-dom";
import { PortType } from "../../../DTO";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const PortEdit: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [port, setPort] = useState<PortType>();

    const user = useAppSelector(selectUser);

    const { id } = useParams();

    const fetchPortById = () => {
        setLoading(true);
        PortApi.ShowPort(user, Number(id))
            .then((data) => {
                setPort(data.port);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchPortById();
    }, [id]);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("portList"), href: "/definition/port/list" },
        { name: t("edit") + " " + id, href: "#" },
    ];

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("edit")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {port && port.id > 0 && (
                        <Formik<PortForm.FormValues>
                            validationSchema={PortForm.ValidationSchema(t)}
                            initialValues={{
                                name: port.name,
                                addressId: Number(port.address?.id),
                                addressName: String(port.address?.title),
                                description: port.description,
                                type: port.type,
                                status: port.status,
                            }}
                            onSubmit={(event) => {
                                setLoading(true);
                                PortApi.EditPort(
                                    user,
                                    Number(id),
                                    event.name,
                                    event.description,
                                    event.type,
                                    event.status,
                                    Number(port.address?.id),
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate("/port/list");
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err.data.message,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <PortForm.PortFormCreateAndEdit
                                    submitTxt={t("submit")}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
