import { AccommodationManagerType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAdmin } from "../../admin";
import { convertToAccommodationType } from "../accommodation";

export const ListManager = (user: userType, accommodationId: number) => {
    return new Promise<{ manager: AccommodationManagerType[] }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `admin/accommodation/${accommodationId}/manager`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        manager: convertToAccommodationManagerTypeList(
                            data.data,
                        ),
                    });
                })
                .catch((error: any) => reject(error.data.message));
        },
    );
};

export const convertToAccommodationManagerTypeList = (
    data: any[],
): AccommodationManagerType[] => {
    return data.map((element) => convertToAccommodationManagerType(element));
};

export const convertToAccommodationManagerType = (
    data: any,
): AccommodationManagerType => {
    let out: AccommodationManagerType = {
        id: data?.id,
        accommodationId: data?.accommodation_id,
        adminId: data?.admin_id,
        updatedAt: data?.updated_at,
        createdAt: data?.created_at,
        admin: convertToAdmin(data?.admin),
        accommodation: data?.accommodation?.name,
    };

    return out;
};
