import { Api } from "../api";
import { userType, DriverType } from "../../DTO";

export const EditDriver = (
    user: userType,
    driverId: number,
    passportId: number,
    licenceId: number,
    vehicleId: number,
    phone: string,
    firstName: string,
    lastName: string,
    commissionPercent: number,
    commissionVipPercent: number,
) => {
    return new Promise<{ driver: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/driver/${driverId}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                passport_id: passportId,
                licence_id: licenceId,
                vehicle_id: vehicleId,
                phone,
                first_name: firstName,
                last_name: lastName,
                commission: commissionPercent,
                commission_vip: commissionVipPercent,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    driver: data.data,
                });
            })
            .catch((error) => {
                return reject(error);
            });
    });
};
