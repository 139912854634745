import { Api } from "../../api";
import { RouteType, userType } from "../../../DTO";
import { convertToRoute } from "../trip_list";

export const EditRoute = (
    user: userType,
    id: number,
    cityInId: number,
    cityOutId: number,
    status: "Active" | "Pending",
) => {
    return new Promise<{ route: any }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/routes/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify([
                {
                    city_in_id: cityInId,
                    city_out_id: cityOutId,
                    status: status,
                },
            ]),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    route: data.data,
                });
            })
            .catch((error) => reject(error));
    });
};
