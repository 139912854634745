import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        passportId: Yup.string().required(t("passportIdError")).min(1),
        firstName: Yup.string().required(t("firstNameError")).min(1),
        lastName: Yup.string().required(t("lastNameError")).min(1),
        licenceId: Yup.string().required(t("licenseIdError")).min(1),
        vehicleName: Yup.string().required(t("vehicleError")).min(1),
        phoneNumber: Yup.string().required(t("phoneNumberError")),
        commission: Yup.string().required(t("commissionError")).max(2),
        commissionVip: Yup.string().required(t("commissionVipError")).max(2),
    });
