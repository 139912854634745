import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { AdminUserType } from "../../../DTO";
import { Loading } from "../../../components";
import { AdminApi } from "../../../api";

export const AdminInfo = () => {
    const [admin, setAdmin] = useState<AdminUserType>({
        id: 0,
        user_id: 0,
        first_name: "",
        last_name: "",
        username: "",
        phone: "",
        email: "",
        password: "",
        phone_verified_at: "",
        email_verified_at: "",
        last_login: "",
        remember_token: "",
        deleted_at: "",
        created_at: "",
        updated_at: "",
    });
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const { id } = useParams();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("adminInfo")), href: "#" },
    ];

    const showAdmin = () => {
        setLoading(true);
        AdminApi.ShowAdmin(user, String(id))
            .then((data) => {
                setAdmin(data.admin);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        showAdmin();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("adminInfo")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex justify-content-center flex-row flex-wrap rounded px-3">
                    <div className="col-6 p-2   align-items-center align-self-center ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="5x"
                                icon={faUserCircle}
                            />
                            <strong className="text-center mt-2">
                                {admin.first_name} {admin.last_name}
                            </strong>
                            <strong className="text-center mt-2">
                                {admin.email}
                            </strong>
                            <hr />
                            <div>
                                <span>{t("username")}:</span>
                                <strong className="mx-2">
                                    {admin.username}
                                </strong>
                            </div>
                            <div>
                                <span>{t("password")}:</span>
                                <strong className="mx-2">
                                    {admin.password}
                                </strong>
                            </div>
                            <div>
                                <span>{t("phoneNumber")}:</span>
                                <strong className="mx-2">{admin.phone}</strong>
                            </div>
                            <div>
                                <span>{t("registerDate")}:</span>
                                <strong className="mx-2">
                                    {admin.created_at}
                                </strong>
                            </div>
                            <div
                                className="position-absolute info-dot-icon"
                                style={{ top: "2px", right: "12px" }}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </div>
                            <div
                                className="position-absolute border bg-custom-white rounded info-edit-part"
                                style={{ right: 10 }}
                            >
                                <Link
                                    to={`/admin-user/edit/${admin.id}`}
                                    className="p-2"
                                >
                                    <span>
                                        <small>{t("editProfile")}</small>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
