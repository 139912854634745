import { Api } from "../../api";
import { RouteType, userType } from "../../../DTO";
import { convertToRoute } from "../trip_list";

export const CreateRoute = (
    user: userType,
    cityInId: number,
    cityOutId: number,
) => {
    return new Promise<{ route: RouteType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/routes",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                city_in_id: cityInId,
                city_out_id: cityOutId,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    route: convertToRoute(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
