import { userType } from "../../../DTO";
import { Api } from "../../api";

export const CreateManager = (
    user: userType,
    accommodationId: number,
    adminId: number,
) => {
    return new Promise<{ manager: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/accommodation/${accommodationId}/manager`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: adminId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    manager: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
