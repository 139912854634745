import { userType } from "../../DTO";
import { Api } from "../api";

export const DeleteVehicle = (user: userType, vehicleId: number) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/vehicle/delete/${vehicleId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => resolve({ data: data.data }))
            .catch((err) => reject(err.data.message));
    });
};
