import React, { FC, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import { PaginationType, AccommodationRoomType } from "../../../../DTO";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { HotelApi } from "../../../../api";
import {
    useAppNavigate,
    useAppPagination,
    useAppParams,
} from "../../../../Router/hook";
import "./style.scss";
import { useTranslation } from "react-i18next";

export const HotelRoomTypeList: FC = () => {
    const [room, setRoom] = useState<AccommodationRoomType[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [roomSearch, setRoomSearch] = useState<string>("");

    const { id } = useAppParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: "#",
        },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchHotelRoomTypeList = (page: number) => {
        setLoading(true);

        HotelApi.RoomTypeApi.ListRoomType(user, Number(id), page, roomSearch)
            .then((data) => {
                setRoom(data.roomType);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    };

    useEffect(() => {
        fetchHotelRoomTypeList(pagination.current_page);
    }, [id]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("roomType")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("roomTypeTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="show-room-search-input"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <Link
                                to={`/hotel/accommodation/${id}/room-type/new`}
                                className="btn btn-success"
                            >
                                {t("create")} +
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="show-room-search-input"></input>
                    <div className="room-type-search  p-2 flex-row justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("roomType")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="number"
                                        placeholder={String(
                                            t("roomTypePlaceholder"),
                                        )}
                                        onChange={(e) =>
                                            setRoomSearch(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button
                            className="btn btn-sm btn-success mx-1 text-nowrap"
                            disabled={roomSearch.length <= 0}
                            onClick={() =>
                                fetchHotelRoomTypeList(pagination.current_page)
                            }
                        >
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("code")}</th>
                                    <th>{t("adult")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {room.length > 0 &&
                                    room.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.code}</td>
                                            <td>{item.adult}</td>
                                            <td>
                                                <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                    <Link
                                                        to={`/hotel/accommodation/${id}/room-type/${item.id}/inventory/new`}
                                                        className="btn btn-sm btn-primary text-custom-light"
                                                    >
                                                        {t("assignInventory")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${id}/room-type/${item.id}/rate-plan/list`}
                                                        className="btn btn-sm btn-secondary text-custom-light"
                                                    >
                                                        {t("ratePlan")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${id}/room-type/info/${item.id}`}
                                                        className="btn btn-sm btn-info"
                                                    >
                                                        {t("info")}
                                                    </Link>
                                                    <Link
                                                        className="btn btn-sm btn-warning"
                                                        to={`/hotel/accommodation/${id}/room-type/edit/${item.id}`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                        />
                                                    </Link>
                                                    <button
                                                        onClick={() =>
                                                            Swal.fire({
                                                                text: String(
                                                                    t(
                                                                        "alertDelete",
                                                                    ),
                                                                ),
                                                                icon: "warning",
                                                                showCancelButton:
                                                                    true,
                                                                confirmButtonColor:
                                                                    "#3085d6",
                                                                cancelButtonColor:
                                                                    "#d33",
                                                                confirmButtonText:
                                                                    String(
                                                                        t(
                                                                            "yesDelete",
                                                                        ),
                                                                    ),
                                                                cancelButtonText:
                                                                    String(
                                                                        t(
                                                                            "cancel",
                                                                        ),
                                                                    ),
                                                            }).then(
                                                                (result) => {
                                                                    setLoading(
                                                                        true,
                                                                    );
                                                                    HotelApi.RoomTypeApi.DeleteRoomType(
                                                                        user,
                                                                        Number(
                                                                            id,
                                                                        ),
                                                                        item.id,
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                setLoading(
                                                                                    false,
                                                                                );
                                                                                if (
                                                                                    result.isConfirmed
                                                                                ) {
                                                                                    Swal.fire(
                                                                                        String(
                                                                                            t(
                                                                                                "delete",
                                                                                            ),
                                                                                        ),
                                                                                        String(
                                                                                            t(
                                                                                                "deleteMessage",
                                                                                            ),
                                                                                        ),
                                                                                        "success",
                                                                                    );
                                                                                    fetchHotelRoomTypeList(
                                                                                        pagination.current_page,
                                                                                    );
                                                                                }
                                                                            },
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err,
                                                                            ) => {
                                                                                setLoading(
                                                                                    false,
                                                                                );
                                                                                Swal.fire(
                                                                                    {
                                                                                        icon: "error",
                                                                                        text: err,
                                                                                    },
                                                                                );
                                                                            },
                                                                        );
                                                                },
                                                            )
                                                        }
                                                        className="btn btn-sm btn-danger"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                        />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/hotel/accommodation/${id}/room-type/list?current_page=${pageNum}`,
                                );
                                fetchHotelRoomTypeList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
