import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading, CustomBreadCrumb } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CityType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { CityForm } from "../../../../form";
import { useAppNavigate } from "../../../../Router/hook";

export const CityEdit = () => {
    const [cityOB, setCityOB] = useState<CityType>();
    const [loading, setLoading] = useState(false);

    const user = useAppSelector(selectUser);

    const { id } = useParams();

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("cityList")), href: "/definition/city" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchCityOB();
    }, [id]);

    const fetchCityOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchCityId(user, id)
                .then((data) => {
                    setCityOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("editCity")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <h4 className="my-2">{t("editCity")}</h4>
                        {cityOB && (
                            <div className="d-flex flex-row flex-wrap">
                                <Formik<CityForm.FormValues>
                                    validationSchema={CityForm.ValidationSchemaEdit(
                                        t,
                                    )}
                                    initialValues={{
                                        lat: String(cityOB.latitude),

                                        lng: String(cityOB.longitude),

                                        name: String(cityOB.name),

                                        enName: String(cityOB.name_en),

                                        provinceName: String(
                                            cityOB?.province?.name,
                                        ),
                                        domainCode: String(cityOB.domain_code),
                                        provinceId: Number(cityOB.province?.id),
                                        status: cityOB.status
                                            ? cityOB.status
                                            : "Active",
                                        statusAccommodation:
                                            cityOB.statusAccommodation
                                                ? cityOB.statusAccommodation
                                                : "Active",
                                        statusCar: cityOB.statusCar
                                            ? cityOB.statusCar
                                            : "Active",
                                        statusFlight: cityOB.statusFlight
                                            ? cityOB.statusFlight
                                            : "Active",
                                    }}
                                    onSubmit={(event) => {
                                        if (id) {
                                            setLoading(true);
                                            AddressApi.EditCity(
                                                user,
                                                {
                                                    id: 0,
                                                    domain_code:
                                                        event.domainCode,
                                                    latitude: event.lat,
                                                    longitude: event.lng,
                                                    name: event.name,
                                                    name_en: event.enName,
                                                    province_id:
                                                        event.provinceId,
                                                    status: event.status,
                                                    statusAccommodation:
                                                        event.statusAccommodation,
                                                    statusCar: event.statusCar,
                                                    statusFlight:
                                                        event.statusFlight,
                                                },
                                                id,
                                            )
                                                .then((data) => {
                                                    setLoading(false);
                                                    navigate(
                                                        "/definition/city",
                                                    );
                                                })
                                                .catch((err) => {
                                                    setLoading(false);
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: err.data.message,
                                                    });
                                                });
                                        }
                                    }}
                                    component={(props: any) => (
                                        <CityForm.CityFormEdit
                                            submitTxt={t("submit")}
                                            {...props}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
