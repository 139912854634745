import { GatewayType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToGatewayType } from "./list";

export const ChangeStatusGateway = (
    user: userType,
    gatewayId: number,
    status: "active" | "inactive",
) => {
    return new Promise<{ gateway: GatewayType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/gateway/${gatewayId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    gateway: convertToGatewayType(data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
