import React, { useEffect, useState } from "react";
import {
    faBars,
    faChartLine,
    faDoorClosed,
    faDoorOpen,
    faEarthAmerica,
    faHotel,
    faList,
    faMoneyBill,
    faMoneyCheckDollar,
    faMoon,
    faPlane,
    faSun,
    faTaxi,
    faUserCircle,
    faUserFriends,
    faUserLock,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import "./style.scss";
import {
    eraseCookie,
    useAppDispatch,
    useAppSelector,
} from "../../../redux-config/hooks";
import {
    selectDarkMode,
    setDarkMode,
} from "../../../redux-config/entities/dark-mode";
import { selectUser } from "../../../redux-config/entities/user";
import {
    selectLanguage,
    setLanguage,
} from "../../../redux-config/entities/language";
import { SideBarPart } from "../../sideBarPart";
import "../../../config/bootstrap/custom.scss";
import { hasPermission } from "../../../Router";

export const SiteLayout: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const darkMode = useAppSelector(selectDarkMode);
    const user = useAppSelector(selectUser);
    const language = useAppSelector(selectLanguage);

    const { i18n, t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            className="d-flex flex-column position-relative"
            style={{ height: "100vh" }}
            dir={language == "en" ? "ltr" : "rtl"}
        >
            <div className="d-flex flex-row w-100 shadow bg-third text-white p-2 justify-content-between align-items-center ">
                <div>
                    <strong>{t("safartic")}</strong>
                    <label
                        htmlFor="sidebarCheck"
                        className="sidebarLeftArrow  "
                        style={{ cursor: "pointer", zIndex: 10 }}
                    >
                        <FontAwesomeIcon
                            className="mx-3 text-white"
                            icon={faBars}
                        />
                    </label>
                </div>
                <div className="d-flex flex-row gap-2 align-items-center">
                    <div className="d-flex flex-row gap-2">
                        <span>{t("role")}:</span>
                        <strong className="fa-fade">{user.role}</strong>
                    </div>
                    <span
                        className="mx-3"
                        onClick={() => {
                            dispatch(setDarkMode(!darkMode));
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {darkMode ? (
                            <FontAwesomeIcon icon={faMoon} />
                        ) : (
                            <FontAwesomeIcon icon={faSun} />
                        )}
                    </span>
                    <select
                        onChange={(e) => {
                            if (e.target.value == "0") {
                                i18n.changeLanguage("en");
                            } else {
                                i18n.changeLanguage("ar");
                            }
                            dispatch(
                                setLanguage(
                                    e.target.value == "0" ? "en" : "ar",
                                ),
                            );
                        }}
                        defaultValue={language == "en" ? 0 : 1}
                        className="p-1 rounded bg-custom-white text-custom-dark"
                    >
                        <option value={0}>{t("english")}</option>
                        <option value={1}>{t("arabic")}</option>
                    </select>
                </div>
            </div>

            <div
                className="d-flex flex-row h-100 overflow-hidden  border-top "
                data-bs-theme={darkMode ? "dark" : "light"}
            >
                <input
                    id={"sidebarCheck"}
                    type="checkbox"
                    className="sidebarCheck d-none"
                    onChange={() => {
                        setIsSideBarOpen(!isSideBarOpen);
                    }}
                ></input>
                <div
                    className="d-flex flex-column bg-third pt-4 text-white sidebar h-100 pb-5 pb-md-1"
                    style={{ overflowY: "auto" }}
                >
                    <FontAwesomeIcon
                        className="mx-2 userImageSiteLayout"
                        size="3x"
                        icon={faUserCircle}
                    />
                    <span className="my-2 text-center userNameSiteLayout ">
                        {user?.first_name + " " + user?.last_name}
                    </span>
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [],
                            main: {
                                icon: faChartLine,
                                title: t("dashboard"),
                                link: "/",
                            },
                        }}
                    />
                    {hasPermission("admin-list", user) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [],
                                main: {
                                    icon: faUserFriends,
                                    title: t("adminUsers"),
                                    link: "/admin-user",
                                },
                            }}
                        />
                    )}
                    {hasPermission("user-list", user) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [],
                                main: {
                                    icon: faUsers,
                                    title: t("appUsers"),
                                    link: "/app-user",
                                },
                            }}
                        />
                    )}
                    {hasPermission("role-list", user) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [],
                                main: {
                                    icon: faUserLock,
                                    title: t("userAccess"),
                                    link: "/user-access",
                                },
                            }}
                        />
                    )}
                    {(hasPermission("address-list", user) ||
                        hasPermission("route-list", user) ||
                        hasPermission("port-list", user) ||
                        hasPermission("country-list", user) ||
                        hasPermission("province-list", user) ||
                        hasPermission("city-list", user)) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [
                                    {
                                        title: t("address"),
                                        link: "/definition/address/list",
                                    },
                                    {
                                        link: "/definition/country",
                                        title: t("country"),
                                    },
                                    {
                                        link: "/definition/province",
                                        title: t("province"),
                                    },
                                    {
                                        link: "/definition/city",
                                        title: t("city"),
                                    },
                                    {
                                        title: t("port"),
                                        link: "/definition/port/list",
                                    },
                                    {
                                        title: t("route"),
                                        link: "/definition/route/list",
                                    },
                                ],
                                main: {
                                    icon: faEarthAmerica,
                                    title: "Definition",
                                },
                            }}
                        />
                    )}

                    {(hasPermission("vehicle-list", user) ||
                        hasPermission("driver-list", user) ||
                        hasPermission("trip-list", user)) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [
                                    {
                                        link: "/vehicle/trip/list",
                                        title: t("trip"),
                                    },
                                    {
                                        link: "/vehicle/driver/list",
                                        title: t("driver"),
                                    },
                                    {
                                        link: "/vehicle/car/list",
                                        title: t("cars"),
                                    },
                                ],
                                main: { icon: faTaxi, title: t("riding") },
                            }}
                        />
                    )}
                    {(hasPermission("accommodation-list", user) ||
                        hasPermission(
                            "withdrawal-accommodation-list",
                            user,
                        )) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [
                                    {
                                        title: t("accommodation"),
                                        link: "/hotel/accommodation/list",
                                    },
                                    {
                                        title: t("withdrawal"),
                                        link: "/hotel/withdrawal",
                                    },
                                ],
                                main: {
                                    icon: faHotel,
                                    title: t("hotel"),
                                },
                            }}
                        />
                    )}
                    {/* {hasPermission("flight-list", user) && ( */}
                    <SideBarPart
                        data={{
                            isSideBarOpen: isSideBarOpen,
                            children: [
                                {
                                    title: t("commission"),
                                    link: "/airplane/commission",
                                },
                            ],
                            main: {
                                icon: faPlane,
                                title: t("airplane"),
                            },
                        }}
                    />
                    {(hasPermission("accommodation-booking-report", user) ||
                        hasPermission("flight-list", user)) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [
                                    {
                                        link: "/report/hotel",
                                        title: t("hotel"),
                                    },
                                    {
                                        title: t("flight"),
                                        link: "/report/flight",
                                    },
                                ],
                                main: {
                                    icon: faList,
                                    title: t("reports"),
                                },
                            }}
                        />
                    )}
                    {/* )} */}
                    {(hasPermission("wallet-list", user) ||
                        hasPermission("withdrawal-list", user) ||
                        hasPermission("gateway-list", user)) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [
                                    {
                                        link: "/payment/wallet",
                                        title: t("wallets"),
                                    },
                                    {
                                        link: "/payment/gateway",
                                        title: t("gateway"),
                                    },
                                    {
                                        link: "/payment/withdrawal",
                                        title: t("withdrawal"),
                                    },
                                ],
                                main: {
                                    icon: faMoneyBill,
                                    title: t("payment"),
                                },
                            }}
                        />
                    )}
                    {hasPermission("price-currency-show", user) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [],
                                main: {
                                    icon: faMoneyCheckDollar,
                                    title: t("priceCurrency"),
                                    link: "/price-currency/list",
                                },
                            }}
                        />
                    )}
                    {hasPermission("audit-log-list", user) && (
                        <SideBarPart
                            data={{
                                isSideBarOpen: isSideBarOpen,
                                children: [],
                                main: {
                                    icon: faList,
                                    title: t("log"),
                                    link: "/log",
                                },
                            }}
                        />
                    )}
                    <div
                        onClick={() => {
                            Swal.fire({
                                text: String(t("messageLogout")),
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                cancelButtonText: String(t("cancel")),
                                confirmButtonText: String(
                                    t("buttonTextLogout"),
                                ),
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    eraseCookie("user");
                                    localStorage.clear();
                                    window.location.replace("/login");
                                }
                            });
                        }}
                        className="d-flex flex-row align-items-center sidePart p-2 exitPart"
                    >
                        <FontAwesomeIcon
                            icon={faDoorClosed}
                            className="mx-2 doorClose"
                        />
                        <FontAwesomeIcon
                            icon={faDoorOpen}
                            className="mx-2 doorOpen"
                        />
                        <span>{t("exit")}</span>
                    </div>
                </div>
                <div
                    style={{ overflowY: "auto" }}
                    className="w-100 p-2 bg-custom-light position-relative "
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
