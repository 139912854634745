import { UserAppType, PaginationType, userType, WalletType } from "../../DTO";
import { Api } from "../api";
import { convertToUser } from "../driver";
import { convertToPaginationType } from "../help";

export const FetchList = (
    user: userType,
    current_page: number,
    phoneNumber?: string,
) => {
    return new Promise<{ data: UserAppType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "users/all",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    phone_number: phoneNumber && phoneNumber,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToUserAppList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToUserAppList(data: any[]): UserAppType[] {
    const userList = data.map((item: any) => {
        return convertToUser(item);
    });
    return userList;
}
