import { userType } from "../../../DTO";
import { Api } from "../../api";

export const DeleteRoomType = (
    user: userType,
    accommodationId: number,
    roomTypeId: number,
) => {
    return new Promise<{ roomType: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/accommodation/${accommodationId}/room-type/${roomTypeId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    roomType: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
