import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { Formik } from "formik";

import { Loading, CustomBreadCrumb } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { AddressApi } from "../../../../api";
import { CityForm } from "../../../../form";
import { useAppNavigate } from "../../../../Router/hook";
import { useTranslation } from "react-i18next";

export const CityCreate = () => {
    const user = useAppSelector(selectUser);

    const [loading, setLoading] = useState(true);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("cityList")), href: "/definition/city" },
        { name: t("create"), href: "#" },
    ];

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("cityNew")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <h4 className="my-2">{t("createCity")}</h4>
                        <div className="d-flex flex-row flex-wrap">
                            <Formik<CityForm.FormValues>
                                validationSchema={CityForm.ValidationSchema(t)}
                                initialValues={{
                                    lat: "",
                                    lng: "",
                                    name: "",
                                    enName: "",
                                    provinceName: "",
                                    domainCode: "",
                                    provinceId: 0,
                                    status: "Active",
                                    statusFlight: "Active",
                                    statusAccommodation: "Active",
                                    statusCar: "Active",
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    AddressApi.CreateCity(user, {
                                        id: 0,
                                        domain_code: event.domainCode,
                                        latitude: event.lat,
                                        longitude: event.lng,
                                        name: event.name,
                                        name_en: event.enName,
                                        province_id: event.provinceId,
                                        status: event.status,
                                        statusAccommodation:
                                            event.statusAccommodation,
                                        statusCar: event.statusCar,
                                        statusFlight: event.statusFlight,
                                    })
                                        .then((data) => {
                                            setLoading(false);
                                            navigate("/definition/city");
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err.data.message,
                                                color: "#000",
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <CityForm.CityFormEdit
                                        submitTxt={String(t("submit"))}
                                        {...props}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
