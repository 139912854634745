import { AdminUserType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToAdmin } from "./list";

export const ShowAdmin = (user: userType, id: string) => {
    return new Promise<{ admin: AdminUserType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/show/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    admin: convertToAdmin(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
