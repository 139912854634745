import { PriceCurrencyType, userType } from "../../DTO";
import { Api } from "../api";

export const ShowPriceCurrency = (user: userType) => {
    return new Promise<{ priceCurrency: PriceCurrencyType[] }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `admin/price/currency`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        priceCurrency: convertToPriceCurrencyTypeList(
                            data.data,
                        ),
                    });
                })
                .catch((error: any) => {
                    return reject(error.message);
                });
        },
    );
};

export const convertToPriceCurrencyTypeList = (
    data: any[],
): PriceCurrencyType[] => {
    return data.map((element) => convertToPriceCurrencyType(element));
};

export const convertToPriceCurrencyType = (data: any): PriceCurrencyType => {
    let out: PriceCurrencyType = {
        baseCurrency: data?.base_currency,
        type: data?.type,
        quoteCurrency: data?.quote_currency,
        basePrice: data?.base_price,
        quotePrice: data?.quote_price,
        id: data?.id,
    };

    return out;
};
