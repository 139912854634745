import {
    OrderDrivingDriverType,
    OrderDrivingRouteCityType,
    OrderDrivingRouteType,
    OrderDrivingType,
    OrderDrivingVehicleType,
    OrderFlightCompanyType,
    OrderFlightPortType,
    OrderFlightType,
    OrderHotelTripDetailPlacementType,
    OrderHotelTripDetailRoomType,
    OrderHotelTripType,
    OrderHotelType,
    OrderPassengerType,
    OrderProductType,
    OrderSeatType,
    OrderType,
    OrderUserType,
    PaginationType,
    userType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const FetchList = (user: userType, current_page: number) => {
    return new Promise<{ data: OrderType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "order/get",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToOrderList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToOrderList(data: any[]): OrderType[] {
    const orderList = data.map((item: any) => {
        return convertToOrder(item);
    });
    return orderList;
}
export function convertToOrder(item: any): OrderType {
    const orderOb: OrderType = {
        id: item?.id,
        userId: item?.user_id,
        productId: item?.product_id,
        status: item?.status,
        price: item?.price,
        totalPrice: item?.total_price,
        discountCode: item?.discount_code,
        tripId: item?.trip_id,
        orderProduct: convertOrderProductList(item?.order_product),
        user: convertOrderUser(item?.user),
        flight: item?.flight ? convertOrderFlight(item?.flight) : null,
        driving: item?.driving ? convertOrderDriving(item?.driving) : null,
        hotel: item?.hotel ? convertOrderHotel(item?.hotel) : null,
    };
    return orderOb;
}

export const convertOrderProductList = (data: any[]): OrderProductType[] => {
    return data.map((element) => convertOrderProduct(element));
};

export const convertOrderProduct = (data: any): OrderProductType => {
    let out: OrderProductType = {
        id: data?.id,
        passengerId: data?.passenger_id,
        orderId: data?.order_id,
        seatId: data?.seat_id,
        passenger: convertOrderProductPassenger(data?.passenger),
        seat: data?.seat && convertOrderProductSeat(data?.seat),
    };

    return out;
};

export const convertOrderProductSeat = (data: any): OrderSeatType => {
    let out: OrderSeatType = {
        id: data?.id,
        vehicleId: data?.vehicle_id,
        seatModelId: data?.seat_model_id,
        num: data?.num,
        col: data?.col,
        row: data?.row,
        status: data?.status,
    };

    return out;
};

export const convertOrderProductPassenger = (data: any): OrderPassengerType => {
    let out: OrderPassengerType = {
        id: data?.id,
        status: data?.status,
        userId: data?.user_id,
        firstName: data?.first_name,
        lastName: data?.last_name,
        birth: data?.birth,
        age: data?.age,
        gender: data?.gender,
        type: data?.type,
        identificationNum: data?.identification_num,
        passNum: data?.pass_num,
        passOrigin: data?.pass_origin,
        passExpire: data?.pass_expire,
    };

    return out;
};

export const convertOrderUser = (data: any): OrderUserType => {
    let out: OrderUserType = {
        id: data?.id,
        firstName: data?.first_name,
        lastName: data?.last_name,
        phone: data?.phone,
        email: data?.email,
        code: data?.code,
        status: data?.status,
        avatar: data?.avatar,
        shebaNum: data?.sheba_num,
        bankCart: data?.bank_cart,
        identityCode: data?.identity_code,
        lastLogin: data?.last_login,
        phoneVerifiedAt: data?.phone_verified_at,
        rememberToken: data?.remember_token,
    };

    return out;
};

export const convertOrderFlight = (data: any): OrderFlightType => {
    let out: OrderFlightType = {
        id: data?.id,
        companyId: data?.company_id,
        timeIn: data?.time_in,
        timeOut: data?.time_out,
        class: data?.class,
        userId: data?.user_id,
        numberFlight: data?.number_flight,
        modelFlight: data?.model_flight,
        price: data?.price,
        priceChild: data?.price_child,
        remain: data?.remain,
        status: data?.status,
        company: convertOrderFlightCompany(data?.company),
        portIn: convertOrderFlightPort(data?.port_in),
        portOut: convertOrderFlightPort(data?.port_out),
    };

    return out;
};

export const convertOrderFlightCompany = (
    data: any,
): OrderFlightCompanyType => {
    let out: OrderFlightCompanyType = {
        name: data?.name,
        status: data?.status,
        type: data?.type,
        url: data?.url,
    };

    return out;
};

export const convertOrderFlightPort = (data: any): OrderFlightPortType => {
    let out: OrderFlightPortType = {
        cityId: data?.city_id,
        id: data?.id,
        name: data?.name,
        type: data?.type,
    };

    return out;
};

export const convertOrderDriving = (data: any): OrderDrivingType => {
    let out: OrderDrivingType = {
        driver: convertOrderDrivingDriver(data?.driver),
        duration: data?.duration,
        expireTime: data?.expire_time,
        id: data?.id,
        model: data?.model,
        portIn: convertOrderFlightPort(data?.port_in),
        portInId: data?.port_in_id,
        portOut: convertOrderFlightPort(data?.port_out),
        portOutId: data?.port_out_id,
        price: data?.price,
        priceFront: data?.price_front,
        remain: data?.remain,
        route: convertOrderDrivingRoute(data?.route),
        status: data?.status,
        seat: convertOrderProductSeatList(data?.seat),
        time: data?.time,
        vehicle: convertOrderDrivingRouteVehicle(data?.vehicle),
        vehicleId: data?.vehicle_id,
    };

    return out;
};

export const convertOrderDrivingDriver = (
    data: any,
): OrderDrivingDriverType => {
    let out: OrderDrivingDriverType = {
        color: data?.color,
        id: data?.id,
        licenceId: data?.licence_id,
        passportId: data?.passport_id,
        phone: data?.phone,
        plateNumber: data?.plate_number,
        status: data?.status,
        verified: data?.verified,
    };

    return out;
};

export const convertOrderDrivingRoute = (data: any): OrderDrivingRouteType => {
    let out: OrderDrivingRouteType = {
        cityIn: convertOrderDrivingRouteCity(data?.city_in),
        cityInId: data?.city_in_id,
        cityOut: convertOrderDrivingRouteCity(data?.city_out),
        cityOutId: data?.city_out_id,
        id: data?.id,
        max: data?.max,
        min: data?.min,
        price: data?.price,
        status: data?.status,
    };

    return out;
};

export const convertOrderDrivingRouteCity = (
    data: any,
): OrderDrivingRouteCityType => {
    let out: OrderDrivingRouteCityType = {
        domainCode: data?.domain_code,
        id: data?.id,
        latitude: data?.latitude,
        longitude: data?.longitude,
        name: data?.name,
        nameEn: data?.name_en,
    };

    return out;
};

export const convertOrderProductSeatList = (data: any[]): OrderSeatType[] => {
    return data.map((element) => convertOrderProductSeat(element));
};

export const convertOrderDrivingRouteVehicle = (
    data: any,
): OrderDrivingVehicleType => {
    let out: OrderDrivingVehicleType = {
        id: data?.id,
        model: data?.model,
        seatModelId: data?.seat_model_id,
        status: data?.status,
        title: data?.title,
    };

    return out;
};

export const convertOrderHotel = (data: any): OrderHotelType => {
    let out: OrderHotelType = {
        countPassenger: data?.count_passenger,
        endDate: data?.end_date,
        id: data?.id,
        price: data?.price,
        startDate: data?.start_date,
        status: data?.status,
        userId: data?.user_id,
        trip: convertOrderHotelTripList(data?.trip),
    };

    return out;
};

export const convertOrderHotelTripList = (
    data: any[],
): OrderHotelTripType[] => {
    return data.map((element) => convertOrderHotelTrip(element));
};

export const convertOrderHotelTrip = (data: any): OrderHotelTripType => {
    let out: OrderHotelTripType = {
        detail: {
            id: data?.detail?.id,
            placement: convertOrderHotelDetailPlacement(
                data?.detail?.placement,
            ),
            placementId: data?.detail?.placement_id,
            roomTypeId: data?.detail?.room_type_id,
            roomType: convertOrderHotelDetailRoom(data?.detail?.room_type),
            status: data?.detail?.status,
            userId: data?.detail?.user_id,
        },
    };

    return out;
};

export const convertOrderHotelDetailPlacement = (
    data: any,
): OrderHotelTripDetailPlacementType => {
    let out: OrderHotelTripDetailPlacementType = {
        addressId: data?.address_id,
        description: data?.description,
        id: data?.id,
        metaData: data?.meta_data,
        name: data?.name,
        policy: data?.policy,
        review: data?.review,
        star: data?.star,
        status: data?.status,
        userId: data?.user_id,
    };

    return out;
};

export const convertOrderHotelDetailRoom = (
    data: any,
): OrderHotelTripDetailRoomType => {
    let out: OrderHotelTripDetailRoomType = {
        description: data?.description,
        id: data?.id,
        name: data?.name,
    };

    return out;
};
