import { Api } from "../../api";
import { PaginationType, RouteType, userType } from "../../../DTO";
import { convertToRoute } from "../trip_list";
import { convertToPaginationType } from "../../help";

export const RouteList = (
    user: userType,
    currentPage?: number,
    routeName?: string,
) => {
    return new Promise<{ route: RouteType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/routes",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                    city_in: routeName,
                    city_out: routeName,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        route: convertToRouteList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    });
                })
                .catch((error) => reject(error));
        },
    );
};

export const convertToRouteList = (data: any[]) => {
    return data.map((element) => convertToRoute(element));
};
