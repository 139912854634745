import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { DiscountForm } from "../../form";
import { CustomBreadCrumb, Loading } from "../../components";
import { DiscountApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { useAppNavigate } from "../../Router/hook";
import { useParams } from "react-router-dom";
import { DiscountType } from "../../DTO";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const EditDiscountCode: FC = () => {
    const [discount, setDiscount] = useState<DiscountType>({
        id: 0,
        name: "",
        code: "",
        userId: 0,
        expireTime: "",
        amount: 0,
        model: "",
        deletedAt: "",
        createdAt: "",
        updatedAt: "",
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: t("discountCode"), href: "/discount-code" },
        { name: t("edit"), href: "#" },
    ];

    const { id } = useParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    useEffect(() => {
        setLoading(true);
        DiscountApi.ShowDiscount(user, String(id))
            .then((data) => {
                setDate(data.discountCode.expireTime);
                setDiscount(data.discountCode);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    }, []);

    const editDiscountCode = (
        code: number,
        name: string,
        expireTime: string,
        amount: number,
    ) => {
        setLoading(true);
        DiscountApi.EditDiscount(
            user,
            code,
            name,
            expireTime,
            amount,
            String(id),
        )
            .then((data) => {
                navigate("/discount-code");
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("discountCode")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {discount.name.length > 0 && (
                        <Formik<DiscountForm.FormValues>
                            validationSchema={DiscountForm.ValidationSchema(t)}
                            initialValues={{
                                name: discount.name,
                                amount: Number(discount.amount),
                                code: Number(discount.code),
                                expireTime: date,
                            }}
                            onSubmit={(event) => {
                                editDiscountCode(
                                    event.code,
                                    event.name,
                                    date,
                                    event.amount,
                                );
                            }}
                            component={(props: any) => (
                                <DiscountForm.DiscountCodeFormCreate
                                    submitTxt={String(t("edit"))}
                                    setDate={setDate}
                                    date={date}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
