// @ts-ignore
import { FC } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { FilePondFile } from "filepond";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { useAppLocation } from "../../Router/hook";
import { useTranslation } from "react-i18next";

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateSize,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateType,
    FilePondPluginFileEncode,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
);

export const Content: FC<{
    contents: File[];
    setContents: Function;
}> = ({ contents, setContents }) => {
    const handleUpdateContent = (fileItems: FilePondFile[]) => {
        setContents(fileItems.map((fileItem) => fileItem.file));
    };

    const { pathname } = useAppLocation();

    const { t } = useTranslation();

    return (
        <FilePond
            required={pathname.includes("new")}
            allowMultiple
            allowReorder
            allowFileEncode
            allowImagePreview
            allowImageCrop
            allowDrop
            allowImageEdit
            allowImageResize
            allowImageTransform
            acceptedFileTypes={["image/jpeg, image/png, image/jpg"]}
            credits={false}
            files={contents}
            onupdatefiles={(fileItem) => handleUpdateContent(fileItem)}
            dropOnPage
            dropValidation
            name="files"
            labelIdle={`${String(
                t("contentLabel"),
            )} <span class="filepond--label-action">Browse</span>`}
        />
    );
};
