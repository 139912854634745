import { BookingType, PaginationType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";
import { convertToBookingTypeList } from "./list";

export const ReportBooking = (
    user: userType,
    currentPage: number,
    accommodationId?: number,
    status?: "finalized" | "reserved" | "not_confirmed" | "canceled" | string,
    fromDate?: string,
    toDate?: string,
) => {
    return new Promise<{
        report: BookingType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/booking/report`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
                accommodation_id: accommodationId,
                status,
                from_date: fromDate,
                to_date: toDate,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    report: convertToBookingTypeList(data?.data?.data),
                    pagination: convertToPaginationType(data?.meta),
                }),
            )
            .catch((err) => {
                console.log(err);
                return reject(err.data.message);
            });
    });
};
