import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import DatePicker from "react-multi-date-picker";
import gregorian_en from "react-date-object/locales/gregorian_en";
import gregorian from "react-date-object/calendars/gregorian";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Swal from "sweetalert2";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";
import { convertDateFormat } from "./logic";
import { useAppLocation } from "../../Router/hook";
import { DriverType, PortType, RouteType } from "../../DTO";
import { DriverApi, PortApi, TripApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { Shimmer } from "../../components";

export const TripFormCreate = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        setDate,
        date,
    } = props;
    const [routeName, setRouteName] = useState(values.routeName);
    const [routeList, setRouteList] = useState<RouteType[]>([]);
    const [selectedRoute, setSelectedRoute] = useState(values.routeId);
    const [routeLoading, setRouteLoading] = useState<boolean>(false);

    const [driverName, setDriverName] = useState(values.driverName);
    const [driverList, setDriverList] = useState<DriverType[]>([]);
    const [selectedDriver, setSelectedDriver] = useState(values.driverId);
    const [driverLoading, setDriverLoading] = useState<boolean>(false);

    const [portInName, setPortInName] = useState(values.portInName);
    const [portInList, setPortInList] = useState<PortType[]>([]);
    const [selectedPortIn, setSelectedPortIn] = useState(values.portInId);
    const [portInLoading, setPortInLoading] = useState<boolean>(false);

    const [portOutName, setPortOutName] = useState(values.portOutName);
    const [portOutList, setPortOutList] = useState<PortType[]>([]);
    const [selectedPortOut, setSelectedPortOut] = useState(values.portOutId);
    const [portOutLoading, setPortOutLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const fetchRouteList = (query: string) => {
        TripApi.RouteApi.RouteList(user, 1, query)
            .then((data) => {
                setRouteList(data.route);
                setRouteLoading(false);
            })
            .catch((err) => {
                setRouteLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchPortInList = (query: string) => {
        PortApi.ListPort(user, 1, query)
            .then((data) => {
                setPortInList(data.port);
                setPortInLoading(false);
            })
            .catch((err) => {
                setPortInLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchPortOutList = (query: string) => {
        PortApi.ListPort(user, 1, query)
            .then((data) => {
                setPortOutList(data.port);
                setPortOutLoading(false);
            })
            .catch((err) => {
                setPortOutLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchDriverList = (query: string) => {
        DriverApi.FetchList(user, 1, query)
            .then((data) => {
                setDriverList(data.data);
                setDriverLoading(false);
            })
            .catch((err) => {
                setDriverLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const { pathname } = useAppLocation();

    useEffect(() => {
        values.portInId = selectedPortIn;
    }, [selectedPortIn]);

    useEffect(() => {
        values.portInName = portInName;
    }, [portInName]);

    const throttledSearchPortIn = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchPortInList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchPortIn.cancel();
        };
    }, [throttledSearchPortIn]);

    useEffect(() => {
        values.portOutId = selectedPortOut;
    }, [selectedPortOut]);

    useEffect(() => {
        values.portOutName = portOutName;
    }, [portOutName]);

    const throttledSearchPortOut = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchPortOutList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchPortOut.cancel();
        };
    }, [throttledSearchPortOut]);

    useEffect(() => {
        values.routeId = selectedRoute;
    }, [selectedRoute]);

    useEffect(() => {
        values.routeName = routeName;
    }, [routeName]);

    const throttledSearchRoute = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchRouteList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchRoute.cancel();
        };
    }, [throttledSearchRoute]);

    useEffect(() => {
        values.driverId = selectedDriver;
    }, [selectedDriver]);

    useEffect(() => {
        values.driverName = driverName;
    }, [driverName]);

    const throttledSearchDriver = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchDriverList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchDriver.cancel();
        };
    }, [throttledSearchDriver]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="trip-date"
                        className="position-relative h-3 d-flex flex-column col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("time")}</Form.Label>
                        <DatePicker
                            minDate={new Date().setDate(
                                new Date().getDate() + 1,
                            )}
                            calendarPosition={"bottom-center"}
                            inputClass={"p-1 rounded w-100"}
                            value={date}
                            onChange={(array: any) => {
                                setDate(convertDateFormat(array.toString?.()));
                            }}
                            calendar={gregorian}
                            locale={gregorian_en}
                            format="YYYY/MM/DD HH:mm"
                            plugins={[<TimePicker position="bottom" />]}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="trip-driver-id"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("driverPhone")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="driverName"
                            type="string"
                            name="driverName"
                            onFocus={() => {
                                setDriverLoading(true);
                                fetchDriverList(driverName);
                            }}
                            placeholder={String(t("driverPhonePlaceholder"))}
                            disabled={pathname.includes("/vehicle/trip/edit")}
                            onBlur={handleBlur}
                            value={driverName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setDriverName(e.target.value);
                                throttledSearchDriver(e);
                            }}
                            isInvalid={
                                touched.driverName && !!errors.driverName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {driverList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setDriverList([])}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {driverList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 g-cursor-pointer w-100 border-bottom text-center"
                                            onClick={() => {
                                                setSelectedDriver(
                                                    Number(item.id),
                                                );
                                                setDriverName(
                                                    `${item.user?.first_name} ${item.user?.last_name} <---> ${item.phone}`,
                                                );
                                                setDriverList([]);
                                            }}
                                        >
                                            {`${item.user?.first_name} ${item.user?.last_name} <---> ${item.phone}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                driverLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="position-absolute w-100 text-center border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.driverName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-route-id"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("routeName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="routeName"
                            onFocus={() => {
                                setRouteLoading(true);
                                fetchRouteList("");
                            }}
                            disabled={pathname.includes("/vehicle/trip/edit")}
                            type="string"
                            name="routeName"
                            placeholder={String(t("routeNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={routeName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setRouteName(e.target.value);
                                throttledSearchRoute(e);
                            }}
                            isInvalid={touched.routeName && !!errors.routeName}
                        />
                        <div className={"position-relative col-12"}>
                            {routeList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setRouteList([])}
                                    className="position-absolute rounded-2 overflow-auto border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {routeList.map((item, index) => (
                                        <strong
                                            key={index}
                                            className="p-3 g-cursor-pointer text-center w-100 border-bottom"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedRoute(
                                                    Number(item.id),
                                                );
                                                setRouteName(
                                                    `${item.city_in?.name} ---> ${item.city_out?.name}`,
                                                );
                                                setRouteList([]);
                                            }}
                                        >
                                            {`${item.city_in?.name} ---> ${item.city_out?.name}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                routeLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center w-100 position-absolute border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.routeName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-port-in"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("portInName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="portInName"
                            type="string"
                            name="portInName"
                            onFocus={() => {
                                setPortInLoading(true);
                                fetchPortInList("");
                            }}
                            placeholder={String(t("portInNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={portInName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setPortInName(e.target.value);
                                throttledSearchPortIn(e);
                            }}
                            isInvalid={
                                touched.portInName && !!errors.portInName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {portInList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setPortInList([])}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {portInList.map((item, index) => (
                                        <strong
                                            key={index}
                                            className="p-3 text-center border-bottom w-100 g-cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedPortIn(
                                                    Number(item.id),
                                                );
                                                setPortInName(
                                                    `${item.name} | ${item.city?.name}`,
                                                );
                                                setPortInList([]);
                                            }}
                                        >
                                            {`${item.name} | ${item.city?.name}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                portInLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="position-absolute w-100 text-center border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.portInName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-port-out"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("portOutName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="portOutName"
                            type="string"
                            name="portOutName"
                            onFocus={() => {
                                setPortOutLoading(true);
                                fetchPortOutList("");
                            }}
                            placeholder={String(t("portOutNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={portOutName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setPortOutName(e.target.value);
                                throttledSearchPortOut(e);
                            }}
                            isInvalid={
                                touched.portOutName && !!errors.portOutName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {portOutList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setPortOutList([])}
                                    className="position-absolute border rounded-2 overflow-auto border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {portOutList.map((item, index) => (
                                        <strong
                                            key={index}
                                            className="p-3 text-center w-100 border-bottom g-cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedPortOut(
                                                    Number(item.id),
                                                );
                                                setPortOutName(
                                                    `${item.name} | ${item.city?.name}`,
                                                );
                                                setPortOutList([]);
                                            }}
                                        >
                                            {`${item.name} | ${item.city?.name}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                portOutLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="position-absolute w-100 text-center border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.portOutName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-price"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("price")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="price"
                            className="h3"
                            type="number"
                            name="price"
                            placeholder={String(t("pricePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.price}
                            onChange={handleChange}
                            isInvalid={touched.price && !!errors.price}
                        />
                        <Form.Text className="text-danger">
                            {errors.price}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-price"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("priceFront")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="price-front"
                            className="h3"
                            type="number"
                            name="priceFront"
                            placeholder={String(t("priceFrontPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.priceFront}
                            onChange={handleChange}
                            isInvalid={
                                touched.priceFront && !!errors.priceFront
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.priceFront}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-duration"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("duration")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="duration"
                            className="h3"
                            type="number"
                            name="duration"
                            placeholder={String(t("durationPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.duration}
                            onChange={handleChange}
                            isInvalid={touched.duration && !!errors.duration}
                        />
                        <Form.Text className="text-danger">
                            {errors.duration}
                        </Form.Text>
                    </Form.Group>

                    {pathname.includes("/vehicle/trip/edit/") && (
                        <Form.Group
                            controlId="route-status"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>{t("status")}</Form.Label>
                            <Form.Select
                                aria-label="status"
                                className="h3"
                                name="status"
                                onBlur={handleBlur}
                                value={values.status}
                                onChange={handleChange}
                                isInvalid={touched.status && !!errors.status}
                            >
                                <option className="d-none">
                                    {t("placeholderStatus")}
                                </option>
                                <option
                                    value={"Active"}
                                    className="text-success"
                                >
                                    {t("active")}
                                </option>
                                <option
                                    value={"Pending"}
                                    className="text-warning"
                                >
                                    {t("pending")}
                                </option>
                                <option
                                    value={"Completed"}
                                    className="text-success"
                                >
                                    {t("completed")}
                                </option>
                                <option
                                    value={"Cancelled"}
                                    className="text-danger"
                                >
                                    {t("canceled")}
                                </option>
                                <option
                                    value={"Rejected"}
                                    className="text-danger"
                                >
                                    {t("rejected")}
                                </option>
                                <option
                                    value={"Failed"}
                                    className="text-danger"
                                >
                                    {t("failed")}
                                </option>
                            </Form.Select>
                            <Form.Text className="text-danger">
                                {errors.status}
                            </Form.Text>
                        </Form.Group>
                    )}
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={date.length === 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
