import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { ProvinceType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { Formik } from "formik";
import { ProvinceForm } from "../../../../form";
import { useAppNavigate } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
export const ProvinceEdit = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [provinceOB, setProvinceOB] = useState<ProvinceType>();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("province"), href: "/definition/province" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];
    const navigate = useAppNavigate();

    useEffect(() => {
        fetchProvinceOB();
    }, [id]);

    const fetchProvinceOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchProvinceId(user, id)
                .then((data) => {
                    setProvinceOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} |{t("province")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <h4 className="my-2">{t("provinceEdit")}</h4>
                        {provinceOB && (
                            <div className="d-flex flex-row flex-wrap">
                                <Formik<ProvinceForm.FormValues>
                                    validationSchema={ProvinceForm.ValidationSchemaEdit(
                                        t,
                                    )}
                                    initialValues={{
                                        status: provinceOB.status,
                                        name:
                                            provinceOB.name != null
                                                ? provinceOB.name
                                                : "",
                                        enName:
                                            provinceOB.name_en != null
                                                ? provinceOB.name_en
                                                : "",
                                        countryName: provinceOB?.country?.name,
                                        domainCode:
                                            provinceOB.domain_code != null
                                                ? provinceOB.domain_code
                                                : "",
                                        countryId:
                                            provinceOB.country?.id != null
                                                ? provinceOB.country?.id
                                                : 0,
                                    }}
                                    onSubmit={(event) => {
                                        if (id) {
                                            setLoading(true);
                                            AddressApi.EditProvince(
                                                user,
                                                {
                                                    id: 0,
                                                    domain_code:
                                                        event.domainCode,
                                                    name: event.name,
                                                    name_en: event.enName,
                                                    country: {
                                                        id: event.countryId,
                                                        domain_code: "",
                                                        name: "",
                                                        name_en: "",
                                                        status: "Active",
                                                    },
                                                    status: event.status,
                                                },
                                                id,
                                            )
                                                .then((data) => {
                                                    setLoading(false);
                                                    navigate(
                                                        "/definition/province",
                                                    );
                                                })
                                                .catch((err) => {
                                                    setLoading(false);
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: err.data.message,
                                                    });
                                                });
                                        }
                                    }}
                                    component={(props: any) => (
                                        <ProvinceForm.ProvinceFormEdit
                                            submitTxt={t("submit")}
                                            {...props}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
