import { OrderChartType, userType } from "../../DTO";
import { Api } from "../api";

export const OrderChart = (user: userType) => {
    return new Promise<{ chart: OrderChartType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "order/chart",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({ chart: castOrderChart(data.data) });
            })
            .catch((error) => reject(error));
    });
};

export const castOrderChart = (data: any): OrderChartType => {
    let out: OrderChartType = {
        amount: data.amount,
        date: data.date,
        totalDriver: data.total_driver,
        totalOrder: data.total_order,
        totalSuccess: data.total_success,
        totalUser: data.total_user,
    };

    return out;
};
