//@ts-nocheck

import { useState, useEffect, useRef, useCallback } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

function LeafletgeoSearch() {
    const map = useMap();
    useEffect(() => {
        const provider = new OpenStreetMapProvider();

        const searchControl = new GeoSearchControl({
            provider,
            marker: {
                icon,
            },
        });

        map.addControl(searchControl);

        return () => map.removeControl(searchControl);
    }, []);

    return null;
}

export function ChangeView({ handleMapMove, handleMapMoveEnd }) {
    const map = useMap();
    const onMove = useCallback(() => {
        handleMapMove(map.getCenter());
    }, [map]);

    const onMoveEnd = useCallback(() => {
        handleMapMoveEnd(map.getCenter());
    }, [map]);

    useEffect(() => {
        map.on("move", onMove);
        map.on("moveend", onMoveEnd);
        return () => {
            map.off("move", onMove);
            map.off("moveend", onMoveEnd);
        };
    }, [map, onMove]);

    return null;
}

export default function CustomMap({ markerChange, location, height }) {
    const [geoData, setGeoData] = useState({ lat: 36.1901, lng: 43.993 });
    const markerRef = useRef(null);
    const center = [geoData.lat, geoData.lng];

    useEffect(() => {
        if (location) {
            setGeoData({ lat: location.lat, lng: location.lng });
        }
    }, []);

    const handleMapMove = (data) => {
        setGeoData({ lat: data.lat, lng: data.lng });
    };
    const handleMapMoveEnd = (data) => {
        markerChange({ lat: data.lat, lng: data.lng });
    };

    return (
        <MapContainer
            center={location ? location : geoData}
            zoom={7}
            style={{ height: height, zIndex: 1 }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {geoData.lat && geoData.lng && (
                <Marker
                    ref={markerRef}
                    position={[geoData.lat, geoData.lng]}
                    icon={icons}
                />
            )}
            <LeafletgeoSearch />
            <ChangeView
                handleMapMove={handleMapMove}
                handleMapMoveEnd={handleMapMoveEnd}
            />
        </MapContainer>
    );
}

const icon = L.icon({
    iconSize: [0, 0],
    iconAnchor: [0, 0],
    popupAnchor: [0, 0],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
});

const icons = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
