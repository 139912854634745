import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";

const StarRating: FC<{ rating: number; setRating?: Function }> = ({
    rating,
    setRating,
}) => {
    const [hover, setHover] = useState(0);

    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        key={index}
                        className={
                            index <= (hover || rating)
                                ? "text-warning mx-lg-1"
                                : "mx-lg-1"
                        }
                        fontSize={24}
                        onClick={() => setRating && setRating(index)}
                        onMouseEnter={() => setRating && setHover(index)}
                        onMouseLeave={() => setRating && setHover(rating)}
                        icon={rating > 0 ? faStar : faStarEmpty}
                    />
                );
            })}
        </div>
    );
};

export default StarRating;
