import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelForm } from "../../../form";
import { HotelApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { useTranslation } from "react-i18next";

export const HotelRatePlanCreate: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const { id, roomId } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/list`,
        },
        { name: `Create`, href: "#" },
    ];

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("ratePlan")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("create")}</h4>
                        <Formik<HotelForm.RatePlanForm.FormValues>
                            validationSchema={HotelForm.RatePlanForm.ValidationSchema(
                                t,
                            )}
                            initialValues={{
                                name: "",
                                description: "",
                                code: "",
                                discount: "",
                                price: "",
                            }}
                            onSubmit={(event) => {
                                setLoading(true);
                                HotelApi.RatePlanApi.CreateRatePlan(
                                    user,
                                    Number(id),
                                    Number(roomId),
                                    event.name,
                                    event.code,
                                    Number(event.price),
                                    event.description,
                                    Number(event.discount),
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate(
                                            `/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/list`,
                                        );
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <HotelForm.RatePlanForm.HotelRatePlan
                                    submitTxt={t("submit")}
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
