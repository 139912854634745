import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./style.scss";

export const CustomBreadCrumb: React.FC<{
    breadCrumbList: { name: string; href: string }[];
}> = ({ breadCrumbList }) => {
    return (
        <Breadcrumb>
            {breadCrumbList.map((item, index) => (
                <Link to={item.href} className="breadcrumb-item mx-1">
                    {item.name}
                </Link>
            ))}
        </Breadcrumb>
    );
};
