import {
    AirportAirItineraryPricingDetailsType,
    AirportAirItineraryPricingInfoItinTotalFareType,
    AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerFareType,
    AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerTypeQuantityType,
    AirportAirItineraryPricingInfoPTCFareBreakdownsType,
    AirportAirItineraryPricingInfoType,
    AirportAirItineraryType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentBaggageAllowanceType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentEquipmentType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingCabinType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentTPAExtensionsType,
    AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType,
    AirportOriginDestinationInformationOriginDestinationOptionTPAExtensionsType,
    AirportOriginDestinationInformationOriginDestinationOptionType,
    AirportOriginDestinationInformationType,
    AirportType,
    BookingInfoContractType,
    BookingInfoMetaDataContractInfoType,
    BookingInfoMetaDataItemsItemsAirReservationTicketingType,
    BookingInfoMetaDataItemsItemsAirReservationType,
    BookingInfoMetaDataItemsItemsBookingReferenceIDType,
    BookingInfoMetaDataItemsItemsType,
    BookingInfoMetaDataItemsType,
    BookingInfoMetaDataType,
    BookingInfoPassengersType,
    BookingInfoType,
    PaginationType,
    userType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const BookingListAirport = (user: userType, currentPage: number) => {
    return new Promise<{ book: BookingInfoType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/flight/booking",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) =>
                    resolve({
                        book: convertToBookingInfoTypeList(data.data),
                        pagination: convertToPaginationType(data.meta),
                    }),
                )
                .catch((err) => reject(err.data.message));
        },
    );
};

export const convertToBookingInfoTypeList = (
    data: any[],
): BookingInfoType[] => {
    return data.map((element) => convertToBookingInfoType(element));
};

export const convertToBookingInfoType = (data: any): BookingInfoType => {
    let out: BookingInfoType = {
        id: data?.id,
        userId: data?.user_id,
        status: data?.status,
        bookingId: data?.booking_id,
        reservedUntil: data?.reserved_until,
        contractNo: data?.contract_no,
        amount: data?.amount,
        currency: data?.currency,
        contactsEmail: data?.contacts_email,
        contactsCountryAccessCode: data?.contacts_country_access_code,
        contactsPhoneNumber: data?.contacts_phone_number,
        sessionId: data?.session_id,
        combinationId: data?.combination_id,
        recommendationId: data?.recommendation_id,
        subsystemId: data?.subsystem_id,
        subsystemName: data?.subsystem_name,
        currencyToPay: data?.currency_to_pay,
        apiRef: data?.api_ref,
        passengers: convertToBookingInfoPassengersTypeList(data?.passengers),
        metaData: convertToBookingPassengersMetaDataType(data?.meta_data),
        contract: convertToBookingInfoContractTypeList(
            data?.contract[0]?.Invoices[0]?.Services,
        ),
        flightDetail: data?.flight_details,
    };

    return out;
};

export const convertToBookingPassengersMetaDataTypeList = (
    data: any[],
): BookingInfoMetaDataType[] => {
    return data.map((element) =>
        convertToBookingPassengersMetaDataType(element),
    );
};

export const convertToBookingPassengersMetaDataType = (
    data: any,
): BookingInfoMetaDataType => {
    let out: BookingInfoMetaDataType = {
        BookId: data?.BookId,
        ContractInfo: convertToBookingPassengersMetaDataContractInfoType(
            data?.ContractInfo,
        ),
        Items: convertToBookingPassengersItemsTypeList(data?.Items),
    };

    return out;
};

export const convertToBookingPassengersMetaDataContractInfoType = (
    data: any,
): BookingInfoMetaDataContractInfoType => {
    let out: BookingInfoMetaDataContractInfoType = {
        TTL: data?.TTL,
        TwoPhase: data?.TwoPhase,
        ContractNo: data?.ContractNo,
        Amount: data?.Amount,
        Currency: data?.Currency,
    };

    return out;
};

export const convertToBookingPassengersItemsTypeList = (
    data: any[],
): BookingInfoMetaDataItemsType[] => {
    return data.map((element) => convertToBookingPassengersItemsType(element));
};

export const convertToBookingPassengersItemsItemsType = (
    data: any,
): BookingInfoMetaDataItemsItemsType => {
    let out: BookingInfoMetaDataItemsItemsType = {
        AirReservation: convertToBookingPassengersItemsItemsAirReservationType(
            data?.AirReservation,
        ),
        AirItineraryPricingInfo: convertToAirportAirItineraryPricingInfoType(
            data?.AirItineraryPricingInfo,
        ),
    };

    return out;
};

export const convertToBookingPassengersItemsItemsAirReservationType = (
    data: any,
): BookingInfoMetaDataItemsItemsAirReservationType => {
    let out: BookingInfoMetaDataItemsItemsAirReservationType = {
        BookingReferenceID:
            convertToBookingPassengersItemsItemsAirReservationBookingReferenceIDType(
                data?.BookingReferenceID,
            ),
        Ticketing:
            convertToBookingPassengersItemsItemsAirReservationTicketingType(
                data?.Ticketing,
            ),
    };

    return out;
};

export const convertToBookingPassengersItemsItemsAirReservationBookingReferenceIDType =
    (data: any): BookingInfoMetaDataItemsItemsBookingReferenceIDType => {
        let out: BookingInfoMetaDataItemsItemsBookingReferenceIDType = {
            TicketTimeLimit: data?.TicketTimeLimit,
            TicketType: data?.TicketType,
        };

        return out;
    };

export const convertToBookingPassengersItemsItemsAirReservationTicketingType = (
    data: any,
): BookingInfoMetaDataItemsItemsAirReservationTicketingType => {
    let out: BookingInfoMetaDataItemsItemsAirReservationTicketingType = {
        Type: data?.Type,
        IDContext: data?.ID_Context,
    };

    return out;
};

export const convertToBookingPassengersItemsType = (
    data: any,
): BookingInfoMetaDataItemsType => {
    let out: BookingInfoMetaDataItemsType = {
        Success: data?.Success,
        Items: convertToBookingPassengersItemsItemsType(data?.Items),
        AirItinerary: convertToAirportAirItineraryType(data?.AirItinerary),
        serviceId: data?.serviceId,
    };

    return out;
};

export const convertToBookingInfoContractTypeList = (
    data: any[],
): BookingInfoContractType[] => {
    return data.map((element) =>
        convertToBookingInfoContractType(element?.Data?.Item),
    );
};

export const convertToBookingInfoContractType = (
    data: any,
): BookingInfoContractType => {
    let out: BookingInfoContractType = {
        airItineraryPricingInfo: convertToAirportAirItineraryPricingInfoType(
            data?.AirItineraryPricingInfo,
        ),
        originDestinationInformation:
            convertToAirportOriginDestinationInformationType(
                data?.OriginDestinationInformation,
            ),
    };

    return out;
};

export const convertToBookingInfoPassengersTypeList = (
    data: any[],
): BookingInfoPassengersType[] => {
    return data.map((element) => convertToBookingPassengersType(element));
};

export const convertToBookingPassengersType = (
    data: any,
): BookingInfoPassengersType => {
    let out: BookingInfoPassengersType = {
        id: data?.id,
        travelerGivenName: data?.traveler_given_name,
        travelerNamePrefix: data?.traveler_name_prefix,
        travelerSurname: data?.traveler_surname,
        travelerDocId: data?.traveler_doc_id,
        travelerDocIssueCountry: data?.traveler_doc_issue_country,
        travelerExpireDate: data?.traveler_expire_date,
        travelerInnerDocType: data?.traveler_inner_doc_type,
        travelerNationalId: data?.traveler_national_id,
        travelerBirthDate: data?.traveler_birth_date,
        travelerPassengerTypeCode: data?.traveler_passenger_type_code,
        travelerPassengerEmail: data?.traveler_passenger_email,
        apiRef: data?.api_ref,
        metaData: convertToBookingPassengersItemsTypeList(data?.meta_data),
    };

    return out;
};

export const convertToAirportTypeList = (data: any[]): AirportType[] => {
    return data.map((element) => convertToAirportType(element));
};

export const convertToAirportType = (data: any): AirportType => {
    let out: AirportType = {
        airItinerary: convertToAirportAirItineraryTypeList(data?.AirItinerary),
        airItineraryPricingInfo: convertToAirportAirItineraryPricingInfoType(
            data?.AirItineraryPricingInfo,
        ),
        airItineraryPricingDetails:
            convertToAirportAirItineraryPricingDetailsTypeList(
                data?.AirItineraryPricingInfo?.AirItineraryPricingDetails,
            ),
        originDestinationInformation:
            convertToAirportOriginDestinationInformationType(
                data?.OriginDestinationInformation,
            ),
    };

    return out;
};

export const convertToAirportAirItineraryTypeList = (
    data: any[],
): AirportAirItineraryType[] => {
    return data.map((element) => convertToAirportAirItineraryType(element));
};

export const convertToAirportAirItineraryPricingDetailsTypeList = (
    data: any[],
): AirportAirItineraryPricingDetailsType[] => {
    return data.map((element) =>
        convertToAirportAirItineraryPricingDetailsType(element),
    );
};

export const convertToAirportAirItineraryType = (
    data: any,
): AirportAirItineraryType => {
    let out: AirportAirItineraryType = {
        sessionId: data?.SessionId,
        combinationId: data?.CombinationId,
        recommendationId: data?.RecommendationId,
        subsystemId: data?.SubsystemId,
        subsystemName: data?.SubsystemName,
    };

    return out;
};

export const convertToAirportAirItineraryPricingInfoType = (
    data: any,
): AirportAirItineraryPricingInfoType => {
    let out: AirportAirItineraryPricingInfoType = {
        itinTotalFare: convertToAirportAirItineraryPricingInfoItinTotalFareType(
            data?.ItinTotalFare,
        ),
        ptcFareBreakdowns:
            convertToAirportAirItineraryPricingInfoPtcFareBreakdownsTypeList(
                data?.PTC_FareBreakdowns,
            ),
    };

    return out;
};

export const convertToAirportAirItineraryPricingInfoPtcFareBreakdownsTypeList =
    (data: any[]): AirportAirItineraryPricingInfoPTCFareBreakdownsType[] => {
        return data.map((element) =>
            convertToAirportAirItineraryPricingInfoPtcFareBreakdownsType(
                element,
            ),
        );
    };

export const convertToAirportAirItineraryPricingInfoItinTotalFareType = (
    data: any,
): AirportAirItineraryPricingInfoItinTotalFareType => {
    let out: AirportAirItineraryPricingInfoItinTotalFareType = {
        baseFare: data?.BaseFare,
        currency: data?.Currency,
        serviceTax: data?.ServiceTax,
        totalFare: data?.TotalFare,
        original: data?.Original,
        totalCommission: data?.TotalCommission,
        totalTax: data?.TotalTax,
    };

    return out;
};

export const convertToAirportAirItineraryPricingInfoPtcFareBreakdownsType = (
    data: any,
): AirportAirItineraryPricingInfoPTCFareBreakdownsType => {
    let out: AirportAirItineraryPricingInfoPTCFareBreakdownsType = {
        passengerFare:
            convertToAirportAirItineraryPricingInfoPtcFareBreakdownsPassengerFareType(
                data?.PassengerFare,
            ),
        passengerTypeQuantity:
            convertToAirportAirItineraryPricingInfoPtcFareBreakdownsPassengerTypeQuantityType(
                data?.PassengerTypeQuantity,
            ),
    };

    return out;
};

export const convertToAirportAirItineraryPricingInfoPtcFareBreakdownsPassengerFareType =
    (
        data: any,
    ): AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerFareType => {
        let out: AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerFareType =
            {
                baseFare: data?.BaseFare,
                currency: data?.Currency,
                serviceTax: data?.ServiceTax,
                totalFare: data?.TotalFare,
                taxes: data?.Taxes,
                commission: data?.Commission,
            };

        return out;
    };

export const convertToAirportAirItineraryPricingInfoPtcFareBreakdownsPassengerTypeQuantityType =
    (
        data: any,
    ): AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerTypeQuantityType => {
        let out: AirportAirItineraryPricingInfoPTCFareBreakdownsPassengerTypeQuantityType =
            {
                code: data?.Code,
                quantity: data?.Quantity,
            };

        return out;
    };

export const convertToAirportAirItineraryPricingDetailsType = (
    data: any,
): AirportAirItineraryPricingDetailsType => {
    let out: AirportAirItineraryPricingDetailsType = {
        airItineraryPricingInfo: convertToAirportAirItineraryPricingInfoType(
            data?.AirItineraryPricingInfo,
        ),
    };

    return out;
};

export const convertToAirportOriginDestinationInformationType = (
    data: any,
): AirportOriginDestinationInformationType => {
    let out: AirportOriginDestinationInformationType = {
        originDestinationOption:
            convertToAirportOriginDestinationInformationOriginDestinationOptionTypeList(
                data?.OriginDestinationOption,
            ),
    };

    return out;
};

export const convertToAirportOriginDestinationInformationOriginDestinationOptionTypeList =
    (
        data: any[],
    ): AirportOriginDestinationInformationOriginDestinationOptionType[] => {
        return data.map((element) =>
            convertToAirportOriginDestinationInformationOriginDestinationOptionType(
                element,
            ),
        );
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionType =
            {
                departureDateTime: data?.DepartureDateTime,
                departureDateJ: data?.DepartureDateJ,
                arrivalDateTime: data?.ArrivalDateTime,
                journeyDuration: data?.JourneyDuration,
                journeyDurationPerMinute: data?.JourneyDurationPerMinute,
                originLocation: data?.OriginLocation,
                destinationLocation: data?.DestinationLocation,
                tpaExtensions:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionTpaExtensionsType(
                        data?.TPA_Extensions,
                    ),
                flightSegment:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentTypeList(
                        data?.FlightSegment,
                    ),
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentTypeList =
    (
        data: any[],
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType[] => {
        return data.map((element) =>
            convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType(
                element,
            ),
        );
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionTpaExtensionsType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionTPAExtensionsType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionTPAExtensionsType =
            {
                origin: data?.Origin,
                destination: data?.Destination,
                flightType: data?.FlightType,
                isCharter: data?.IsCharter,
                isForeign: data?.IsForeign,
                isLock: data?.IsLock,
                agencyCode: data?.AgencyCode,
                stop: data?.Stop,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentType =
            {
                arrivalDateTime: data?.ArrivalDateTime,
                departureDateTime: data?.DepartureDateTime,
                journeyDuration: data?.JourneyDuration,
                marketingCabin:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingCabinType(
                        data?.MarketingCabin,
                    ),
                departureAirport:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType(
                        data?.DepartureAirport,
                    ),
                arrivalAirport:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType(
                        data?.ArrivalAirport,
                    ),
                marketingAirline:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType(
                        data?.MarketingAirline,
                    ),
                operatingAirline:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType(
                        data?.OperatingAirline,
                    ),
                cabinClassCode: data?.CabinClassCode,
                tpaExtensions:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentُpaExtensionsType(
                        data?.TPA_Extensions,
                    ),
                equipment:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentEquipmentType(
                        data?.Equipment,
                    ),
                seatsRemaining: data?.SeatsRemaining,
                baggageAllowance:
                    convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentBaggageAllowanceType(
                        data?.BaggageAllowance,
                    ),
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingCabinType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingCabinType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingCabinType =
            {
                unitOfMeasureCode: data?.BaggageAllowance?.UnitOfMeasureCode,
                unitOfMeasureQuantity:
                    data?.BaggageAllowance?.UnitOfMeasureQuantity,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentDepartureAirportType =
            {
                locationCode: data?.LocationCode,
                airportName: data?.AirportName,
                terminal: data?.Terminal,
                gate: data?.Gate,
                codeContext: data?.CodeContext,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentMarketingAirlineType =
            {
                code: data?.Code,
                companyShortName: data?.CompanyShortName,
                flightNumber: data?.FlightNumber,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentُpaExtensionsType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentTPAExtensionsType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentTPAExtensionsType =
            {
                origin: data?.Origin,
                destination: data?.Destination,
                departureDateG: data?.DepartureDateG,
                departureDateJ: data?.DepartureDateJ,
                arrivalDateG: data?.ArrivalDateG,
                arrivalDateJ: data?.ArrivalDateJ,
                flightTime: data?.FlightTime,
                arrivalTime: data?.ArrivalTime,
                airlineNameFa: data?.AirlineNameFa,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentEquipmentType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentEquipmentType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentEquipmentType =
            {
                aircraftTailNumber: data?.AircraftTailNumber,
                airEquipType: data?.AirEquipType,
                changeofGauge: data?.ChangeofGauge,
            };

        return out;
    };

export const convertToAirportOriginDestinationInformationOriginDestinationOptionFlightSegmentBaggageAllowanceType =
    (
        data: any,
    ): AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentBaggageAllowanceType => {
        let out: AirportOriginDestinationInformationOriginDestinationOptionFlightSegmentBaggageAllowanceType =
            {
                unitOfMeasure: data?.UnitOfMeasure,
                unitOfMeasureCode: data?.UnitOfMeasureCode,
                unitOfMeasureQuantity: data?.UnitOfMeasureQuantity,
            };

        return out;
    };
