import { userType } from "../../../DTO";
import { Api } from "../../api";

export const DeleteRatePlan = (
    user: userType,
    accommodationId: number,
    roomId: number,
    ratePlanId: number,
) => {
    return new Promise<{ ratePlan: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/accommodation/${accommodationId}/room-type/${roomId}/rate-plan/${ratePlanId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    ratePlan: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
