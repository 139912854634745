import { AdminUserType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const FetchList = (
    user: userType,
    current_page: number,
    phoneNumber?: string,
) => {
    return new Promise<{ data: AdminUserType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/get",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    phone_number: phoneNumber && phoneNumber,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToAdminList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToAdminList(data: any[]): AdminUserType[] {
    const adminList = data.map((item: any) => {
        return convertToAdmin(item);
    });

    return adminList;
}

export function convertToAdmin(data: any): AdminUserType {
    const adminOb = {
        id: data?.id,
        user_id: data?.user_id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        username: data?.username,
        phone: data?.phone,
        email: data?.email,
        password: data?.password,
        phone_verified_at: data?.phone_verified_at,
        email_verified_at: data?.email_verified_at,
        last_login: data?.last_login,
        remember_token: data?.remember_token,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
    };

    return adminOb;
}
