import { Form, Button, Badge } from "react-bootstrap";
import { FormikProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { FormValues, PropType } from "./type";
import StarRating from "../../../components/star-rating";
import { AddressType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { AddressApi } from "../../../api";
import { Content, Shimmer } from "../../../components";
import { useAppLocation } from "../../../Router/hook";

export const HotelAccommodationCreateEdit = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        star,
        setStar,
        hotelImages,
        callbackAttributes,
    } = props;

    const [contents, setContents] = useState<File[]>(values.contents);

    const [checked, setChecked] = useState<boolean>(
        values.status === "active" ? true : false,
    );

    const [attribute, setAttribute] = useState<string>(values.attribute);
    const [attributes, setAttributes] = useState<string[]>(values.attributes);

    const [addressName, setAddressName] = useState(values.addressName);
    const [addressList, setAddressList] = useState<AddressType[]>([]);
    const [selectedAddress, setSelectedAddress] = useState(values.addressId);
    const [addressLoading, setAddressLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        values.contents = contents;
    }, [contents]);

    useEffect(() => {
        values.status = checked === true ? "active" : "inactive";
    }, [checked]);

    useEffect(() => {
        if (attribute !== "") {
            setAttributes([...attributes, attribute]);
            callbackAttributes(attributes);
        }
        values.attribute = "";
    }, [attribute]);

    useEffect(() => {
        values.attributes = attributes;
        callbackAttributes(attributes);
    }, [attributes]);

    const user = useAppSelector(selectUser);

    const { pathname } = useAppLocation();

    const fetchAddressList = (query: string) => {
        AddressApi.FetchAddress(user, query)
            .then((data) => {
                setAddressList(data.address);
                setAddressLoading(false);
            })
            .catch((err) => {
                setAddressLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        values.addressId = selectedAddress;
    }, [selectedAddress]);

    useEffect(() => {
        values.addressName = addressName;
    }, [addressName]);

    const throttledSearchAddress = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchAddressList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchAddress.cancel();
        };
    }, [throttledSearchAddress]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="placement-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={t("placeholderName")}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="placement-code"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("code")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="code"
                            className="h3"
                            type="string"
                            name="code"
                            placeholder={t("codePlaceholder")}
                            onBlur={handleBlur}
                            value={values.code}
                            onChange={handleChange}
                            isInvalid={touched.code && !!errors.code}
                        />
                        <Form.Text className="text-danger">
                            {errors.code}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="placement-description"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            as={"textarea"}
                            cols={5}
                            rows={4}
                            aria-label="description"
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={String(t("placeholderDescription"))}
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={
                                touched.description && !!errors.description
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.description}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="placement-policy"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("policy")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            as={"textarea"}
                            cols={5}
                            rows={4}
                            aria-label="policy"
                            className="h3"
                            type="string"
                            name="policy"
                            placeholder={String(t("policyPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.policy}
                            onChange={handleChange}
                            isInvalid={touched.policy && !!errors.policy}
                        />
                        <Form.Text className="text-danger">
                            {errors.policy}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-city-out-id"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("addressName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="addressName"
                            type="string"
                            onFocus={() => {
                                setAddressLoading(true);
                                fetchAddressList("");
                            }}
                            name="addressName"
                            placeholder={String(t("addressNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={addressName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setAddressName(e.target.value);
                                throttledSearchAddress(e);
                            }}
                            isInvalid={
                                touched.addressName && !!errors.addressName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {addressList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setAddressList([])}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {addressList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 border-bottom text-center w-100"
                                            onClick={() => {
                                                setSelectedAddress(
                                                    Number(item.id),
                                                );
                                                setAddressName(item.title);
                                                setAddressList([]);
                                            }}
                                        >
                                            {item.title}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                addressLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center position-absolute w-100 border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.addressName}
                        </Form.Text>
                    </Form.Group>
                    {pathname.includes("/hotel/accommodation/edit") && (
                        <Form.Group
                            controlId="placement-status"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>{t("status")}</Form.Label>
                            <Form.Check
                                type="switch"
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                            <Form.Text className="text-danger">
                                {errors.policy}
                            </Form.Text>
                        </Form.Group>
                    )}
                    <div className="col-12 col-sm-6 ">
                        <div className="flex-row d-flex justify-content-between my-2">
                            <Form.Group
                                controlId={`hotel-attribute`}
                                className="position-relative"
                            >
                                <Form.Label>{t("facilities")}</Form.Label>
                                <div className={"d-flex flex-row gap-2 col-12"}>
                                    <Form.Control
                                        autoComplete="off"
                                        aria-label="facilities"
                                        className=" col-8"
                                        type={"string"}
                                        name="attribute"
                                        placeholder={String(
                                            t("facilitiesPlaceholder"),
                                        )}
                                        onBlur={handleBlur}
                                        value={values.attribute}
                                        onChange={handleChange}
                                        isInvalid={
                                            touched.attribute &&
                                            !!errors.attribute &&
                                            attributes.length === 0
                                        }
                                    />
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            !errors.attribute &&
                                                setAttribute(values.attribute);
                                        }}
                                        className="align-self-center col-4"
                                    >
                                        {t("AddFacilities")}
                                    </Button>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.attribute}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row flex-wrap gap-2">
                            {attributes.length >= 1 &&
                                attributes.map((item: string) => (
                                    <div
                                        key={item}
                                        className=" border border-1 position-relative rounded-3 border-warning my-2 p-2"
                                    >
                                        <strong>{item}</strong>
                                        <Badge
                                            style={{
                                                cursor: "pointer",
                                                zIndex: 10,
                                                top: -10,
                                                right: -10,
                                                position: "absolute",
                                            }}
                                            pill
                                            bg="danger"
                                            onClick={() =>
                                                setAttributes(
                                                    attributes.filter(
                                                        (element) =>
                                                            element !== item,
                                                    ),
                                                )
                                            }
                                        >
                                            ×
                                        </Badge>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="position-relative col-12 col-sm-6 p-2">
                        <span>{t("star")}</span>
                        <StarRating rating={star} setRating={setStar} />
                    </div>

                    {hotelImages && (
                        <div className="position-relative col-12  p-2">
                            {hotelImages.map((image) => (
                                <img
                                    src={image.path}
                                    alt={image.path}
                                    width={200}
                                    height={200}
                                    style={{ objectFit: "contain" }}
                                    className="m-2"
                                />
                            ))}
                        </div>
                    )}
                    <div className="position-relative col-12  p-2">
                        <Content
                            contents={contents}
                            setContents={setContents}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
