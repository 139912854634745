import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        addressId: Yup.string().required(t("addressError")).min(1),
        name: Yup.string().required(t("nameError")).min(1),
        description: Yup.string().required(t("descriptionError")).min(1),
        policy: Yup.string().required(t("policyError")).min(1),
        code: Yup.string().required(t("codeError")).min(1),
    });
