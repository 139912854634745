import { Api } from "../api";
import { DiscountType, PaginationType, userType } from "../../DTO";
import { convertDiscountCode } from "./edit";
import { convertToPaginationType } from "../help";

export const ListDiscount = (user: userType, pageNumber: number) => {
    return new Promise<{
        discountCode: DiscountType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "coupon/get",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                page: pageNumber,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    discountCode: convertDiscountCodeList(data.data.data),
                    pagination: convertToPaginationType({
                        current_page: data.data.current_page,
                        per_page: data.data.per_page,
                        total: data.data.total,
                    }),
                });
            })
            .catch((error) => reject(error));
    });
};

export const convertDiscountCodeList = (data: []): DiscountType[] => {
    return data.map((element: any) => convertDiscountCode(element));
};
