import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import gregorian_en from "react-date-object/locales/gregorian_en";
import gregorian from "react-date-object/calendars/gregorian";
import { Button, Carousel, Form, Modal } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel } from "@fortawesome/free-solid-svg-icons";

import { CustomBreadCrumb, Loading } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { HotelApi } from "../../../../api";
import { useAppNavigate, useAppParams } from "../../../../Router/hook";
import StarRating from "../../../../components/star-rating";
import { convertDateFormat } from "../../../../form/trip/logic";
import { AccommodationRoomType } from "../../../../DTO";
import { useTranslation } from "react-i18next";

export const HotelRoomTypeShow: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [room, setRoom] = useState<AccommodationRoomType>();

    const user = useAppSelector(selectUser);

    const { id, roomId } = useAppParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/list`,
        },
        { name: `${t("roomType")} ${roomId}`, href: "#" },
    ];

    const fetchSingleRoomType = () => {
        setLoading(true);

        HotelApi.RoomTypeApi.ShowRoomType(user, Number(id), Number(roomId))
            .then((data) => {
                setRoom(data.roomType);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchSingleRoomType();
    }, [id, roomId]);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("roomType")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {room && (
                    <>
                        <div className="d-flex flex-row flex-wrap rounded px-3">
                            <div className="col-12  p-2">
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row position-relative">
                                    <div className="col-12 col-lg-4 h-100">
                                        <FontAwesomeIcon
                                            icon={faHotel}
                                            size={"10x"}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-8 d-flex flex-row">
                                        <div className="col-12 col-lg-6 d-flex h-100 flex-column gap-1 justify-content-center align-items-center ">
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("name")}:</span>
                                                <strong>{room.name}</strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("code")}:</span>
                                                <strong>{room.code}</strong>
                                            </div>

                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("adult")}:</span>
                                                <strong>{room.adult}</strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("count")}:</span>
                                                <strong className="text-primary fa-beat-fade">
                                                    {room.count}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1 ">
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("description")}:</span>
                                                <strong className="text-break">
                                                    {room.description}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  p-2">
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row position-relative">
                                    <div className="col-12 col-lg-4 h-100">
                                        <Carousel>
                                            {room.accommodation.attachments.data.map(
                                                (hotelImage) => (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={
                                                                hotelImage.path
                                                            }
                                                            alt={
                                                                hotelImage.path
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "contain",
                                                                height: "300px",
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ),
                                            )}
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-lg-8 d-flex flex-row">
                                        <div className="col-12 col-lg-6 d-flex h-100 flex-column gap-1 justify-content-center align-items-center ">
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("name")}:</span>
                                                <strong>
                                                    {room.accommodation.name}
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("code")}:</span>
                                                <strong>
                                                    {room.accommodation.code}
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("status")}:</span>
                                                <strong
                                                    className={
                                                        room.accommodation
                                                            .status === "active"
                                                            ? "badge bg-success"
                                                            : "badge bg-danger"
                                                    }
                                                >
                                                    {room.accommodation.status}
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("address")}:</span>
                                                <strong>
                                                    {
                                                        room.accommodation
                                                            .address.title
                                                    }
                                                </strong>
                                            </div>
                                            <StarRating
                                                rating={room.accommodation.star}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1 ">
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("description")}:</span>
                                                <strong className="text-break">
                                                    {
                                                        room.accommodation
                                                            .description
                                                    }
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("policy")}:</span>
                                                <strong className="text-break">
                                                    {room.accommodation.policy}
                                                </strong>
                                            </div>
                                            <hr />
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("facilities")}:</span>
                                                <div className="d-flex flex-row gap-2 flex-wrap">
                                                    {room.accommodation.facilities.map(
                                                        (element) => (
                                                            <strong
                                                                className="bg-custom-light p-1 rounded-2"
                                                                key={element.id}
                                                            >
                                                                {element.name}
                                                            </strong>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-2">
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                                    <strong className="text-center my-2">
                                        {t("ratePlan")}
                                    </strong>
                                    <div className="col-12 d-flex flex-row flex-wrap">
                                        {room.ratePlans.map((rateItem) => (
                                            <div
                                                key={rateItem.id}
                                                className="col-12 col-lg-4 p-2 border border-1 rounded-4 d-flex flex-column gap-2 align-items-center justify-content-center px-2"
                                            >
                                                <div className="d-flex flex-row gap-2">
                                                    <span>{t("name")}:</span>
                                                    <strong>
                                                        {rateItem.name}
                                                    </strong>
                                                </div>
                                                <div className="d-flex flex-row gap-2">
                                                    <span>{t("code")}:</span>
                                                    <strong>
                                                        {rateItem.code}
                                                    </strong>
                                                </div>
                                                <div className="d-flex flex-row gap-2">
                                                    <span>{t("price")}:</span>
                                                    <strong className="text-success fa-beat-fade">
                                                        {rateItem.price.toLocaleString()}
                                                    </strong>
                                                </div>
                                                <hr />
                                                <div className="d-flex flex-column gap-1 align-self-start">
                                                    <span>
                                                        {t("description")}:
                                                    </span>
                                                    <strong className="text-break">
                                                        {rateItem.description}
                                                    </strong>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
