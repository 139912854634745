import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import "./style.scss";
import {
    CustomPagination,
    Loading,
    CustomBreadCrumb,
} from "../../../components";
import { PaginationType, UserAppType } from "../../../DTO";
import { useAppPagination } from "../../../Router/hook";
import { AppUserApi } from "../../../api";

export const AppUsers = () => {
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [userAppList, setUserAppList] = useState<UserAppType[]>([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("userList")), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useNavigate();

    useEffect(() => {
        fetchUserAppList(pagination.current_page);
    }, []);

    const fetchUserAppList = (page: number) => {
        setLoading(true);
        AppUserApi.FetchList(user, page)
            .then((data) => {
                setUserAppList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const handleChangeStatus = (userId: number) => {
        AppUserApi.ChangeStatusUser(user, userId)
            .then(() => {
                Swal.fire(
                    String(t("statusChange")),
                    String(t("statusChangeMessage")),
                    "success",
                );
                fetchUserAppList(pagination.current_page);
            })
            .catch((err) =>
                Swal.fire({
                    icon: "error",
                    text: err,
                }),
            );
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("userList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("userTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showAdminSearchInputa"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                        </div>
                    </div>
                    <input type="checkbox" id="showAdminSearchInput"></input>
                    <div className="adminSearchBox  p-2 flex -row justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("id")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="number"
                                        placeholder={String("idPlaceholder")}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("email")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="email"
                                        placeholder={t("emailPlaceholder")}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("fullName")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="text"
                                        placeholder={String(
                                            t("fullNamePlaceholder"),
                                        )}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button className="btn btn-sm btn-success mx-1 text-nowrap">
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("fullName")}</th>
                                    <th>{t("phoneNumber")}</th>
                                    <th>{t("email")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userAppList.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>
                                            {item?.first_name} {item?.last_name}
                                        </td>
                                        <td>{item?.phone}</td>
                                        <td>{item?.email}</td>
                                        <td>
                                            <strong className="badge bg-success">
                                                {item.status}
                                            </strong>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/app-user/info/${item.id}`}
                                                    className="btn btn-info btn-sm"
                                                >
                                                    {t("info")}
                                                </Link>
                                                <Link
                                                    to={`/app-user/edit/${item.id}`}
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                    />
                                                </Link>
                                                <Form.Switch
                                                    checked={
                                                        item.status === "active"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        handleChangeStatus(
                                                            Number(item.id),
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(`/app-user?current_page=${pageNum}`);
                                fetchUserAppList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
