import { userType } from "../../../DTO";
import { Api } from "../../api";

export const CreateRole = (
    user: userType,
    name: string,
    permissions: number[],
) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/role/store",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                permissions,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve(data.data);
            })
            .catch((err) => reject(err));
    });
};
