import { Api } from "../../api";
import { userType } from "../../../DTO";

export const DeleteRoute = (user: userType, id: number) => {
    return new Promise<{}>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/routes/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({});
            })
            .catch((error) => reject(error));
    });
};
