import { CountryType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToProvinceList } from "./provinceList";

export const FetchCountryList = (
    user: userType,
    current_page: number,
    country?: CountryType,
) => {
    return new Promise<{ data: CountryType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/country",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    name: country?.name,
                    name_en: country?.name_en,
                    id: country?.id,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToCountryList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToCountryList(data: any[]): CountryType[] {
    const countryList = data.map((item: any) => {
        return convertToCountry(item);
    });

    return countryList;
}

export function convertToCountry(data: any): CountryType {
    const countryOb: CountryType = {
        id: data?.id,
        status: data?.status,
        description: data?.description,
        name: data?.name,
        name_en: data?.name_en,
        domain_code: data?.domain_code,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        description_en: data?.description_en,
        updated_at: data?.updated_at,
        province: data?.province ? convertToProvinceList(data?.province) : null,
    };

    return countryOb;
}

export const FetchCountryId = (user: userType, id: string) => {
    return new Promise<{ data: CountryType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/country/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCountry(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const CreateCountry = (user: userType, country: CountryType) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/country`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            body: {
                name: country?.name,
                name_en: country.name_en,
                domain_code: country.domain_code,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const EditCountry = (
    user: userType,
    country: CountryType,
    id: string,
) => {
    return new Promise<{ data: CountryType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/country/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: country?.name,
                name_en: country.name_en,
                domain_code: country.domain_code,
                // status: country.status,
            }),
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCountry(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const SearchCountry = (user: userType, country: CountryType) => {
    return new Promise<{ data: CountryType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `country/search`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                name: country?.name,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCountryList(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const DeleteCountry = (user: userType, id: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/country/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};
