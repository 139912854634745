import React, { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import gregorian_en from "react-date-object/locales/gregorian_en";
import gregorian from "react-date-object/calendars/gregorian";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";
import { convertDateFormat } from "../../trip/logic";

export const HotelInventory = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        dateTo,
        setDateTo,
        dateFrom,
        setDateFrom,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="inventory-availability"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("availability")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="availability"
                            className="h3"
                            type="number"
                            name="availability"
                            placeholder={String(t("availabilityPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.availability}
                            onChange={handleChange}
                            isInvalid={
                                touched.availability && !!errors.availability
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.availability}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="inventory-date-from"
                        className="position-relative d-flex flex-column col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("dateFrom")}</Form.Label>
                        <DatePicker
                            value={dateFrom}
                            onChange={(array: any) => {
                                setDateFrom(
                                    convertDateFormat(array.toString?.()),
                                );
                            }}
                            calendar={gregorian}
                            locale={gregorian_en}
                            format="YYYY/MM/DD"
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="inventory-date-to"
                        className="position-relative d-flex flex-column col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("dateTo")}</Form.Label>
                        <DatePicker
                            value={dateTo}
                            onChange={(array: any) => {
                                setDateTo(
                                    convertDateFormat(array.toString?.()),
                                );
                            }}
                            calendar={gregorian}
                            locale={gregorian_en}
                            format="YYYY/MM/DD"
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    disabled={dateTo.length <= 0 && dateFrom.length <= 0}
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
