import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";

import { DealType, PaginationType } from "../../../DTO";
import { PaymentApi } from "../../../api";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "../../../components";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const TransactionList: FC = () => {
    const [transaction, setTransaction] = useState<DealType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("transactionList"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchTransactionList = (page: number) => {
        setLoading(true);
        PaymentApi.TransactionApi.TransactionList(user, page)
            .then((data) => {
                setTransaction(data.transaction);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchTransactionList(pagination.current_page);
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("transactionList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("transactionTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("userPhone")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("currency")}</th>
                                    <th>{t("gateway")}</th>
                                    <th>{t("device")}</th>
                                    <th>{t("device")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transaction.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.user?.phone}</td>
                                        <td>{item?.type}</td>
                                        <td>{item?.amount}</td>
                                        <td>{item?.currency}</td>
                                        <td>{item?.gateway}</td>
                                        <td>{item?.device}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item?.status === "success"
                                                        ? "badge bg-success"
                                                        : "badge bg-warning"
                                                }
                                            >
                                                {item?.status}
                                            </strong>{" "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/payment/transaction?current_page=${pageNum}`,
                                );
                                fetchTransactionList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
