import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { PermissionType, RoleType } from "../../../DTO";
import { UserAccessApi } from "../../../api";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux-config/entities/user";
import { Loading } from "../../../components";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NewRole: React.FC = () => {
    const user = useSelector(selectUser);
    const [permissionsList, setPermissionsList] = useState<PermissionType[]>(
        [],
    );
    const [loading, setLoading] = useState(false);
    const [selectedPermissionsId, setSelectedPermissionsId] = useState<
        number[]
    >([]);
    const [isAllCheck, setIsAllCheck] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const navigate = useNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("roleList"), href: "/user-access" },
        { name: t("create"), href: "#" },
    ];

    useEffect(() => {
        fetchApi();
    }, []);

    // const fetchRoleID = () => {
    //     if (id) {
    //         UserAccessApi.RoleApi.ShowRole(user, Number(id))
    //             .then((data) => {
    //                 setLoading(false);
    //                 setName(data.role.name);
    //                 const list: number[] = [];
    //                 data.role.permissions.map((mainItem) => {
    //                     list.push(mainItem.id);
    //                 });

    //                 setSelectedPermissionsId(list);
    //             })
    //             .catch((err) => {
    //                 setLoading(false);
    //             });
    //     }
    // };

    useEffect(() => {
        const allList = handleGetAllPermissionId();
        if (
            allList.length === selectedPermissionsId.length &&
            selectedPermissionsId.length !== 0
        ) {
            setIsAllCheck(true);
        } else {
            setIsAllCheck(false);
        }
    }, [selectedPermissionsId]);

    const fetchApi = () => {
        setLoading(true);
        UserAccessApi.PermissionApi.ListPermission(user)
            .then((data) => {
                setPermissionsList(data.permission);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleCheckBoxChange = (id: number) => {
        if (selectedPermissionsId.indexOf(id) >= 0) {
            let list: number[] = selectedPermissionsId;
            const deleteList = list.filter((data) => data != id);
            setSelectedPermissionsId(deleteList);
        } else {
            setSelectedPermissionsId([...selectedPermissionsId, id]);
        }
    };

    const handleGetAllPermissionId = (): number[] => {
        let list: number[] = [];
        for (var i = 0; i < permissionsList.length; i++) {
            var permissions = permissionsList[i].permissions;
            for (var j = 0; j < permissions.length; j++) {
                var id = permissions[j].id;
                list.push(id);
            }
        }
        return list;
    };

    const handleAllClick = () => {
        let list = handleGetAllPermissionId();
        if (
            list.length !== selectedPermissionsId.length ||
            selectedPermissionsId.length === 0
        ) {
            setSelectedPermissionsId(list);
        } else {
            setSelectedPermissionsId([]);
        }
    };

    const handleIsCheck = (id: number): boolean => {
        if (selectedPermissionsId.indexOf(id) >= 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleCreateNewRoles = () => {
        setLoading(true);
        UserAccessApi.RoleApi.CreateRole(user, name, selectedPermissionsId)
            .then((data) => {
                setLoading(false);
                Swal.fire({
                    text: t("roleChangeMessage"),
                    icon: "success",
                }).then(() => {
                    navigate("/user-access");
                });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("roleNew")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <Form className="d-flex flex-row flex-wrap">
                            <Form.Group
                                className="mx-2"
                                style={{ minWidth: "230px" }}
                            >
                                <Form.Label className="mb-0">
                                    {t("name")}
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    disabled={name === "Coach"}
                                    size="sm"
                                    type="text"
                                    placeholder={String(t("namePlaceholder"))}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                />
                            </Form.Group>
                        </Form>
                        <Form.Group className="d-flex flex-row justify-content-between">
                            <Form.Label
                                style={{ cursor: "pointer" }}
                                htmlFor={"selectAll"}
                            >
                                {t("selectAll")}
                            </Form.Label>
                            <Form.Check
                                name="group1"
                                type={"checkbox"}
                                className="mx-2"
                                id={"selectAll"}
                                onChange={() => {
                                    handleAllClick();
                                    setIsAllCheck(!isAllCheck);
                                }}
                                checked={isAllCheck}
                            />
                        </Form.Group>
                    </div>
                    <div className="d-flex flex-row flex-wrap ">
                        {permissionsList.map((item, index) => (
                            <div
                                className="d-flex flex-column col-12 col-md-6 col-lg-4 p-2"
                                key={index}
                            >
                                <strong>{item.group}</strong>
                                <ul className="d-flex flex-column">
                                    {item?.permissions.map((data, index) => (
                                        <li key={index}>
                                            <Form.Group className="d-flex flex-row justify-content-between">
                                                <Form.Label
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    htmlFor={String(data.id)}
                                                >
                                                    {data.name}
                                                </Form.Label>
                                                <Form.Check
                                                    name="group1"
                                                    type={"checkbox"}
                                                    id={String(data.id)}
                                                    onChange={(e) => {
                                                        handleCheckBoxChange(
                                                            data.id,
                                                        );
                                                    }}
                                                    checked={handleIsCheck(
                                                        data.id,
                                                    )}
                                                />
                                            </Form.Group>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <Button
                            disabled={
                                selectedPermissionsId.length <= 0 ||
                                name.length <= 1
                            }
                            onClick={() => {
                                handleCreateNewRoles();
                            }}
                        >
                            {t("submit")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
