import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { DriverForm } from "../../../../form";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { DriverApi, VehicleApi } from "../../../../api";
import { Loading } from "../../../../components";
import { useAppNavigate } from "../../../../Router/hook";
import { VehicleModelType } from "../../../../DTO";

export const NewDriver: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("driverList"), href: "/vehicle/driver" },
        { name: t("new"), href: "#" },
    ];

    const navigate = useAppNavigate();

    const createDriver = (
        passportId: number,
        licenceId: number,
        vehicleId: number,
        phone: string,
        firstName: string,
        lastName: string,
        commission: number,
        commissionVip: number,
    ) => {
        setLoading(true);
        DriverApi.CreateDriver(
            user,
            passportId,
            licenceId,
            vehicleId,
            phone,
            firstName,
            lastName,
            commission,
            commissionVip,
        )
            .then((data) => {
                navigate("/vehicle/driver/list");
                setLoading(false);
            })
            .then((err: any) => {
                setLoading(false);
                // Swal.fire({
                //     icon: "error",
                //     text: err.data.message,
                // });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("new")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<DriverForm.FormValues>
                        validationSchema={DriverForm.ValidationSchema(t)}
                        initialValues={{
                            licenceId: "",
                            passportId: "",
                            vehicleId: 0,
                            vehicleName: "",
                            phoneNumber: "",
                            firstName: "",
                            lastName: "",
                            commission: "",
                            commissionVip: "",
                        }}
                        onSubmit={(event) => {
                            createDriver(
                                Number(event.passportId),
                                Number(event.licenceId),
                                event.vehicleId,
                                event.phoneNumber,
                                event.firstName,
                                event.lastName,
                                Number(event.commission),
                                Number(event.commissionVip),
                            );
                        }}
                        component={(props: any) => (
                            <DriverForm.DriverFormCreate
                                submitTxt={t("submit")}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
