import * as Yup from "yup";

export const ValidationSchemaEdit = (t: any) =>
    Yup.object().shape({
        firstName: Yup.string().required(t("firstNameError")).min(1).max(128),
        lastName: Yup.string().required(t("lastNameError")).min(1).max(128),
        email: Yup.string()
            .required(t("emailError"))
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Please enter valid email",
            ),
    });

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        firstName: Yup.string()
            .required(t("firstNameError"))
            .min(1, t("firstNameMinError"))
            .max(128, t("firstNameMaxError")),
        lastName: Yup.string()
            .required(t("lastNameError"))
            .min(1, t("lastNameMinError"))
            .max(128, t("lastNameMaxError")),
        roleId: Yup.number().required(),
        username: Yup.string()
            .required(t("usernameError"))
            .min(1, t("usernameMinError"))
            .max(128, t("usernameMaxError")),
        phoneNumber: Yup.string().required(t("phoneNumberError")),
        email: Yup.string()
            .required(t("emailError"))
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                t("emailMatchError"),
            ),
        password: Yup.string()
            .required(t("passwordError"))
            .min(1, t("passwordMinError"))
            .max(128, t("passwordMaxError")),
    });
