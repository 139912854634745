import { PortType, userType } from "../../DTO";
import { Api } from "../api";
import { castPort } from "./list";

export const CreatePort = (
    user: userType,
    name: string,
    description: string,
    addressId: number,
    type: "BUS" | "CAR" | "AIR",
) => {
    return new Promise<{ port: PortType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/port",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                name,
                description,
                address_id: addressId,
                type,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    port: castPort(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
