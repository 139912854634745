import { AllPaymentType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToUser } from "../driver";
import { convertToPaginationType } from "../help";
import { castDeal } from "./transaction";

export const AllPayment = (user: userType, currentPage: number) => {
    return new Promise<{
        payment: AllPaymentType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "payment/admin",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    payment: castAllPaymentList(data.data.data),
                    pagination: convertToPaginationType(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};

const castAllPaymentList = (data: any[]): AllPaymentType[] => {
    return data.map((element) => castAllPayment(element));
};

export const castAllPayment = (data: any): AllPaymentType => {
    let out: AllPaymentType = {
        id: data?.id,
        userId: data?.user_id,
        transactionId: data?.transaction_id,
        status: data?.status,
        type: data?.type,
        orderId: data?.order_id,
        amount: data?.amount,
        deletedAt: data?.deleted_at,
        createdAt: data?.created_at,
        updatedAt: data?.updated_at,
        transaction: castDeal(data?.transaction),
        user: convertToUser(data?.user),
    };

    return out;
};
