import { AccommodationConfigType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationConfigType } from "./list";

export const EditConfigAccommodation = (
    user: userType,
    id: number,
    configId: number,
    key: string,
    value: string,
) => {
    return new Promise<{ accommodationConfig: any }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/accommodation/${id}/config/${configId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                key,
                value,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    accommodationConfig: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
