import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        driverName: Yup.string().required(t("driverPhoneError")),
        portInName: Yup.string().required(t("portInError")),
        portOutName: Yup.string().required(t("portOutError")),
        price: Yup.string().required(t("priceError")),
        routeName: Yup.string().required(t("routeNameError")),
        duration: Yup.number().required(t("durationError")),
    });
