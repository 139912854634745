import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        title: Yup.string().required(t("titleError")),
        postalCode: Yup.string().required(t("postalError")).min(10),
        detail: Yup.string().required(t("detailError")),
        cityInName: Yup.string().required(t("cityInNameError")),
        lat: Yup.string().required(t("latError")).min(0).max(128),
        lng: Yup.string().required(t("lngError")).min(0).max(128),
    });
