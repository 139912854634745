import { userType } from "../../DTO";
import { Api } from "../../api";

export const CreateVehicle = (
    user: userType,
    seatModelId: number,
    title: string,
    model: "VAN" | "BUS" | "CAR",
) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/vehicle/store",
            body: {
                seat_model_id: seatModelId,
                title,
                model,
            },
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.message);
            });
    });
};
