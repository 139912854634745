import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchUserLogin } from "../../../api";
import { setLocalItems } from "../../hooks";
import { initialState } from "./state";

export const loginUserAction = createAsyncThunk(
    "user/login",
    async (
        userData: { phone: string; password: string },
        { rejectWithValue },
    ) => {
        try {
            const response = await FetchUserLogin(
                userData.phone,
                userData.password,
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const userSlice = createSlice({
    initialState: initialState,
    name: "User/slice",
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loginUserAction.fulfilled, (state, action) => {
            setLocalItems(
                "user",
                JSON.stringify(action.payload, (key, value) => {
                    if (key === "permissions") {
                        return undefined;
                    }
                    return value;
                }),
            );
            setLocalItems("user-permissions", action.payload.permissions);
            state.value = action.payload;
        });
    },
});

export const UserReducer = userSlice.reducer;
