import React, { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import _ from "lodash";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import { FormValues, PropType } from "./type";
import { CountryType } from "../../DTO";
import { AddressApi } from "../../api";
import { selectUser } from "../../redux-config/entities/user";
import { useTranslation } from "react-i18next";
import { Shimmer } from "../../components";

export const ProvinceFormEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const { t } = useTranslation();

    const [countryName, setCountryName] = useState(values.countryName);
    const [countryList, setCountryList] = useState<CountryType[]>([]);
    const [selectedCountry, setSelectedCountry] = useState(values.countryId);
    const [countryLoading, setCountryLoading] = useState<boolean>(false);

    const user = useSelector(selectUser);

    useEffect(() => {
        values.countryId = selectedCountry;
    }, [selectedCountry]);

    useEffect(() => {
        values.countryName = countryName;
    }, [countryName]);

    const throttledSearchCountry = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchCountryList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchCountry.cancel();
        };
    }, [throttledSearchCountry]);

    const fetchCountryList = (query: string) => {
        AddressApi.FetchCountryList(user, 1, {
            domain_code: "",
            id: 0,
            name: query,
            name_en: "",
            status: "Active",
        })
            .then((data) => {
                setCountryList(data.data);
                setCountryLoading(false);
            })
            .catch((err) => {
                setCountryLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="province-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="province-enName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("enName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="enName"
                            className="h3"
                            type="string"
                            name="enName"
                            placeholder={String(t("placeholderEnName"))}
                            onBlur={handleBlur}
                            value={values.enName}
                            onChange={handleChange}
                            isInvalid={touched.enName && !!errors.enName}
                        />
                        <Form.Text className="text-danger">
                            {errors.enName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="province-domainCode"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("domainCode")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="domainCode"
                            className="h3"
                            type="string"
                            name="domainCode"
                            placeholder={String(t("placeholderDomainCode"))}
                            onBlur={handleBlur}
                            value={values.domainCode}
                            onChange={handleChange}
                            isInvalid={
                                touched.domainCode && !!errors.domainCode
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.domainCode}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="province-countryId"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("countryName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="countryName"
                            type="string"
                            onFocus={() => {
                                setCountryLoading(true);
                                fetchCountryList("");
                            }}
                            name="countryName"
                            placeholder={String(t("placeholderCountryName"))}
                            onBlur={handleBlur}
                            value={countryName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setCountryName(e.target.value);
                                throttledSearchCountry(e);
                            }}
                            isInvalid={
                                touched.countryName && !!errors.countryName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {countryList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setCountryList([])}
                                    className="position-absolute border rounded-2 border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {countryList.map((item, index) => (
                                        <span
                                            key={index}
                                            className="p-3 w-100 text-center border-bottom g-cursor-pointer"
                                            onClick={() => {
                                                setSelectedCountry(item.id);
                                                setCountryName(item.name);
                                                setCountryList([]);
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                countryLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center w-100 position-absolute border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.countryName}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
