import { FC, useEffect, useState } from "react";
import { BookingType } from "../../../DTO";
import { useAppParams } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { HotelApi } from "../../../api";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const BookingList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [booking, setBooking] = useState<BookingType[]>([]);
    const [showModalBooking, setShowModalBooking] = useState<boolean>(false);
    const [bookingModalData, setBookingModalData] = useState<BookingType>();

    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: `${t("accommodation")} ${id}`,
            href: `/hotel/accommodation/info/${id}`,
        },
        { name: t("booking"), href: "#" },
    ];

    const fetchBookingList = () => {
        setLoading(true);

        HotelApi.BookingApi.ListBooking(user, Number(id))
            .then((data) => {
                setBooking(data.booking);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchBookingList();
    }, [id]);

    const handleConfirmHotel = (
        hotelId: number,
        bookId: number,
        confirm: boolean,
    ) => {
        setLoading(true);

        HotelApi.BookingApi.CashConfirm(user, hotelId, bookId, confirm)
            .then(() => {
                fetchBookingList();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("booking")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("bookingTable")}</h4>
                        <div className="d-flex flex-row gap-2"></div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("arrivalDate")}</th>
                                    <th>{t("departureDate")}</th>
                                    <th>{t("adult")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("priceDollar")}</th>
                                    <th>{t("gateway")}</th>
                                    <th>{t("reservedAt")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {booking.length > 0 &&
                                    booking.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item.arrivalDate}</td>
                                            <td>{item.departureDate}</td>
                                            <td>{item.adult}</td>
                                            <td>
                                                {item.price.toLocaleString()}
                                            </td>
                                            <td>
                                                {item.priceDollar.toLocaleString()}
                                            </td>
                                            <td>{item.transaction?.gateway}</td>
                                            <td>
                                                {item.reservedAt.slice(0, 10)}
                                            </td>
                                            <td>
                                                <strong
                                                    className={`badge ${
                                                        item.status ===
                                                        "reserved"
                                                            ? "bg-success"
                                                            : item.status ===
                                                              "canceled"
                                                            ? "bg-danger"
                                                            : item.status ===
                                                              "finalized"
                                                            ? "bg-info"
                                                            : item.status ===
                                                                  "not_confirmed" &&
                                                              "bg-warning"
                                                    }`}
                                                >
                                                    {item.status}
                                                </strong>
                                            </td>
                                            <td>
                                                <div
                                                    className={
                                                        "d-flex flex-row gap-2 justify-content-center align-items-center"
                                                    }
                                                >
                                                    <button
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => {
                                                            setShowModalBooking(
                                                                true,
                                                            );
                                                            setBookingModalData(
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        {t("detail")}
                                                    </button>
                                                    {item.transaction
                                                        ?.gateway === "Cash" &&
                                                        item.status !==
                                                            "finalized" && (
                                                            <div
                                                                className={
                                                                    "d-flex flex-row gap-2"
                                                                }
                                                            >
                                                                <button
                                                                    className={
                                                                        "btn btn-sm btn-success"
                                                                    }
                                                                    onClick={() =>
                                                                        handleConfirmHotel(
                                                                            Number(
                                                                                id,
                                                                            ),
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                            true,
                                                                        )
                                                                    }
                                                                >
                                                                    {t("paid")}
                                                                </button>
                                                                <button
                                                                    className={
                                                                        "btn btn-sm btn-danger"
                                                                    }
                                                                    onClick={() =>
                                                                        handleConfirmHotel(
                                                                            Number(
                                                                                id,
                                                                            ),
                                                                            Number(
                                                                                item.id,
                                                                            ),
                                                                            false,
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        "notPay",
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                </div>
                                            </td>{" "}
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <Modal
                show={showModalBooking}
                onHide={() => setShowModalBooking(false)}
                size="xl"
                centered
            >
                {bookingModalData && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("booking")} {bookingModalData.id}{" "}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="overflow-auto">
                            <div className="p-4 text-center text-nowrap">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>{t("id")}</th>
                                            <th>{t("name")}</th>
                                            <th>{t("code")}</th>
                                            <th>{t("adult")}</th>
                                            <th>{t("passengerName")}</th>
                                            <th>{t("passengerGender")}</th>
                                            <th>{t("passengerNationality")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bookingModalData.bookingPassengers
                                            .length > 0 &&
                                            bookingModalData.bookingPassengers.map(
                                                (element, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {
                                                                element
                                                                    .roomTypeId
                                                                    ?.id
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                element
                                                                    .roomTypeId
                                                                    .name
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                element
                                                                    .roomTypeId
                                                                    .code
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                element
                                                                    .roomTypeId
                                                                    .adult
                                                            }
                                                        </td>
                                                        <td>
                                                            {element.firstName}{" "}
                                                            {element.lastName}
                                                        </td>
                                                        <td>
                                                            {element.gender}
                                                        </td>
                                                        <td>
                                                            {
                                                                element.nationalityCode
                                                            }
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
};
