import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb, Loading } from "../../../../components";
import { TripForm } from "../../../../form";
import "./style.scss";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { TripApi } from "../../../../api";
import { useAppNavigate } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const VehicleTripNew: FC = () => {
    const [date, setDate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("trip"), href: "/vehicle/trip/list" },
        { name: t("new"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("new")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <CustomBreadCrumb breadCrumbList={crumbs} />
            <div className="d-flex flex-column ">
                <Formik<TripForm.FormValues>
                    validationSchema={TripForm.ValidationSchema(t)}
                    initialValues={{
                        driverId: 0,
                        driverName: "",
                        routeName: "",
                        routeId: 0,
                        price: "",
                        time: date,
                        duration: "",
                        portInId: 0,
                        portInName: "",
                        portOutId: 0,
                        portOutName: "",
                        priceFront: "",
                        status: "Active",
                    }}
                    onSubmit={(event) => {
                        setLoading(true);

                        TripApi.CreateTrip(
                            user,
                            Number(event.routeId),
                            Number(event.price),
                            Number(event.driverId),
                            date,
                            Number(event.duration),
                            Number(event.portInId),
                            Number(event.portOutId),
                            Number(event.priceFront),
                        )
                            .then(() => {
                                navigate("/vehicle/trip/list");
                                setLoading(false);
                            })
                            .catch((error) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    text: error.data.message,
                                });
                            });
                    }}
                    component={(props: any) => (
                        <TripForm.TripFormCreate
                            submitTxt={t("submit")}
                            setDate={setDate}
                            date={date}
                            {...props}
                        />
                    )}
                />
            </div>
        </div>
    );
};
