import {
    BookingPassengerType,
    BookingRatePlanType,
    BookingType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToUserType } from "../../user-login";
import { convertToAccommodationType } from "../accommodation";
import { convertToAccommodationRoomRatePlanType } from "../rate-plan";
import { convertToAccommodationRoomType } from "../room-type";
import { castDeal } from "../../payment/transaction";
import { convertToPriceCurrencyType } from "../../price-currency";
import { convertToAdmin } from "../../admin";

export const ListBooking = (user: userType, accommodationId: number) => {
    return new Promise<{ booking: BookingType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/accommodation/${accommodationId}/booking`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    booking: convertToBookingTypeList(data.data.data),
                }),
            )
            .catch((error: any) => {
                reject(error.data.message);
            });
    });
};

export const convertToBookingTypeList = (data: any[]): BookingType[] => {
    return data.map((element) => convertToBookingType(element));
};

export const convertToBookingType = (data: any): BookingType => {
    let out: BookingType = {
        adult: data?.adult,
        reference: data?.reference,
        arrivalDate: data?.arrival_date,
        bookerEmail: data?.booker_email,
        bookerPhone: data?.booker_phone,
        bookingPassengers:
            data?.booking_passengers.length > 0
                ? convertToBookingPassengersTypeList(data?.booking_passengers)
                : [],
        bookingRatePlans: convertToBookingRatePlansTypeList(
            data?.booking_rate_plans,
        ),
        departureDate: data?.departure_date,
        id: data?.id,
        price: data?.price,
        priceDollar: data?.price_dollar,
        remainToFinalized: data?.remain_to_finalized,
        remainToFinalizedJ: data?.remain_to_finalized_j,
        reservedAt: data?.reserved_at,
        status: data?.status,
        transaction: data?.transaction
            ? castDeal(data?.transaction)
            : undefined,
        currencyRate: data?.currency_rate
            ? convertToPriceCurrencyType(data?.currency_rate)
            : undefined,
        hotelCommission: data?.hotel_commission,
        user: data?.user ? convertToAdmin(data?.user) : undefined,
    };

    return out;
};

export const convertToBookingPassengersTypeList = (
    data: any[],
): BookingPassengerType[] => {
    return data.map((element) => convertToBookingPassengersType(element));
};

export const convertToBookingPassengersType = (
    data: any,
): BookingPassengerType => {
    let out: BookingPassengerType = {
        bookingId: data?.booking_id,
        roomTypeId: convertToAccommodationRoomType(data?.room_type_id),
        email: data?.email,
        firstName: data?.first_name,
        foreignCountry: data?.foreign_country,
        gender: data?.gender,
        lastName: data?.last_name,
        nationalityCode: data?.nationality_code,
        passportCountry: data?.passport_country,
        passportExpiryDate: data?.passport_expiry_date,
        passportNumber: data?.passport_number,
        phone: data?.phone,
    };

    return out;
};

export const convertToBookingRatePlansTypeList = (
    data: any[],
): BookingRatePlanType[] => {
    return data.map((element) => convertToBookingRatePlansType(element));
};

export const convertToBookingRatePlansType = (
    data: any,
): BookingRatePlanType => {
    let out: BookingRatePlanType = {
        id: data?.id,
        bookingId: data?.booking_id,
        accommodation: convertToAccommodationType(data?.accommodation),
        ratePlan: convertToAccommodationRoomRatePlanType(data?.rate_plan),
        roomType: convertToAccommodationRoomType(data?.room_type),
    };

    return out;
};
