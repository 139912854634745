import { AccommodationRoomRatePlanType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationRoomRatePlanType } from "./list";

export const CreateRatePlan = (
    user: userType,
    accommodationId: number,
    roomId: number,
    name: string,
    code: string,
    price: number,
    description: string,
    discount: number,
) => {
    return new Promise<{ ratePlan: AccommodationRoomRatePlanType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "post",
                path: `admin/accommodation/${accommodationId}/room-type/${roomId}/rate-plan`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    code,
                    price,
                    description,
                    discount,
                }),
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        ratePlan: convertToAccommodationRoomRatePlanType(
                            data.data,
                        ),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};
