import {
    AccommodationInventoryType,
    PaginationType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";
import { convertToAccommodationType } from "../accommodation";
import { convertToAccommodationRoomType } from "../room-type";

export const ListInventory = (
    user: userType,
    accommodationId: number,
    currentPage: number,
    roomId?: number[],
    dateFrom?: string,
    dateTo?: string,
    availability?: string,
) => {
    let params: any;

    if (
        roomId &&
        roomId.length > 0 &&
        dateFrom &&
        dateFrom.length > 0 &&
        dateTo &&
        dateTo.length > 0 &&
        availability &&
        availability.length > 0
    ) {
        params = {
            page: currentPage,
            room_type_ids: roomId,
            date_from: dateFrom,
            date_to: dateTo,
            availability: availability,
        };
    } else {
        params = {
            page: currentPage,
        };
    }

    return new Promise<{
        inventory: AccommodationInventoryType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/accommodation/${accommodationId}/inventory`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: params,
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    inventory: convertToAccommodationInventoryTypeList(
                        data.data,
                    ),
                    pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};

export const convertToAccommodationInventoryTypeList = (
    data: any[],
): AccommodationInventoryType[] => {
    return data.map((element) => convertToAccommodationInventoryType(element));
};

export const convertToAccommodationInventoryType = (
    data: any,
): AccommodationInventoryType => {
    let out: AccommodationInventoryType = {
        id: data?.id,
        accommodation: convertToAccommodationType(data?.accommodation),
        availability: data?.availability,
        date: data?.date,
        roomType: convertToAccommodationRoomType(data?.room_type),
    };

    return out;
};
