import { userType } from "../../../DTO";
import { Api } from "../../api";

export const DeleteRole = (user: userType, roleId: number) => {
    return new Promise<{ role: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/role/${roleId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    role: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
