import { SeatListType, VehicleModelType, userType } from "../../DTO";
import { Api } from "../../api";
import { convertToVehicle } from "./get_list";

export const ShowVehicle = (user: userType, id: number) => {
    return new Promise<{ vehicle: VehicleModelType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/vehicle/show/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    vehicle: castVehicleModelList(data.data),
                });
            })
            .catch((error: any) => {
                return reject(error.message);
            });
    });
};

export const castVehicleModelList = (data: []) => {
    return data.map((element: any) => {
        let out: VehicleModelType = {
            vehicle: convertToVehicle(element.vehicle),
            model: convertToSeatVehicleListType(element.model),
        };
        return out;
    });
};

export function convertToSeatVehicleListType(data: any[]): SeatListType[] {
    const tripList = data.map((item: any) => {
        return convertToSeatVehicleType(item);
    });
    return tripList;
}

export function convertToSeatVehicleType(item: any): SeatListType {
    const tripOb = {
        vehicle_id: item?.vehicle_id,
        id: item?.id,
        num: item?.num,
        col: Number(item?.col),
        row: Number(item?.row),
        status: item?.status,
        deleted_at: item?.deleted_at,
        created_at: item?.created_at,
        updated_at: item?.updated_at,
        capacity: item?.capacity,
    };
    return tripOb;
}
