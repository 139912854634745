import { SeatListType, SeatModelType, userType } from "../../../DTO";
import { Api } from "../../api";

export const SeatList = (user: userType) => {
    return new Promise<{ seatModel: SeatModelType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/seat/model/get",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    seatModel: convertToSeatModelListType(data.data),
                });
            })
            .catch((error) => {
                return reject(error.message);
            });
    });
};

export function convertToSeatModelListType(data: any): SeatModelType[] {
    const tripList = data.map((item: any) => {
        return convertToModelType(item);
    });
    return tripList;
}

export function convertToModelType(item: any): SeatModelType {
    const tripOb = {
        name: item.name,
        model: convertToSeatListType(item.model),
    };
    return tripOb;
}

export function convertToSeatListType(data: any): SeatListType[] {
    const tripList = data.map((item: any) => {
        return convertToSeatType(item);
    });
    return tripList;
}

export function convertToSeatType(item: any): SeatListType {
    const tripOb = {
        id: item?.id,
        num: item?.seat_num,
        col: Number(item?.seat_col),
        row: Number(item?.seat_row),
        status: item?.status,
        deleted_at: item?.deleted_at,
        created_at: item?.created_at,
        updated_at: item?.updated_at,
        capacity: item?.capacity,
    };
    return tripOb;
}
