import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { useAppParams } from "../../../Router/hook";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Link } from "react-router-dom";
import { Form, Modal, Table } from "react-bootstrap";
import { HotelApi } from "../../../api";
import Swal from "sweetalert2";
import { WithdrawalType } from "../../../DTO";
import { hasPermission } from "../../../Router";

export const HotelWithdrawalList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [withdrawal, setWithdrawal] = useState<WithdrawalType[]>([]);

    const { id } = useAppParams();

    useEffect(() => {
        fetchWithdrawal();
    }, [id]);

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("withdrawal")), href: `/hotel/withdrawal` },
    ];

    const fetchWithdrawal = () => {
        setLoading(true);

        HotelApi.WithdrawalApi.ListWithdrawal(user, Number(id))
            .then((data) => {
                setWithdrawal(data.withdrawal);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleCreateRequestWithdrawal = (
        type: "accept" | "reject",
        withdrawalId: number,
    ) => {
        setLoading(true);

        HotelApi.WithdrawalApi.AcceptRejectWithdrawal(
            user,
            Number(withdrawalId),
            type,
        )
            .then(() => {
                setLoading(false);
                fetchWithdrawal();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("withdrawalList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("withdrawalTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("adminFullName")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawal.length > 0 &&
                                    withdrawal.map((item, index: number) => (
                                        <tr
                                            key={index}
                                            className="align-baseline"
                                        >
                                            <td>{item.id}</td>
                                            <td>{item.accommodation?.name}</td>
                                            <td>{item.amount}</td>
                                            <td>
                                                {item.admin?.first_name}{" "}
                                                {item.admin?.last_name}
                                            </td>
                                            <td>
                                                <strong
                                                    className={`badge ${
                                                        item.status === "accept"
                                                            ? "bg-success"
                                                            : item.status ===
                                                              "pending"
                                                            ? "bg-warning"
                                                            : "bg-danger"
                                                    }`}
                                                >
                                                    {item.status}
                                                </strong>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-row justify-content-center align-items-center align-self-center gap-2">
                                                    {hasPermission(
                                                        "withdrawal-edit",
                                                        user,
                                                    ) &&
                                                        item.status ===
                                                            "pending" && (
                                                            <>
                                                                <button
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() =>
                                                                        handleCreateRequestWithdrawal(
                                                                            "reject",
                                                                            item.id,
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        "reject",
                                                                    )}
                                                                </button>
                                                                <button
                                                                    className="btn btn-sm btn-success"
                                                                    onClick={() =>
                                                                        handleCreateRequestWithdrawal(
                                                                            "accept",
                                                                            item.id,
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        "accept",
                                                                    )}
                                                                </button>
                                                            </>
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
