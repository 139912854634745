import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

import { BookingInfoType, PaginationType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { AirplaneApi } from "../../../api";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "../../../components";

export const ReportFlightList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [booking, setBooking] = useState<BookingInfoType[]>([]);
    const [pagination, stePagination] = useState<PaginationType>(
        useAppPagination(),
    );

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const navigate = useAppNavigate();

    useEffect(() => {
        fetchBookingInfoList(pagination.current_page);
    }, []);

    const fetchBookingInfoList = (page: number) => {
        setLoading(true);

        AirplaneApi.BookingListAirport(user, page)
            .then((data) => {
                setBooking(data.book);
                stePagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("flightReport"), href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("flightReport")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <div className="d-flex flex-column">
                    <CustomBreadCrumb breadCrumbList={crumbs} />
                    <div className="d-flex flex-column bg-custom-white shadow rounded p-3">
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2">
                            <h4 className="text-nowrap">{t("flightReport")}</h4>
                        </div>
                        <div className="p-4 text-center text-nowrap">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>{t("id")}</th>
                                        <th>{t("originLocationCode")}</th>
                                        <th>{t("destinationLocationCode")}</th>
                                        <th>{t("departureDate")}</th>
                                        <th>{t("totalAdult")}</th>
                                        <th>{t("price")}</th>
                                        <th>{t("reservedUntil")}</th>
                                        <th>{t("contractNo")}</th>
                                        <th>{t("status")}</th>
                                    </tr>
                                </thead>
                                <tbody className="align-baseline">
                                    {booking.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>
                                                {
                                                    item.flightDetail
                                                        ?.origin_location_code
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.flightDetail
                                                        ?.destination_location_code
                                                }
                                            </td>
                                            <td>
                                                {item.flightDetail?.departure_date_time.slice(
                                                    0,
                                                    10,
                                                )}
                                            </td>
                                            <td>
                                                {Number(
                                                    item.flightDetail?.adults,
                                                ) +
                                                    Number(
                                                        item.flightDetail
                                                            ?.child,
                                                    ) +
                                                    Number(
                                                        item.flightDetail
                                                            ?.infants,
                                                    )}
                                            </td>
                                            <td>
                                                {Number(
                                                    item?.amount,
                                                ).toLocaleString()}{" "}
                                                {item?.currency}
                                            </td>
                                            <td>
                                                {item?.reservedUntil.slice(
                                                    0,
                                                    10,
                                                )}{" "}
                                                {item?.reservedUntil.slice(
                                                    11,
                                                    19,
                                                )}
                                            </td>
                                            <td>{t(item?.contractNo)}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <CustomPagination
                                total={pagination.total}
                                perPage={pagination.per_page}
                                currentPage={pagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/report/flight?current_page=${pageNum}`,
                                    );
                                    fetchBookingInfoList(pageNum);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
