import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb, Loading } from "../../../../components";
import { RouteForm } from "../../../../form";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { TripApi } from "../../../../api";
import { useAppNavigate, useAppParams } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { RouteType } from "../../../../DTO";

export const RouteEdit: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [route, setRoute] = useState<RouteType>();

    const { id } = useAppParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("route"), href: "/definition/route/list" },
        { name: t("new"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchSingleRoute = () => {
        setLoading(true);

        TripApi.RouteApi.ShowRoute(user, Number(id))
            .then((data) => {
                setRoute(data.route);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchSingleRoute();
    }, [id]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("new")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <CustomBreadCrumb breadCrumbList={crumbs} />
            <div className="d-flex flex-column ">
                {route && (
                    <Formik<RouteForm.FormValues>
                        validationSchema={RouteForm.ValidationSchema(t)}
                        initialValues={{
                            cityInId: Number(route.city_in_id),
                            cityInName: `${route.city_in?.name} | ${route.city_in?.name_en} | ${route.city_in?.domain_code}`,
                            cityOutId: Number(route.city_out_id),
                            cityOutName: `${route.city_out?.name} | ${route.city_out?.name_en} | ${route.city_out?.domain_code}`,
                            status: "Active",
                        }}
                        onSubmit={(event) => {
                            setLoading(true);
                            TripApi.RouteApi.EditRoute(
                                user,
                                Number(id),
                                event.cityInId,
                                event.cityOutId,
                                event.status,
                            )
                                .then(() => {
                                    setLoading(false);
                                    navigate("/definition/route/list");
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    Swal.fire({
                                        icon: "error",
                                        text: err.data.message,
                                    });
                                });
                        }}
                        component={(props: any) => (
                            <RouteForm.RouteFormCreateEdit
                                submitTxt={t("submit")}
                                {...props}
                            />
                        )}
                    />
                )}
            </div>
        </div>
    );
};
