import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { useAppParams } from "../../../Router/hook";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Link } from "react-router-dom";
import { Form, Modal, Table } from "react-bootstrap";
import { HotelApi } from "../../../api";
import Swal from "sweetalert2";
import { WithdrawalType } from "../../../DTO";
import { hasPermission } from "../../../Router";

export const AccommodationWithdrawalList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalWithdrawal, setShowModalWithdrawal] =
        useState<boolean>(false);
    const [amount, setAmount] = useState<string>("");
    const [withdrawal, setWithdrawal] = useState<WithdrawalType[]>([]);

    const { id } = useAppParams();

    useEffect(() => {
        fetchAccommodationWithdrawal();
    }, [id]);

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("hotelAccommodation")), href: `/hotel/accommodation` },
        {
            name: `${String(t("hotelAccommodation"))} ${id}`,
            href: `/hotel/accommodation`,
        },
        {
            name: String(t("withdrawal")),
            href: "#",
        },
    ];

    const fetchAccommodationWithdrawal = () => {
        setLoading(true);

        HotelApi.AccommodationWithdrawalApi.ListAccommodationWithdrawal(
            user,
            Number(id),
        )
            .then((data) => {
                setWithdrawal(data.withdrawal);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleCreateRequestWithdrawal = () => {
        setLoading(true);

        HotelApi.AccommodationWithdrawalApi.CreateAccommodationWithdrawal(
            user,
            Number(id),
            Number(amount),
        )
            .then(() => {
                setLoading(false);
                setAmount("");
                setShowModalWithdrawal(false);
                fetchAccommodationWithdrawal();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("withdrawalList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("withdrawalTable")}</h4>
                        <div className="d-flex flex-row ">
                            {hasPermission(
                                "withdrawal-accommodation-create",
                                user,
                            ) && (
                                <button
                                    onClick={() => setShowModalWithdrawal(true)}
                                    className="btn btn-success"
                                >
                                    {t("withdrawalCreate")} +
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("adminFullName")}</th>
                                    <th>{t("status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawal.length > 0 &&
                                    withdrawal.map((item, index: number) => (
                                        <tr
                                            key={index}
                                            className="align-baseline"
                                        >
                                            <td>{item.id}</td>
                                            <td>{item.accommodation?.name}</td>
                                            <td>{item.amount}</td>
                                            <td>
                                                {item.admin?.first_name}{" "}
                                                {item.admin?.last_name}
                                            </td>
                                            <td>
                                                <strong
                                                    className={`badge ${
                                                        item.status === "accept"
                                                            ? "bg-success"
                                                            : item.status ===
                                                              "pending"
                                                            ? "bg-warning"
                                                            : "bg-danger"
                                                    }`}
                                                >
                                                    {item.status}
                                                </strong>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <Modal
                show={showModalWithdrawal}
                onHide={() => setShowModalWithdrawal(false)}
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group
                        controlId="accommodation-amount"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("amount")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="amount"
                            className="h3"
                            type="string"
                            name="amount"
                            placeholder={String(t("amountPlaceholder"))}
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <Form.Text className="text-danger">
                            {amount.length <= 0 && t("amountError")}
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-success"
                        disabled={amount.length <= 0}
                        onClick={handleCreateRequestWithdrawal}
                    >
                        {t("submit")}
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => setShowModalWithdrawal(false)}
                    >
                        {t("cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
