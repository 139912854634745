import { AccommodationType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationType } from "./list";

export const ShowAccommodation = (user: userType, id: number) => {
    return new Promise<{ accommodation: AccommodationType }>(
        (resolve, reject) => {
            const api = new Api({
                path: `admin/accommodation/${id}`,
                method: "get",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        accommodation: convertToAccommodationType(data.data),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};
