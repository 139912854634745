import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Swal from "sweetalert2";

import { FormValues, PropType } from "./type";
import { useAppLocation } from "../../Router/hook";
import { CityType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { AddressApi } from "../../api";
import { Shimmer } from "../../components";

export const RouteFormCreateEdit = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [cityInName, setCityInName] = useState(values.cityInName);
    const [cityInList, setCityInList] = useState<CityType[]>([]);
    const [selectedCityIn, setSelectedCityIn] = useState(values.cityInId);
    const [cityInLoading, setCityInLoading] = useState<boolean>(false);

    const [cityOutName, setCityOutName] = useState(values.cityOutName);
    const [cityOutList, setCityOutList] = useState<CityType[]>([]);
    const [selectedCityOut, setSelectedCityOut] = useState(values.cityOutId);
    const [cityOutLoading, setCityOutLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const fetchCityInList = (query: string) => {
        AddressApi.SearchCity(user, query)
            .then((data) => {
                setCityInList(data.data);
                setCityInLoading(false);
            })
            .catch((err) => {
                setCityInLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchCityOutList = (query: string) => {
        AddressApi.SearchCity(user, query)
            .then((data) => {
                setCityOutList(data.data);
                setCityOutLoading(false);
            })
            .catch((err) => {
                setCityOutLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const { pathname } = useAppLocation();

    useEffect(() => {
        values.cityInId = selectedCityIn;
    }, [selectedCityIn]);

    useEffect(() => {
        values.cityInName = cityInName;
    }, [cityInName]);

    const throttledSearchCityIn = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchCityInList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchCityIn.cancel();
        };
    }, [throttledSearchCityIn]);

    useEffect(() => {
        values.cityOutId = selectedCityOut;
    }, [selectedCityOut]);

    useEffect(() => {
        values.cityOutName = cityOutName;
    }, [cityOutName]);

    const throttledSearchCityOut = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchCityOutList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchCityOut.cancel();
        };
    }, [throttledSearchCityOut]);

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="trip-city-in-id"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("cityInName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="cityInName"
                            type="string"
                            name="cityInName"
                            onFocus={() => {
                                setCityInLoading(true);
                                fetchCityInList("");
                            }}
                            placeholder={String(t("placeholderCityInName"))}
                            onBlur={handleBlur}
                            value={cityInName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setCityInName(e.target.value);
                                throttledSearchCityIn(e);
                            }}
                            isInvalid={
                                touched.cityInName && !!errors.cityInName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {cityInList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setCityInList([])}
                                    className="position-absolute rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {cityInList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 g-cursor-pointer text-center w-100 border-bottom"
                                            onClick={() => {
                                                setSelectedCityIn(
                                                    Number(item.id),
                                                );
                                                setCityInName(
                                                    `${item.name} | ${item.name_en} | ${item.domain_code}`,
                                                );
                                                setCityInList([]);
                                            }}
                                        >
                                            {`${item.name} | ${item.name_en} | ${item.domain_code}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                cityInLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center position-absolute w-100 border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.cityInName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="trip-city-out-id"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("cityOutName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="cityOutName"
                            type="string"
                            onFocus={() => {
                                setCityOutLoading(true);
                                fetchCityOutList("");
                            }}
                            name="cityOutName"
                            placeholder={String(t("placeholderCityOutName"))}
                            onBlur={handleBlur}
                            value={cityOutName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setCityOutName(e.target.value);
                                throttledSearchCityOut(e);
                            }}
                            isInvalid={
                                touched.cityOutName && !!errors.cityOutName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {cityOutList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setCityOutList([])}
                                    className="position-absolute border overflow-auto rounded-2 border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {cityOutList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 g-cursor-pointer w-100 text-center border-bottom"
                                            onClick={() => {
                                                setSelectedCityOut(
                                                    Number(item.id),
                                                );
                                                setCityOutName(
                                                    `${item.name} | ${item.name_en} | ${item.domain_code}`,
                                                );
                                                setCityOutList([]);
                                            }}
                                        >
                                            {`${item.name} | ${item.name_en} | ${item.domain_code}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                cityOutLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center position-absolute w-100 border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.cityOutName}
                        </Form.Text>
                    </Form.Group>
                    {pathname.includes("/definition/route/edit") && (
                        <Form.Group
                            controlId="route-status"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>{t("status")}</Form.Label>
                            <Form.Select
                                aria-label="status"
                                className="h3"
                                name="status"
                                onBlur={handleBlur}
                                value={values.status}
                                onChange={handleChange}
                                isInvalid={touched.status && !!errors.status}
                            >
                                <option className="d-none">
                                    {t("placeholderStatus")}
                                </option>
                                <option
                                    value={"Active"}
                                    className="text-success"
                                >
                                    {t("active")}
                                </option>
                                <option
                                    value={"InActive"}
                                    className="text-warning"
                                >
                                    {t("inActive")}
                                </option>
                            </Form.Select>
                            <Form.Text className="text-danger">
                                {errors.status}
                            </Form.Text>
                        </Form.Group>
                    )}
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
