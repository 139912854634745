import React from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";

export const UserFormEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="user-firstName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("firstName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="firstName"
                            className="h3"
                            type="string"
                            name="firstName"
                            placeholder={String(t("firstNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Text className="text-danger">
                            {errors.firstName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-lastName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("lastName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            className="h3"
                            type="string"
                            name="lastName"
                            placeholder={String(t("lastNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Text className="text-danger">
                            {errors.lastName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="user-phone-number"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("phoneNumber")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="phone number"
                            className="h3"
                            type="string"
                            name="phoneNumber"
                            placeholder={t("placeholderPhoneNumber")}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={
                                touched.phoneNumber && !!errors.phoneNumber
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.phoneNumber}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
