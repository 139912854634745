import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { AddressForm } from "../../../form";
import { AddressApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { useTranslation } from "react-i18next";

export const HotelAddressCreate: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("addressList")), href: "/definition/address/list" },
        { name: String(t("create")), href: "#" },
    ];

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("addressNew")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("addressNew")}</h4>
                        <Formik<AddressForm.FormValues>
                            validationSchema={AddressForm.ValidationSchema(t)}
                            initialValues={{
                                cityInId: 0,
                                cityInName: "",
                                detail: "",
                                lat: "",
                                lng: "",
                                title: "",
                                buildingNumber: "",
                                postalCode: "",
                            }}
                            onSubmit={(event) => {
                                setLoading(true);
                                AddressApi.CreateAddress(
                                    user,
                                    event.title,
                                    event.detail,
                                    event.cityInId,
                                    Number(event.lat),
                                    Number(event.lng),
                                    event.postalCode,
                                    event.buildingNumber,
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate("/definition/address/list");
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <AddressForm.AddressCreateForm
                                    submitTxt={String(t("submit"))}
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
