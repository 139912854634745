import React, { FC, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { AddressType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components";
import { AddressApi } from "../../../api";
import { useTranslation } from "react-i18next";

export const HotelAddressList: FC = () => {
    const [address, setAddress] = useState<AddressType[]>([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("addressList")), href: "#" },
    ];

    useEffect(() => {
        fetchHotelAddressList();
    }, []);

    const fetchHotelAddressList = () => {
        setLoading(true);
        AddressApi.FetchAddress(user)
            .then((data) => {
                setAddress(data.address);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("addressList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("addressTable")}</h4>
                        <div className="d-flex flex-row ">
                            <Link
                                to={`/definition/address/new`}
                                className="btn btn-success"
                            >
                                {t("add")} +
                            </Link>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("title")}</th>
                                    <th>{t("detail")}</th>
                                    <th>{t("cityName")}</th>
                                    <th>{t("postalCode")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {address.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.title}</td>
                                        <td>{item?.detail}</td>
                                        <td>{item.city?.name}</td>
                                        <td>{item.postalCode}</td>
                                        <td className="align-baseline">
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/definition/address/info/${item.id}`}
                                                    className="btn btn-info btn-sm"
                                                >
                                                    {t("info")}
                                                </Link>
                                                <Link
                                                    to={`/definition/address/edit/${item.id}`}
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                    />
                                                </Link>
                                                <button
                                                    onClick={() => {
                                                        Swal.fire({
                                                            text: `${t(
                                                                "alertDelete",
                                                            )}, ${
                                                                item.title
                                                            }"?`,
                                                            icon: "warning",
                                                            showCancelButton:
                                                                true,
                                                            confirmButtonColor:
                                                                "#3085d6",
                                                            cancelButtonColor:
                                                                "#d33",
                                                            cancelButtonText:
                                                                String(
                                                                    t(
                                                                        t(
                                                                            "cancel",
                                                                        ),
                                                                    ),
                                                                ),
                                                            confirmButtonText:
                                                                String(
                                                                    t(
                                                                        "yesDelete",
                                                                    ),
                                                                ),
                                                        }).then((result) => {
                                                            if (
                                                                result.isConfirmed
                                                            ) {
                                                                AddressApi.DeleteAddress(
                                                                    user,
                                                                    Number(
                                                                        item.id,
                                                                    ),
                                                                )
                                                                    .then(
                                                                        (
                                                                            data,
                                                                        ) => {
                                                                            setLoading(
                                                                                false,
                                                                            );
                                                                            fetchHotelAddressList();
                                                                        },
                                                                    )
                                                                    .catch(
                                                                        (
                                                                            err,
                                                                        ) => {
                                                                            setLoading(
                                                                                false,
                                                                            );
                                                                        },
                                                                    );
                                                            }
                                                        });
                                                    }}
                                                    className="btn btn-sm btn-danger"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};
