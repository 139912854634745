import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { SeatModelType } from "../../../../DTO";
import { VehicleApi } from "../../../../api";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CustomBreadCrumb, Loading } from "../../../../components";
import { CarForm } from "../../../../form";
import { useAppNavigate } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const CreateCar: React.FC = () => {
    const [seatModel, setSeatModel] = useState<SeatModelType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("riding"), href: "/vehicle/car/list" },
        { name: t("createCar"), href: "#" },
    ];

    const navigate = useAppNavigate();

    useEffect(() => {
        setLoading(true);
        VehicleApi.SeatApi.SeatList(user)
            .then((data) => {
                setSeatModel(data.seatModel);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("create")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <Formik<CarForm.FormValues>
                    validationSchema={CarForm.ValidationSchema(t)}
                    initialValues={{
                        seatModel: 0,
                        model: "BUS",
                        title: "",
                    }}
                    onSubmit={(event) => {
                        setLoading(true);

                        VehicleApi.CreateVehicle(
                            user,
                            event.seatModel,
                            event.title,
                            event.model,
                        )
                            .then(() => {
                                navigate("/vehicle/car/list");
                                setLoading(false);
                            })
                            .catch((error) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    text: error.data.message,
                                });
                            });
                    }}
                    component={(props: any) => (
                        <CarForm.CarFormCreate
                            submitTxt={t("submit")}
                            seatModel={seatModel}
                            {...props}
                        />
                    )}
                />
            </div>
        </>
    );
};
