import { userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToWithdrawalTypeList } from "../../payment/withdrawal";

export const ListAccommodationWithdrawal = (
    user: userType,
    accommodationId: number,
) => {
    return new Promise<{ withdrawal: any }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/withdrawal/accommodation/${accommodationId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    withdrawal: convertToWithdrawalTypeList(data.data.data),
                });
            })
            .catch((err) => reject(err.data.message));
    });
};
