import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";

import { WithdrawalType } from "../../../DTO";
import { PaymentApi } from "../../../api";
import { useAppNavigate } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { CustomBreadCrumb, Loading } from "../../../components";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const WithdrawalList: FC = () => {
    const [withdrawal, setWithdrawal] = useState<WithdrawalType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("withdrawalList"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const fetchWithdrawalList = () => {
        setLoading(true);
        PaymentApi.WithdrawalApi.ListWithdrawal(user)
            .then((data) => {
                setWithdrawal(data.withdrawal);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchWithdrawalList();
    }, []);

    const handleAcceptWithdrawal = (id: number) => {
        setLoading(true);
        PaymentApi.WithdrawalApi.AcceptWithdrawal(user, id)
            .then(() => {
                setLoading(false);
                fetchWithdrawalList();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleRejectWithdrawal = (id: number) => {
        setLoading(true);
        PaymentApi.WithdrawalApi.RejectWithdrawal(user, id)
            .then(() => {
                setLoading(false);
                fetchWithdrawalList();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("withdrawalList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("withdrawalTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("userPhone")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawal.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.amount}</td>
                                        <td>{item?.description}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item?.status === "accept"
                                                        ? "badge bg-success"
                                                        : item?.status ===
                                                          "pending"
                                                        ? "badge bg-warning"
                                                        : "bg-danger"
                                                }
                                            >
                                                {item?.status}
                                            </strong>
                                        </td>
                                        <td>{item.user?.phone}</td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/app-user/info/${item.user?.id}`}
                                                    className="btn btn-sm btn-info"
                                                >
                                                    {t("userInfo")}
                                                </Link>
                                                {item?.status === "pending" && (
                                                    <>
                                                        <button
                                                            className="btn btn-sm btn-success"
                                                            onClick={() =>
                                                                handleAcceptWithdrawal(
                                                                    Number(
                                                                        item?.id,
                                                                    ),
                                                                )
                                                            }
                                                        >
                                                            {t("accept")}
                                                        </button>
                                                        <button
                                                            className="btn btn-sm btn-danger"
                                                            onClick={() =>
                                                                handleRejectWithdrawal(
                                                                    Number(
                                                                        item?.id,
                                                                    ),
                                                                )
                                                            }
                                                        >
                                                            {t("reject")}
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};
