import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { Loading, StaticMap, CustomBreadCrumb } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CityType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { useTranslation } from "react-i18next";

export const CityInfo = () => {
    const [cityOB, setCityOB] = useState<CityType>();
    const [loading, setLoading] = useState(false);

    const user = useAppSelector(selectUser);

    const { id } = useParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("cityList")), href: "/definition/city" },
        { name: `${t("info")} ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchCityOB();
    }, [id]);

    const fetchCityOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchCityId(user, id)
                .then((data) => {
                    setCityOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("infoCity")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="my-2">{t("infoCity")}</h4>
                            <Link
                                to={`/definition/city/edit/${id}`}
                                className="btn btn-warning "
                            >
                                {t("edit")}
                            </Link>
                        </div>
                        {cityOB && (
                            <div className="d-flex flex-row flex-wrap">
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("id")}</span>
                                    <span>{cityOB?.id} </span>
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("cityName")}</span>
                                    {cityOB?.name}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("enCityName")}</span>
                                    {cityOB?.name_en}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("status")}</span>
                                    <strong
                                        className={`badge ${
                                            cityOB?.status == "Active"
                                                ? "bg-success"
                                                : "bg-danger"
                                        }`}
                                    >
                                        {cityOB?.status == "Active"
                                            ? t("active")
                                            : t("inactive")}
                                    </strong>
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("statusCar")}</span>
                                    <strong
                                        className={`badge ${
                                            cityOB?.statusCar == "Active"
                                                ? "bg-success"
                                                : "bg-danger"
                                        }`}
                                    >
                                        {cityOB?.statusCar == "Active"
                                            ? t("active")
                                            : t("inactive")}
                                    </strong>
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("statusAccommodation")}</span>
                                    <strong
                                        className={`badge ${
                                            cityOB?.statusAccommodation ==
                                            "Active"
                                                ? "bg-success"
                                                : "bg-danger"
                                        }`}
                                    >
                                        {cityOB?.statusAccommodation == "Active"
                                            ? t("active")
                                            : t("inactive")}
                                    </strong>
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("statusFlight")}</span>
                                    <strong
                                        className={`badge ${
                                            cityOB?.statusFlight == "Active"
                                                ? "bg-success"
                                                : "bg-danger"
                                        }`}
                                    >
                                        {cityOB?.statusFlight == "Active"
                                            ? t("active")
                                            : t("inactive")}
                                    </strong>
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("domainCode")}</span>
                                    {cityOB?.domain_code}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("createdAt")}</span>
                                    {cityOB?.created_at?.slice(0, 10)}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("lastUpdated")}</span>
                                    {cityOB?.updated_at?.slice(0, 10)}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("provinceName")}</span>
                                    {cityOB?.province?.name}
                                </div>
                                <div className="col-12 col-md-6 mb-2 gap-2 d-flex flex-row ">
                                    <span>{t("enProvinceName")}</span>
                                    {cityOB?.province?.name_en}
                                </div>
                                <div className="col-12 mb-2 gap-2 d-flex flex-row p-2">
                                    <StaticMap
                                        location={{
                                            lat: Number(cityOB.latitude),
                                            lng: Number(cityOB.longitude),
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
