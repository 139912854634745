import { FC, useEffect, useState } from "react";
import { AccommodationBalanceType } from "../../../DTO";
import { useAppParams } from "../../../Router/hook";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { HotelApi } from "../../../api";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Table } from "react-bootstrap";

export const BalanceList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [balance, setBalance] = useState<AccommodationBalanceType>();

    const { id } = useAppParams();

    useEffect(() => {
        fetchBalanceList();
    }, [id]);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        {
            name: String(t("hotelAccommodation")),
            href: `/hotel/accommodation/list`,
        },
        {
            name: `${String(t("hotelAccommodation"))} ${id}`,
            href: `/hotel/accommodation`,
        },
        {
            name: String(t("balance")),
            href: "#",
        },
    ];

    const user = useAppSelector(selectUser);

    const fetchBalanceList = () => {
        setLoading(true);

        HotelApi.BalanceApi.AccommodationBalance(user, Number(id))
            .then((data) => {
                setBalance(data.balance);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("balance")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("balanceTable")}</h4>
                        <div className="d-flex flex-row ">
                            <div className="d-flex flex-row gap-2">
                                <h4>{t("balance")}:</h4>
                                <h4 className="text-success fa-fade">
                                    {Number(balance?.balance).toLocaleString()}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("bookingPrice")}</th>
                                    <th>{t("bookingPriceDollar")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balance &&
                                    balance?.data.length > 0 &&
                                    balance &&
                                    balance?.data.map((item, index: number) => (
                                        <tr
                                            key={index}
                                            className="align-baseline"
                                        >
                                            <td>{item.id}</td>
                                            <td>
                                                {item.amount.toLocaleString()}
                                            </td>
                                            <td>{item.type}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                {item.booking.price.toLocaleString()}
                                            </td>
                                            <td>
                                                {item.booking.priceDollar.toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
