import { AddressType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToCity } from "./cityList";

export const FetchAddress = (user: userType, title?: string) => {
    return new Promise<{ address: AddressType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/address",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                title,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    address: castAddressList(data.data.data),
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const castAddressList = (data: any[]): AddressType[] => {
    return data.map((element) => castAddress(element));
};

export const castAddress = (data: any): AddressType => {
    let out: AddressType = {
        id: data?.id,
        title: data?.title,
        detail: data?.detail,
        postalCode: data?.postal_code,
        latitude: data?.latitude,
        longitude: data?.longitude,
        city: convertToCity(data?.city),
    };

    return out;
};

export const CreateAddress = (
    user: userType,
    title: string,
    detail: string,
    cityId: number,
    lat: number,
    lng: number,
    postalCode: string,
    buildingNumber: string,
) => {
    return new Promise<{ address: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/address",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                detail,
                city_id: cityId,
                lat,
                lng,
                postal_code: postalCode,
                building_number: buildingNumber,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    address: data.data,
                });
            })
            .catch((error) => reject(error.data.message));
    });
};

export const ShowAddress = (user: userType, addressId: number) => {
    return new Promise<{ address: AddressType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/address/${addressId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    address: castAddress(data.data),
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};

export const EditAddress = (
    user: userType,
    addressId: number,
    title: string,
    detail: string,
    cityId: number,
    lat: number,
    lng: number,
    postalCode: string,
    buildingNumber: string,
) => {
    return new Promise<{ address: any }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/address/${addressId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                detail,
                city_id: cityId,
                lat,
                lng,
                postal_code: postalCode,
                building_number: buildingNumber,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    address: data.data,
                });
            })
            .catch((error) => reject(error));
    });
};

export const DeleteAddress = (user: userType, addressId: number) => {
    return new Promise<{ address: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/address/${addressId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => resolve({ address: data.data }))
            .catch((err) => reject(err.data.message));
    });
};
