import { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import _ from "lodash";

import { FormValues, PropType } from "./type";
import { VehicleModelType, VehicleType } from "../../DTO";
import { VehicleApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { Shimmer } from "../../components";

export const DriverFormCreate = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [vehicleName, setVehicleName] = useState(values.vehicleName);
    const [vehicleList, setVehicleList] = useState<VehicleModelType[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState(values.vehicleId);
    const [vehicleLoading, setVehicleLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    useEffect(() => {
        values.vehicleId = selectedVehicle;
    }, [selectedVehicle]);

    useEffect(() => {
        values.vehicleName = vehicleName;
    }, [vehicleName]);

    const fetchVehicleList = (query: string) => {
        VehicleApi.FetchList(user, query)
            .then((data) => {
                setVehicleList(data.data);
                setVehicleLoading(false);
            })
            .catch((err) => {
                setVehicleLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const throttledSearchVehicle = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchVehicleList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchVehicle.cancel();
        };
    }, [throttledSearchVehicle]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="driver-firstName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("firstName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="firstName"
                            className="h3"
                            type="string"
                            name="firstName"
                            placeholder={String(t("firstNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Text className="text-danger">
                            {errors.firstName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-lastName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("lastName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="lastName"
                            className="h3"
                            type="string"
                            name="lastName"
                            placeholder={String(t("lastNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Text className="text-danger">
                            {errors.lastName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-passportId"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("passportId")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="passportId"
                            className="h3"
                            type="number"
                            name="passportId"
                            placeholder={String("passportIdPlaceholder")}
                            onBlur={handleBlur}
                            value={values.passportId}
                            onChange={handleChange}
                            isInvalid={
                                touched.passportId && !!errors.passportId
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.passportId}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-licenceId"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("licenseId")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="licenceId"
                            className="h3"
                            type="number"
                            name="licenceId"
                            placeholder={String(t("licenseIdPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.licenceId}
                            onChange={handleChange}
                            isInvalid={touched.licenceId && !!errors.licenceId}
                        />
                        <Form.Text className="text-danger">
                            {errors.licenceId}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group
                        controlId="trip-vehicle"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("vehicleName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="vehicleName"
                            type="string"
                            name="vehicleName"
                            onFocus={() => {
                                setVehicleLoading(true);
                                fetchVehicleList("");
                            }}
                            placeholder={String(t("vehicleNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={vehicleName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setVehicleName(e.target.value);
                                throttledSearchVehicle(e);
                            }}
                            isInvalid={
                                touched.vehicleName && !!errors.vehicleName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {vehicleList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setVehicleList([])}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {vehicleList.map((item, index) => (
                                        <strong
                                            key={index}
                                            className="p-3 text-center border-bottom w-100 g-cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedVehicle(
                                                    Number(item.vehicle?.id),
                                                );
                                                setVehicleName(
                                                    `${item?.vehicle?.title}`,
                                                );
                                                setVehicleList([]);
                                            }}
                                        >
                                            {`${item?.vehicle?.title}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                vehicleLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="position-absolute w-100 text-center border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.vehicleName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-phoneNumber"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("phoneNumber")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="phone number"
                            className="h3"
                            type="string"
                            name="phoneNumber"
                            placeholder={String(t("placeholderPhoneNumber"))}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={
                                touched.phoneNumber && !!errors.phoneNumber
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.phoneNumber}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-commission"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("commission")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="commission"
                            className="h3"
                            pattern="[0-9]*"
                            max={100}
                            type="number"
                            name="commission"
                            placeholder={String(t("commissionPlaceholder"))}
                            value={values.commission}
                            onChange={handleChange}
                        />
                        <Form.Text className="text-danger">
                            {errors.commission}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-commission-vip"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("commissionVip")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="commissionVip"
                            className="h3"
                            pattern="[0-9]*"
                            max={100}
                            type="number"
                            name="commissionVip"
                            placeholder={String(t("commissionVipPlaceholder"))}
                            value={values.commissionVip}
                            onChange={handleChange}
                        />
                        <Form.Text className="text-danger">
                            {errors.commissionVip}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
