import { ActualFileObject } from "filepond";
import { AccommodationType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationType } from "./list";

export const CreateAccommodation = (
    user: userType,
    name: string,
    code: string,
    addressId: number,
    description: string,
    star: number,
    policy: string,
    attachments: File[],
    facilities: string[],
) => {
    const data = new FormData();

    data.append("name", name);
    data.append("policy", policy);
    data.append("description", description);
    data.append("code", code);
    data.append("star", String(star));
    data.append("address_id", String(addressId));
    attachments.forEach((element) => {
        return data.append("attachments[]", element);
    });
    facilities.forEach((item) => {
        return data.append("facilities[]", item);
    });

    return new Promise<{ accommodation: AccommodationType }>(
        (resolve, reject) => {
            const api = new Api({
                path: "admin/accommodation",
                method: "post",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "multipart/form-data",
                },
                body: data,
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        accommodation: convertToAccommodationType(data.data),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};
