import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { CustomBreadCrumb, Loading } from "../../../components";
import { UserForm } from "../../../form";
import { UserAppType } from "../../../DTO";
import { AppUserApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate } from "../../../Router/hook";

export const EditUser: React.FC = () => {
    const [appUser, setAppUser] = useState<UserAppType>();
    const [loading, setLoading] = useState<boolean>(false);

    const { id } = useParams();

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    useEffect(() => {
        fetchSingleUser();
    }, [id]);

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("userList")), href: "/app-user" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];

    const fetchSingleUser = () => {
        setLoading(true);

        AppUserApi.ShowUser(user, Number(id))
            .then((data) => {
                setAppUser(data.user);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("userEdit")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {appUser && (
                        <Formik<UserForm.FormValues>
                            validationSchema={UserForm.ValidationSchemaEdit(t)}
                            initialValues={{
                                firstName: String(appUser.first_name),
                                lastName: String(appUser.last_name),
                                phoneNumber: String(appUser.phone),
                            }}
                            onSubmit={(event) => {
                                setLoading(true);

                                AppUserApi.EditUser(
                                    user,
                                    Number(id),
                                    event.firstName,
                                    event.lastName,
                                    event.phoneNumber,
                                )
                                    .then((data) => navigate("/app-user"))
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <UserForm.UserFormEdit
                                    submitTxt={String(t("submit"))}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
