import React from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";

export const CountryFormEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="country-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="country-enName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("enName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="enName"
                            className="h3"
                            type="string"
                            name="enName"
                            placeholder={String("placeholderEnName")}
                            onBlur={handleBlur}
                            value={values.enName}
                            onChange={handleChange}
                            isInvalid={touched.enName && !!errors.enName}
                        />
                        <Form.Text className="text-danger">
                            {errors.enName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="country-domainCode"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("domainCode")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="domainCode"
                            className="h3"
                            type="string"
                            name="domainCode"
                            placeholder={String(t("placeholderDomainCode"))}
                            onBlur={handleBlur}
                            value={values.domainCode}
                            onChange={handleChange}
                            isInvalid={
                                touched.domainCode && !!errors.domainCode
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.domainCode}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
