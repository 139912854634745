import { PriceCurrencyType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPriceCurrencyType } from "./show";

export const CreatePriceCurrency = (
    user: userType,
    price: number,
    type: "dollarToDinar" | "dollarToRial" | "dinarToRial",
) => {
    return new Promise<{ priceCurrency: PriceCurrencyType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "post",
                path: `admin/price/currency`,
                body: JSON.stringify({
                    price,
                    type,
                }),
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        priceCurrency: convertToPriceCurrencyType(data.data),
                    });
                })
                .catch((error: any) => reject(error.message));
        },
    );
};
