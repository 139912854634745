import { userType } from "../../../DTO";
import { Api } from "../../api";

export const CashConfirm = (
    user: userType,
    accommodationId: number,
    bookingId: number,
    hasConfirm: boolean,
) => {
    return new Promise<{ confirm: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/accommodation/${accommodationId}/booking/${bookingId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                confirm: hasConfirm,
            }),
        });

        return api
            .call()
            .then((data: any) => resolve({ confirm: data.data }))
            .catch((err) => reject(err.data.message));
    });
};
