import React, { useState, useEffect, FC } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Carousel, Form, Modal, Table } from "react-bootstrap";

import {
    AccommodationConfigType,
    AccommodationConfigValueType,
    AccommodationType,
} from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelApi } from "../../../api";
import StarRating from "../../../components/star-rating";
import { useTranslation } from "react-i18next";
import { config } from "@fortawesome/fontawesome-svg-core";

export const HotelAccommodationShow: FC = () => {
    const [hotel, setHotel] = useState<AccommodationType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [hotelConfigList, setHotelConfigList] = useState<
        AccommodationConfigType[]
    >([]);
    const [hotelConfig, setHotelConfig] = useState<AccommodationConfigType>();
    const [showModalConfig, setShowModalConfig] = useState<boolean>(false);
    const [configKey, setConfigKey] = useState<string>("");
    const [configValue, setConfigValue] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<
        AccommodationConfigValueType[]
    >([]);
    const [configValueCancel, setConfigValueCancel] = useState<
        AccommodationConfigValueType[]
    >([]);
    const [configId, setConfigId] = useState<string>("");
    const [configType, setConfigType] = useState<"create" | "edit">("create");
    const [valueCancel12, setValueCancel12] =
        useState<AccommodationConfigValueType>({ hour: 12, percent: "" });
    const [valueCancel24, setValueCancel24] =
        useState<AccommodationConfigValueType>({ hour: 24, percent: "" });
    const [valueCancel48, setValueCancel48] =
        useState<AccommodationConfigValueType>({ hour: 48, percent: "" });
    const [valueCancel72, setValueCancel72] =
        useState<AccommodationConfigValueType>({ hour: 72, percent: "" });
    const [valueType, setValueType] = useState<"cancel" | "normal">("normal");

    const user = useAppSelector(selectUser);

    const { id } = useParams();

    const { t } = useTranslation();

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("hotelAccommodation"), href: "/hotel/accommodation/list" },
        { name: `${t("accommodation")} ${id}`, href: "#" },
    ];

    const handleCreateConfig = () => {
        setLoading(true);

        HotelApi.ConfigApi.CreateConfigAccommodation(
            user,
            Number(id),
            configKey,
            valueType === "cancel"
                ? JSON.stringify([
                      valueCancel12,
                      valueCancel24,
                      valueCancel48,
                      valueCancel72,
                  ])
                : configValue,
        )
            .then((data) => {
                setShowModalConfig(false);
                setLoading(false);
                fetchHotelConfigList();
                setConfigValue("");
                setConfigKey("");
                setConfigId("");
                setConfigValueCancel([]);
            })
            .catch((err) => {
                setLoading(false);
                setShowModalConfig(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleEditConfig = () => {
        setLoading(true);

        HotelApi.ConfigApi.EditConfigAccommodation(
            user,
            Number(id),
            Number(configId),
            configKey,
            valueType === "cancel"
                ? JSON.stringify([
                      valueCancel12,
                      valueCancel24,
                      valueCancel48,
                      valueCancel72,
                  ])
                : configValue,
        )
            .then((data) => {
                setShowModalConfig(false);
                setLoading(false);
                setConfigValue("");
                setConfigKey("");
                setConfigId("");
                setConfigValueCancel([]);
                fetchHotelConfigList();
            })
            .catch((err) => {
                setLoading(false);
                setShowModalConfig(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchAccommodationId = () => {
        setLoading(true);

        HotelApi.AccommodationApi.ShowAccommodation(user, Number(id))
            .then((data) => {
                setLoading(false);
                setHotel(data.accommodation);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchHotelConfigList = () => {
        setLoading(true);

        HotelApi.ConfigApi.ListConfigAccommodation(user, Number(id))
            .then((data) => {
                setHotelConfigList(data.accommodationConfig);
                data.accommodationConfig.forEach((element) => {
                    if (element.key === "cancellation") {
                        setValueCancel12(element.value[0]);
                        setValueCancel24(element.value[1]);
                        setValueCancel48(element.value[2]);
                        setValueCancel72(element.value[3]);
                    }
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const fetchHotelConfigById = (configId: number) => {
        setLoading(true);

        HotelApi.ConfigApi.ShowConfigAccommodation(user, Number(id), configId)
            .then((data) => {
                setHotelConfig(data.accommodationConfig);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchAccommodationId();
        fetchHotelConfigList();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("info")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {hotel && (
                    <>
                        <div className="d-flex flex-row flex-wrap rounded px-3">
                            <div className="col-12  p-2">
                                <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-row position-relative">
                                    <div className="col-12 col-lg-4 h-100">
                                        <Carousel>
                                            {hotel.attachments.data.map(
                                                (hotelImage) => (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={
                                                                hotelImage.path
                                                            }
                                                            alt={
                                                                hotelImage.path
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "contain",
                                                                height: "300px",
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ),
                                            )}
                                        </Carousel>
                                    </div>
                                    <div className="col-12 col-lg-8 d-flex flex-row">
                                        <div className="col-12 col-lg-6 d-flex h-100 flex-column gap-1 justify-content-center align-items-center ">
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("name")}:</span>
                                                <strong>{hotel.name}</strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("code")}:</span>
                                                <strong>{hotel.code}</strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("status")}:</span>
                                                <strong
                                                    className={
                                                        hotel.status ===
                                                        "active"
                                                            ? "badge bg-success"
                                                            : "badge bg-danger"
                                                    }
                                                >
                                                    {hotel.status}
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-row gap-2">
                                                <span>{t("address")}:</span>
                                                <strong>
                                                    {hotel.address.title}
                                                </strong>
                                            </div>
                                            <StarRating rating={hotel.star} />
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1 ">
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("description")}:</span>
                                                <strong className="text-break">
                                                    {hotel.description}
                                                </strong>
                                            </div>
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("policy")}:</span>
                                                <strong className="text-break">
                                                    {hotel.policy}
                                                </strong>
                                            </div>
                                            <hr />
                                            <div className="d-flex flex-column gap-1">
                                                <span>{t("facilities")}:</span>
                                                <div className="d-flex flex-row gap-2 flex-wrap">
                                                    {hotel.facilities.map(
                                                        (element) => (
                                                            <strong
                                                                className="bg-custom-light p-1 rounded-2"
                                                                key={element.id}
                                                            >
                                                                {element.name}
                                                            </strong>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-2 mt-4">
                            <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                                <div className="d-flex flex-row justify-content-between px-4">
                                    <h4 className="text-nowrap">
                                        {t("configTable")}
                                    </h4>
                                    {/* <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setShowModalConfig(true);
                                            setConfigType("create");
                                            setValueType("normal");
                                            setConfigKey("");
                                        }}
                                    >
                                        {t("create")}
                                    </button> */}
                                </div>
                                <div
                                    className="overflow-auto"
                                    style={{ maxHeight: "15rem" }}
                                >
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>{t("id")}</th>
                                                <th>{t("key")}</th>
                                                <th>{t("value")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hotelConfigList.length > 0 &&
                                                hotelConfigList.map(
                                                    (item, index: number) => (
                                                        <tr key={index}>
                                                            <td>{item?.id}</td>
                                                            <td>{item.key}</td>
                                                            <td>
                                                                {item.key ==
                                                                "cancellation" ? (
                                                                    <strong
                                                                        onClick={() => {
                                                                            setValueType(
                                                                                "cancel",
                                                                            );
                                                                            setShowAlert(
                                                                                true,
                                                                            );
                                                                            setModalValue(
                                                                                Array.isArray(
                                                                                    item.value,
                                                                                )
                                                                                    ? item.value
                                                                                    : [],
                                                                            );
                                                                        }}
                                                                        className={
                                                                            "shadow p-2 rounded-2 g-cursor-pointer"
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "showConfigValue",
                                                                        )}
                                                                    </strong>
                                                                ) : (
                                                                    <strong
                                                                        onClick={() =>
                                                                            setValueType(
                                                                                "normal",
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            item.value
                                                                        }
                                                                    </strong>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        if (
                                                                            Array.isArray(
                                                                                item.value,
                                                                            )
                                                                        ) {
                                                                            setValueType(
                                                                                "cancel",
                                                                            );
                                                                            setConfigKey(
                                                                                item.key,
                                                                            );
                                                                            setShowModalConfig(
                                                                                true,
                                                                            );
                                                                            setConfigType(
                                                                                "edit",
                                                                            );
                                                                            setConfigId(
                                                                                String(
                                                                                    item.id,
                                                                                ),
                                                                            );
                                                                        } else {
                                                                            setValueType(
                                                                                "normal",
                                                                            );
                                                                            setConfigKey(
                                                                                item.key,
                                                                            );
                                                                            setShowModalConfig(
                                                                                true,
                                                                            );
                                                                            fetchHotelConfigById(
                                                                                item.id,
                                                                            );

                                                                            setConfigValue(
                                                                                item.value,
                                                                            );
                                                                            setConfigId(
                                                                                String(
                                                                                    item.id,
                                                                                ),
                                                                            );
                                                                            setConfigType(
                                                                                "edit",
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="btn btn-sm btn-warning"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faEdit
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                            {/* <td>
                                                                <button
                                                                    onClick={() =>
                                                                        Swal.fire(
                                                                            {
                                                                                text: `${t(
                                                                                    "alertDelete",
                                                                                )} ${
                                                                                    item.key
                                                                                }?`,
                                                                                icon: "warning",
                                                                                showCancelButton:
                                                                                    true,
                                                                                confirmButtonColor:
                                                                                    "#3085d6",
                                                                                cancelButtonColor:
                                                                                    "#d33",
                                                                                confirmButtonText:
                                                                                    String(
                                                                                        t(
                                                                                            "yesDelete",
                                                                                        ),
                                                                                    ),
                                                                                cancelButtonText:
                                                                                    String(
                                                                                        t(
                                                                                            "cancel",
                                                                                        ),
                                                                                    ),
                                                                            },
                                                                        ).then(
                                                                            (
                                                                                result,
                                                                            ) => {
                                                                                setLoading(
                                                                                    true,
                                                                                );
                                                                                HotelApi.ConfigApi.DeleteConfigAccommodation(
                                                                                    user,
                                                                                    Number(
                                                                                        id,
                                                                                    ),
                                                                                    item.id,
                                                                                )
                                                                                    .then(
                                                                                        () => {
                                                                                            setLoading(
                                                                                                false,
                                                                                            );
                                                                                            if (
                                                                                                result.isConfirmed
                                                                                            ) {
                                                                                                Swal.fire(
                                                                                                    String(
                                                                                                        t(
                                                                                                            "delete",
                                                                                                        ),
                                                                                                    ),
                                                                                                    String(
                                                                                                        t(
                                                                                                            "deleteMessage",
                                                                                                        ),
                                                                                                    ),
                                                                                                    "success",
                                                                                                );
                                                                                                fetchHotelConfigList();
                                                                                                fetchAccommodationId();
                                                                                            }
                                                                                        },
                                                                                    )
                                                                                    .catch(
                                                                                        (
                                                                                            err,
                                                                                        ) => {
                                                                                            setLoading(
                                                                                                false,
                                                                                            );
                                                                                            Swal.fire(
                                                                                                {
                                                                                                    icon: "error",
                                                                                                    text: err,
                                                                                                },
                                                                                            );
                                                                                        },
                                                                                    );
                                                                            },
                                                                        )
                                                                    }
                                                                    className="btn btn-sm btn-danger"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                    />
                                                                </button>
                                                            </td> */}
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Modal
                show={showModalConfig}
                onHide={() => {
                    setShowModalConfig(false);
                    setConfigValueCancel([]);
                    setConfigValue("");
                    setConfigKey("");
                }}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("config")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        controlId="config-key"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("key")}</Form.Label>
                        <Form.Control
                            disabled
                            autoComplete="off"
                            aria-label="configKey"
                            className="h3"
                            type="string"
                            name="configKey"
                            placeholder={String(t("keyPlaceholder"))}
                            value={configKey}
                            onChange={(e) => setConfigKey(e.target.value)}
                        />
                        {configKey.length <= 0 && (
                            <Form.Text className="text-danger">
                                {t("keyError")}
                            </Form.Text>
                        )}
                    </Form.Group>
                    {valueType === "cancel" ? (
                        <div className={"d-flex flex-column gap-2"}>
                            <Form.Group
                                controlId="config-value"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>
                                    <span>{t("hour")}: 12</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="configValue"
                                    className="h3"
                                    type="string"
                                    name="configValue"
                                    placeholder={`${t("percent")}`}
                                    value={valueCancel12.percent}
                                    onChange={(e) => {
                                        setValueCancel12({
                                            hour: 12,
                                            percent: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="config-value"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>
                                    <span>{t("hour")}: 24</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="configValue"
                                    className="h3"
                                    type="string"
                                    name="configValue24"
                                    placeholder={`${t("percent")}`}
                                    value={valueCancel24.percent}
                                    onChange={(e) => {
                                        setValueCancel24({
                                            hour: 24,
                                            percent: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="config-value"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>
                                    <span>{t("hour")}: 48</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="configValue"
                                    className="h3"
                                    type="string"
                                    name="configValue48"
                                    placeholder={`${t("percent")}`}
                                    value={valueCancel48.percent}
                                    onChange={(e) => {
                                        setValueCancel48({
                                            hour: 48,
                                            percent: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="config-value"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>
                                    <span>{t("hour")}: 72</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="configValue"
                                    className="h3"
                                    type="string"
                                    name="configValue72"
                                    placeholder={`${t("percent")}`}
                                    value={valueCancel72.percent}
                                    onChange={(e) => {
                                        setValueCancel72({
                                            hour: 72,
                                            percent: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </div>
                    ) : (
                        valueType === "normal" && (
                            <Form.Group
                                controlId="config-value"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>{t("value")}</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="configValue"
                                    className="h3"
                                    type="string"
                                    name="configValue"
                                    placeholder={String(t("valuePlaceholder"))}
                                    value={configValue}
                                    onChange={(e) =>
                                        setConfigValue(e.target.value)
                                    }
                                />
                                {configValue.length <= 0 && (
                                    <Form.Text className="text-danger">
                                        {t("valueError")}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setConfigKey("");
                            setConfigValue("");
                            setShowModalConfig(false);
                            setConfigValueCancel([]);
                        }}
                    >
                        {t("close")}
                    </Button>
                    <Button
                        variant="success"
                        onClick={
                            configType === "create"
                                ? handleCreateConfig
                                : handleEditConfig
                        }
                    >
                        {t("submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAlert}
                onHide={() => setShowAlert(false)}
                centered
                size={"sm"}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="d-flex flex-column gap-3">
                        {modalValue.map((element, index) => (
                            <div
                                key={index}
                                className={
                                    "d-flex flex-row gap-5 border-bottom"
                                }
                            >
                                <span>
                                    {t("hour")}: {element.hour}
                                </span>
                                |
                                <span>
                                    {t("percent")}: {element.percent}
                                </span>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
