import { PortType, userType } from "../../DTO";
import { Api } from "../api";
import { castPort } from "./list";

export const EditPort = (
    user: userType,
    id: number,
    name: string,
    description: string,
    type: "AIR" | "CAR" | "BUS",
    status: "Active" | "InActive",
    addressId: number,
) => {
    return new Promise<{ port: PortType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/port/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                description,
                type,
                address_id: addressId,
                status,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    port: castPort(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
