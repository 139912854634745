import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";

import { PaginationType, WalletType } from "../../../DTO";
import { PaymentApi } from "../../../api";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "../../../components";
import { useTranslation } from "react-i18next";

export const WalletList: FC = () => {
    const [wallets, setWallets] = useState<WalletType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("walletList"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchListWallet = (page: number) => {
        setLoading(true);
        PaymentApi.WalletApi.WalletAllList(user, page)
            .then((data) => {
                setWallets(data.wallet);
                // setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchListWallet(pagination.current_page);
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("walletList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("walletTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("balance")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("currency")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("user")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wallets.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item?.type === "increase"
                                                        ? "badge bg-success"
                                                        : "badge bg-danger"
                                                }
                                            >
                                                {item?.type}
                                            </strong>
                                        </td>
                                        <td>{item?.balance}</td>
                                        <td>{item?.amount}</td>
                                        <td>{item?.description}</td>
                                        <td>{item?.currency}</td>
                                        <td>
                                            {" "}
                                            <strong
                                                className={
                                                    item?.status === "active"
                                                        ? "badge bg-success"
                                                        : "badge bg-danger"
                                                }
                                            >
                                                {item?.status}
                                            </strong>
                                        </td>
                                        <td>
                                            {item?.user?.first_name}{" "}
                                            {item?.user?.last_name}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/payment/wallet?current_page=${pageNum}`,
                                );
                                fetchListWallet(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
