import React, { FC, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import { PaginationType, AccommodationRoomRatePlanType } from "../../../../DTO";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { HotelApi } from "../../../../api";
import {
    useAppNavigate,
    useAppPagination,
    useAppParams,
} from "../../../../Router/hook";
import "./style.scss";
import { useTranslation } from "react-i18next";

export const HotelRatePlanList: FC = () => {
    const [ratePlan, setRatePlan] = useState<AccommodationRoomRatePlanType[]>(
        [],
    );
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [rateSearch, setRateSearch] = useState<string>("");

    const { id, roomId } = useAppParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/list`,
        },
        {
            name: t("ratePlan"),
            href: "#",
        },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchHotelRatePlanList = (page: number) => {
        setLoading(true);

        HotelApi.RatePlanApi.ListRatePlan(
            user,
            Number(id),
            Number(roomId),
            page,
            rateSearch,
        )
            .then((data) => {
                setRatePlan(data.ratePlan);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    };

    useEffect(() => {
        fetchHotelRatePlanList(pagination.current_page);
    }, [id, roomId]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("ratePlan")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("ratePlanTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="show-rate-search-input"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <Link
                                to={`/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/new`}
                                className="btn btn-success"
                            >
                                {t("create")} +
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="show-rate-search-input"></input>
                    <div className="rate-plan-search  p-2 flex-row justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("ratePlan")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="number"
                                        placeholder={String(
                                            t("ratePlanPlaceholder"),
                                        )}
                                        onChange={(e) =>
                                            setRateSearch(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button
                            className="btn btn-sm btn-success mx-1 text-nowrap"
                            disabled={rateSearch.length <= 0}
                            onClick={() =>
                                fetchHotelRatePlanList(pagination.current_page)
                            }
                        >
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("code")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("discount")}</th>
                                    <th>{t("description")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ratePlan.length > 0 &&
                                    ratePlan.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.code}</td>
                                            <td>
                                                {item.price.toLocaleString()}
                                            </td>
                                            <td>{item.discount}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                <Link
                                                    className="btn btn-sm btn-warning"
                                                    to={`/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/edit/${item.id}`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                    />
                                                </Link>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() =>
                                                        Swal.fire({
                                                            text: String(
                                                                t(
                                                                    "alertDelete",
                                                                ),
                                                            ),
                                                            icon: "warning",
                                                            showCancelButton:
                                                                true,
                                                            confirmButtonColor:
                                                                "#3085d6",
                                                            cancelButtonColor:
                                                                "#d33",
                                                            confirmButtonText:
                                                                String(
                                                                    t(
                                                                        "yesDelete",
                                                                    ),
                                                                ),
                                                            cancelButtonText:
                                                                String(
                                                                    t("cancel"),
                                                                ),
                                                        }).then((result) => {
                                                            setLoading(true);
                                                            HotelApi.RatePlanApi.DeleteRatePlan(
                                                                user,
                                                                Number(id),
                                                                Number(roomId),
                                                                item.id,
                                                            )
                                                                .then(() => {
                                                                    setLoading(
                                                                        false,
                                                                    );
                                                                    if (
                                                                        result.isConfirmed
                                                                    ) {
                                                                        Swal.fire(
                                                                            String(
                                                                                t(
                                                                                    "delete",
                                                                                ),
                                                                            ),
                                                                            String(
                                                                                "deleteMessage",
                                                                            ),
                                                                            "success",
                                                                        );
                                                                        fetchHotelRatePlanList(
                                                                            pagination.current_page,
                                                                        );
                                                                    }
                                                                })
                                                                .catch(
                                                                    (err) => {
                                                                        setLoading(
                                                                            false,
                                                                        );
                                                                        Swal.fire(
                                                                            {
                                                                                icon: "error",
                                                                                text: err,
                                                                            },
                                                                        );
                                                                    },
                                                                );
                                                        })
                                                    }
                                                    className="btn btn-sm btn-danger"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/hotel/accommodation/${id}/room-type/${roomId}/rate-plan/list?current_page=${pageNum}`,
                                );
                                fetchHotelRatePlanList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
