import { userType } from "../../../DTO";
import { Api } from "../../api";

export const DeleteAccommodation = (user: userType, id: number) => {
    return new Promise<{ accommodation: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/accommodation/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    accommodation: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
