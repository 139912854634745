import { userType } from "../../DTO";
import { Api } from "../api";
import {
    convertPermission,
    convertPermissionList,
    convertToPermissionList,
} from "../user-access/permission";
import { convertRoleList } from "../user-access/role";

export const FetchUserLogin = (phone: string, password: string) => {
    return new Promise<{ data: userType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/login",
            body: {
                phone: phone,
                password: password,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToUserType(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export function convertToUserType(item: any): userType {
    const returnUser: userType = {
        id: item?.user?.id,
        username: item?.username,
        first_name: item?.user?.first_name,
        last_name: item?.user?.last_name,
        phone: item?.user?.phone,
        email: item?.user?.email,
        code: item?.user?.code,
        status: item?.user?.status,
        avatar: item?.user?.avatar,
        sheba_num: item?.user?.sheba_num,
        bank_cart: item?.user?.bank_cart,
        identity_code: item?.user?.identity_code,
        last_login: item?.user?.last_login,
        phone_verified_at: item?.user?.phone_verified_at,
        deleted_at: item?.user?.deleted_at,
        created_at: item?.user?.created_at,
        updated_at: item?.user?.updated_at,
        attachments: item?.user?.attachments,
        access_token: item?.access_token,
        role: String(item?.role[0]?.name),
        permissions: convertPermissionList(item?.permissions),
    };
    return returnUser;
}
