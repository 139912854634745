import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, Table } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import { Link } from "react-router-dom";

import { BookingType, PaginationType } from "../../../DTO";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { BookingApi } from "../../../api/hotel";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "../../../components";
import { convertDateFormat } from "../../../form/trip/logic";
import "../style.scss";
export const ReportHotelList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reportHotel, setReportHotel] = useState<BookingType[]>([]);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [accommodationId, setAccommodationId] = useState<number>();
    const [status, setStatus] = useState<
        "finalized" | "reserved" | "not_confirmed" | "canceled" | string
    >();
    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const navigate = useAppNavigate();

    useEffect(() => {
        fetchReportBookingList(pagination.current_page);
    }, []);

    const fetchReportBookingList = (page: number) => {
        setLoading(true);

        BookingApi.ReportBooking(
            user,
            page,
            accommodationId,
            status,
            fromDate,
            toDate,
        )
            .then((data) => {
                setReportHotel(data.report);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("hotelReport"), href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("hotelReport")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <div className="d-flex flex-column">
                    <CustomBreadCrumb breadCrumbList={crumbs} />
                    <div className="d-flex flex-column bg-custom-white shadow rounded p-3">
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2">
                            <h4 className="text-nowrap">{t("hotelReport")}</h4>
                            <div className="d-flex flex-row ">
                                <label
                                    htmlFor="showAdminSearchInput"
                                    className="btn bg-info mx-1 text-nowrap"
                                >
                                    <span>{t("filter")}</span>
                                    <FontAwesomeIcon
                                        className={"mx-1"}
                                        icon={faSearch}
                                    />
                                </label>
                            </div>
                        </div>
                        <input
                            type="checkbox"
                            id="showAdminSearchInput"
                        ></input>
                        <div className="adminSearchBox  p-2 flex-row gap-3 align-items-center">
                            <div>
                                <div className="d-flex flex-row flex-wrap gap-3">
                                    <Form.Group>
                                        <Form.Label className="mb-0">
                                            {t("id")}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder={String(
                                                t("idPlaceholder"),
                                            )}
                                            onChange={(e) =>
                                                setAccommodationId(
                                                    Number(e.target.value),
                                                )
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="mb-0">
                                            {t("status")}
                                        </Form.Label>
                                        <Form.Select
                                            onChange={(e) => {
                                                setStatus(e.target.value);
                                            }}
                                        >
                                            <option className="d-none text-center">
                                                {t("statusPlaceholder")}
                                            </option>
                                            <option
                                                className="text-center"
                                                value={"finalized"}
                                            >
                                                {t("finalized")}
                                            </option>
                                            <option
                                                className="text-center"
                                                value={"reserved"}
                                            >
                                                {t("reserved")}
                                            </option>
                                            <option
                                                className="text-center"
                                                value={"not_confirmed"}
                                            >
                                                {t("not_confirmed")}
                                            </option>
                                            <option
                                                className="text-center"
                                                value={"canceled"}
                                            >
                                                {t("canceled")}
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column">
                                        <Form.Label className="mb-0">
                                            {t("fromDate")}
                                        </Form.Label>
                                        <DatePicker
                                            value={fromDate}
                                            onChange={(array: any) => {
                                                if (array != null) {
                                                    setFromDate(
                                                        convertDateFormat(
                                                            array.toString?.(),
                                                        ),
                                                    );
                                                } else {
                                                    setFromDate("");
                                                }
                                            }}
                                            calendar={gregorian}
                                            locale={gregorian_en}
                                            format="YYYY/MM/DD"
                                        />
                                    </Form.Group>
                                    <Form.Group className="d-flex flex-column">
                                        <Form.Label className="mb-0">
                                            {t("toDate")}
                                        </Form.Label>
                                        <DatePicker
                                            value={toDate}
                                            onChange={(array: any) => {
                                                setToDate(
                                                    convertDateFormat(
                                                        array.toString?.(),
                                                    ),
                                                );
                                            }}
                                            calendar={gregorian}
                                            locale={gregorian_en}
                                            format="YYYY/MM/DD"
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    fetchReportBookingList(1);
                                }}
                                className="btn bg-success mx-1 text-nowrap align-self-end"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className="mx-1"
                                    icon={faSearch}
                                />
                            </button>
                        </div>
                        <div className="p-4 text-center text-nowrap">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>{t("id")}</th>
                                        <th>{t("hotelName")}</th>
                                        <th>{t("arrivalDate")}</th>
                                        <th>{t("departureDate")}</th>
                                        <th>{t("adult")}</th>
                                        <th>{t("price")}</th>
                                        <th>{t("priceDollar")}</th>
                                        <th>{t("status")}</th>
                                        <th>{t("hotelCommission")}</th>
                                        <th>{t("reservedAt")}</th>
                                    </tr>
                                </thead>
                                <tbody className="align-baseline">
                                    {reportHotel.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>
                                                {
                                                    item?.bookingRatePlans[0]
                                                        .accommodation.name
                                                }
                                            </td>
                                            <td>
                                                {item?.arrivalDate.slice(0, 10)}
                                            </td>
                                            <td>
                                                {item.departureDate.slice(
                                                    0,
                                                    10,
                                                )}
                                            </td>
                                            <td>{item?.adult}</td>
                                            <td>
                                                {item?.price.toLocaleString()}
                                            </td>
                                            <td>
                                                {item?.priceDollar.toLocaleString()}
                                            </td>
                                            <td>{t(item?.status)}</td>
                                            <td>{item.hotelCommission} %</td>
                                            <td>
                                                {item.reservedAt.slice(0, 10)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <CustomPagination
                                total={pagination.total}
                                perPage={pagination.per_page}
                                currentPage={pagination.current_page}
                                changePage={(pageNum: number) => {
                                    navigate(
                                        `/report/hotel?current_page=${pageNum}`,
                                    );
                                    fetchReportBookingList(pageNum);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
