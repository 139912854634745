import { useEffect, useState } from "react";

import { SeatListType, SeatModelType } from "../../DTO";
import { SeatUI } from "./seat-ui";

export const SeatShow: React.FC<{ seatData: SeatModelType }> = ({
    seatData,
}) => {
    const [show, setShow] = useState<SeatListType[]>([]);

    useEffect(() => {
        findLargestRowAndColumn([...seatData.model]);
    }, [seatData.model]);

    async function findLargestRowAndColumn(data: SeatListType[]) {
        let largestRow: Number = 0;
        let largestCol: Number = 0;

        data.forEach((item) => {
            if (item.row && item.col) {
                if (item.row > largestRow) {
                    largestRow = item.row;
                }
                if (item.col > largestCol) {
                    largestCol = item.col;
                }
            }
        });

        let emptySeat: SeatListType[] = [];
        [...Array(Number(largestRow) + 1)].map((_, indexRow) => {
            [...Array(Number(largestCol) + 1)].map((_, indexCol) => {
                emptySeat.push({
                    id: 0,
                    col: indexCol,
                    row: indexRow,
                });
            });
        });

        let fullSeat = emptySeat;
        emptySeat.forEach((item, index) => {
            data.forEach((itemAvl) => {
                if (item.col == itemAvl.col && item.row == itemAvl.row) {
                    fullSeat[index] = itemAvl;
                }
            });
        });

        setShow(fullSeat);
    }

    return (
        <div className=" p-2 col-12 col-md-6 col-lg-4">
            <div className="d-flex flex-column align-items-center border  p-3 h-100">
                <span>{seatData?.name}</span>
                <div className="border border-bottom my-3 w-100"></div>
                <div className="mt-3 d-flex flex-row flex-wrap">
                    {show.map((item, index) => {
                        return (
                            <>
                                {index > 0 &&
                                    show[index - 1]?.row != item.row && (
                                        <div className="w-100"></div>
                                    )}
                                {item.status != "Active" ? (
                                    <div
                                        style={{ width: "30px" }}
                                        className="mx-2 mb-2"
                                    ></div>
                                ) : (
                                    <div
                                        className="mx-2 mb-2 text-white"
                                        key={index}
                                    >
                                        <SeatUI
                                            color={"blue"}
                                            number={item.num + ""}
                                        />
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
