import { Api } from "../../api";
import { DealType, PaginationType, userType } from "../../../DTO";
import { convertToPaginationType } from "../../help";
import { convertToUser } from "../../driver";

export const TransactionList = (user: userType, currentPage: number) => {
    return new Promise<{ transaction: DealType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "transactions/admin",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        transaction: castDealList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    });
                })
                .catch((error) => reject(error));
        },
    );
};

export const castDealList = (data: any[]): DealType[] => {
    return data.map((element) => castDeal(element));
};

export const castDeal = (data: any): DealType => {
    let out: DealType = {
        id: data?.id,
        userId: data?.user_id,
        status: data?.status,
        type: data?.type,
        amount: data?.amount,
        gateway: data?.gateway,
        device: data?.device,
        description: data?.description,
        currency: data?.currency,
        authority: data?.authority,
        code: data?.code,
        refId: data?.ref_id,
        metaData: data?.meta_data,
        deletedAt: data?.deleted_at,
        createdAt: data?.created_at,
        updatedAt: data?.updated_at,
        user: convertToUser(data?.user),
    };

    return out;
};
