import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Loading, CustomBreadCrumb } from "../../../../components";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CountryType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useAppNavigate } from "../../../../Router/hook";

export const CountryInfo = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [countryOB, setCountryOB] = useState<CountryType>();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("country")), href: "/definition/country" },
        { name: ` ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchCountry();
    }, [id]);

    const fetchCountry = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchCountryId(user, id)
                .then((data) => {
                    setCountryOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("country")} | {t("country")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="my-2">{t("infoCountry")}</h4>
                            <Link
                                to={`/definition/country/${id}/edit`}
                                className="btn btn-warning "
                            >
                                {t("edit")}
                            </Link>
                        </div>
                        {countryOB && (
                            <>
                                <div className="d-flex flex-row flex-wrap">
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("id")}</span>
                                        <span>{countryOB?.id} </span>
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("title")}</span>
                                        {countryOB?.name}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("enName")}</span>
                                        {countryOB?.name_en}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("domainCode")}</span>
                                        {countryOB?.domain_code}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("createdAt")}</span>
                                        {countryOB?.created_at?.slice(0, 10)}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("lastUpdated")}</span>
                                        {countryOB?.updated_at?.slice(0, 10)}
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex flex-row flex-wrap">
                                    {countryOB.province?.map((element) => (
                                        <div className="col-12 col-md-6 mb-2">
                                            <span className="mx-1">
                                                Province:
                                            </span>
                                            {element.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
