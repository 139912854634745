import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";

import { Loading } from "../../../components";
import { useAppNavigate } from "../../../Router/hook";
import { AddressType } from "../../../DTO";
import { AddressApi, PortApi } from "../../../api";
import { AddressForm, PortForm } from "../../../form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const PortCreate: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalAddress, setShowModalAddress] = useState<boolean>(false);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("portList")), href: "/port/list" },
        { name: String(t("new")), href: "#" },
    ];

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("new")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />

                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <button
                        onClick={() => navigate("/definition/address/new")}
                        className="btn btn-info mx-1 text-nowrap justify-content-end align-self-end align-items-end"
                    >
                        <span>{t("addAddress")}</span>
                        <FontAwesomeIcon className={"mx-1"} icon={faAdd} />
                    </button>

                    <Formik<PortForm.FormValues>
                        validationSchema={PortForm.ValidationSchema(t)}
                        initialValues={{
                            name: "",
                            addressId: 0,
                            addressName: "",
                            description: "",
                            type: "AIR",
                            status: "Active",
                        }}
                        onSubmit={(event) => {
                            setLoading(true);
                            PortApi.CreatePort(
                                user,
                                event.name,
                                event.description,
                                event.addressId,
                                event.type,
                            )
                                .then(() => {
                                    setLoading(false);
                                    navigate("/definition/port/list");
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    Swal.fire({
                                        icon: "error",
                                        text: err.data.message,
                                    });
                                });
                        }}
                        component={(props: any) => (
                            <PortForm.PortFormCreateAndEdit
                                submitTxt={t("create")}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
