import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelForm } from "../../../form";
import { HotelApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { AccommodationRoomType } from "../../../DTO";
import { useTranslation } from "react-i18next";

export const HotelRoomTypeEdit: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [room, setRoom] = useState<AccommodationRoomType>();

    const { id, roomId } = useAppParams();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("roomType"),
            href: `/hotel/accommodation/${id}/room-type/list`,
        },
        { name: t("edit"), href: "#" },
    ];

    const fetchSingleRoomType = () => {
        setLoading(true);

        HotelApi.RoomTypeApi.ShowRoomType(user, Number(id), Number(roomId))
            .then((data) => {
                setRoom(data.roomType);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchSingleRoomType();
    }, [id, roomId]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("roomType")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("edit")}</h4>
                        {room && (
                            <Formik<HotelForm.RoomTypeForm.FormValues>
                                validationSchema={HotelForm.RoomTypeForm.ValidationSchema(
                                    t,
                                )}
                                initialValues={{
                                    name: room.name,
                                    description: room.description,
                                    code: room.code,
                                    adult: room.adult,
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    HotelApi.RoomTypeApi.EditRoomType(
                                        user,
                                        Number(id),
                                        Number(roomId),
                                        event.name,
                                        event.code,
                                        event.adult,
                                        event.description,
                                    )
                                        .then(() => {
                                            setLoading(false);
                                            navigate(
                                                `/hotel/accommodation/${id}/room-type/list`,
                                            );
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <HotelForm.RoomTypeForm.HotelRoomType
                                        submitTxt={t("submit")}
                                        {...props}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
