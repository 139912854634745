import React, { useEffect, useState } from "react";
import "./style.scss";
import { loginUserAction, selectUser } from "../../redux-config/entities/user";
import { useAppDispatch, useAppSelector } from "../../redux-config/hooks";
import { useTranslation } from "react-i18next";
import { setLocalItems } from "../../redux-config/hooks";

export const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const [phone, setPhone] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const handleLogin = (e: any) => {
        e.preventDefault();
        if (phone.length > 3 && password.length > 1) {
            dispatch(loginUserAction({ phone, password })).then((data) => {});
        }
    };

    useEffect(() => {
        setLocalItems("language", "en");
        if (user.access_token && user.access_token.length > 1) {
            window.location.href = "/";
        }
    }, [user]);

    return (
        <div className="loginContainer px-3">
            <div className="container bg-light shadow-lg rounded p-3 p-sm-5 ">
                <div className="row p-lg-5">
                    <div className="col-sm-6 d-sm-flex d-none">
                        <img
                            src="./image/login-image.webp"
                            id="login-img"
                            className="w-100"
                            style={{ objectFit: "contain" }}
                            alt=""
                        />
                    </div>
                    <div className="col-sm-6 p-3">
                        <form
                            onSubmit={(e: any) => {
                                handleLogin(e);
                            }}
                        >
                            <div>
                                <h4>{t("welcome")}</h4>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="phone">
                                    {t("phoneNumber")}
                                </label>
                                <input
                                    id="phone"
                                    dir="ltr"
                                    type="number"
                                    className="form-control"
                                    placeholder="0*********"
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="password">
                                    {t("password")}
                                </label>
                                <input
                                    id="password"
                                    dir="ltr"
                                    type="password"
                                    className="form-control"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-check mt-2">
                                <input
                                    id="law"
                                    className="form-check-input"
                                    type="checkbox"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="law"
                                >
                                    {t("rememberMe")}
                                </label>
                            </div>

                            <hr />
                            <button
                                className="btn btn-info text-white  w-100 mt-3"
                                type="submit"
                            >
                                {t("login")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
