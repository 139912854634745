import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, Table } from "react-bootstrap";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CountryType, PaginationType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { useAppLocation, useAppPagination } from "../../../../Router/hook";
import "./style.scss";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const CountryList = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [countryList, setCountryList] = useState<CountryType[]>([]);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("countryList")), href: "#" },
    ];

    useEffect(() => {
        fetchCountryList(pagination.current_page);
    }, []);

    const fetchCountryList = (page: number) => {
        setLoading(true);
        AddressApi.FetchCountryList(user, page)
            .then((data) => {
                setCountryList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("country")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("countryTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showAdminSearchInput"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("countrySearch")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/definition/country/create");
                                }}
                                href={"/definition/country/create"}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                <span>{t("create")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faPlus}
                                />
                            </a>
                        </div>
                    </div>
                    <input type="checkbox" id="showAdminSearchInput"></input>
                    <div className="adminSearchBox p-2  flex-wrap flex-sm-nowrap justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("id")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="number"
                                        placeholder={String(t("idPlaceholder"))}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("name")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="text"
                                        placeholder={String(
                                            t("placeholderName"),
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("enName")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="text"
                                        placeholder={String(
                                            t("placeholderEnName"),
                                        )}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button className="btn btn-sm btn-success mx-1 text-nowrap">
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("enName")}</th>
                                    <th>{t("domainCode")}</th>
                                    <th>{t("createdAt")}</th>
                                    <th>{t("updatedAt")}</th>
                                    <th>Status</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {countryList.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.name_en}</td>
                                        <td>{item.domain_code}</td>
                                        <td>{item.created_at?.slice(0, 10)}</td>
                                        <td>{item.updated_at?.slice(0, 10)}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item.status === "Active"
                                                        ? "bg-success badge"
                                                        : "bg-danger badge"
                                                }
                                            >
                                                {item.status}
                                            </strong>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/definition/country/${item.id}/edit`}
                                                className="btn btn-warning btn-sm"
                                            >
                                                {t("edit")}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/definition/country/${item.id}`}
                                                className="btn btn-info btn-sm"
                                            >
                                                {t("info")}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/definition/country/?current_page=${pageNum}`,
                                );
                                fetchCountryList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
