import { userType } from "../../../DTO";
import { Api } from "../../api";

export const AcceptWithdrawal = (user: userType, withdrawalId: number) => {
    return new Promise<{ withdrawal: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/withdrawal/${withdrawalId}/accept`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    withdrawal: data.data,
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};
