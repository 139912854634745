import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";

import { DiscountForm } from "../../form";
import { CustomBreadCrumb, Loading } from "../../components";
import { DiscountApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { useAppNavigate } from "../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const CreateDiscountCode: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("discountCode"), href: "/discount-code" },
        { name: t("new"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const createDiscountCode = (
        code: number,
        name: string,
        expireTime: string,
        amount: number,
    ) => {
        setLoading(true);
        DiscountApi.CreateDiscount(user, code, name, expireTime, amount)
            .then(() => {
                navigate("/discount-code");
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} |{t("discountCode")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <Formik<DiscountForm.FormValues>
                        validationSchema={DiscountForm.ValidationSchema(t)}
                        initialValues={{
                            name: "",
                            amount: 0,
                            code: 0,
                            expireTime: date,
                        }}
                        onSubmit={(event) => {
                            createDiscountCode(
                                event.code,
                                event.name,
                                date,
                                event.amount,
                            );
                        }}
                        component={(props: any) => (
                            <DiscountForm.DiscountCodeFormCreate
                                submitTxt={String(t("submit"))}
                                setDate={setDate}
                                date={date}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};
