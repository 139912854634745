import { userType } from "../../../DTO";
import { Api } from "../../api";

export const AcceptRejectWithdrawal = (
    user: userType,
    withdrawalId: number,
    action: "accept" | "reject",
) => {
    return new Promise<{ withdrawal: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/withdrawal/${withdrawalId}/accommodation`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                action,
            }),
        });

        return api
            .call()
            .then((data: any) => resolve({ withdrawal: data }))
            .catch((err) => reject(err.data.message));
    });
};
