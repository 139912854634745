import { PermissionType, userType } from "../../../DTO";
import { getLocalItems } from "../../hooks";
import { userStoreType } from "./type";

let user: userType = {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    code: "",
    status: "",
    avatar: "",
    sheba_num: "",
    bank_cart: "",
    identity_code: "",
    last_login: "",
    phone_verified_at: "",
    deleted_at: "",
    created_at: "",
    updated_at: "",
    attachments: [],
    access_token: "",
    permissions: [],
    role: "",
};

if (
    JSON.parse(getLocalItems("user") + "") != null &&
    JSON.parse(getLocalItems("user") + "") != ""
) {
    user = JSON.parse(getLocalItems("user") + "");
}

let permissions: PermissionType[] = [];

if (
    JSON.parse(localStorage.getItem(`siteName-user-permissions`) + "") != null
) {
    permissions = JSON.parse(
        localStorage.getItem(`siteName-user-permissions`) + "",
    );
}

export const initialState: userStoreType = {
    value: { ...user, permissions: permissions },
    status: "idle",
};
