import React, { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { FormValues, PropType } from "./type";
import { CityType } from "../../DTO";
import { AddressApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import CustomMap from "../../components/map";
import { Shimmer } from "../../components";

export const AddressCreateForm = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [cityInName, setCityInName] = useState(values.cityInName);
    const [cityList, setCityList] = useState<CityType[]>([]);
    const [selectedCityIn, setSelectedCityIn] = useState(values.cityInId);
    const [cityLoading, setCityLoading] = useState<boolean>(false);

    const [lat, setLat] = useState(values.lat);
    const [lng, setLng] = useState(values.lng);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    useEffect(() => {
        values.lat = lat;
    }, [lat]);

    useEffect(() => {
        values.lng = lng;
    }, [lng]);

    const fetchCityInList = (query: string) => {
        AddressApi.SearchCity(user, query)
            .then((data) => {
                setCityList(data.data);
                setCityLoading(false);
            })
            .catch((err) => {
                setCityLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        values.cityInId = selectedCityIn;
    }, [selectedCityIn]);

    useEffect(() => {
        values.cityInName = cityInName;
    }, [cityInName]);

    const throttledSearchCityIn = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchCityInList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchCityIn.cancel();
        };
    }, [throttledSearchCityIn]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="address-title"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("title")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="title"
                            className="h3"
                            type="string"
                            name="title"
                            placeholder={String(t("placeholderTitle"))}
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Text className="text-danger">
                            {errors.title}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="address-detail"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("detail")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            as={"textarea"}
                            rows={3}
                            aria-label="detail"
                            className="h3"
                            type="string"
                            name="detail"
                            placeholder={String(t("placeholderDetail"))}
                            onBlur={handleBlur}
                            value={values.detail}
                            onChange={handleChange}
                            isInvalid={touched.detail && !!errors.detail}
                        />
                        <Form.Text className="text-danger">
                            {errors.detail}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="address-postalCode"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("postalCode")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="postalCode"
                            className="h3"
                            type="string"
                            name="postalCode"
                            placeholder={String(t("postalCodePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.postalCode}
                            onChange={handleChange}
                            isInvalid={
                                touched.postalCode && !!errors.postalCode
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.postalCode}
                        </Form.Text>
                    </Form.Group>{" "}
                    <Form.Group
                        controlId="address-buildingNumber"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("buildingNumber")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="buildingNumber"
                            className="h3"
                            type="string"
                            name="buildingNumber"
                            placeholder={String(t("buildingPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.buildingNumber}
                            onChange={handleChange}
                            isInvalid={
                                touched.buildingNumber &&
                                !!errors.buildingNumber
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.buildingNumber}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="address-city-in-id"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("cityInName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="cityInName"
                            type="string"
                            onFocus={() => {
                                setCityLoading(true);
                                fetchCityInList("");
                            }}
                            name="cityInName"
                            placeholder={String(t("placeholderCityInName"))}
                            onBlur={handleBlur}
                            value={cityInName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setCityInName(e.target.value);
                                throttledSearchCityIn(e);
                            }}
                            isInvalid={
                                touched.cityInName && !!errors.cityInName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {cityList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                    onMouseLeave={() => setCityList([])}
                                >
                                    {cityList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 border-bottom w-100 text-center"
                                            onClick={() => {
                                                setSelectedCityIn(
                                                    Number(item.id),
                                                );
                                                setCityInName(item.name);
                                                setCityList([]);
                                            }}
                                        >
                                            {item.name}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                cityLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center w-100 position-absolute border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.cityInName}
                        </Form.Text>
                    </Form.Group>
                    {values.lat != "null" && values.lng != "null" && (
                        <div className="col-12 mb-2 p-2">
                            <CustomMap
                                location={{
                                    lat: Number(values.lat),
                                    lng: Number(values.lng),
                                }}
                                markerChange={(data: any) => {
                                    setLat(data.lat);
                                    setLng(data.lng);
                                }}
                                height={"300px"}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
