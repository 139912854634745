import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { ProvinceType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useAppNavigate } from "../../../../Router/hook";

export const ProvinceInfo = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [province, setProvinceOB] = useState<ProvinceType>();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("province"), href: "/definition/province" },
        { name: ` ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchProvince();
    }, [id]);

    const fetchProvince = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchProvinceId(user, id)
                .then((data) => {
                    setProvinceOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("province")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="my-2">{t("provinceInfo")}</h4>
                            <Link
                                to={`/definition/province/edit/${id}`}
                                className="btn btn-warning "
                            >
                                {t("edit")}
                            </Link>
                        </div>
                        {province && (
                            <>
                                <div className="d-flex flex-row flex-wrap">
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("id")}</span>
                                        <span>{province?.id} </span>
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("provinceName")}: </span>
                                        {province?.name}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("provinceEnName")}: </span>
                                        {province?.name_en}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("domainCode")}: </span>
                                        {province?.domain_code}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("createdAt")}</span>
                                        {province?.created_at?.slice(0, 10)}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("lastUpdated")}</span>
                                        {province?.updated_at?.slice(0, 10)}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("countryName")}: </span>
                                        {province?.country?.name}
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <span>{t("enCountryName")}</span>
                                        {province?.country?.name_en}
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex flex-row flex-wrap">
                                    {province.city?.map((element) => (
                                        <div className="col-12 col-md-6 mb-2">
                                            <span className="mx-1">
                                                {t("city")}:
                                            </span>
                                            {element.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
