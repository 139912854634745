import React, { FC, useEffect, useState } from "react";
import { PaginationType, PortType } from "../../../DTO";
import { Helmet } from "react-helmet";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "../../../components";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, Table } from "react-bootstrap";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { PortApi } from "../../../api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const PortList: FC = () => {
    const [port, setPort] = useState<PortType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );

    const navigate = useAppNavigate();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("portList"), href: "#" },
    ];

    const fetchPortList = (page: number) => {
        setLoading(true);

        PortApi.ListPort(user, page)
            .then((data) => {
                setPort(data.port);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.data.message,
                });
            });
    };

    useEffect(() => {
        fetchPortList(pagination.current_page);
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("portList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("portTable")}</h4>
                        <div className="d-flex flex-row ">
                            <Link
                                to={"/definition/port/new"}
                                className="btn btn-primary mx-1 text-nowrap"
                            >
                                {t("createPort")}
                            </Link>
                        </div>
                    </div>

                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("addressName")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {port.map((item) => (
                                    <tr key={item?.id}>
                                        <td>{item?.id}</td>
                                        <td>{item?.type}</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.address?.title}</td>
                                        <td>
                                            <Link
                                                to={`/definition/port/info/${item?.id}`}
                                                className="btn btn-info btn-sm"
                                            >
                                                {t("info")}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/definition/port/edit/${item?.id}`}
                                                className="btn btn-warning btn-sm"
                                            >
                                                {t("edit")}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(`/port/list?current_page=${pageNum}`);
                                fetchPortList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
