import { userType } from "../../../DTO";
import { Api } from "../../api";

export const CreateCommission = (user: userType, commission: number) => {
    return new Promise<{ commission: any }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/flight/commission`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                commission,
            }),
        });

        return api
            .call()
            .then((data: any) =>
                resolve({
                    commission: data.data,
                }),
            )
            .catch((err) => reject(err.data.message));
    });
};
