import { GatewayType, userType } from "../../../DTO";
import { Api } from "../../api";

export const ListGateway = (user: userType) => {
    return new Promise<{ gateway: GatewayType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/gateway",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    gateway: convertToGatewayTypeList(data.data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToGatewayTypeList = (data: any[]): GatewayType[] => {
    return data.map((element) => convertToGatewayType(element));
};

export const convertToGatewayType = (data: any): GatewayType => {
    let out: GatewayType = {
        currency: data?.currency,
        description: data?.description,
        gate: data?.gate,
        id: data?.id,
        logo: data?.logo,
        name: data?.name,
        status: data?.status,
    };

    return out;
};
