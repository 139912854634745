import React from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";

export const HotelRatePlan = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="rate-plan-title"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="rate-plan-description"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            as={"textarea"}
                            cols={5}
                            rows={5}
                            aria-label="description"
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={String(t("placeholderDescription"))}
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={
                                touched.description && !!errors.description
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.description}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="rate-plan-code"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("code")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="code"
                            className="h3"
                            type="string"
                            name="code"
                            placeholder={String(t("codePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.code}
                            onChange={handleChange}
                            isInvalid={touched.code && !!errors.code}
                        />
                        <Form.Text className="text-danger">
                            {errors.code}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="rate-plan-discount"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("discount")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="discount"
                            className="h3"
                            type="number"
                            name="discount"
                            placeholder={String(t("discountPlaceholder"))}
                            onBlur={handleBlur}
                            value={values.discount}
                            onChange={handleChange}
                            isInvalid={touched.discount && !!errors.discount}
                        />
                        <Form.Text className="text-danger">
                            {errors.discount}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="rate-plan-price"
                        className="position-relative col-12 col-md-6 p-2"
                    >
                        <Form.Label>{t("price")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="price"
                            className="h3"
                            type="number"
                            name="price"
                            placeholder={String(t("pricePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.price}
                            onChange={handleChange}
                            isInvalid={touched.price && !!errors.price}
                        />
                        <Form.Text className="text-danger">
                            {errors.price}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
