import { RoleType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertRole } from "./list";

export const EditRole = (
    user: userType,
    roleId: number,
    name: string,
    permissions: number[],
) => {
    return new Promise<{ role: RoleType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/role/${roleId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                permissions,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    role: convertRole(data.data),
                });
            })
            .catch((error: any) => {
                reject(error.data.message);
            });
    });
};
