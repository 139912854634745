import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelForm } from "../../../form";
import { HotelApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import {
    AccommodationAttachmentsDataType,
    AccommodationType,
} from "../../../DTO";
import { useTranslation } from "react-i18next";

export const HotelAccommodationEdit: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [star, setStar] = useState<number>(0);
    const [hotel, setHotel] = useState<AccommodationType>();
    const [hotelImages, setHotelImages] = useState<
        AccommodationAttachmentsDataType[]
    >([]);
    const [currentAttributes, setCurrentAttributes] = useState<string[]>([]);

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const callbackAttributes = (attributes: string[]) => {
        setCurrentAttributes(attributes);
    };

    const { id } = useParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("hotelAccommodation"), href: "/hotel/accommodation/list" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];

    const fetchHotelAccommodationById = () => {
        setLoading(true);

        HotelApi.AccommodationApi.ShowAccommodation(user, Number(id))
            .then((data) => {
                setHotel(data.accommodation);
                setStar(data.accommodation.star);
                setHotelImages(data.accommodation.attachments.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchHotelAccommodationById();
    }, [id]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | ${t("hotelAccommodation")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("create")}</h4>
                        {hotel && (
                            <Formik<HotelForm.AccommodationForm.FormValues>
                                validationSchema={HotelForm.AccommodationForm.ValidationSchema(
                                    t,
                                )}
                                initialValues={{
                                    status: hotel.status,
                                    description: hotel.description,
                                    name: hotel.name,
                                    addressId: hotel.address.id,
                                    addressName: hotel.address.title,
                                    policy: hotel.policy,
                                    code: hotel.code,
                                    contents: [],
                                    attribute: "",
                                    attributes: [],
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    HotelApi.AccommodationApi.EditAccommodation(
                                        user,
                                        Number(id),
                                        event.status,
                                        event.name,
                                        event.code,
                                        Number(event.addressId),
                                        event.description,
                                        star,
                                        event.policy,
                                        event.contents,
                                        currentAttributes,
                                    )
                                        .then(() => {
                                            setLoading(false);
                                            navigate(
                                                "/hotel/accommodation/list",
                                            );
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <HotelForm.AccommodationForm.HotelAccommodationCreateEdit
                                        submitTxt={t("submit")}
                                        star={star}
                                        setStar={setStar}
                                        hotelImages={hotelImages}
                                        callbackAttributes={callbackAttributes}
                                        {...props}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
