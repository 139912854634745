import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { Form, Table } from "react-bootstrap";
import { Loading, CustomBreadCrumb } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CityType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import "./style.scss";

export const CityList = () => {
    const [loading, setLoading] = useState(false);
    const [cityList, setCityList] = useState<CityType[]>([]);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("cityList")), href: "#" },
    ];

    useEffect(() => {
        fetchCityList();
    }, []);

    const fetchCityList = () => {
        setLoading(true);
        AddressApi.FetchCityList(user, 1)
            .then((data) => {
                setCityList(data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("cityList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("cityTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="showAdminSearchInput"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            <Link
                                to={"/definition/city/create"}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                <span>{t("create")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faPlus}
                                />
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="showAdminSearchInput"></input>
                    <div className="adminSearchBox p-2  flex-wrap flex-sm-nowrap justify-content-between align-items-center">
                        <div>
                            <Form className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("id")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="number"
                                        placeholder={t("idPlaceholder")}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("name")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="text"
                                        placeholder={String(
                                            t("placeholderName"),
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mx-2 my-2"
                                    style={{ minWidth: "230px" }}
                                >
                                    <Form.Label className="mb-0">
                                        {t("enName")}
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        size="sm"
                                        type="text"
                                        placeholder={String(
                                            t("placeholderEnName"),
                                        )}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <button className="btn btn-sm btn-success mx-1 text-nowrap">
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("enName")}</th>
                                    <th>{t("domainCode")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody className="align-baseline">
                                {cityList.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.name_en}</td>
                                        <td>{item.domain_code}</td>
                                        <td>
                                            <strong
                                                className={
                                                    item.status === "Active"
                                                        ? "bg-success badge"
                                                        : "bg-danger badge"
                                                }
                                            >
                                                {item.status}
                                            </strong>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/definition/city/edit/${item.id}`}
                                                    className="btn btn-warning btn-sm"
                                                >
                                                    {t("edit")}
                                                </Link>
                                                <Link
                                                    to={`/definition/city/${item.id}`}
                                                    className="btn btn-info btn-sm"
                                                >
                                                    {t("info")}
                                                </Link>
                                                <button
                                                    onClick={() => {
                                                        Swal.fire({
                                                            text: `${t(
                                                                "alertDelete",
                                                            )}, ${item.name}?`,
                                                            icon: "warning",
                                                            showCancelButton:
                                                                true,
                                                            confirmButtonColor:
                                                                "#3085d6",
                                                            cancelButtonColor:
                                                                "#d33",
                                                            cancelButtonText:
                                                                String(
                                                                    t("cancel"),
                                                                ),
                                                            confirmButtonText:
                                                                String(
                                                                    t(
                                                                        "yesDelete",
                                                                    ),
                                                                ),
                                                        }).then((result) => {
                                                            if (
                                                                result.isConfirmed
                                                            ) {
                                                                AddressApi.DeleteCity(
                                                                    user,
                                                                    String(
                                                                        item.id,
                                                                    ),
                                                                ).then(
                                                                    (data) => {
                                                                        Swal.fire(
                                                                            String(
                                                                                t(
                                                                                    "delete",
                                                                                ),
                                                                            ),
                                                                            String(
                                                                                t(
                                                                                    "deleteMessage",
                                                                                ),
                                                                            ),
                                                                            "success",
                                                                        );
                                                                        fetchCityList();
                                                                        // pagination.current_page,
                                                                    },
                                                                );
                                                            }
                                                        });
                                                    }}
                                                    className="btn btn-sm btn-danger"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/definition/city/?current_page=${pageNum}`,
                                );
                                fetchCityList(pageNum);
                            }}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
