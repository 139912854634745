import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { seatType } from "../../DTO/seat";
import { Loading } from "../loading";
import { VehicleApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { useAppNavigate } from "../../Router/hook";
import { Seat } from "./seat";

export type seatListType = { rowNumber: string; seatCount: string }[];

export const CreateVehicleSeatComponent: React.FC = () => {
    const [vehicleName, setVehicleName] = useState("");
    const [rowNumber, setRowNumber] = useState("0");
    const [submitRow, setSubmitRow] = useState("0");
    const [columnNumber, setColumnNumber] = useState("0");
    const [submitColumn, setSubmitColumn] = useState("0");
    const [loading, setLoading] = useState<boolean>(false);
    const [seatCount, setSeatCount] = useState(0);
    const [seatData, setSeatData] = useState<seatType[]>([]);
    const [vehicleType, setVehicleType] = useState<string>("CAR");
    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const handleSeatCount = (index: number) => {
        setSeatCount(index);
    };

    const handleSeatAdd = (id: number, row: number, col: number) => {
        setSeatData([...seatData, { num: id, row, col }]);
    };

    const handleSeatDelete = (id: number, row: number, col: number) => {
        const list = seatData.filter((item) => {
            return item.num != id;
        });
        setSeatData(list);
    };

    const handleCreateSeat = () => {
        setLoading(true);
        VehicleApi.SeatApi.CreateSeatModel(user, {
            capacity: seatCount,
            name: vehicleName,
            type: vehicleType,
            model: seatData,
        })
            .then((data) => {
                setLoading(false);
                navigate("/vehicle/car/seat-list");
            })
            .catch(() => setLoading(false));
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("car")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-row">
                <div className="col-3 p-2">
                    <div className="d-flex flex-column bg-custom-white p-3  rounded">
                        <div className="d-flex flex-row justify-content-between mb-3">
                            <span>{t("vehicle")}</span>
                            <select
                                onChange={(e) => {
                                    setVehicleType(String(e.target.value));
                                }}
                                className="p-1 rounded bg-custom-white text-custom-dark"
                            >
                                <option value={"CAR"}>{t("car")}</option>
                                <option value={"VAN"}>{t("van")}</option>
                                <option value={"BUS"}>{t("bus")}</option>
                            </select>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-3">
                            <span>{t("rowModel")}:</span>
                            <InputGroup style={{ width: "90px" }} size="sm">
                                <Form.Control
                                    autoComplete="off"
                                    value={vehicleName}
                                    onChange={(e) => {
                                        setVehicleName(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex flex-row justify-content-between ">
                            <span>{t("rowNumber")}:</span>
                            <InputGroup style={{ width: "90px" }} size="sm">
                                <Form.Control
                                    autoComplete="off"
                                    type="number"
                                    min={1}
                                    value={rowNumber}
                                    onChange={(e) => {
                                        setRowNumber(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="outline-success"
                                    onClick={() => {
                                        setSubmitRow(rowNumber);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </InputGroup>
                        </div>
                        <div className="d-flex flex-row justify-content-between my-3">
                            <span>{t("columnNumber")}:</span>
                            <InputGroup style={{ width: "90px" }} size="sm">
                                <Form.Control
                                    autoComplete="off"
                                    type="number"
                                    min={1}
                                    value={columnNumber}
                                    onChange={(e) => {
                                        setColumnNumber(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="outline-success"
                                    onClick={() => {
                                        setSubmitColumn(columnNumber);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </InputGroup>
                        </div>
                        <strong>{t("noteTitle")}</strong>
                        <ul>
                            <li>
                                <small>{t("firstNoteMessage")}</small>
                            </li>
                            <li>
                                <small>{t("secondNoteMessage")}</small>
                            </li>
                            <li>
                                <small>{t("thirdNoteMessage")}</small>
                            </li>
                            <li>
                                <small>{t("forthNoteMessage")}</small>
                            </li>
                        </ul>
                        <Button
                            variant="success"
                            onClick={handleCreateSeat}
                            disabled={seatCount <= 1}
                        >
                            {t("submit")}
                        </Button>
                    </div>
                </div>
                <div className="col-9 p-2">
                    <Seat
                        handleSeatAdd={handleSeatAdd}
                        handleSeatCount={handleSeatCount}
                        handleSeatDelete={handleSeatDelete}
                        seatCount={seatCount}
                        submitColumn={submitColumn}
                        submitRow={submitRow}
                        title={t("frontVehicle")}
                    />
                </div>
            </div>
        </div>
    );
};
