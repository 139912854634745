import { PermissionPivotType, PermissionType, userType } from "../../../DTO";
import { Api } from "../../api";

export const ListPermission = (user: userType) => {
    return new Promise<{
        permission: PermissionType[];
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/permission/get",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    permission: convertPermissionList(data.data),
                });
            })
            .catch((err) => reject(err));
    });
};

export const convertPermissionList = (data: any[]): PermissionType[] => {
    return data.map((element) => convertPermission(element));
};

export const convertPermission = (data: any): PermissionType => {
    let out: PermissionType = {
        group: data?.group,
        permissions: convertToPermissionList(data?.permissions),
    };

    return out;
};

export const convertToPermissionList = (data: any[]): PermissionPivotType[] => {
    return data.map((element) => convertPermissionPivot(element));
};

export const convertPermissionPivot = (data: any): PermissionPivotType => {
    let out: PermissionPivotType = {
        id: data?.id,
        name: data?.name,
        description: data?.description,
        group: data?.group,
        guardName: data?.guard_name,
    };

    return out;
};
