import { combineReducers } from "@reduxjs/toolkit";
import { LoaderReducer } from "./entities/loader/slice";
import { UserReducer } from "./entities/user/slice";
import { DarkModeReducer } from "./entities/dark-mode";
import { LanguageReducer } from "./entities/language";

export const RootReducers = combineReducers({
    user: UserReducer,
    showLoader: LoaderReducer,
    showDarkMode: DarkModeReducer,
    showLanguage: LanguageReducer,
});
