import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { ActualFileObject } from "filepond";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { HotelForm } from "../../../form";
import { HotelApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { useTranslation } from "react-i18next";

export const HotelAccommodationCreate: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [star, setStar] = useState<number>(0);
    const [currentAttributes, setCurrentAttributes] = useState<string[]>([]);

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const callbackAttributes = (attributes: string[]) => {
        setCurrentAttributes(attributes);
    };

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("hotelAccommodation"), href: "/hotel/accommodation/list" },
        { name: t("create"), href: "#" },
    ];

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("hotelAccommodation")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">Create Hotel Accommodation</h4>
                        <Formik<HotelForm.AccommodationForm.FormValues>
                            validationSchema={HotelForm.AccommodationForm.ValidationSchema(
                                t,
                            )}
                            initialValues={{
                                description: "",
                                name: "",
                                addressId: 0,
                                addressName: "",
                                policy: "",
                                code: "",
                                contents: [],
                                attribute: "",
                                attributes: [],
                                status: "active",
                            }}
                            onSubmit={(event) => {
                                setLoading(true);
                                HotelApi.AccommodationApi.CreateAccommodation(
                                    user,
                                    event.name,
                                    event.code,
                                    Number(event.addressId),
                                    event.description,
                                    star,
                                    event.policy,
                                    event.contents,
                                    currentAttributes,
                                )
                                    .then(() => {
                                        setLoading(false);
                                        navigate("/hotel/accommodation/list");
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "error",
                                            text: err,
                                        });
                                    });
                            }}
                            component={(props: any) => (
                                <HotelForm.AccommodationForm.HotelAccommodationCreateEdit
                                    submitTxt={String(t("submit"))}
                                    star={star}
                                    setStar={setStar}
                                    callbackAttributes={callbackAttributes}
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
