import React, { useEffect, useMemo, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { FormValues, PropType } from "./type";
import { useAppLocation } from "../../Router/hook";
import { AddressType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { AddressApi } from "../../api";
import { Shimmer } from "../../components";

export const PortFormCreateAndEdit = (
    props: PropType & FormikProps<FormValues>,
) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [addressName, setAddressName] = useState(values.addressName);
    const [addressList, setAddressList] = useState<AddressType[]>([]);
    const [selectedAddress, setSelectedAddress] = useState(values.addressId);
    const [addressLoading, setAddressLoading] = useState<boolean>(false);

    const { pathname } = useAppLocation();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const fetchAddressList = (query: string) => {
        AddressApi.FetchAddress(user, query)
            .then((data) => {
                setAddressList(data.address);
                setAddressLoading(false);
            })
            .catch((err) => {
                setAddressLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        values.addressId = selectedAddress;
    }, [selectedAddress]);

    useEffect(() => {
        values.addressName = addressName;
    }, [addressName]);

    const throttledSearchAddress = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchAddressList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchAddress.cancel();
        };
    }, [throttledSearchAddress]);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="port-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="port-description"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="description"
                            as={"textarea"}
                            rows={3}
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={String("placeholderDescription")}
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={
                                touched.description && !!errors.description
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.description}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="port-address-id"
                        className="col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("addressName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="addressName"
                            onFocus={() => {
                                setAddressLoading(true);
                                fetchAddressList("");
                            }}
                            disabled={pathname.includes(
                                "/definition/port/edit",
                            )}
                            type="string"
                            name="addressName"
                            placeholder={String(t("addressNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={addressName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setAddressName(e.target.value);
                                throttledSearchAddress(e);
                            }}
                            isInvalid={
                                touched.addressName && !!errors.addressName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {addressList.length > 0 ? (
                                <div
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    onMouseLeave={() => setAddressList([])}
                                    className="position-absolute overflow-auto rounded-2 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {addressList.map((item, index) => (
                                        <strong
                                            key={index}
                                            className="p-3 border-bottom w-100 text-center"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedAddress(
                                                    Number(item.id),
                                                );
                                                setAddressName(
                                                    `${item.title} | ${item.city?.name}`,
                                                );
                                                setAddressList([]);
                                            }}
                                        >
                                            {`${item.title} | ${item.city?.name}`}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                addressLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center position-absolute w-100 border rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.addressName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="port-type"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("type")}</Form.Label>
                        <Form.Select
                            aria-label="type"
                            className="h3"
                            name="type"
                            onBlur={handleBlur}
                            value={values.type}
                            onChange={handleChange}
                            isInvalid={touched.type && !!errors.type}
                        >
                            {/*<option value={"AIR"}>{t("air")}</option>*/}
                            <option value={"BUS"}>{t("bus")}</option>
                            <option value={"CAR"}>{t("car")}</option>
                        </Form.Select>
                        <Form.Text className="text-danger">
                            {errors.type}
                        </Form.Text>
                    </Form.Group>
                    {pathname.includes("/definition/port/edit") && (
                        <Form.Group
                            controlId="port-status"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>{t("status")}</Form.Label>
                            <Form.Select
                                aria-label="status"
                                className="h3"
                                name="status"
                                onBlur={handleBlur}
                                value={values.status}
                                onChange={handleChange}
                                isInvalid={touched.status && !!errors.status}
                            >
                                <option className="d-none">
                                    {t("placeholderStatus")}
                                </option>
                                <option
                                    value={"Active"}
                                    className="text-success"
                                >
                                    {t("active")}
                                </option>
                                <option
                                    value={"InActive"}
                                    className="text-danger"
                                >
                                    {t("inActive")}
                                </option>
                            </Form.Select>
                            <Form.Text className="text-danger">
                                {errors.status}
                            </Form.Text>
                        </Form.Group>
                    )}
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="success"
                    type="submit"
                    className="col-12 col-sm-4 mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
