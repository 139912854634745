import { FC, useEffect, useState } from "react";
import { GatewayType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { PaymentApi } from "../../../api";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const GatewayList: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [gateway, setGateway] = useState<GatewayType[]>([]);

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("gatewayList"), href: "#" },
    ];

    const fetchGatewayList = () => {
        setLoading(true);

        PaymentApi.GatewayApi.ListGateway(user)
            .then((data) => {
                setGateway(data.gateway);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const handleChangeStatusGateway = (
        status: "active" | "inactive",
        id: number,
    ) => {
        PaymentApi.GatewayApi.ChangeStatusGateway(user, Number(id), status)
            .then(() => {
                Swal.fire({
                    text: String(t("statusChangeMessage")),
                    icon: "success",
                });
                fetchGatewayList();
            })
            .catch((error) => {
                Swal.fire({
                    text: error,
                    icon: "error",
                });
            });
    };

    useEffect(() => {
        fetchGatewayList();
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} {t("gatewayList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("gatewayTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("logo")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("currency")}</th>
                                    <th>{t("status")}</th>
                                </tr>
                            </thead>
                            <tbody className="align-baseline">
                                {gateway.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>

                                        <td>
                                            <img
                                                src={item.logo}
                                                alt={item.name}
                                                width={50}
                                                height={50}
                                                style={{ objectFit: "contain" }}
                                            />
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{item?.description}</td>
                                        <td>
                                            <div className="d-flex flex-row gap-1 justify-content-center align-items-center align-self-center">
                                                {item?.currency.map(
                                                    (element) => (
                                                        <strong>
                                                            {element}
                                                        </strong>
                                                    ),
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                value={item?.status}
                                                defaultChecked={
                                                    item?.status === "active"
                                                }
                                                checked={
                                                    item?.status === "active"
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    handleChangeStatusGateway(
                                                        e.target.checked
                                                            ? "active"
                                                            : "inactive",
                                                        item.id,
                                                    );
                                                }}
                                            />{" "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};
