import { LogType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertOrderUser } from "../order";

export const AdminLog = (
    user: userType,
    type: "storeroute" | "storedriver" | "storetrip",
    page: number,
) => {
    const data = new FormData();
    data.append("type", type);
    return new Promise<{ log: LogType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "post",
                path: "admin/audit-log",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "multipart/form-data",
                },
                body: data,
                params: {
                    page,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        log: convertToLogList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    });
                })
                .catch((error) => reject(error));
        },
    );
};

export const convertToLogList = (data: any[]): LogType[] => {
    return data.map((element) => convertToLog(element));
};

export const convertToLog = (data: any): LogType => {
    let out: LogType = {
        id: data.id,
        action: data.action,
        userable_type: data.userable,
        userable_id: data.userable_id,
        ip: data.ip,
        entity_type: data.entity_type,
        entity_id: data.entity_id,
        request_body: JSON.parse(data?.request_body),
        request_header: JSON.parse(data?.request_header),
        status_code: data?.status_code,
        meta_data: data?.meta_data,
        remember_token: data?.remember_token,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
        user: convertOrderUser(data?.user),
    };
    return out;
};
