import {
    AccommodationRoomType,
    PaginationType,
    RoomTypeRatePlans,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";
import { convertToAccommodationType } from "../accommodation";

export const ListRoomType = (
    user: userType,
    accommodationId: number,
    currentPage: number,
    roomTypeSearch?: string,
) => {
    return new Promise<{
        roomType: AccommodationRoomType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/accommodation/${accommodationId}/room-type`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                search: roomTypeSearch,
                page: currentPage,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    roomType: convertToAccommodationRoomTypeList(data.data),
                    pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};

export const convertToAccommodationRoomTypeList = (
    data: any[],
): AccommodationRoomType[] => {
    return data.map((element) => convertToAccommodationRoomType(element));
};

export const convertToAccommodationRoomType = (
    data: any,
): AccommodationRoomType => {
    let out: AccommodationRoomType = {
        accommodation: convertToAccommodationType(data?.accommodation),
        adult: data?.adult,
        code: data?.code,
        description: data?.description,
        id: data?.id,
        name: data?.name,
        ratePlans: data?.rate_plans
            ? convertToRatePlansTypeList(data?.rate_plans)
            : [],
        count: data?.count,
    };

    return out;
};

export const convertToRatePlansTypeList = (
    data: any[],
): RoomTypeRatePlans[] => {
    return data.map((element) => convertToRatePlansType(element));
};

export const convertToRatePlansType = (data: any): RoomTypeRatePlans => {
    let out: RoomTypeRatePlans = {
        code: data?.code,
        description: data?.description,
        id: data?.id,
        name: data?.name,
        price: data?.price,
    };

    return out;
};
