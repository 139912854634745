import React, { useEffect, useState } from "react";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { DriverForm } from "../../../../form";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { DriverApi, VehicleApi } from "../../../../api";
import { Loading } from "../../../../components";
import { useAppNavigate, useAppParams } from "../../../../Router/hook";
import { DriverType, VehicleModelType } from "../../../../DTO";

export const EditDriver: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [driver, setDriver] = useState<DriverType>();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const { id } = useAppParams();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("driverList"), href: "/vehicle/driver/list" },
        { name: t("edit") + id, href: "#" },
    ];

    const navigate = useAppNavigate();

    useEffect(() => {
        fetchSingleDriver();
    }, []);

    const fetchSingleDriver = () => {
        setLoading(true);

        DriverApi.ShowDriver(user, String(id))
            .then((data) => {
                setDriver(data.driver);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const editDriver = (
        passportId: number,
        licenceId: number,
        vehicleId: number,
        phone: string,
        firstName: string,
        lastName: string,
        commission: number,
        commissionVip: number,
    ) => {
        setLoading(true);
        DriverApi.EditDriver(
            user,
            Number(id),
            passportId,
            licenceId,
            vehicleId,
            phone,
            firstName,
            lastName,
            commission,
            commissionVip,
        )
            .then((data) => {
                navigate("/vehicle/driver/list");
                setLoading(false);
            })
            .then((err: any) => {
                setLoading(false);
                // Swal.fire({
                //     icon: "error",
                //     text: err.data.message,
                // });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("edit")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    {driver && (
                        <Formik<DriverForm.FormValues>
                            validationSchema={DriverForm.ValidationSchema(t)}
                            initialValues={{
                                licenceId: String(driver.licence_id),
                                passportId: String(driver.passport_id),
                                vehicleId: Number(driver.vehicle?.id),
                                vehicleName: String(driver.vehicle?.title),
                                phoneNumber: String(driver.phone),
                                firstName: String(driver.user?.first_name),
                                lastName: String(driver.user?.last_name),
                                commission: String(
                                    Math.floor(Number(driver.commission)),
                                ),
                                commissionVip: String(
                                    Math.floor(Number(driver.commissionVip)),
                                ),
                            }}
                            onSubmit={(event) => {
                                editDriver(
                                    Number(event.passportId),
                                    Number(event.licenceId),
                                    event.vehicleId,
                                    event.phoneNumber,
                                    event.firstName,
                                    event.lastName,
                                    Number(event.commission),
                                    Number(event.commissionVip),
                                );
                            }}
                            component={(props: any) => (
                                <DriverForm.DriverFormCreate
                                    submitTxt={t("submit")}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
