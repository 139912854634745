import { FC, useEffect, useState } from "react";
import { FlightCommissionType } from "../../../DTO";
import { AirplaneApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal } from "react-bootstrap";

export const FlightCommissionScreen: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [commission, setCommission] = useState<FlightCommissionType>({
        commission: 0,
        createdAt: "",
        id: 0,
        updatedAt: "",
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [commissionNumber, setCommissionNumber] = useState<string>("");

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    useEffect(() => {
        fetchFlightCommission();
    }, []);

    const fetchFlightCommission = () => {
        setLoading(true);

        AirplaneApi.CommissionApi.ShowCommission(user)
            .then((data) => {
                setCommission(data.commission);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleCreateCommission = () => {
        setLoading(true);

        AirplaneApi.CommissionApi.CreateCommission(
            user,
            Number(commissionNumber),
        )
            .then(() => {
                setLoading(false);
                setShowModal(false);
                fetchFlightCommission();
                setCommissionNumber("");
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("flightCommission")), href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("flightCommission")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column gap-2">
                    <button
                        className="btn btn-success align-self-end"
                        onClick={() => {
                            setShowModal(true);
                            setCommissionNumber("");
                        }}
                    >
                        {t("addCommission")} +
                    </button>
                    <div className="d-flex justify-content-center flex-row flex-wrap rounded px-3">
                        <div className="col-6 p-2   align-items-center align-self-center ">
                            <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                                <FontAwesomeIcon
                                    className="mx-2 userImageSiteLayout"
                                    size="5x"
                                    icon={faPlane}
                                />
                                <hr />
                                <div>
                                    <span>{t("commission")}:</span>
                                    <strong className="mx-2">
                                        {commission.commission}
                                    </strong>
                                </div>
                                <div>
                                    <span>{t("createdAt")}:</span>
                                    <strong className="mx-2">
                                        {commission.createdAt.slice(0, 10)}
                                    </strong>
                                </div>
                                <div>
                                    <span>{t("updatedAt")}:</span>
                                    <strong className="mx-2">
                                        {commission.updatedAt.slice(0, 10)}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                animation
                centered
            >
                <Modal.Header closeButton>{t("addCommission")}</Modal.Header>
                <Modal.Body>
                    <Form.Group
                        controlId="commission"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("commission")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="commission"
                            className="h3"
                            type="number"
                            name="commission"
                            placeholder={String(t("commissionPlaceholder"))}
                            value={commissionNumber}
                            onChange={(e) =>
                                setCommissionNumber(e.target.value)
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={commissionNumber.length <= 0}
                        onClick={handleCreateCommission}
                    >
                        {t("submit")}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowModal(false);
                            setCommissionNumber("");
                        }}
                    >
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
