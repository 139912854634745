import { WithdrawalType, userType } from "../../../DTO";
import { convertToAdmin } from "../../admin";
import { Api } from "../../api";
import { convertToUser } from "../../driver";
import { convertToAccommodationType } from "../../hotel/accommodation";

export const ListWithdrawal = (user: userType) => {
    return new Promise<{ withdrawal: WithdrawalType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/withdrawal",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    withdrawal: convertToWithdrawalTypeList(data.data.data),
                });
            })
            .catch((error: any) => reject(error.data.message));
    });
};

export const convertToWithdrawalTypeList = (data: any[]): WithdrawalType[] => {
    return data.map((element) => convertToWithdrawalType(element));
};

export const convertToWithdrawalType = (data: any): WithdrawalType => {
    let out: WithdrawalType = {
        amount: data?.amount,
        description: data?.description,
        id: data?.id,
        status: data?.status,
        type: data?.type,
        user: data?.user ? convertToUser(data?.user) : null,
        admin: data?.admin ? convertToAdmin(data?.admin.user) : null,
        accommodation: data?.accommodation
            ? convertToAccommodationType(data?.accommodation)
            : null,
    };

    return out;
};
