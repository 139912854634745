import { CityType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToProvince } from "./provinceList";

export const FetchCityList = (
    user: userType,
    current_page: number,
    city?: CityType,
) => {
    return new Promise<{
        data: CityType[];
        // pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/city",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                page: current_page,
                name: city?.name,
                name_en: city?.name_en,
                id: city?.id,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCityList(data.data),
                    // pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export function convertToCityList(data: any[]): CityType[] {
    const cityList = data.map((item: any) => {
        return convertToCity(item);
    });

    return cityList;
}

export function convertToCity(data: any): CityType {
    const cityOb: CityType = {
        id: data?.id,
        name: data?.name,
        name_en: data?.name_en,
        domain_code: data?.domain_code,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
        province_id: data?.province_id,
        latitude: data?.latitude,
        longitude: data?.longitude,
        province: data?.province
            ? convertToProvince(data?.province)
            : undefined,
        description: data?.description,
        status: data?.status,
        statusAccommodation: data?.status_accommodation,
        statusCar: data?.status_car,
        statusFlight: data?.status_flight,
    };

    return cityOb;
}

export const FetchCityId = (user: userType, id: string) => {
    return new Promise<{ data: CityType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/city/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCity(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const CreateCity = (user: userType, city: CityType) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/city`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: city?.name,
                name_en: city?.name_en,
                domain_code: city?.domain_code,
                province_id: city?.province_id,
                latitude: city?.latitude,
                longitude: city?.longitude,
                status_accommodation: city?.statusAccommodation,
                status_car: city?.statusCar,
                status_flight: city?.statusFlight,
            }),
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const EditCity = (user: userType, city: CityType, id: string) => {
    return new Promise<{ data: CityType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/city/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: city?.name,
                name_en: city?.name_en,
                domain_code: city?.domain_code,
                province_id: city?.province_id,
                latitude: city?.latitude,
                longitude: city?.longitude,
                status: city?.status,
                status_accommodation: city?.statusAccommodation,
                status_car: city?.statusCar,
                status_flight: city?.statusFlight,
            }),
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCity(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const DeleteCity = (user: userType, id: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/city/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const SearchCity = (user: userType, name: string) => {
    return new Promise<{ data: CityType[] }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `city`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                name,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToCityList(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};
