import { PaginationType } from "../../DTO";

export function convertToPaginationType(data: any): PaginationType {
    const pagination: PaginationType = {
        current_page: data.current_page,
        per_page: data.per_page,
        total: data.total,
    };

    return pagination;
}
