import React, { FC, useEffect, useMemo, useState } from "react";
import { Badge, Button, Form, Modal, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import _ from "lodash";
import DatePicker from "react-multi-date-picker";

import {
    PaginationType,
    AccommodationInventoryType,
    AccommodationRoomType,
} from "../../../../DTO";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { HotelApi } from "../../../../api";
import {
    useAppNavigate,
    useAppPagination,
    useAppParams,
} from "../../../../Router/hook";
import "./style.scss";
import { convertDateFormat } from "../../../../form/trip/logic";
import { useTranslation } from "react-i18next";

export const HotelInventoryList: FC = () => {
    const [inventory, setInventory] = useState<AccommodationInventoryType[]>(
        [],
    );
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [roomTypeName, setRoomTypeName] = useState<string>("");
    const [roomTypeList, setRoomTypeList] = useState<AccommodationRoomType[]>(
        [],
    );
    const [roomTypeNameList, setRoomTypeNameList] = useState<
        AccommodationRoomType[]
    >([]);
    const [roomTypeId, setRoomTypeId] = useState<number[]>([]);
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateTo, setDateTo] = useState<string>("");
    const [availability, setAvailability] = useState<string>("");
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
    const [modalData, setModalData] = useState<AccommodationInventoryType>();
    const [modalAvailability, setModalAvailability] = useState<number>(0);

    const { id } = useAppParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("inventory"),
            href: `#`,
        },
    ];

    const fetchRoomTypeListBySearchRoom = (query: string) => {
        HotelApi.RoomTypeApi.ListRoomType(user, Number(id), 1, query)
            .then((data) => {
                setRoomTypeList(data.roomType);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const handleEditInventory = () => {
        setLoading(true);

        HotelApi.InventoryApi.EditInventory(
            user,
            Number(id),
            Number(modalData?.roomType?.id),
            modalAvailability,
            String(modalData?.date),
        )
            .then(() => {
                fetchHotelInventoryList(pagination.current_page);
                setLoading(false);
                setShowModalEdit(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchHotelInventoryList = (page: number) => {
        setLoading(true);

        HotelApi.InventoryApi.ListInventory(
            user,
            Number(id),
            page,
            roomTypeId,
            dateFrom,
            dateTo,
            availability,
        )
            .then((data) => {
                setInventory(data.inventory);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchHotelInventoryList(pagination.current_page);
    }, [id]);

    const throttledSearchRoomType = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchRoomTypeListBySearchRoom(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchRoomType.cancel();
        };
    }, [throttledSearchRoomType]);

    const handleDeletedRoomType = (id: number) => {
        setRoomTypeNameList(
            roomTypeNameList.filter((element) => element.id != id),
        );
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("inventory")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("inventoryTable")}</h4>
                        <div className="d-flex flex-row ">
                            <label
                                htmlFor="show-rate-search-input"
                                className="btn btn-info mx-1 text-nowrap"
                            >
                                <span>{t("search")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faSearch}
                                />
                            </label>
                            {/* <Link
                                to={`/hotel/accommodation/${id}/inventory/new`}
                                className="btn btn-success"
                            >
                                Create Inventory +
                            </Link> */}
                        </div>
                    </div>
                    <input type="checkbox" id="show-rate-search-input"></input>
                    <div className="rate-plan-search  p-2 flex-row justify-content-between align-items-center">
                        <div>
                            <div className="d-flex flex-row flex-wrap">
                                <Form.Group
                                    controlId="peer-group-user-id"
                                    className="position-relative col-12 col-sm-6 p-2"
                                >
                                    <Form.Label>{t("name")}</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        aria-label="roomName"
                                        className="h3"
                                        type="string"
                                        name="roomName"
                                        placeholder={String(
                                            t("placeholderName"),
                                        )}
                                        value={roomTypeName}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            setRoomTypeName(e.target.value);
                                            throttledSearchRoomType(e);
                                        }}
                                    />
                                    {roomTypeName.length > 0 &&
                                        roomTypeList.length > 0 && (
                                            <div
                                                style={{ zIndex: 10 }}
                                                className="position-absolute border border-1 d-flex gap-2 flex-column flex-wrap bg-custom-white w-100 p-2"
                                            >
                                                {roomTypeList.map(
                                                    (item, index) => (
                                                        <strong
                                                            onClick={() => {
                                                                setRoomTypeName(
                                                                    "",
                                                                );
                                                                setRoomTypeId([
                                                                    ...roomTypeId,
                                                                    item.id,
                                                                ]);
                                                                setRoomTypeNameList(
                                                                    [
                                                                        ...roomTypeNameList,
                                                                        item,
                                                                    ],
                                                                );
                                                                setRoomTypeList(
                                                                    [],
                                                                );
                                                            }}
                                                            key={index}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className="d-flex flex-column gap-1 align-self-center align-items-center"
                                                        >
                                                            {`${t("name")}: ${
                                                                item.name
                                                            } | ${t("code")}: ${
                                                                item.code
                                                            }`}
                                                        </strong>
                                                    ),
                                                )}
                                            </div>
                                        )}
                                    {roomTypeNameList.length > 0 && (
                                        <div className="p-2 d-flex flex-row flex-wrap gap-3">
                                            {roomTypeNameList.map(
                                                (elementName) => (
                                                    <div
                                                        key={elementName.id}
                                                        className="position-relative border border-1 rounded-5 shadow-sm d-flex flex-column gap-2 align-items-center justify-content-center"
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                handleDeletedRoomType(
                                                                    elementName.id,
                                                                )
                                                            }
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                left: 0,
                                                                top: 0,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <Badge
                                                                pill
                                                                bg="danger"
                                                            >
                                                                ×
                                                            </Badge>
                                                        </span>
                                                        <div className="d-flex flex-column gap-1 align-items-center align-self-center p-3">
                                                            <strong className="text-info fa-fade">
                                                                {t("name")}:{" "}
                                                                {
                                                                    elementName.name
                                                                }
                                                            </strong>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    )}
                                </Form.Group>
                                <Form.Group
                                    controlId="inventory-date-from"
                                    className="position-relative d-flex flex-column col-12 col-sm-6 p-2"
                                >
                                    <Form.Label>{t("dateFrom")}</Form.Label>
                                    <DatePicker
                                        value={dateFrom}
                                        onChange={(array: any) => {
                                            setDateFrom(
                                                convertDateFormat(
                                                    array.toString?.(),
                                                ),
                                            );
                                        }}
                                        calendar={gregorian}
                                        locale={gregorian_en}
                                        format="YYYY/MM/DD"
                                    />
                                </Form.Group>
                                <Form.Group
                                    controlId="inventory-date-to"
                                    className="position-relative d-flex flex-column col-12 col-sm-6 p-2"
                                >
                                    <Form.Label>{t("dateTo")}</Form.Label>
                                    <DatePicker
                                        value={dateTo}
                                        onChange={(array: any) => {
                                            setDateTo(
                                                convertDateFormat(
                                                    array.toString?.(),
                                                ),
                                            );
                                        }}
                                        calendar={gregorian}
                                        locale={gregorian_en}
                                        format="YYYY/MM/DD"
                                    />
                                </Form.Group>
                                <Form.Group
                                    controlId="trip-availability"
                                    className="position-relative col-12 col-sm-6 p-2"
                                >
                                    <Form.Label>{t("availability")}</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        aria-label="availability"
                                        className="h3"
                                        type="string"
                                        name="availability"
                                        placeholder={String(
                                            t("availabilityPlaceholder"),
                                        )}
                                        value={availability}
                                        onChange={(e) =>
                                            setAvailability(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <button
                            className="btn btn-sm btn-success mx-1 text-nowrap"
                            onClick={() =>
                                fetchHotelInventoryList(pagination.current_page)
                            }
                        >
                            <span>{t("search")}</span>
                            <FontAwesomeIcon className="mx-1" icon={faSearch} />
                        </button>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("accommodation")}</th>
                                    <th>{t("roomType")}</th>
                                    <th>{t("date")}</th>
                                    <th>{t("availability")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {inventory.length > 0 &&
                                    inventory.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>
                                                <strong
                                                    className="fa-bounce badge bg-primary pointer-cursor"
                                                    onClick={() =>
                                                        navigate(
                                                            `/hotel/accommodation/info/${item.accommodation.id}`,
                                                        )
                                                    }
                                                >
                                                    {item?.accommodation.name}
                                                </strong>
                                            </td>
                                            <td>
                                                <strong
                                                    className="fa-bounce badge bg-secondary pointer-cursor"
                                                    onClick={() =>
                                                        navigate(
                                                            `/hotel/accommodation/${item.accommodation.id}/room-type/info/${item.roomType.id}`,
                                                        )
                                                    }
                                                >
                                                    {item?.roomType.name}
                                                </strong>
                                            </td>
                                            <td>{item?.date}</td>
                                            <td>{item.availability}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-warning"
                                                    onClick={() => {
                                                        setShowModalEdit(true);
                                                        setModalData(item);
                                                        setModalAvailability(
                                                            item.availability,
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/hotel/accommodation/${id}/inventory?current_page=${pageNum}`,
                                );
                                fetchHotelInventoryList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModalEdit}
                onHide={() => setShowModalEdit(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("edit")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalData && modalData?.id > 0 && (
                        <>
                            <Form.Group
                                controlId="inventory-date"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>{t("date")}</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="modal-date"
                                    className="h3"
                                    type="string"
                                    name="modalDate"
                                    disabled={true}
                                    value={String(modalData.date)}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="inventory-availability"
                                className="position-relative col-12 p-2"
                            >
                                <Form.Label>{t("availability")}</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    aria-label="modal-availability"
                                    className="h3"
                                    type="number"
                                    name="modalAvailability"
                                    placeholder={String(
                                        t("availabilityPlaceholder"),
                                    )}
                                    value={modalAvailability}
                                    onChange={(e) =>
                                        setModalAvailability(
                                            Number(e.target.value),
                                        )
                                    }
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowModalEdit(false);
                        }}
                    >
                        {t("close")}
                    </Button>
                    <Button variant="success" onClick={handleEditInventory}>
                        {t("submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
