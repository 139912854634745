import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center w-100 bg-light"
            style={{ height: "100vh" }}
        >
            <h1>
                <FontAwesomeIcon icon={faExclamationTriangle} /> {t("404")}
            </h1>
            <p>{t("404Message")}</p>
            <Link className="btn btn-info shadow" to="/">
                {t("goBack")}
            </Link>
        </div>
    );
};
