import { AccommodationRoomRatePlanType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationRoomRatePlanType } from "./list";

export const ShowRatePlan = (
    user: userType,
    accommodationId: number,
    roomId: number,
    rateId: number,
) => {
    return new Promise<{ ratePlan: AccommodationRoomRatePlanType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `admin/accommodation/${accommodationId}/room-type/${roomId}/rate-plan/${rateId}`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        ratePlan: convertToAccommodationRoomRatePlanType(
                            data.data,
                        ),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};
