import { DriverType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToDriver } from "./list";

export const ShowDriver = (user: userType, id: string) => {
    return new Promise<{ driver: DriverType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/driver/show/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    driver: convertToDriver(data.data),
                });
            })
            .catch((error) => {
                return reject(error);
            });
    });
};
