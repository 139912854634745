import { faEllipsisH, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import "./style.scss";
import { LogType, PaginationType, TripType } from "../../../../DTO";
import { LogApi, TripApi } from "../../../../api";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { Loader } from "../../../loader";
import { Loading, StaticMap } from "../../../../components";
import CustomMap from "../../../../components/map";
import Swal from "sweetalert2";
import { useAppPagination } from "../../../../Router/hook";
import { useTranslation } from "react-i18next";

export const VehicleTripInfo: React.FC<{}> = () => {
    const [trip, setTrip] = useState<TripType>();
    const [loading, setLoading] = useState<boolean>(false);

    const { id } = useParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("trip"), href: "/vehicle/trip/list" },
        { name: `${t("info")} ${id}`, href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const fetchTripById = () => {
        setLoading(true);
        TripApi.ShowTrip(user, Number(id))
            .then((data) => {
                setTrip(data.trip);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchTripById();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("info")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column ">
                {loading && <Loading />}
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-row flex-wrap">
                    <div className="col-12 bg-custom-white rounded-5 shadow p-3 my-2">
                        <h4 className={"text-center"}>{t("driver")}</h4>
                        <hr />
                        <img
                            className={"rounded-circle align-self-center"}
                            width={100}
                            height={100}
                            src={String(trip?.driver?.user?.avatar)}
                            alt={String(trip?.driver?.user?.first_name)}
                        />
                        <div className="d-flex flex-wrap flex-row">
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("firstName")}:</span>
                                <strong>
                                    {trip?.driver?.user?.first_name}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("lastName")}:</span>
                                <strong>{trip?.driver?.user?.last_name}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("phoneNumber")}:</span>
                                <strong>{trip?.driver?.user?.phone}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>Code:</span>
                                <strong>{trip?.driver?.user?.code}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("code")}:</span>
                                <strong>{trip?.driver?.user?.code}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("licenseId")}:</span>
                                <strong>{trip?.driver?.licence_id}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("passportId")}:</span>
                                <strong>{trip?.driver?.passport_id}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("state")}:</span>
                                <strong>{trip?.driver?.status}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("model")}:</span>
                                <strong>{trip?.driver?.vehicle?.model}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("title")}:</span>
                                <strong>{trip?.driver?.vehicle?.title}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bg-custom-white rounded-5 shadow p-3 my-2">
                        <h4 className={"text-center"}>{t("trip")}</h4>
                        <hr />
                        <div className="d-flex flex-wrap flex-row">
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("duration")}:</span>
                                <strong>{trip?.duration}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("model")}:</span>
                                <strong>{trip?.model}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("price")}:</span>
                                <strong>
                                    {Number(trip?.price).toLocaleString()}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("priceFront")}:</span>
                                <strong>
                                    {Number(trip?.price_front).toLocaleString()}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("remain")}:</span>
                                <strong>{trip?.remain}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("time")}:</span>
                                <strong>{trip?.time}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bg-custom-white rounded-5 shadow p-3 my-2">
                        <h4 className={"text-center"}>{t("port")}</h4>
                        <hr />
                        <div className="d-flex flex-wrap flex-row">
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("portInName")}:</span>
                                <strong>{trip?.portIn.name}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("portInType")}:</span>
                                <strong>{trip?.portIn.type}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("portOutName")}:</span>
                                <strong>{trip?.portOut.name}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("portOutType")}:</span>
                                <strong>{trip?.portOut.type}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bg-custom-white rounded-5 shadow p-3 my-2">
                        <h4 className={"text-center"}>{t("route")}</h4>
                        <hr />
                        <div className="d-flex flex-wrap flex-row">
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityInName")}:</span>
                                <strong>{trip?.route?.city_in?.name}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityInNameEnglish")}:</span>
                                <strong>{trip?.route?.city_in?.name_en}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityInDomain")}:</span>
                                <strong>
                                    {trip?.route?.city_in?.domain_code}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityOutName")}:</span>
                                <strong>{trip?.route?.city_out?.name}</strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityOutNameEnglish")}:</span>
                                <strong>
                                    {trip?.route?.city_out?.name_en}
                                </strong>
                            </div>
                            <div
                                className={
                                    "d-flex flex-row col-12 col-lg-3 col-md-4 gap-1 p-3"
                                }
                            >
                                <span>{t("cityOutDomain")}:</span>
                                <strong>
                                    {trip?.route?.city_out?.domain_code}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-2 ">
                        {trip?.route?.city_in?.latitude &&
                            trip.route.city_in.latitude.length > 0 && (
                                <StaticMap
                                    height={"480px"}
                                    location={{
                                        lat: Number(
                                            trip?.route?.city_in?.latitude,
                                        ),
                                        lng: Number(
                                            trip?.route?.city_in?.longitude,
                                        ),
                                    }}
                                    location2={{
                                        lat: Number(
                                            trip?.route?.city_out?.latitude,
                                        ),
                                        lng: Number(
                                            trip?.route?.city_out?.longitude,
                                        ),
                                    }}
                                />
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};
