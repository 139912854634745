import {
    PaginationType,
    userType,
    VehicleModelType,
    VehicleType,
} from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { castVehicleModelList } from "./show";

export const FetchList = (
    user: userType,
    // current_page?: number,
    title?: string,
) => {
    return new Promise<{
        data: VehicleModelType[];
        // pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/vehicle/get",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                // page: current_page,
                title: title && title?.length > 0 && title,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: castVehicleModelList(data.data),
                    // pagination: convertToPaginationType({
                    //     current_page: data.data.current_page,
                    //     per_page: data.data.per_page,
                    //     total: data.data.total,
                    // }),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export function convertToVehicleAppList(data: any[]): VehicleType[] {
    const vehicleOB = data.map((item: any) => {
        return convertToVehicle(item);
    });
    return vehicleOB;
}

export function convertToVehicle(data: any): VehicleType {
    const vehicleOb: VehicleType = {
        id: data?.id,
        user_id: data?.user_id,
        picture_id: data?.picture_id,
        company_id: data?.company_id,
        title: data?.title,
        seat_model_id: data?.seat_model_id,
        model: data?.model,
        status: data?.status,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
    };
    return vehicleOb;
}
