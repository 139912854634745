import React from "react";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import "./style.scss";
import { CreateVehicleSeatComponent } from "../../../../components";
import { useTranslation } from "react-i18next";

export const CreateVehicleSeat: React.FC<{}> = () => {
    const { t } = useTranslation();
    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("riding"), href: "/vehicle/car/list" },
        { name: t("create"), href: "#" },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("seat")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <CreateVehicleSeatComponent />
            </div>
        </>
    );
};
