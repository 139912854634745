import React from "react";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { store } from "./redux-config/store";
import { RouterPage } from "./Router/index";
import "./index.scss";

function App() {
    return (
        <Provider store={store}>
            <ToastContainer />
            <RouterPage />
        </Provider>
    );
}

export default App;
