import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { DiscountType, PaginationType } from "../../DTO";
import { useAppNavigate, useAppPagination } from "../../Router/hook";
import { DiscountApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { CustomPagination, Loading } from "../../components";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const ListDiscountCode: FC<{}> = () => {
    const [discount, setDiscount] = useState<DiscountType[]>([]);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: t("discountCode"), href: "/discount-code" },
    ];

    const user = useAppSelector(selectUser);

    const fetchDiscountCodeList = () => {
        setLoading(true);
        DiscountApi.ListDiscount(user, pagination.current_page)
            .then((data) => {
                setDiscount(data.discountCode);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const deleteDiscountCode = (id: string) => {
        setLoading(true);
        DiscountApi.DeleteDiscount(user, id)
            .then(() => {
                fetchDiscountCodeList();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchDiscountCodeList();
    }, []);

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("discountCode")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">
                            {t("discountCodeTable")}
                        </h4>
                        <div className="d-flex flex-row">
                            <Link
                                to={"/discount-code/new"}
                                className="btn btn-success "
                            >
                                {t("create")} +
                            </Link>
                        </div>
                    </div>
                    <input type="checkbox" id="showAdminSearchInput"></input>
                    <div className="adminSearchBox p-2  flex-wrap flex-sm-nowrap justify-content-between align-items-center"></div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("code")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("ExpireDate")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {discount.map((discountItem) => (
                                    <tr key={discountItem.id}>
                                        <td>{discountItem.id}</td>
                                        <td>{discountItem.name}</td>
                                        <td>{discountItem.code}</td>
                                        <td>{discountItem.amount}</td>
                                        <td>{discountItem.expireTime}</td>
                                        <td>
                                            <Link
                                                to={`/discount-code/edit/${discountItem.id}`}
                                                className="btn btn-warning btn-sm"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                />
                                            </Link>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() =>
                                                    deleteDiscountCode(
                                                        String(discountItem.id),
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={10}
                            perPage={10}
                            currentPage={currentPage}
                            changePage={(pageNum: number) => {
                                setCurrentPage(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
