import { userType } from "../../DTO";
import { Api } from "../api";

export const DeleteDiscount = (user: userType, id: string) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `coupon/delete/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({ data: data.data });
            })
            .catch((error) => reject(error));
    });
};
