import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Modal, Table } from "react-bootstrap";

import {
    AllPaymentType,
    DealType,
    PaginationType,
    TransactionType,
} from "./../../DTO";
import { PaymentApi } from "./../../api";
import { useAppNavigate, useAppPagination } from "./../../Router/hook";
import { useAppSelector } from "./../../redux-config/hooks";
import { selectUser } from "./../../redux-config/entities/user";
import {
    CustomBreadCrumb,
    CustomPagination,
    Loading,
} from "./../../components";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const PaymentList: FC = () => {
    const [payment, setPayment] = useState<AllPaymentType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [showModalTransaction, setShowModalTransaction] =
        useState<boolean>(false);
    const [modalTransactionData, setModalTransactionData] =
        useState<DealType>();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("paymentList"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchListAllPayment = (page: number) => {
        setLoading(true);
        PaymentApi.AllPayment(user, page)
            .then((data) => {
                setPayment(data.payment);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchListAllPayment(pagination.current_page);
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("paymentList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("paymentTable")}</h4>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("userPhone")}</th>
                                    <th>{t("transaction")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("updatedTime")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payment.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.user.phone}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setShowModalTransaction(
                                                        true,
                                                    );

                                                    setModalTransactionData(
                                                        item.transaction,
                                                    );
                                                }}
                                            >
                                                {t("showTransaction")}
                                            </button>
                                        </td>
                                        <td>{item?.amount}</td>
                                        <td>
                                            <strong>{item?.type}</strong>
                                        </td>
                                        <td>
                                            {" "}
                                            <strong
                                                className={
                                                    item?.status === "success"
                                                        ? "badge bg-success"
                                                        : "badge bg-danger"
                                                }
                                            >
                                                {item?.status}
                                            </strong>
                                        </td>
                                        <td>
                                            {item?.updatedAt.slice(0, 10)}{" "}
                                            {item?.updatedAt.slice(11, 19)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(`/payment?current_page=${pageNum}`);
                                fetchListAllPayment(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModalTransaction}
                onHide={() => setShowModalTransaction(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("transaction")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalTransactionData && (
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex flex-row">
                                <span>{t("id")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.id}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("status")}:</span>
                                <strong className="mx-1">
                                    <span
                                        className={
                                            modalTransactionData.status ===
                                            "success"
                                                ? "badge bg-success"
                                                : "badge bg-warning"
                                        }
                                    >
                                        {modalTransactionData.status}
                                    </span>
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("type")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.type}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("amount")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.amount}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("currency")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.currency}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("gateway")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.gateway}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("device")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.device}
                                </strong>
                            </div>
                            <div className="d-flex flex-row">
                                <span>{t("description")}:</span>
                                <strong className="mx-1">
                                    {modalTransactionData.description}
                                </strong>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowModalTransaction(false)}
                    >
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
