import { Api } from "../../api";
import { DealType, PaginationType, userType } from "../../../DTO";
import { castDealList } from "./list";
import { convertToPaginationType } from "../../help";

export const UserTransactionList = (
    user: userType,
    id: number,
    // currentPage: number,
) => {
    return new Promise<{
        userTransaction: DealType[];
        // pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/transaction/user/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            // params: {
            //     page: currentPage,
            // },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    userTransaction: castDealList(data.data.data),
                    // pagination: convertToPaginationType(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
