import { AccommodationRoomType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationRoomType } from "./list";

export const CreateRoomType = (
    user: userType,
    accommodationId: number,
    name: string,
    code: string,
    adult: number,
    description: string,
) => {
    return new Promise<{ roomType: AccommodationRoomType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "post",
                path: `admin/accommodation/${accommodationId}/room-type`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    code,
                    adult,
                    description,
                }),
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        roomType: convertToAccommodationRoomType(data.data),
                    });
                })
                .catch((error: any) => {
                    return reject(error.data.message);
                });
        },
    );
};
