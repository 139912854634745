import React, { FC, useState } from "react";

export const Seat: FC<{
    submitRow: string;
    submitColumn: string;
    seatCount: number;
    handleSeatCount: Function;
    handleSeatAdd: Function;
    handleSeatDelete: Function;
    title: string;
}> = ({
    submitRow,
    handleSeatAdd,
    handleSeatCount,
    handleSeatDelete,
    seatCount,
    submitColumn,
    title,
}) => {
    return (
        <div className="d-flex flex-column bg-custom-white justify-content-center align-items-center p-3  rounded mx-2">
            <div className=" d-flex flex-column">
                <div className="border border-1 p-2 rounded d-flex flex-column">
                    <div className="w-100 text-center pt-3">
                        <span>{title}</span>
                        <hr />
                    </div>
                    <div className="d-flex flex-column">
                        {Array.from(
                            { length: Number(submitRow) },
                            (_, rowIndex) => (
                                <div className="d-flex flex-row">
                                    {Array.from(
                                        { length: Number(submitColumn) },
                                        (_, columnIndex) => (
                                            <HandleSeatBtn
                                                rowIndex={rowIndex}
                                                columnIndex={columnIndex}
                                                seatCount={seatCount}
                                                handleSeatCount={
                                                    handleSeatCount
                                                }
                                                handleSeatAdd={handleSeatAdd}
                                                handleSeatDelete={
                                                    handleSeatDelete
                                                }
                                            />
                                        ),
                                    )}
                                </div>
                            ),
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HandleSeatBtn: React.FC<{
    rowIndex: number;
    columnIndex: number;
    seatCount: number;
    handleSeatCount: Function;
    handleSeatAdd: Function;
    handleSeatDelete: Function;
}> = ({
    rowIndex,
    columnIndex,
    seatCount,
    handleSeatCount,
    handleSeatAdd,
    handleSeatDelete,
}) => {
    const [seatId, setSeatId] = useState<number>(0);

    if (seatId != 0) {
        return (
            <button
                className="btn btn-info m-1"
                style={{ width: "3rem", height: "3rem" }}
                onClick={() => {
                    setSeatId(0);
                    handleSeatCount(seatCount - 1);
                    handleSeatDelete(seatId, rowIndex, columnIndex);
                }}
            >
                {seatId}
            </button>
        );
    } else {
        return (
            <button
                onClick={() => {
                    setSeatId(seatCount + 1);
                    handleSeatCount(seatCount + 1);
                    handleSeatAdd(seatCount + 1, rowIndex, columnIndex);
                }}
                className="btn btn-info m-1"
                style={{ width: "3rem", height: "3rem" }}
            >
                +
            </button>
        );
    }
};
