import {
    AccommodationAddressType,
    AccommodationAttachmentsDataType,
    AccommodationAttachmentsType,
    AccommodationFacilitiesType,
    AccommodationType,
    PaginationType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToPaginationType } from "../../help";

export const ListAccommodation = (user: userType, currentPage: number) => {
    return new Promise<{
        accommodation: AccommodationType[];
        pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            path: "admin/accommodation",
            method: "get",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    accommodation: convertToAccommodationTypeList(
                        data.data.data,
                    ),
                    pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};

export const convertToAccommodationTypeList = (
    data: any[],
): AccommodationType[] => {
    return data.map((element) => convertToAccommodationType(element));
};

export const convertToAccommodationType = (data: any): AccommodationType => {
    let out: AccommodationType = {
        address: convertToAccommodationAddressType(data?.address),
        attachments: convertToAccommodationAttachmentsType(data?.attachments),
        code: data?.code,
        hasAttachments: data?.hasAttachments,
        id: data?.id,
        name: data?.name,
        description: data?.description,
        policy: data?.policy,
        star: data?.star,
        status: data?.status,
        facilities: data?.facilities
            ? convertToAccommodationFacilitiesTypeList(data?.facilities)
            : [],
    };

    return out;
};

export const convertToAccommodationAddressType = (
    data: any,
): AccommodationAddressType => {
    let out: AccommodationAddressType = {
        detail: data?.detail,
        id: data?.id,
        lat: data?.lat,
        lng: data?.lng,
        title: data?.title,
    };

    return out;
};

export const convertToAccommodationAttachmentsType = (
    data: any,
): AccommodationAttachmentsType => {
    let out: AccommodationAttachmentsType = {
        data: data?.data
            ? convertToAccommodationAttachmentsDataTypeList(data?.data)
            : [],
    };

    return out;
};

export const convertToAccommodationAttachmentsDataTypeList = (
    data: any[],
): AccommodationAttachmentsDataType[] => {
    return data.map((element) =>
        convertToAccommodationAttachmentsDataType(element),
    );
};

export const convertToAccommodationAttachmentsDataType = (
    data: any,
): AccommodationAttachmentsDataType => {
    let out: AccommodationAttachmentsDataType = {
        path: data?.path,
    };

    return out;
};

export const convertToAccommodationFacilitiesTypeList = (
    data: any[],
): AccommodationFacilitiesType[] => {
    return data.map((element) => convertToAccommodationFacilitiesType(element));
};

export const convertToAccommodationFacilitiesType = (
    data: any,
): AccommodationFacilitiesType => {
    let out: AccommodationFacilitiesType = {
        id: data?.id,
        name: data?.name,
        description: data?.description,
    };

    return out;
};
