import { FC, useEffect, useMemo, useState } from "react";
import { AccommodationManagerType, AdminUserType } from "../../../DTO";
import { AdminApi, HotelApi } from "../../../api";
import { useAppParams } from "../../../Router/hook";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { CustomBreadCrumb, Loading } from "../../../components";
import { Link } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const ManagerList: FC = () => {
    const [manager, setManager] = useState<AccommodationManagerType[]>([]);
    const [adminName, setAdminName] = useState<string>("");
    const [adminList, setAdminList] = useState<AdminUserType[]>([]);
    const [selectedAdmin, setSelectedAdmin] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModalManager, setShowModalManager] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<"create" | "delete">("create");
    const { id } = useAppParams();

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        {
            name: t("hotelAccommodation"),
            href: `/hotel/accommodation/list`,
        },
        {
            name: t("manager"),
            href: "#",
        },
    ];

    const fetchManagerList = () => {
        setLoading(true);
        HotelApi.ManagerApi.ListManager(user, Number(id))
            .then((data) => {
                setLoading(false);
                setManager(data.manager);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const fetchAdminList = (query: string) => {
        AdminApi.FetchList(user, 1, query)
            .then((data) => {
                setAdminList(data.data);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const throttledSearchAdmin = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchAdminList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchAdmin.cancel();
        };
    }, [throttledSearchAdmin]);

    useEffect(() => {
        fetchManagerList();
    }, [id]);

    const handleCreateManager = () => {
        setLoading(true);

        HotelApi.ManagerApi.CreateManager(user, Number(id), selectedAdmin)
            .then(() => {
                setLoading(false);
                setShowModalManager(false);
                setAdminList([]);
                setAdminName("");
                setSelectedAdmin(0);
                fetchManagerList();
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const handleDeleteManager = () => {
        setLoading(true);

        HotelApi.ManagerApi.DeleteManager(user, Number(id), selectedAdmin)
            .then(() => {
                setLoading(false);
                setShowModalManager(false);
                setAdminList([]);
                setAdminName("");
                setSelectedAdmin(0);
                fetchManagerList();
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("manager")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("managerTable")}</h4>
                        <div className="d-flex flex-row gap-2">
                            <button
                                onClick={() => {
                                    setShowModalManager(true);
                                    setTypeModal("create");
                                }}
                                className="btn btn-success"
                            >
                                {t("create")} +
                            </button>
                            <button
                                onClick={() => {
                                    setShowModalManager(true);
                                    setTypeModal("delete");
                                }}
                                className="btn btn-danger"
                            >
                                {t("delete")} -
                            </button>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("managerName")}</th>
                                    <th>{t("hotelName")}</th>
                                    <th>{t("createdAt")}</th>
                                    <th>{t("updatedAt")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {manager.length > 0 &&
                                    manager.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>
                                                {item?.admin?.first_name}{" "}
                                                {item?.admin?.last_name}
                                            </td>
                                            <td>{item?.accommodation}</td>
                                            <td>
                                                {item.createdAt.slice(0, 10)}{" "}
                                                {item.createdAt.slice(11, 19)}
                                            </td>
                                            <td>
                                                {item.updatedAt.slice(0, 10)}{" "}
                                                {item.updatedAt.slice(11, 19)}
                                            </td>
                                            <td>
                                                <Link
                                                    className="btn btn-sm btn-secondary"
                                                    to={`/hotel/accommodation/info/${item.accommodationId}`}
                                                >
                                                    {t("accommodation")}
                                                </Link>
                                            </td>{" "}
                                            <td>
                                                <Link
                                                    className="btn btn-sm btn-info"
                                                    to={`/admin-user/info/${item.accommodationId}`}
                                                >
                                                    {t("manager")}
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal
                    show={showModalManager}
                    onHide={() => setShowModalManager(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {typeModal === "create"
                                ? t("create")
                                : typeModal === "delete" && t("delete")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group
                            controlId="admin-id"
                            className="position-relative col-12 p-2"
                        >
                            <Form.Label>{t("admin")}</Form.Label>
                            <Form.Control
                                autoComplete="off"
                                aria-label="AdminName"
                                className="h3"
                                type="string"
                                name="AdminName"
                                placeholder={String(t("adminPlaceholder"))}
                                value={adminName}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setAdminName(e.target.value);
                                    throttledSearchAdmin(e);
                                }}
                            />
                            {adminList.length > 0 && (
                                <div
                                    style={{ zIndex: 10 }}
                                    className="position-absolute border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {adminList.map((item, index) => (
                                        <strong
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-1 align-self-center"
                                            onClick={() => {
                                                setSelectedAdmin(
                                                    Number(item.id),
                                                );
                                                setAdminName(
                                                    `${item?.first_name} ${item?.last_name} <---> ${item.phone}`,
                                                );
                                                setAdminList([]);
                                            }}
                                        >
                                            {`${item?.first_name} ${item?.last_name} <---> ${item.phone}`}
                                        </strong>
                                    ))}
                                </div>
                            )}
                            <Form.Text className="text-danger">
                                {adminName.length <= 0 && t("adminError")}
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setAdminName("");
                                setSelectedAdmin(0);
                                setAdminList([]);
                                setShowModalManager(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => {
                                if (typeModal === "create") {
                                    handleCreateManager();
                                } else {
                                    handleDeleteManager();
                                }
                            }}
                        >
                            {t("submit")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
