import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Loading, CustomBreadCrumb } from "../../../../components";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { AddressApi } from "../../../../api";
import { Formik } from "formik";
import { CountryForm } from "../../../../form";
import { useAppNavigate } from "../../../../Router/hook";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export const CountryCreate = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useAppNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("country")), href: "/definition/country" },
        { name: String(t("create")), href: "#" },
    ];

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("country")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <h4 className="my-2">{t("createCountry")}</h4>
                        <div className="d-flex flex-row flex-wrap">
                            <Formik<CountryForm.FormValues>
                                validationSchema={CountryForm.ValidationSchemaEdit(
                                    t,
                                )}
                                initialValues={{
                                    name: "",
                                    enName: "",
                                    domainCode: "",
                                    status: "Active",
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    AddressApi.CreateCountry(user, {
                                        id: 0,
                                        domain_code: event.domainCode,
                                        name: event.name,
                                        name_en: event.enName,
                                        status: event.status,
                                    })
                                        .then((data) => {
                                            navigate("/definition/country");
                                            setLoading(false);
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err.data.message,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <CountryForm.CountryFormEdit
                                        submitTxt={t("submit")}
                                        {...props}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
