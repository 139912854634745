import { userType } from "../../../DTO";
import { Api } from "../../api";

export const EditInventory = (
    user: userType,
    accommodationId: number,
    roomId: number,
    availability: number,
    date: string,
) => {
    return new Promise<{ inventory: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/accommodation/${accommodationId}/inventory/room-type/${roomId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ availability, date }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    inventory: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
