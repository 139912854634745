import { Api } from "../api";
import { TripType, userType } from "../../DTO";
import { convertToTrip } from "./trip_list";

export const EditTrip = (
    user: userType,
    id: number,
    price: number,
    duration: number,
    portInId: number,
    portOutId: number,
    status:
        | "Pending"
        | "Active"
        | "Completed"
        | "Cancelled"
        | "Rejected"
        | "Failed",
    priceFront: number,
    time: string,
) => {
    return new Promise<{ trip: TripType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `trip/update/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify([
                {
                    price: price,
                    duration,
                    port_in_id: portInId,
                    port_out_id: portOutId,
                    status,
                    price_front: priceFront,
                    time,
                },
            ]),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    trip: data,
                });
            })
            .catch((error) => reject(error));
    });
};
