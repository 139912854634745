import { TripType, PaginationType, userType, RouteType } from "../../DTO";
import { convertToCity } from "../address";
import { Api } from "../api";
import { convertToDriver } from "../driver";
import { convertToPaginationType } from "../help";
import { convertOrderFlightPort } from "../order";
import { convertToVehicle } from "../vehicle/get_list";

export const FetchTripList = (user: userType, current_page: number) => {
    return new Promise<{ data: TripType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/trip/get",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToTripAppList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToTripAppList(data: any[]): TripType[] {
    const userList = data.map((item: any) => {
        return convertToTrip(item);
    });
    return userList;
}

export function convertToTrip(data: any): TripType {
    const tripOB: TripType = {
        id: data.id,
        vehicle_id: data.vehicle_id,
        route_id: data.route_id,
        driver_id: data.driver_id,
        port_in_id: data.port_in_id,
        port_out_id: data.port_out_id,
        price: data.price,
        expire_time: data.expire_time,
        time: data.time,
        model: data.model,
        remain: data?.remain,
        status: data.status,
        deleted_at: data.deleted_at,
        created_at: data.created_at,
        updated_at: data.updated_at,
        vehicle: convertToVehicle(data.vehicle),
        route: convertToRoute(data.route),
        driver: convertToDriver(data?.driver),
        price_front: data?.price_front,
        duration: data?.duration,
        portIn: convertOrderFlightPort(data?.port_in),
        portOut: convertOrderFlightPort(data?.port_out),
    };
    return tripOB;
}

export function convertToRoute(data: any): RouteType {
    const routeOb: RouteType = {
        id: data?.id,
        city_in_id: data?.city_in_id,
        city_out_id: data?.city_out_id,
        price: data?.price,
        status: data?.status,
        city_in: convertToCity(data?.city_in),
        city_out: convertToCity(data?.city_out),
        min: data?.min,
        max: data?.max,
    };
    return routeOb;
}
