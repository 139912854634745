import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { Form, Table } from "react-bootstrap";
import { CustomPagination, Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { DriverApi } from "../../../../api";
import { selectUser } from "../../../../redux-config/entities/user";
import { useAppSelector } from "../../../../redux-config/hooks";
import { DriverType, PaginationType } from "../../../../DTO";
import { useAppPagination } from "../../../../Router/hook";
import { useTranslation } from "react-i18next";

export const VehicleDriverList = () => {
    const { t } = useTranslation();
    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("driverList"), href: "#" },
    ];

    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [driverList, setDriverList] = useState<DriverType[]>([]);

    useEffect(() => {
        fetchDriverList(pagination.current_page);
    }, []);

    const fetchDriverList = (page: number) => {
        setLoading(true);
        DriverApi.FetchList(user, page)
            .then((data) => {
                setDriverList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const changeStatus = (id: string, status: "Active" | "InActive") => {
        setLoading(true);
        DriverApi.ChangeStatusDriver(user, id, status)
            .then(() => {
                setLoading(false);
                fetchDriverList(pagination.current_page);
                Swal.fire({
                    text: String(t("statusChangeMessage")),
                    icon: "success",
                });
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("driverList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("driverTable")}</h4>
                        <div className="d-flex flex-row ">
                            <Link
                                to={`/vehicle/driver/new`}
                                className="btn btn-success"
                            >
                                {t("create")} +
                            </Link>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("fullName")}</th>
                                    <th>{t("phone")}</th>
                                    <th>{t("carType")}</th>
                                    <th>{t("carName")}</th>
                                    <th>{t("commission")}</th>
                                    <th>{t("commissionVip")}</th>
                                    <th>{t("status")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {driverList.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>
                                            {item?.user?.first_name}{" "}
                                            {item?.user?.last_name}
                                        </td>
                                        <td>
                                            <a href={`tel:${item.phone}`}>
                                                {item.phone}
                                            </a>
                                        </td>
                                        {/* <td></td>
                                        <td></td> */}
                                        <td>{item?.vehicle?.model}</td>
                                        <td>{item?.vehicle?.title}</td>
                                        <td>
                                            {Math.floor(
                                                Number(item.commission),
                                            )}
                                            %
                                        </td>
                                        <td>
                                            {Math.floor(
                                                Number(item.commissionVip),
                                            )}
                                            %
                                        </td>
                                        <td>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    value={item.status}
                                                    defaultChecked={
                                                        item?.status ===
                                                        "Active"
                                                    }
                                                    checked={
                                                        item?.status ===
                                                        "Active"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        changeStatus(
                                                            String(item.id),
                                                            e.target.checked
                                                                ? "Active"
                                                                : "InActive",
                                                        );
                                                    }}
                                                />
                                            </Form>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/vehicle/driver/info/${item.id}`}
                                                className="btn btn-info btn-sm"
                                            >
                                                {t("info")}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/vehicle/driver/${item.id}/edit`}
                                                className="btn btn-warning btn-sm"
                                            >
                                                {t("edit")}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/vehicle/driver/list?current_page=${pageNum}`,
                                );
                                fetchDriverList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
