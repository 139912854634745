import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import Swal from "sweetalert2";

import { selectUser } from "../../../redux-config/entities/user";
import { useAppNavigate, useAppParams } from "../../../Router/hook";
import { CustomBreadCrumb, Loading } from "../../../components";
import { AddressForm } from "../../../form";
import { AddressApi } from "../../../api";
import { useAppSelector } from "../../../redux-config/hooks";
import { AddressType } from "../../../DTO";
import { useTranslation } from "react-i18next";

export const HotelAddressEdit: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [address, setAddress] = useState<AddressType>();

    const { id } = useAppParams();

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("addressList")), href: "/definition/address/list" },
        { name: `${t("edit")} ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchAddressOB();
    }, [id]);

    const fetchAddressOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.ShowAddress(user, Number(id))
                .then((data) => {
                    setAddress(data.address);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err,
                    });
                });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("addressEdit")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div>
                        <h4 className="my-2">{t("addressEdit")}</h4>
                        {address && (
                            <Formik<AddressForm.FormValues>
                                validationSchema={AddressForm.ValidationSchema(
                                    t,
                                )}
                                initialValues={{
                                    cityInId: Number(address.city?.id),
                                    cityInName: String(address.city?.name),
                                    detail: String(address.detail),
                                    lat: String(address.latitude),
                                    lng: String(address.longitude),
                                    title: String(address.title),
                                    buildingNumber: "",
                                    postalCode: String(address.postalCode),
                                }}
                                onSubmit={(event) => {
                                    setLoading(true);
                                    AddressApi.EditAddress(
                                        user,
                                        Number(id),
                                        event.title,
                                        event.detail,
                                        event.cityInId,
                                        Number(event.lat),
                                        Number(event.lng),
                                        event.postalCode,
                                        event.buildingNumber,
                                    )
                                        .then(() => {
                                            setLoading(false);
                                            navigate(
                                                "/definition/address/list",
                                            );
                                        })
                                        .catch((err) => {
                                            setLoading(false);
                                            Swal.fire({
                                                icon: "error",
                                                text: err,
                                            });
                                        });
                                }}
                                component={(props: any) => (
                                    <AddressForm.AddressCreateForm
                                        submitTxt={String(t("submit"))}
                                        {...props}
                                    />
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
