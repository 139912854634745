import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { DealType, UserAppType } from "../../../DTO";
import { AppUserApi, PaymentApi } from "../../../api";
import { Loading, CustomBreadCrumb } from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";

export const InfoUser: React.FC = () => {
    const [transaction, setTransaction] = useState<DealType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [appUser, setAppUser] = useState<UserAppType>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [amount, setAmount] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [walletType, setWalletType] = useState<"increase" | "decrease">(
        "increase",
    );

    const { id } = useParams();

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const fetchUser = () => {
        setLoading(true);

        AppUserApi.ShowUser(user, Number(id))
            .then((data) => {
                setAppUser(data.user);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchUserTransaction = () => {
        setLoading(true);

        PaymentApi.TransactionApi.UserTransactionList(user, Number(id))
            .then((data) => {
                setTransaction(data.userTransaction);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchUserTransaction();
        fetchUser();
    }, [id]);

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("userList")), href: "/App-user" },
        { name: `${t("info")} ${id}`, href: "#" },
    ];

    const handleIncreaseWallets = () => {
        setLoading(true);
        PaymentApi.WalletApi.IncreaseWallet(
            user,
            Number(appUser?.id),
            Number(amount),
            description,
        )
            .then(() => {
                setLoading(false);
                setShowModal(false);
                setAmount("");
                setDescription("");
                fetchUser();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleDecreaseWallets = () => {
        setLoading(true);
        PaymentApi.WalletApi.DecreaseWallet(
            user,
            Number(appUser?.id),
            Number(amount),
            description,
        )
            .then(() => {
                setLoading(false);
                setShowModal(false);
                setAmount("");
                setDescription("");
                fetchUser();
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("userInfo")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex justify-content-center my-2  flex-row flex-wrap rounded px-3">
                    <div className="col-12 col-md-6 col-lg-4 px-2">
                        <div className="rounded p-3 align-items-center align-self-center h-100 shadow bg-custom-white d-flex flex-column position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="5x"
                                icon={faUserCircle}
                            />
                            <strong className="text-center mt-2">
                                {appUser?.first_name} {appUser?.last_name}
                            </strong>
                            <strong className="text-center mt-2">
                                {appUser?.email}
                            </strong>
                            <hr />
                            <div>
                                <span>{t("phoneNumber")}:</span>
                                <strong className="mx-2">
                                    {appUser?.phone}
                                </strong>
                            </div>
                            <div>
                                <span>{t("registerDate")}:</span>
                                <strong className="mx-2">
                                    {appUser?.created_at}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-8 px-2">
                        <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                            <div className="d-flex flex-row justify-content-between px-4">
                                <h4 className="text-nowrap">
                                    {t("walletTable")}
                                </h4>
                                <div className="d-flex flex-row gap-2">
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            setShowModal(true);
                                            setWalletType("increase");
                                        }}
                                    >
                                        {t("increase")} +
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setShowModal(true);
                                            setWalletType("decrease");
                                        }}
                                    >
                                        {t("increase")} -
                                    </button>
                                </div>
                            </div>
                            <div
                                className="overflow-auto"
                                style={{ maxHeight: "15rem" }}
                            >
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>{t("id")}</th>
                                            <th>{t("type")}</th>
                                            <th>{t("balance")}</th>
                                            <th>{t("amount")}</th>
                                            <th>{t("description")}</th>
                                            <th>{t("currency")}</th>
                                            <th>{t("status")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appUser?.wallets &&
                                            appUser?.wallets.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item?.id}</td>
                                                        <td>
                                                            <strong
                                                                className={
                                                                    item?.type ===
                                                                    "increase"
                                                                        ? "badge bg-success"
                                                                        : "badge bg-danger"
                                                                }
                                                            >
                                                                {item?.type}
                                                            </strong>
                                                        </td>
                                                        <td>{item?.balance}</td>
                                                        <td>{item?.amount}</td>
                                                        <td>
                                                            {item?.description}
                                                        </td>
                                                        <td>
                                                            {item?.currency}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <strong
                                                                className={
                                                                    item?.status ===
                                                                    "active"
                                                                        ? "badge bg-success"
                                                                        : "badge bg-danger"
                                                                }
                                                            >
                                                                {item?.status}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 shadow">
                        <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                            <h4 className="text-nowrap">
                                {t("transactionTable")}
                            </h4>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>{t("id")}</th>
                                        <th>{t("type")}</th>
                                        <th>{t("amount")}</th>
                                        <th>{t("currency")}</th>
                                        <th>{t("gateway")}</th>
                                        <th>{t("device")}</th>
                                        <th>{t("status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transaction.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item?.type}</td>
                                            <td>{item?.amount}</td>
                                            <td>{item?.currency}</td>
                                            <td>{item?.gateway}</td>
                                            <td>{item?.device}</td>
                                            <td>
                                                <strong
                                                    className={
                                                        item?.status ===
                                                        "success"
                                                            ? "badge bg-success"
                                                            : "badge bg-warning"
                                                    }
                                                >
                                                    {item?.status}
                                                </strong>{" "}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    setAmount("");
                    setDescription("");
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("wallet")}{" "}
                        {`${
                            walletType === "increase" ? "Increase" : "Decrease"
                        }`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column gap-2">
                    <Form.Group
                        controlId="wallet-amount"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("amount")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="amount"
                            className="h3"
                            type="number"
                            name="amount"
                            placeholder={String("amountPlaceholder")}
                            value={amount}
                            onChange={(e) => setAmount(String(e.target.value))}
                        />
                        <Form.Text className="text-danger">
                            {amount.length <= 0 && "Amount is required!"}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="wallet-description"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="description"
                            className="h3"
                            type="string"
                            name="description"
                            placeholder={String("placeholderDescription")}
                            value={description}
                            onChange={(e) =>
                                setDescription(String(e.target.value))
                            }
                        />
                        <Form.Text className="text-danger">
                            {description.length <= 0 &&
                                "Description is required!"}
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowModal(false);
                            setAmount("");
                            setDescription("");
                        }}
                    >
                        {t("close")}
                    </Button>
                    <Button
                        disabled={amount.length <= 0 && description.length <= 0}
                        variant="success"
                        onClick={() => {
                            if (walletType === "increase") {
                                handleIncreaseWallets();
                            } else {
                                handleDecreaseWallets();
                            }
                        }}
                    >
                        {t("submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
