import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";
import { SeatModelType } from "../../DTO";

export const CarFormCreate = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
        seatModel,
    } = props;

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="vehicle-seat-model"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("seatModel")}</Form.Label>
                        <Form.Select
                            onChange={handleChange}
                            aria-label="seatModel"
                            className="h3"
                            name="seatModel"
                            onBlur={handleBlur}
                            value={values.seatModel}
                            isInvalid={touched.seatModel && !!errors.seatModel}
                        >
                            {seatModel.length > 0 && (
                                <>
                                    <option className="d-none">
                                        {t("seatModelPlaceholder")}
                                    </option>
                                    {seatModel.map(
                                        (
                                            seatModelItem: SeatModelType,
                                            index: number,
                                        ) => (
                                            <option
                                                value={Number(
                                                    seatModelItem.model[0].id,
                                                )}
                                                key={index}
                                            >
                                                {seatModelItem.name}
                                            </option>
                                        ),
                                    )}
                                </>
                            )}
                        </Form.Select>
                        <Form.Text className="text-danger">
                            {errors.seatModel}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="car-title"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("title")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="title"
                            className="h3"
                            type="string"
                            name="title"
                            placeholder={String(t("placeholderTitle"))}
                            onBlur={handleBlur}
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Text className="text-danger">
                            {errors.title}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="car-model"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("model")}</Form.Label>
                        <Form.Select
                            aria-label="model"
                            className="h3"
                            name="model"
                            onBlur={handleBlur}
                            value={values.model}
                            onChange={handleChange}
                            isInvalid={touched.model && !!errors.model}
                        >
                            <option className="d-none">
                                {t("modelPlaceholder")}
                            </option>
                            <option value={"VAN"}>{t("van")}</option>
                            <option value={"BUS"}>{t("bus")}</option>
                            <option value={"CAR"}>{t("car")}</option>
                        </Form.Select>
                        <Form.Text className="text-danger">
                            {errors.model}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
