import { AccommodationInventoryType, userType } from "../../../DTO";
import { Api } from "../../api";
import { convertToAccommodationInventoryType } from "./list";

export const CreateInventory = (
    user: userType,
    accommodationId: number,
    roomId: number,
    availability: number,
    dateFrom: string,
    dateTo: string,
) => {
    return new Promise<{ inventory: any[] }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/accommodation/${accommodationId}/inventory/room-type/${roomId}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                availability,
                date_from: dateFrom,
                date_to: dateTo,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    inventory: data.data,
                });
            })
            .catch((error: any) => {
                return reject(error.data.message);
            });
    });
};
