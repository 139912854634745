import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faEdit,
    faSearch,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { Form, Table } from "react-bootstrap";
import { CustomPagination, Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { UserAccessApi } from "../../../api";
import { PaginationType, RoleType } from "../../../DTO";
import { useTranslation } from "react-i18next";

export const UserAccessList = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [rolesList, setRolesList] = useState<RoleType[]>([]);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("accessList"), href: "#" },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        UserAccessApi.RoleApi.ListRole(user)
            .then((data) => {
                setRolesList(data.role);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("accessList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("roleTable")}</h4>
                        <div className="d-flex flex-row ">
                            {/* <label
                htmlFor="showAdminSearchInput"
                className="btn btn-info mx-1 text-nowrap"
              >
                <span>Role search</span>
                <FontAwesomeIcon className={"mx-1"} icon={faSearch} />
              </label> */}
                            <Link
                                to={"new"}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                <span>{t("create")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faAdd}
                                />
                            </Link>
                        </div>
                    </div>
                    {/* <input type="checkbox" id="showAdminSearchInput"></input>
          <div className="adminSearchBox  p-2 flex -row justify-content-between align-items-center">
            <div>
              <Form className="d-flex flex-row flex-wrap">
                <Form.Group className="mx-2" style={{ minWidth: "230px" }}>
                  <Form.Label className="mb-0">Id</Form.Label>
                  <Form.Control  autoComplete="off"                   size="sm"
                    type="number"
                    placeholder="Enter id"
                  />
                </Form.Group>
                <Form.Group className="mx-2" style={{ minWidth: "230px" }}>
                  <Form.Label className="mb-0">Role</Form.Label>
                  <Form.Control  autoComplete="off"                   size="sm"
                    type="text"
                    placeholder="Enter Role"
                  />
                </Form.Group>
              </Form>
            </div>
            <button className="btn btn-sm btn-success mx-1 text-nowrap">
              <span>Search</span>
              <FontAwesomeIcon className="mx-1" icon={faSearch} />
            </button>
          </div> */}
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("guardName")}</th>
                                    <th>{t("status")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rolesList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.guardName}</td>
                                        <td>
                                            <strong
                                                className={`badge ${
                                                    item.status === "active"
                                                        ? "bg-success"
                                                        : "bg-danger"
                                                }`}
                                            >
                                                {item.status}
                                            </strong>
                                        </td>

                                        <td>
                                            <span
                                                onClick={() => {
                                                    Swal.fire({
                                                        text: `${t(
                                                            "alertDelete",
                                                        )}, ${item.name}? `,
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor:
                                                            "#3085d6",
                                                        cancelButtonColor:
                                                            "#d33",
                                                        confirmButtonText:
                                                            String(
                                                                t("yesDelete"),
                                                            ),
                                                        cancelButtonText:
                                                            String("cancel"),
                                                    }).then((result) => {
                                                        if (
                                                            result.isConfirmed
                                                        ) {
                                                            UserAccessApi.RoleApi.DeleteRole(
                                                                user,
                                                                item.id,
                                                            ).then((data) => {
                                                                Swal.fire(
                                                                    String(
                                                                        t(
                                                                            "delete",
                                                                        ),
                                                                    ),
                                                                    t(
                                                                        "deleteMessage",
                                                                    ),
                                                                    "success",
                                                                );
                                                                fetchData();
                                                            });
                                                        }
                                                    });
                                                }}
                                                className="btn btn-sm btn-danger "
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                />
                                            </span>
                                        </td>
                                        <td>
                                            <Link
                                                to={`edit/${item.id}`}
                                                className="btn btn-sm btn-warning"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};
