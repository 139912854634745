import { Api } from "../api";
import { TripType, userType } from "../../DTO";
import { convertToTrip } from "./trip_list";

export const CreateTrip = (
    user: userType,
    routeId: number,
    price: number,
    driverId: number,
    time: string,
    duration: number,
    portInId: number,
    portOutId: number,
    priceFront: number,
) => {
    return new Promise<{ trip: TripType }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "trip/store",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                route_id: routeId,
                price,
                driver_id: driverId,
                time,
                duration,
                port_in_id: portInId,
                port_out_id: portOutId,
                price_front: priceFront,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    trip: convertToTrip(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
