import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormikProps } from "formik";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { FormValues, PropType } from "./type";
import { useAppLocation } from "../../Router/hook";
import { RoleType } from "../../DTO";
import { UserAccessApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";

export const AdminFormCreate = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;
    const [roleList, setRoleList] = useState<RoleType[]>([]);

    const { pathname } = useAppLocation();

    const { t } = useTranslation();

    const user = useAppSelector(selectUser);

    const fetchRoleList = () => {
        UserAccessApi.RoleApi.ListRole(user)
            .then((data) => {
                setRoleList(data.role);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    useEffect(() => {
        fetchRoleList();
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column  ">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="admin-firstName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("firstName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="firstName"
                            className="h3"
                            type="string"
                            name="firstName"
                            placeholder={String(t("firstNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Text className="text-danger">
                            {errors.firstName}
                        </Form.Text>
                    </Form.Group>{" "}
                    <Form.Group
                        controlId="admin-lastName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("lastName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="lastName"
                            className="h3"
                            type="string"
                            name="lastName"
                            placeholder={String(t("lastNamePlaceholder"))}
                            onBlur={handleBlur}
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Text className="text-danger">
                            {errors.lastName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-username"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("username")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            disabled={
                                pathname == "/admin-user/edit" ? true : false
                            }
                            aria-label="username"
                            className="h3"
                            type="string"
                            name="username"
                            placeholder={String(t("placeholderUsername"))}
                            onBlur={handleBlur}
                            value={values.username}
                            onChange={handleChange}
                            isInvalid={touched.username && !!errors.username}
                        />
                        <Form.Text className="text-danger">
                            {errors.username}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-phoneNumber"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("phoneNumber")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="phone number"
                            className="h3"
                            type="string"
                            name="phoneNumber"
                            placeholder={String(t("placeholderNumber"))}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={
                                touched.phoneNumber && !!errors.phoneNumber
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.phoneNumber}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-email"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("email")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="email"
                            className="h3"
                            type="string"
                            name="email"
                            placeholder={String(t("placeholderEmail"))}
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Text className="text-danger">
                            {errors.email}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="driver-vehicleId"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("role")}</Form.Label>
                        <Form.Select
                            aria-label="Choose Role"
                            onChange={handleChange}
                            className="h3"
                            name="roleId"
                            onBlur={handleBlur}
                            value={values.roleId}
                            isInvalid={touched.roleId && !!errors.roleId}
                        >
                            <option className="d-none">
                                {t("rolePlaceholder")}
                            </option>
                            {roleList.map((roleElement, index) => (
                                <option key={index} value={roleElement.id}>
                                    {roleElement?.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Text className="text-danger">
                            {errors.roleId}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="admin-password"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("password")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="password"
                            className="h3"
                            type={"password"}
                            name="password"
                            placeholder={String(t("placeholderPassword"))}
                            onBlur={handleBlur}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Text className="text-danger">
                            {errors.password}
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Button
                    variant="primary"
                    type="submit"
                    className="col-12 col-sm-4 text-white mt-4"
                >
                    {submitTxt}
                </Button>
            </div>
        </Form>
    );
};
