import { DiscountType, userType } from "../../DTO";
import { Api } from "../api";

export const EditDiscount = (
    user: userType,
    code: number,
    name: string,
    expireTime: string,
    amount: number,
    id: string,
) => {
    return new Promise<{ discountCode: DiscountType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `coupon/update/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify([
                {
                    name,
                    code,
                    amount,
                    expire_time: expireTime,
                },
            ]),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    discountCode: convertDiscountCode(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};

export const convertDiscountCode = (data: any): DiscountType => {
    let out: DiscountType = {
        id: data?.id,
        name: data?.name,
        code: data?.code,
        userId: data?.user_id,
        expireTime: data?.expire_time,
        amount: data?.amount,
        model: data?.model,
        deletedAt: data?.deleted_at,
        createdAt: data?.created_at,
        updatedAt: data?.updated_at,
    };

    return out;
};
