import {
    AccommodationBalanceType,
    HotelBalanceType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";
import { convertToBookingType } from "../booking";

export const AccommodationBalance = (
    user: userType,
    accommodationId: number,
) => {
    return new Promise<{ balance: AccommodationBalanceType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `admin/accommodation/${accommodationId}/balance`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        balance: convertToAccommodationBalanceTypeList(
                            data.data,
                        ),
                    });
                })
                .catch((err) => reject(err.data.message));
        },
    );
};

export const convertToAccommodationBalanceTypeList = (
    data: any,
): AccommodationBalanceType => {
    let out: AccommodationBalanceType = {
        balance: data?.balance,
        data: convertToHotelBalanceTypeList(data?.data),
    };

    return out;
};

export const convertToHotelBalanceTypeList = (
    data: any[],
): HotelBalanceType[] => {
    return data.map((element) => convertToHotelBalanceType(element));
};

export const convertToHotelBalanceType = (data: any): HotelBalanceType => {
    let out: HotelBalanceType = {
        amount: data?.amount,
        booking: convertToBookingType(data?.booking),
        description: data?.description,
        id: data?.id,
        type: data?.type,
    };

    return out;
};
