import * as Yup from "yup";

export const ValidationSchemaEdit = (t: any) =>
    Yup.object().shape({
        name: Yup.string().required(t("nameError")).min(1).max(256),
        enName: Yup.string().required(t("enNameError")).min(1).max(256),
        domainCode: Yup.string().required(t("domainCodeError")).min(1).max(128),
        lat: Yup.string().required(t("latError")).min(0).max(128),
        lng: Yup.string().required(t("lngError")).min(0).max(128),
        provinceId: Yup.string().required(t("provinceNameError")).min(1),
    });

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        name: Yup.string().required(t("nameError")).min(1).max(256),
        enName: Yup.string().required(t("enNameError")).min(1).max(256),
        domainCode: Yup.string().required(t("domainCodeError")).min(1).max(128),
        lat: Yup.string().required(t("latError")).min(0).max(128),
        lng: Yup.string().required(t("lngError")).min(0).max(128),
        provinceId: Yup.string().required(t("provinceNameError")).min(1),
    });
