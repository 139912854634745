import { FlightCommissionType, userType } from "../../../DTO";
import { Api } from "../../api";

export const ShowCommission = (user: userType) => {
    return new Promise<{ commission: FlightCommissionType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: `admin/flight/commission`,
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
            });

            return api
                .call()
                .then((data: any) =>
                    resolve({
                        commission: convertToFlightCommissionType(data.data),
                    }),
                )
                .catch((err) => reject(err.data.message));
        },
    );
};

export const convertToFlightCommissionType = (
    data: any,
): FlightCommissionType => {
    let out: FlightCommissionType = {
        commission: data?.commission,
        createdAt: data?.created_at,
        id: data?.id,
        updatedAt: data?.updated_at,
    };

    return out;
};
