import React, { lazy, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";

import { FormValues, PropType } from "./type";
import { ProvinceType } from "../../DTO";
import { AddressApi } from "../../api";
import { selectUser } from "../../redux-config/entities/user";
import { useAppLocation } from "../../Router/hook";
import { Shimmer } from "../../components";

const CustomMap = lazy(() => import("../../components/map"));

export const CityFormEdit = (props: PropType & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        submitTxt,
    } = props;

    const [lat, setLat] = useState(values.lat);
    const [lng, setLng] = useState(values.lng);
    const [provinceName, setProvinceName] = useState(values.provinceName);
    const [provinceList, setProvinceList] = useState<ProvinceType[]>([]);
    const [selectedprovince, setSelectedProvince] = useState(values.provinceId);

    const [provinceLoading, setProvinceLoading] = useState<boolean>(false);

    const user = useSelector(selectUser);

    const { pathname } = useAppLocation();

    useEffect(() => {
        values.lat = lat;
    }, [lat]);

    useEffect(() => {
        values.lng = lng;
    }, [lng]);

    useEffect(() => {
        values.provinceId = selectedprovince;
    }, [selectedprovince]);

    useEffect(() => {
        values.provinceName = provinceName;
    }, [provinceName]);

    const throttledSearchProvince = useMemo(() => {
        return _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            fetchProvinceList(event.target.value);
        }, 1000);
    }, []);

    useEffect(() => {
        return () => {
            throttledSearchProvince.cancel();
        };
    }, [throttledSearchProvince]);

    const fetchProvinceList = (query: string) => {
        AddressApi.FetchProvinceList(user, 1, {
            id: 0,
            name: query,
            name_en: "",
            domain_code: "",
            status: "Active",
        })
            .then((data) => {
                setProvinceList(data.data);
                setProvinceLoading(false);
            })
            .catch((err) => {
                setProvinceLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row col-12 flex-wrap">
                    <Form.Group
                        controlId="city-name"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="name"
                            className="h3"
                            type="string"
                            name="name"
                            placeholder={String(t("placeholderName"))}
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Text className="text-danger">
                            {errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="city-enName"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("enName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="phone number"
                            className="h3"
                            type="string"
                            name="enName"
                            placeholder={String(t("placeholderEnName"))}
                            onBlur={handleBlur}
                            value={values.enName}
                            onChange={handleChange}
                            isInvalid={touched.enName && !!errors.enName}
                        />
                        <Form.Text className="text-danger">
                            {errors.enName}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="city-domainCode"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("domainCode")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="domainCode"
                            className="h3"
                            type="string"
                            name="domainCode"
                            placeholder={String(t("placeholderDomainCode"))}
                            onBlur={handleBlur}
                            value={values.domainCode}
                            onChange={handleChange}
                            isInvalid={
                                touched.domainCode && !!errors.domainCode
                            }
                        />
                        <Form.Text className="text-danger">
                            {errors.domainCode}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group
                        controlId="city-provinceId"
                        className=" col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("provinceName")}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="provinceName"
                            type="string"
                            name="provinceName"
                            onFocus={() => {
                                setProvinceLoading(true);
                                fetchProvinceList("");
                            }}
                            placeholder={String(t("placeholderProvinceName"))}
                            onBlur={handleBlur}
                            value={provinceName}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setProvinceName(e.target.value);
                                throttledSearchProvince(e);
                            }}
                            isInvalid={
                                touched.provinceName && !!errors.provinceName
                            }
                        />
                        <div className={"position-relative col-12"}>
                            {provinceList.length > 0 ? (
                                <div
                                    onMouseLeave={() => setProvinceList([])}
                                    style={{ zIndex: 10, maxHeight: "20rem" }}
                                    className="position-absolute overflow-auto rounded-2 top-0 border border-1 d-flex flex-column bg-custom-white w-100"
                                >
                                    {provinceList.map((item, index) => (
                                        <span
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            className="p-3 border-bottom text-center w-100"
                                            onClick={() => {
                                                setSelectedProvince(item.id);
                                                setProvinceName(item.name);
                                                setProvinceList([]);
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                provinceLoading && (
                                    <div
                                        style={{ zIndex: 10 }}
                                        className="text-center position-absolute border w-100 top-0 rounded-2 p-2 border-2 bg-custom-white"
                                    >
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="w-100 my-2">
                                            <Shimmer
                                                height="20px"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <Form.Text className="text-danger">
                            {errors.provinceName}
                        </Form.Text>
                    </Form.Group>
                    {!pathname.includes("/definition/city/create") && (
                        <Form.Group
                            controlId="route-status"
                            className="position-relative col-12 col-sm-6 p-2"
                        >
                            <Form.Label>{t("status")}</Form.Label>
                            <Form.Select
                                aria-label="status"
                                className="h3"
                                name="status"
                                onBlur={handleBlur}
                                value={values.status}
                                onChange={handleChange}
                                isInvalid={touched.status && !!errors.status}
                            >
                                <option className="d-none">
                                    {t("placeholderStatus")}
                                </option>
                                <option
                                    value={"Active"}
                                    className="text-success"
                                >
                                    {t("active")}
                                </option>
                                <option
                                    value={"InActive"}
                                    className="text-danger"
                                >
                                    {t("inActive")}
                                </option>
                            </Form.Select>
                        </Form.Group>
                    )}
                    <Form.Group
                        controlId="route-status-accommodation"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("statusAccommodation")}</Form.Label>
                        <Form.Select
                            aria-label="statusAccommodation"
                            className="h3"
                            name="statusAccommodation"
                            onBlur={handleBlur}
                            value={values.statusAccommodation}
                            onChange={handleChange}
                            isInvalid={
                                touched.statusAccommodation &&
                                !!errors.statusAccommodation
                            }
                        >
                            <option value={"Active"} className="text-success">
                                {t("active")}
                            </option>
                            <option value={"InActive"} className="text-danger">
                                {t("inActive")}
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group
                        controlId="route-status-car"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("statusCar")}</Form.Label>
                        <Form.Select
                            aria-label="statusCar"
                            className="h3"
                            name="statusCar"
                            onBlur={handleBlur}
                            value={values.statusCar}
                            onChange={handleChange}
                            isInvalid={touched.statusCar && !!errors.statusCar}
                        >
                            <option value={"Active"} className="text-success">
                                {t("active")}
                            </option>
                            <option value={"InActive"} className="text-danger">
                                {t("inActive")}
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group
                        controlId="route-status-flight"
                        className="position-relative col-12 col-sm-6 p-2"
                    >
                        <Form.Label>{t("statusFlight")}</Form.Label>
                        <Form.Select
                            aria-label="statusFlight"
                            className="h3"
                            name="statusFlight"
                            onBlur={handleBlur}
                            value={values.statusFlight}
                            onChange={handleChange}
                            isInvalid={
                                touched.statusFlight && !!errors.statusFlight
                            }
                        >
                            <option value={"Active"} className="text-success">
                                {t("active")}
                            </option>
                            <option value={"InActive"} className="text-danger">
                                {t("inActive")}
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <div className="col-12 mb-2 p-2">
                        <CustomMap
                            location={{
                                lat: Number(values.lat),
                                lng: Number(values.lng),
                            }}
                            markerChange={(data: any) => {
                                setLat(data.lat);
                                setLng(data.lng);
                            }}
                            height={"300px"}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <button
                    type="submit"
                    className="btn btn-primary col-12 col-sm-4 mt-4"
                >
                    {submitTxt}
                </button>
            </div>
        </Form>
    );
};
