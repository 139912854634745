import { userType } from "../../../DTO";
import { Api } from "../../api";

export const AssignRole = (user: userType, id: number, roleId: number) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/assign/admin/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                role_id: roleId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err) => reject(err));
    });
};
