import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import * as Screen from "../screens";
import { SiteLayout } from "../components";
import { useAppSelector } from "../redux-config/hooks";
import { useAppNavigate } from "./hook";
import { selectUser } from "../redux-config/entities/user";
import { userType } from "../DTO";

export const RouterPage = () => {
    const user = useAppSelector(selectUser);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Screen.NotFound />} />
                <Route path="/login" element={<Screen.Login />} />
                <Route path="" element={<Auth />}>
                    <Route
                        path=""
                        element={
                            <SiteLayout>
                                <Screen.Home />
                            </SiteLayout>
                        }
                    ></Route>
                    <Route path="user-access">
                        {hasPermission("role-list", user) && (
                            <Route
                                path=""
                                element={
                                    <SiteLayout>
                                        <Screen.UserAccessList />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("role-edit", user) && (
                            <Route
                                path="edit/:id"
                                element={
                                    <SiteLayout>
                                        <Screen.EditRole />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("role-create", user) && (
                            <Route
                                path="new"
                                element={
                                    <SiteLayout>
                                        <Screen.NewRole />
                                    </SiteLayout>
                                }
                            />
                        )}
                    </Route>
                    <Route path="admin-user">
                        {hasPermission("admin-list", user) && (
                            <Route
                                path=""
                                element={
                                    <SiteLayout>
                                        <Screen.AdminUsers />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("admin-show", user) && (
                            <Route
                                path="info/:id"
                                element={
                                    <SiteLayout>
                                        <Screen.AdminInfo />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("admin-create", user) && (
                            <Route
                                path="new"
                                element={
                                    <SiteLayout>
                                        <Screen.NewAdmin />
                                    </SiteLayout>
                                }
                            />
                        )}
                    </Route>
                    {hasPermission("audit-log-list", user) && (
                        <Route
                            path="log"
                            element={
                                <SiteLayout>
                                    <Screen.Log />
                                </SiteLayout>
                            }
                        />
                    )}
                    <Route path="price-currency">
                        {hasPermission("price-currency-show", user) && (
                            <Route
                                path="list"
                                element={
                                    <SiteLayout>
                                        <Screen.PriceCurrencyList />
                                    </SiteLayout>
                                }
                            />
                        )}
                    </Route>
                    <Route path="app-user">
                        {hasPermission("user-list", user) && (
                            <Route
                                path=""
                                element={
                                    <SiteLayout>
                                        <Screen.AppUsers />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("user-show", user) && (
                            <Route
                                path="info/:id"
                                element={
                                    <SiteLayout>
                                        <Screen.InfoUser />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("user-edit", user) && (
                            <Route
                                path="edit/:id"
                                element={
                                    <SiteLayout>
                                        <Screen.EditUser />
                                    </SiteLayout>
                                }
                            />
                        )}
                    </Route>
                    <Route path="hotel">
                        {hasPermission("withdrawal-list", user) && (
                            <Route
                                path="withdrawal"
                                element={
                                    <SiteLayout>
                                        <Screen.HotelWithdrawalList />
                                    </SiteLayout>
                                }
                            />
                        )}
                        <Route path="accommodation">
                            <Route path=":id">
                                {hasPermission(
                                    "withdrawal-accommodation-list",
                                    user,
                                ) && (
                                    <Route
                                        path="withdrawal"
                                        element={
                                            <SiteLayout>
                                                <Screen.AccommodationWithdrawalList />
                                            </SiteLayout>
                                        }
                                    />
                                )}
                                {hasPermission(
                                    "accommodation-balance-list",
                                    user,
                                ) && (
                                    <Route
                                        path="balance"
                                        element={
                                            <SiteLayout>
                                                <Screen.BalanceList />
                                            </SiteLayout>
                                        }
                                    />
                                )}
                                {hasPermission(
                                    "accommodation-manager-list",
                                    user,
                                ) && (
                                    <Route
                                        path="manager"
                                        element={
                                            <SiteLayout>
                                                <Screen.ManagerList />
                                            </SiteLayout>
                                        }
                                    />
                                )}
                                {hasPermission("booking-list", user) && (
                                    <Route
                                        path="booking"
                                        element={
                                            <SiteLayout>
                                                <Screen.BookingList />
                                            </SiteLayout>
                                        }
                                    />
                                )}
                                <Route path="room-type">
                                    <Route path=":roomId">
                                        {hasPermission(
                                            "inventory-create",
                                            user,
                                        ) && (
                                            <Route
                                                path="inventory/new"
                                                element={
                                                    <SiteLayout>
                                                        <Screen.HotelInventoryCreate />
                                                    </SiteLayout>
                                                }
                                            />
                                        )}
                                        <Route path="rate-plan">
                                            {hasPermission(
                                                "rate-plan-list",
                                                user,
                                            ) && (
                                                <Route
                                                    path="list"
                                                    element={
                                                        <SiteLayout>
                                                            <Screen.HotelRatePlanList />
                                                        </SiteLayout>
                                                    }
                                                />
                                            )}
                                            {hasPermission(
                                                "rate-plan-edit",
                                                user,
                                            ) && (
                                                <Route
                                                    path="edit/:ratePlanId"
                                                    element={
                                                        <SiteLayout>
                                                            <Screen.HotelRatePlanEdit />
                                                        </SiteLayout>
                                                    }
                                                />
                                            )}
                                            {hasPermission(
                                                "rate-plan-create",
                                                user,
                                            ) && (
                                                <Route
                                                    path="new"
                                                    element={
                                                        <SiteLayout>
                                                            <Screen.HotelRatePlanCreate />
                                                        </SiteLayout>
                                                    }
                                                />
                                            )}
                                        </Route>
                                    </Route>
                                    {hasPermission("room-type-list", user) && (
                                        <Route
                                            path="list"
                                            element={
                                                <SiteLayout>
                                                    <Screen.HotelRoomTypeList />
                                                </SiteLayout>
                                            }
                                        />
                                    )}
                                    {hasPermission("room-type-show", user) && (
                                        <Route
                                            path="info/:roomId"
                                            element={
                                                <SiteLayout>
                                                    <Screen.HotelRoomTypeShow />
                                                </SiteLayout>
                                            }
                                        />
                                    )}
                                    {hasPermission("room-type-edit", user) && (
                                        <Route
                                            path="edit/:roomId"
                                            element={
                                                <SiteLayout>
                                                    <Screen.HotelRoomTypeEdit />
                                                </SiteLayout>
                                            }
                                        />
                                    )}
                                    {hasPermission(
                                        "room-type-create",
                                        user,
                                    ) && (
                                        <Route
                                            path="new"
                                            element={
                                                <SiteLayout>
                                                    <Screen.HotelRoomTypeCreate />
                                                </SiteLayout>
                                            }
                                        />
                                    )}
                                </Route>
                                {hasPermission("inventory-list", user) && (
                                    <Route
                                        path="inventory"
                                        element={
                                            <SiteLayout>
                                                <Screen.HotelInventoryList />
                                            </SiteLayout>
                                        }
                                    />
                                )}
                            </Route>
                            {hasPermission("accommodation-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAccommodationList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("accommodation-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAccommodationEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("accommodation-show", user) && (
                                <Route
                                    path="info/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAccommodationShow />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("accommodation-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAccommodationCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                    </Route>
                    <Route path="discount-code">
                        {hasPermission("coupon-list", user) && (
                            <Route
                                path=""
                                element={
                                    <SiteLayout>
                                        <Screen.ListDiscountCode />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("coupon-edit", user) && (
                            <Route
                                path="edit/:id"
                                element={
                                    <SiteLayout>
                                        <Screen.EditDiscountCode />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("coupon-create", user) && (
                            <Route
                                path="new"
                                element={
                                    <SiteLayout>
                                        <Screen.CreateDiscountCode />
                                    </SiteLayout>
                                }
                            />
                        )}
                    </Route>
                    <Route path="payment">
                        <Route
                            path=""
                            element={
                                <SiteLayout>
                                    <Screen.PaymentList />
                                </SiteLayout>
                            }
                        />
                        {hasPermission("gateway-list", user) && (
                            <Route
                                path="gateway"
                                element={
                                    <SiteLayout>
                                        <Screen.GatewayList />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("wallet-list", user) && (
                            <Route
                                path="wallet"
                                element={
                                    <SiteLayout>
                                        <Screen.WalletList />
                                    </SiteLayout>
                                }
                            />
                        )}
                        {hasPermission("withdrawal-list", user) && (
                            <Route
                                path="withdrawal"
                                element={
                                    <SiteLayout>
                                        <Screen.WithdrawalList />
                                    </SiteLayout>
                                }
                            />
                        )}
                        <Route
                            path="transaction"
                            element={
                                <SiteLayout>
                                    <Screen.TransactionList />
                                </SiteLayout>
                            }
                        />
                    </Route>
                    <Route path="report">
                        <Route path="hotel">
                            {hasPermission(
                                "accommodation-booking-report",
                                user,
                            ) && (
                                <Route
                                    path=""
                                    element={
                                        <SiteLayout>
                                            <Screen.ReportHotelList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="flight">
                            {hasPermission("flight-list", user) && (
                                <Route
                                    path=""
                                    element={
                                        <SiteLayout>
                                            <Screen.ReportFlightList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                    </Route>
                    {/* <Route path="order">
                        <Route
                            path="list"
                            element={
                                <SiteLayout>
                                    <Screen.OrderList />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path="info/:id"
                            element={
                                <SiteLayout>
                                    <Screen.OrderInfo />
                                </SiteLayout>
                            }
                        />
                    </Route> */}
                    <Route path="vehicle">
                        <Route path="trip">
                            {hasPermission("trip-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleTripList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("trip-show", user) && (
                                <Route
                                    path="info/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleTripInfo />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("trip-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleTripEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("trip-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleTripNew />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="driver">
                            {hasPermission("driver-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleDriverList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("driver-show", user) && (
                                <Route
                                    path="info/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleDriverInfo />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("driver-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.NewDriver />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("driver-edit", user) && (
                                <Route
                                    path=":id/edit"
                                    element={
                                        <SiteLayout>
                                            <Screen.EditDriver />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="car">
                            {hasPermission("vehicle-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.VehicleCarList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("vehicle-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.CreateCar />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("seat-create", user) && (
                                <Route
                                    path="create-seat"
                                    element={
                                        <SiteLayout>
                                            <Screen.CreateVehicleSeat />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("seat-list", user) && (
                                <Route
                                    path="seat-list"
                                    element={
                                        <SiteLayout>
                                            <Screen.SeatList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                    </Route>
                    <Route path="airplane">
                        <Route
                            path="commission"
                            element={
                                <SiteLayout>
                                    <Screen.FlightCommissionScreen />
                                </SiteLayout>
                            }
                        />
                    </Route>
                    <Route path="definition">
                        <Route path="port">
                            {hasPermission("port-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.PortList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("port-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.PortCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("port-show", user) && (
                                <Route
                                    path="info/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.PortShow />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("port-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.PortEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="country">
                            {hasPermission("country-list", user) && (
                                <Route
                                    path=""
                                    element={
                                        <SiteLayout>
                                            <Screen.CountryList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("country-show", user) && (
                                <Route
                                    path=":id"
                                    element={
                                        <SiteLayout>
                                            <Screen.CountryInfo />
                                        </SiteLayout>
                                    }
                                />
                            )}

                            {hasPermission("country-edit", user) && (
                                <Route
                                    path=":id/edit"
                                    element={
                                        <SiteLayout>
                                            <Screen.CountryEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("country-create", user) && (
                                <Route
                                    path="create"
                                    element={
                                        <SiteLayout>
                                            <Screen.CountryCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="city">
                            {hasPermission("city-list", user) && (
                                <Route
                                    path=""
                                    element={
                                        <SiteLayout>
                                            <Screen.CityList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("city-show", user) && (
                                <Route
                                    path=":id"
                                    element={
                                        <SiteLayout>
                                            <Screen.CityInfo />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("city-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.CityEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("city-create", user) && (
                                <Route
                                    path="create"
                                    element={
                                        <SiteLayout>
                                            <Screen.CityCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="province">
                            {hasPermission("province-list", user) && (
                                <Route
                                    path=""
                                    element={
                                        <SiteLayout>
                                            <Screen.ProvinceList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("province-show", user) && (
                                <Route
                                    path=":id"
                                    element={
                                        <SiteLayout>
                                            <Screen.ProvinceInfo />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("province-edit", user) && (
                                <Route
                                    path=":id/edit"
                                    element={
                                        <SiteLayout>
                                            <Screen.ProvinceEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("province-create", user) && (
                                <Route
                                    path="create"
                                    element={
                                        <SiteLayout>
                                            <Screen.ProvinceCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="route">
                            {hasPermission("route-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.RouteList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("route-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.RouteEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("route-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.AddressRouteNew />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                        <Route path="address">
                            {hasPermission("address-list", user) && (
                                <Route
                                    path="list"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAddressList />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("address-show", user) && (
                                <Route
                                    path="info/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAddressShow />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("address-edit", user) && (
                                <Route
                                    path="edit/:id"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAddressEdit />
                                        </SiteLayout>
                                    }
                                />
                            )}
                            {hasPermission("address-create", user) && (
                                <Route
                                    path="new"
                                    element={
                                        <SiteLayout>
                                            <Screen.HotelAddressCreate />
                                        </SiteLayout>
                                    }
                                />
                            )}
                        </Route>
                    </Route>

                    {/* <Route path="Ticket">
                        <Route
                            path=""
                            element={
                                <SiteLayout>
                                    <Screen.TicketList />
                                </SiteLayout>
                            }
                        />
                        <Route
                            path=":id"
                            element={
                                <SiteLayout>
                                    <Screen.TicketID />
                                </SiteLayout>
                            }
                        />
                    </Route> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const Auth = () => {
    const navigate = useAppNavigate();
    const token = useAppSelector(selectUser);

    React.useEffect(() => {
        if (
            token === undefined ||
            token?.access_token === undefined ||
            token?.access_token == ""
        ) {
            navigate("/login", { replace: false });
        }
    }, []);
    return <Outlet />;
};

export const hasPermission = (
    permissionName: string,
    user: userType,
): boolean => {
    let hasPer = false;

    user.permissions?.forEach((element) => {
        element.permissions.forEach((item) => {
            if (item.name == permissionName) {
                hasPer = true;
            }
        });
    });

    return hasPer;
};
