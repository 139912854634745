import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import { CustomBreadCrumb, Loading } from "../../../components";
import { PortType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user";
import { PortApi } from "../../../api";
import { useAppNavigate } from "../../../Router/hook";

export const PortShow: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [port, setPort] = useState<PortType>();

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const { id } = useParams();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashoard"), href: "/" },
        { name: t("portList"), href: "/definition/port/list" },
        { name: t("info") + " " + id, href: "#" },
    ];

    useEffect(() => {
        setLoading(true);

        PortApi.ShowPort(user, Number(id))
            .then((data) => {
                setPort(data.port);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    }, [id]);

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("info")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flx flex-column bg-custom-white rounded-3 justify-content-center align-items-center">
                    {port && (
                        <div className="d-flex flex-row gap-2 p-4">
                            <div className="col-12 col-md-4 mx-2">
                                <strong className="my-2">
                                    {t("portInfo")}
                                </strong>
                                <div className="d-flex flex-row">
                                    <span>{t("id")}</span>
                                    <strong className="mx-1">{port.id}</strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("type")}: </span>
                                    <strong className="mx-1">
                                        {port?.type}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("name")}: </span>
                                    <strong className="mx-1">
                                        {port.name}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("description")}: </span>
                                    <strong className="mx-1">
                                        {port.description}
                                    </strong>
                                </div>
                                <hr />
                            </div>
                            <div className="col-12 col-md-4">
                                <strong className="my-2">{t("address")}</strong>
                                <div className="d-flex flex-row">
                                    <span>{t("id")}</span>
                                    <strong className="mx-1">
                                        {port.address?.id}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("title")}: </span>
                                    <strong className="mx-1">
                                        {port.address?.title}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("detail")}: </span>
                                    <strong className="mx-1">
                                        {port.address?.detail}
                                    </strong>
                                </div>
                                <hr />
                            </div>
                            <div className="col-12 col-md-4">
                                <strong className="my-2">City</strong>
                                <div className="d-flex flex-row">
                                    <span>{t("id")}</span>
                                    <strong className="mx-1">
                                        {port.city?.id}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("name")}:</span>
                                    <strong className="mx-1">
                                        {port.city?.name}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("detail")}: </span>
                                    <strong className="mx-1">
                                        {port.city?.description}
                                    </strong>
                                </div>
                                <hr />
                                <div className="d-flex flex-row">
                                    <span>{t("provinceName")}: </span>
                                    <strong className="mx-1">
                                        {port.city?.province?.name}
                                    </strong>
                                </div>
                                <hr />
                            </div>
                        </div>
                    )}
                    <button
                        className="col-12 btn btn-warning align-self-center align-items-center"
                        onClick={() => navigate(`/port/edit/${port?.id}`)}
                    >
                        {t("edit")}
                    </button>
                </div>
            </div>
        </>
    );
};
