import { ProvinceType, PaginationType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToPaginationType } from "../help";
import { convertToCityList } from "./cityList";
import { convertToCountry } from "./countryList";

export const FetchProvinceList = (
    user: userType,
    current_page: number,
    province?: ProvinceType,
) => {
    return new Promise<{ data: ProvinceType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/province",
                header: {
                    Authorization: "Bearer " + user?.access_token,
                },
                params: {
                    page: current_page,
                    name: province?.name,
                    name_en: province?.name_en,
                    id: province?.id,
                },
            });
            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        data: convertToProvinceList(data.data.data),
                        pagination: convertToPaginationType({
                            current_page: data.data.current_page,
                            per_page: data.data.per_page,
                            total: data.data.total,
                        }),
                    });
                })
                .catch((err: any) => {
                    return reject(err);
                });
        },
    );
};

export function convertToProvinceList(data: any[]): ProvinceType[] {
    const provinceList = data.map((item: any) => {
        return convertToProvince(item);
    });

    return provinceList;
}

export function convertToProvince(data: any): ProvinceType {
    let provinceOb: ProvinceType = {
        id: data?.id,
        name: data?.name,
        name_en: data?.name_en,
        status: data?.status,
        domain_code: data?.domain_code,
        deleted_at: data?.deleted_at,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
        country: data?.country ? convertToCountry(data?.country) : undefined,
        city: data?.city ? convertToCityList(data?.city) : undefined,
        description: data?.description,
        description_en: data?.description_en,
    };
    return provinceOb;
}

export const FetchProvinceId = (user: userType, id: string) => {
    return new Promise<{ data: ProvinceType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/province/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToProvince(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const CreateProvince = (user: userType, province: ProvinceType) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: `admin/province`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                name: province?.name,
                name_en: province?.name_en,
                domain_code: province?.domain_code,
                country_id: province?.country?.id,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const EditProvince = (
    user: userType,
    province: ProvinceType,
    id: string,
) => {
    return new Promise<{ data: ProvinceType }>((resolve, reject) => {
        const api = new Api({
            method: "put",
            path: `admin/province/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: province?.name,
                name_en: province?.name_en,
                domain_code: province?.domain_code,
                country_id: province?.country?.id,
                // status: province?.status,
            }),
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToProvince(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};

export const DeleteProvince = (user: userType, id: string) => {
    return new Promise<{ data: any }>((resolve, reject) => {
        const api = new Api({
            method: "delete",
            path: `admin/province/${id}`,
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
        });
        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: convertToProvince(data.data),
                });
            })
            .catch((err: any) => {
                return reject(err);
            });
    });
};
