import * as Yup from "yup";

export const ValidationSchema = (t: any) =>
    Yup.object().shape({
        name: Yup.string().required(t("nameError")),
        description: Yup.string().required(t("descriptionError")),
        code: Yup.string().required(t("codeError")),
        discount: Yup.string().required(t("discountError")).max(2),
        price: Yup.number().required(t("priceError")),
    });
