import React, { FC, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import { AccommodationType, PaginationType } from "../../../DTO";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../../components";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { HotelApi } from "../../../api";
import { useAppNavigate, useAppPagination } from "../../../Router/hook";
import StarRating from "../../../components/star-rating";
import { useTranslation } from "react-i18next";

export const HotelAccommodationList: FC = () => {
    const [accommodation, setAccommodation] = useState<AccommodationType[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("hotelAccommodation"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const navigate = useAppNavigate();

    const fetchHotelAccommodationList = (page: number) => {
        setLoading(true);

        HotelApi.AccommodationApi.ListAccommodation(user, page)
            .then((data) => {
                setAccommodation(data.accommodation);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    useEffect(() => {
        fetchHotelAccommodationList(pagination.current_page);
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("hotelAccommodation")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">
                            {t("accommodationTable")}
                        </h4>
                        <div className="d-flex flex-row ">
                            <Link
                                to={`/hotel/accommodation/new`}
                                className="btn btn-success"
                            >
                                {t("create")} +
                            </Link>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("star")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accommodation.length > 0 &&
                                    accommodation.map((item, index: number) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <StarRating
                                                    rating={Number(item.star)}
                                                />
                                            </td>
                                            <td>
                                                <strong
                                                    className={
                                                        item.status === "active"
                                                            ? "badge bg-success"
                                                            : "badge bg-danger"
                                                    }
                                                >
                                                    {item.status}
                                                </strong>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/balance`}
                                                        className="btn btn-sm bg-custom-dark text-custom-light"
                                                    >
                                                        {t("balance")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/withdrawal`}
                                                        className="btn btn-sm bg-custom-white text-custom-black"
                                                    >
                                                        {t("withdrawal")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/booking`}
                                                        className="btn btn-sm btn-primary text-custom-light"
                                                    >
                                                        {t("booking")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/room-type/list`}
                                                        className="btn btn-sm btn-secondary text-custom-light"
                                                    >
                                                        {t("roomType")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/manager`}
                                                        className="btn btn-sm btn-success text-custom-light"
                                                    >
                                                        {t("manager")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/${item.id}/inventory`}
                                                        className="btn btn-sm bg-custom-light text-custom-dark"
                                                    >
                                                        {t("inventory")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/info/${item.id}`}
                                                        className="btn btn-sm btn-info"
                                                    >
                                                        {t("info")}
                                                    </Link>
                                                    <Link
                                                        to={`/hotel/accommodation/edit/${item.id}`}
                                                        className="btn btn-sm btn-warning"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                        />
                                                    </Link>
                                                    <button
                                                        onClick={() =>
                                                            Swal.fire({
                                                                text: `${t(
                                                                    "alertDelete",
                                                                )} ${
                                                                    item.name
                                                                }?`,
                                                                icon: "warning",
                                                                showCancelButton:
                                                                    true,
                                                                confirmButtonColor:
                                                                    "#3085d6",
                                                                cancelButtonColor:
                                                                    "#d33",
                                                                confirmButtonText:
                                                                    String(
                                                                        t(
                                                                            "yesDelete",
                                                                        ),
                                                                    ),
                                                                cancelButtonText:
                                                                    String(
                                                                        "cancel",
                                                                    ),
                                                            }).then(
                                                                (result) => {
                                                                    setLoading(
                                                                        true,
                                                                    );
                                                                    HotelApi.AccommodationApi.DeleteAccommodation(
                                                                        user,
                                                                        item.id,
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                setLoading(
                                                                                    false,
                                                                                );
                                                                                if (
                                                                                    result.isConfirmed
                                                                                ) {
                                                                                    Swal.fire(
                                                                                        String(
                                                                                            t(
                                                                                                "delete",
                                                                                            ),
                                                                                        ),
                                                                                        String(
                                                                                            t(
                                                                                                "deleteMessage",
                                                                                            ),
                                                                                        ),
                                                                                        "success",
                                                                                    );
                                                                                    fetchHotelAccommodationList(
                                                                                        pagination.current_page,
                                                                                    );
                                                                                }
                                                                            },
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                err,
                                                                            ) => {
                                                                                setLoading(
                                                                                    false,
                                                                                );
                                                                                Swal.fire(
                                                                                    {
                                                                                        icon: "error",
                                                                                        text: err,
                                                                                    },
                                                                                );
                                                                            },
                                                                        );
                                                                },
                                                            )
                                                        }
                                                        className="btn btn-sm btn-danger"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                        />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(
                                    `/hotel/accommodation/list?current_page=${pageNum}`,
                                );
                                fetchHotelAccommodationList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
