import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Form, Modal, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import Swal from "sweetalert2";
import "./style.scss";
import { DriverApi, OrderApi, PaymentApi } from "../../../../api";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CustomPagination, Loading } from "../../../../components";
import {
    DealType,
    DriverType,
    OrderType,
    PaginationType,
} from "../../../../DTO";
import { useAppNavigate, useAppPagination } from "../../../../Router/hook";
import { useTranslation } from "react-i18next";

export const VehicleDriverInfo: React.FC<{}> = () => {
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [transaction, setTransaction] = useState<DealType[]>([]);
    const { id } = useParams();
    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("driverList"), href: "/vehicle/driver/list" },
        { name: `${t("info")} ${id}`, href: "#" },
    ];

    const [loading, setLoading] = useState<boolean>(true);
    const [driver, setDriver] = useState<DriverType>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<{
        title: string;
        url: string;
    }>();

    const fetchData = () => {
        setLoading(true);
        if (id) {
            DriverApi.ShowDriver(user, id)
                .then((data) => {
                    setLoading(false);
                    setDriver(data.driver);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    const fetchUserTransaction = (page: number) => {
        if (driver?.user_id && driver?.user_id > 0) {
            setLoading(true);

            PaymentApi.TransactionApi.UserTransactionList(
                user,
                Number(driver.user_id),
                // page,
            )
                .then((data) => {
                    setTransaction(data.userTransaction);
                    // setPagination(data.pagination);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    useEffect(() => {
        fetchData();
        fetchUserTransaction(pagination.current_page);
        // fetchOrder(pageOrder)
    }, [id]);

    const changeStatus = (driverId: string, status: "Active" | "InActive") => {
        setLoading(true);
        DriverApi.ChangeStatusDriver(user, driverId, status)
            .then(() => {
                setLoading(false);
                fetchData();
                Swal.fire({
                    text: String(t("statusChangeMessage")),
                    icon: "success",
                });
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("info")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-row flex-wrap rounded px-3">
                    <div className="col-12  col-md-6 col-lg-4 p-2">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="5x"
                                icon={faUserCircle}
                            />
                            <span className="text-center mt-2">
                                {driver?.user?.first_name}{" "}
                                {driver?.user?.last_name}
                            </span>
                            <a
                                className="text-center"
                                href={`tel:${driver?.phone}`}
                            >
                                <span className="text-center mt-2">
                                    {driver?.phone}
                                </span>
                            </a>
                            <hr />
                            <div>
                                <span>{t("type")}:</span>
                                <span>{t("driver")}</span>
                            </div>
                            <div>
                                <span>{t("commission")}:</span>
                                <span>
                                    {Math.floor(Number(driver?.commission))}
                                </span>
                            </div>
                            <div>
                                <span>{t("commissionVip")}:</span>
                                <span>
                                    {Math.floor(Number(driver?.commissionVip))}
                                </span>
                            </div>
                            <div>
                                <span>{t("createdAt")}: </span>
                                <span>{driver?.created_at?.slice(0, 10)}</span>
                            </div>
                            <div
                                className="position-absolute border bg-custom-white rounded info-edit-part"
                                style={{ right: 10 }}
                            >
                                <Link
                                    to={`/vehicle/driver/edit/${id}`}
                                    className="p-2"
                                >
                                    <small>{t("edit")}</small>
                                </Link>
                            </div>
                            <div
                                className="position-absolute bg-custom-white "
                                style={{ left: 10, top: 8 }}
                            >
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        value={driver?.status}
                                        defaultChecked={
                                            driver?.status === "Active"
                                        }
                                        checked={
                                            driver?.status === "Active"
                                                ? true
                                                : false
                                        }
                                        onChange={(e) => {
                                            changeStatus(
                                                String(driver?.id),
                                                e.target.checked
                                                    ? "Active"
                                                    : "InActive",
                                            );
                                        }}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4 p-2 ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                            <span>{t("licenseImage")}</span>
                            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                                {driver?.user?.picture?.map((item, index) => (
                                    <div key={index} className="m-2">
                                        <img
                                            onClick={() => {
                                                setShowModal(true);
                                                setModalData({
                                                    url: item?.url + "",
                                                    title:
                                                        item?.type == "1"
                                                            ? t("personalPhoto")
                                                            : item?.type == "2"
                                                            ? t(
                                                                  "frontDriverLicense",
                                                              )
                                                            : item?.type == "3"
                                                            ? t(
                                                                  "backDriverLicense",
                                                              )
                                                            : item?.type == "4"
                                                            ? t(
                                                                  "frontNationalId",
                                                              )
                                                            : t(
                                                                  "backNationalId",
                                                              ),
                                                });
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                objectFit: "contain",
                                            }}
                                            src={item.url}
                                            width={70}
                                            height={70}
                                            alt="img"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={showModal}
                        onHide={() => {
                            setShowModal(false);
                        }}
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{modalData?.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-row justify-content-center align-items-center">
                            <img
                                alt={`${modalData?.title}`}
                                src={modalData?.url}
                                className="h-75 w-75"
                            />
                        </Modal.Body>
                    </Modal>
                    <div className="col-12  col-md-6 col-lg-4 p-2 ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                            <span>
                                <span>{t("model")}:</span>
                                <span>{driver?.vehicle?.model}</span>
                            </span>
                            <span>
                                <span>{t("carName")}:</span>
                                <span>{driver?.vehicle?.title}</span>
                            </span>
                            <hr />
                            <span>
                                <span>{t("walletBalance")}:</span>
                                <span>
                                    {driver?.user?.wallets &&
                                        driver?.user?.wallets[
                                            driver?.user?.wallets?.length - 1
                                        ]?.balance}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col-12  col-md-12  p-2 ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                            <span>{t("walletList")}</span>
                            <hr />
                            <div
                                className="overflow-auto w-100"
                                style={{ maxHeight: "9rem" }}
                            >
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>{t("id")}</th>
                                            <th>{t("type")}</th>
                                            <th>{t("balance")}</th>
                                            <th>{t("amount")}</th>
                                            <th>{t("currency")}</th>
                                            <th>{t("description")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {driver?.user?.wallets &&
                                            driver?.user?.wallets.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item?.id}</td>
                                                        <td>
                                                            <strong
                                                                className={
                                                                    item?.type ===
                                                                    "increase"
                                                                        ? "badge bg-success"
                                                                        : "badge bg-danger"
                                                                }
                                                            >
                                                                {item?.type}
                                                            </strong>
                                                        </td>
                                                        <td>{item?.balance}</td>
                                                        <td>{item?.amount}</td>
                                                        <td>
                                                            {item?.currency}
                                                        </td>
                                                        <td>
                                                            {item?.description}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="p-4 text-center text-nowrap rounded p-3 shadow bg-custom-white h-100">
                            <h4 className="text-nowrap">
                                {t("transactionTable")}
                            </h4>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>{t("id")}</th>
                                        <th>{t("type")}</th>
                                        <th>{t("amount")}</th>
                                        <th>{t("currency")}</th>
                                        <th>{t("gateway")}</th>
                                        <th>{t("device")}</th>
                                        <th>{t("status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transaction.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.id}</td>
                                            <td>{item?.type}</td>
                                            <td>{item?.amount}</td>
                                            <td>{item?.currency}</td>
                                            <td>{item?.gateway}</td>
                                            <td>{item?.device}</td>
                                            <td>
                                                <strong
                                                    className={
                                                        item?.status ===
                                                        "success"
                                                            ? "badge bg-success"
                                                            : "badge bg-warning"
                                                    }
                                                >
                                                    {item?.status}
                                                </strong>{" "}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
