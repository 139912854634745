import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import {
    CustomPagination,
    Loading,
    CustomBreadCrumb,
} from "../../../components";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import "./style.scss";
import { AdminApi, UserAccessApi } from "../../../api";
import { AdminUserType, PaginationType, RoleType } from "../../../DTO";
import { useAppPagination } from "../../../Router/hook";

export const AdminUsers = () => {
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [adminList, setAdminList] = useState<AdminUserType[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [adminId, setAdminId] = useState<number>();
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [roleId, setRoleId] = useState<number>();

    const user = useAppSelector(selectUser);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("adminList")), href: "#" },
    ];

    useEffect(() => {
        fetchAdminList(pagination.current_page);
        fetchRoleList();
    }, []);

    const fetchAdminList = (page: number) => {
        setLoading(true);
        AdminApi.FetchList(user, page)
            .then((data) => {
                setAdminList(data.data);
                setPagination(data.pagination);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    const fetchRoleList = () => {
        setLoading(true);
        UserAccessApi.RoleApi.ListRole(user)
            .then((data) => {
                setRoleList(data.role);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    const handleAssignRole = () => {
        UserAccessApi.RoleApi.AssignRole(user, Number(adminId), Number(roleId))
            .then(() => {
                Swal.fire(
                    String(t("assignRole")),
                    String(t("assignRoleMessage")),
                    "success",
                );
                setAdminId(0);
                setRoleId(0);
                setShowModal(false);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err,
                });
            });
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("safartic")} | {t("adminList")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("adminTable")}</h4>
                        <div className="d-flex flex-row ">
                            <button
                                onClick={() => navigate("/admin-user/new")}
                                className="btn btn-success mx-1 text-nowrap"
                            >
                                <span>{t("add")}</span>
                                <FontAwesomeIcon
                                    className={"mx-1"}
                                    icon={faAdd}
                                />
                            </button>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("username")}</th>
                                    <th>{t("fullName")}</th>
                                    <th>{t("phoneNumber")}</th>
                                    <th>{t("email")}</th>
                                    <th>{t("actions")}</th>
                                </tr>
                            </thead>
                            <tbody className="align-baseline">
                                {adminList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.id}</td>
                                        <td>{item?.username}</td>
                                        <td>
                                            {item?.first_name} {item?.last_name}
                                        </td>
                                        <td>{item?.phone}</td>
                                        <td>{item?.email}</td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 justify-content-center align-items-center align-self-center">
                                                <Link
                                                    to={`/admin-user/info/${item?.id}`}
                                                    className="btn btn-info btn-sm"
                                                >
                                                    {t("info")}
                                                </Link>
                                                <button
                                                    className="btn btn-sm btn-dark"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setAdminId(item.id);
                                                    }}
                                                >
                                                    {t("assignRole")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(`/admin-user?current_page=${pageNum}`);
                                fetchAdminList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
                centered
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("assignRole")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setRoleId(Number(e.target.value))}
                    >
                        <option className="d-none">
                            {t("rolePlaceholder")}
                        </option>
                        {roleList.map((element) => (
                            <option key={element.id} value={element.id}>
                                {element.name}
                            </option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => setShowModal(false)}
                    >
                        {t("close")}
                    </Button>
                    <Button
                        disabled={Number(roleId) < 0}
                        variant="success"
                        onClick={handleAssignRole}
                    >
                        {t("submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
