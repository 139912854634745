import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
    Button,
    Dropdown,
    DropdownButton,
    Modal,
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { CustomBreadCrumb, CustomPagination, Loading } from "../../components";
import { useAppNavigate, useAppPagination } from "../../Router/hook";
import { LogType, PaginationType } from "../../DTO";
import { LogApi } from "../../api";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user";
import { useTranslation } from "react-i18next";

export const Log: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationType>(
        useAppPagination(),
    );
    const [log, setLog] = useState<LogType[]>([]);
    const [showModalLog, setShowModalLog] = useState<boolean>(false);
    const [modalLogData, setModalLogData] = useState<any>();
    const [type, setType] = useState<
        "storeroute" | "storedriver" | "storetrip"
    >("storetrip");

    const user = useAppSelector(selectUser);

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("log"), href: "#" },
    ];

    const navigate = useAppNavigate();

    const fetchLogList = (page: number) => {
        setLoading(true);

        LogApi.AdminLog(user, type, page)
            .then((data) => {
                setLog(data.log);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: err.data.message,
                });
            });
    };

    useEffect(() => {
        fetchLogList(pagination.current_page);
    }, [type]);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("log")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                {loading && <Loading />}
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">{t("logTable")}</h4>
                        <div className="d-flex flex-row ">
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={String(t("logType"))}
                            >
                                <Dropdown.Item
                                    onClick={() => setType("storetrip")}
                                >
                                    {t("trip")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setType("storedriver")}
                                >
                                    {t("driver")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setType("storeroute")}
                                >
                                    {t("route")}
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("action")}</th>
                                    <th>{t("adminFullName")}</th>
                                    <th>{t("adminPhone")}</th>
                                    <th>{t("ip")}</th>
                                    <th>{t("status")}</th>
                                    <th>{t("updatedAt")}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {log.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.action}</td>
                                        <td>
                                            {item.user.firstName}{" "}
                                            {item?.user.lastName}
                                        </td>
                                        <td>{item.user.phone}</td>
                                        <td>{item.ip}</td>
                                        <td>{item.status_code}</td>
                                        <td>
                                            {item.updated_at.slice(0, 10)}{" "}
                                            {item.updated_at.slice(11, 19)}
                                        </td>
                                        <td>
                                            <Link
                                                to={`/admin-user/info/${item.user.id}`}
                                                className="btn btn-info btn-sm"
                                            >
                                                {t("adminInfo")}
                                            </Link>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                    setShowModalLog(true);
                                                    setModalLogData(
                                                        item.request_body,
                                                    );
                                                }}
                                            >
                                                {t("changeField")}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <CustomPagination
                            total={pagination.total}
                            perPage={pagination.per_page}
                            currentPage={pagination.current_page}
                            changePage={(pageNum: number) => {
                                navigate(`/log?current_page=${pageNum}`);
                                fetchLogList(pageNum);
                            }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModalLog}
                onHide={() => setShowModalLog(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{type} Log Changes Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalLogData && (
                        <>
                            {type === "storedriver" ? (
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-row">
                                        <span>{t("passportId")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.passport_id}
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("licenseId")}:</span>
                                        <strong className="mx-1">
                                            <span>
                                                {modalLogData.licence_id}
                                            </span>
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("vehicleId")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.vehicle_id}
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("phone")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.phone}
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("firstName")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.first_name}
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("lastName")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.last_name}
                                        </strong>
                                    </div>
                                </div>
                            ) : type === "storeroute" ? (
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-row">
                                        <span>{t("cityInId")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.city_in_id}
                                        </strong>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span>{t("cityOutId")}:</span>
                                        <strong className="mx-1">
                                            {modalLogData.city_out_id}
                                        </strong>
                                    </div>
                                </div>
                            ) : (
                                type === "storetrip" && (
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex flex-row">
                                            <span>{t("driverId")}:</span>
                                            <strong className="mx-1">
                                                {modalLogData.driver_id}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>{t("duration")}:</span>
                                            <strong className="mx-1">
                                                <span>
                                                    {modalLogData.duration}
                                                </span>
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>{t("price")}:</span>
                                            <strong className="mx-1">
                                                {modalLogData.price}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>{t("routeId")}:</span>
                                            <strong className="mx-1">
                                                {modalLogData.route_id}
                                            </strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <span>{t("time")}:</span>
                                            <strong className="mx-1">
                                                {modalLogData.time}
                                            </strong>
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowModalLog(false)}
                    >
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
