import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Loading } from "../../../../components";
import { CustomBreadCrumb } from "../../../../components/customBreadCrumb";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux-config/hooks";
import { selectUser } from "../../../../redux-config/entities/user";
import { CountryType } from "../../../../DTO";
import { AddressApi } from "../../../../api";
import { Formik } from "formik";
import { CountryForm } from "../../../../form";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useAppNavigate } from "../../../../Router/hook";

export const CountryEdit = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [countryOB, setCountryOB] = useState<CountryType>();

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: String(t("dashboard")), href: "/" },
        { name: String(t("country")), href: "/definition/country" },
        { name: `${t("edit")} / ${id}`, href: "#" },
    ];

    useEffect(() => {
        fetchCountryOB();
    }, [id]);

    const fetchCountryOB = () => {
        if (id) {
            setLoading(true);
            AddressApi.FetchCountryId(user, id)
                .then((data) => {
                    setCountryOB(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: err.data.message,
                    });
                });
        }
    };

    const navigate = useAppNavigate();

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("country")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="">
                        <h4 className="my-2">{t("editCountry")}</h4>
                        {countryOB && (
                            <div className="d-flex flex-row flex-wrap">
                                <Formik<CountryForm.FormValues>
                                    validationSchema={CountryForm.ValidationSchemaEdit(
                                        t,
                                    )}
                                    initialValues={{
                                        name:
                                            countryOB.name != null
                                                ? countryOB.name
                                                : "",
                                        enName:
                                            countryOB.name_en != null
                                                ? countryOB.name_en
                                                : "",
                                        domainCode:
                                            countryOB.domain_code != null
                                                ? countryOB.domain_code
                                                : "",
                                        status: countryOB.status,
                                    }}
                                    onSubmit={(event) => {
                                        if (id) {
                                            setLoading(true);
                                            AddressApi.EditCountry(
                                                user,
                                                {
                                                    id: 0,
                                                    domain_code:
                                                        event.domainCode,
                                                    name: event.name,
                                                    name_en: event.enName,
                                                    status: event.status,
                                                },
                                                id,
                                            )
                                                .then((data) => {
                                                    setLoading(false);
                                                    navigate(
                                                        `/definition/country`,
                                                    );
                                                })
                                                .catch((err) => {
                                                    setLoading(false);
                                                    Swal.fire({
                                                        icon: "error",
                                                        text: err.data.message,
                                                    });
                                                });
                                        }
                                    }}
                                    component={(props: any) => (
                                        <CountryForm.CountryFormEdit
                                            submitTxt={t("submit")}
                                            {...props}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
