import { PaginationType, PortAddressType, PortType, userType } from "../../DTO";
import { castAddress, convertToCity } from "../address";
import { Api } from "../api";
import { convertToPaginationType } from "../help";

export const ListPort = (
    user: userType,
    currentPage: number,
    name?: string,
) => {
    return new Promise<{ port: PortType[]; pagination: PaginationType }>(
        (resolve, reject) => {
            const api = new Api({
                method: "get",
                path: "admin/port",
                header: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                params: {
                    page: currentPage,
                    name,
                },
            });

            return api
                .call()
                .then((data: any) => {
                    return resolve({
                        port: castPortList(data.data.data),
                        pagination: convertToPaginationType(data.data),
                    });
                })
                .catch((error) => reject(error));
        },
    );
};

export const castPortList = (data: any[]): PortType[] => {
    return data.map((element) => castPort(element));
};

export const castPort = (data: any): PortType => {
    let out: PortType = {
        id: data?.id,
        cityId: data?.city_id,
        type: data?.type,
        description: data?.description,
        name: data?.name,
        address: castAddress(data?.address),
        city: data?.city ? convertToCity(data?.city) : null,
        status: data?.status,
    };

    return out;
};

export const castPortAddress = (data: any): PortAddressType => {
    let out: PortAddressType = {
        id: data?.id,
        userId: data?.user_id,
        cityId: data?.city_id,
        title: data?.title,
        detail: data?.detail,
        postalCode: data?.postal_code,
        buildingNumber: data?.building_number,
        unit: data?.unit,
        lat: data?.lat,
        lng: data?.lng,
        deletedAt: data?.deleted_at,
        createdAt: data?.created_at,
        updatedAt: data?.updated_at_at,
    };

    return out;
};
