import React, { FC, useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { PriceCurrencyType } from "../../DTO";
import { useAppSelector } from "../../redux-config/hooks";
import { selectUser } from "../../redux-config/entities/user/selector";
import { CustomPagination, Loading } from "../../components";
import { CustomBreadCrumb } from "../../components/customBreadCrumb";
import { useAppPagination } from "../../Router/hook";
import { PriceCurrencyApi } from "../../api";
import { useTranslation } from "react-i18next";

export const PriceCurrencyList: FC = () => {
    const [price, setPrice] = useState<PriceCurrencyType[]>([]);
    const [loading, setLoading] = useState(false);
    const [dollarPrice, setDollarPrice] = useState<string>("");
    const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
    const [priceType, setPriceType] = useState<
        "dollarToDinar" | "dollarToRial" | "dinarToRial"
    >("dollarToDinar");

    const { t } = useTranslation();

    const crumbs = [
        { name: "", href: "" },
        { name: t("dashboard"), href: "/" },
        { name: t("priceCurrency"), href: "#" },
    ];

    const user = useAppSelector(selectUser);

    const fetchPriceCurrencyList = () => {
        setLoading(true);

        PriceCurrencyApi.ShowPriceCurrency(user)
            .then((data) => {
                setPrice(data.priceCurrency);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error,
                });
            });
    };

    const handleCreatePriceCurrency = () => {
        setLoading(true);

        PriceCurrencyApi.CreatePriceCurrency(
            user,
            Number(dollarPrice),
            priceType,
        )
            .then((data: any) => {
                setLoading(false);
                setShowModalCreate(false);
                fetchPriceCurrencyList();
                setDollarPrice("");
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    text: error,
                    icon: "error",
                });
            });
    };

    useEffect(() => {
        fetchPriceCurrencyList();
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    {t("safartic")} | {t("priceCurrency")}
                </title>
                <meta name="description" content="Basic example" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex flex-column">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-column bg-custom-white rounded p-3">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-2 border-bottom">
                        <h4 className="text-nowrap">
                            {t("priceCurrencyTable")}
                        </h4>
                        <div className="d-flex flex-row ">
                            <Dropdown>
                                <Dropdown.Toggle variant="success">
                                    {priceType === "dollarToDinar"
                                        ? t("dollarToDinar")
                                        : priceType === "dollarToRial"
                                        ? t("dollarToRial")
                                        : priceType === "dinarToRial" &&
                                          t("dinarToRial")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setPriceType("dollarToRial");
                                            setShowModalCreate(true);
                                        }}
                                    >
                                        {t("dollarToRial")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setPriceType("dollarToDinar");
                                            setShowModalCreate(true);
                                        }}
                                    >
                                        {t("dollarToDinar")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setPriceType("dinarToRial");
                                            setShowModalCreate(true);
                                        }}
                                    >
                                        {t("dinarToRial")}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="p-4 text-center text-nowrap">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>{t("id")}</th>
                                    <th>{t("type")}</th>
                                    <th>{t("baseCurrency")}</th>
                                    <th>{t("basePrice")}</th>
                                    <th>{t("quoteCurrency")}</th>
                                    <th>{t("quotePrice")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {price.length > 0 &&
                                    price.map((item) => (
                                        <tr>
                                            <td>{item.id}</td>
                                            <td>{item.type}</td>
                                            <td>{item.baseCurrency}</td>
                                            <td>
                                                {item.basePrice.toLocaleString()}
                                            </td>
                                            <td>{item.quoteCurrency}</td>
                                            <td>
                                                {item.quotePrice.toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <Modal
                show={showModalCreate}
                onHide={() => {
                    setShowModalCreate(false);
                    setDollarPrice("");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("priceCurrency")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        controlId="price-currency"
                        className="position-relative col-12 p-2"
                    >
                        <Form.Label>
                            {priceType === "dollarToDinar"
                                ? t("dollarToDinar")
                                : priceType === "dollarToRial"
                                ? t("dollarToRial")
                                : priceType === "dinarToRial" &&
                                  t("dinarToRial")}
                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            aria-label="price-currency"
                            className="h3"
                            type="number"
                            name="dollarPrice"
                            placeholder={
                                priceType === "dollarToDinar"
                                    ? t("dollarToDinarPlaceholder")
                                    : priceType === "dollarToRial"
                                    ? t("dollarToRialPlaceholder")
                                    : priceType === "dinarToRial"
                                    ? t("dinarToRialPlaceholder")
                                    : ""
                            }
                            value={dollarPrice}
                            onChange={(e) => setDollarPrice(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowModalCreate(false);
                            setDollarPrice("");
                        }}
                    >
                        {t("close")}
                    </Button>
                    <Button
                        variant="success"
                        onClick={handleCreatePriceCurrency}
                    >
                        {t("submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
