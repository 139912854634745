import React from "react";

import "./style.scss";

export const SeatUI: React.FC<{ color: string; number?: string }> = ({
    color,
    number,
}) => {
    return (
        <div className="seat-ui d-flex flex-column">
            <div
                style={{ backgroundColor: `${color}` }}
                className="seat-ui-child-1 text-center"
            >
                {number}
            </div>
            <div
                style={{ backgroundColor: `${color}` }}
                className="seat-ui-child-2"
            ></div>
            <div className="seat-ui-child-3"></div>
        </div>
    );
};
