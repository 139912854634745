import { userType } from "../../DTO";
import { Api } from "../api";

export const CreateAdmin = (
    user: userType,
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    phone: string,
    password: string,
    roleId: number,
) => {
    return new Promise<{ admin: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin",
            header: {
                Authorization: "Bearer " + user?.access_token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                username,
                email,
                phone,
                password,
                role_id: roleId,
            }),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    admin: data.data,
                });
            })
            .catch((error) => reject(error));
    });
};
