import { userType } from "../../../DTO";
import { Api } from "../../api";

export const AssignPermission = (
    user: userType,
    permission: { role_id: number; permission_id: number }[],
) => {
    return new Promise<{ data: boolean }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/assign/role",
            header: {
                Authorization: `Bearer ${user.access_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify([
                {
                    permission,
                },
            ]),
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    data: data.data,
                });
            })
            .catch((err) => reject(err));
    });
};
