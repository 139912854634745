import { PortType, userType } from "../../DTO";
import { Api } from "../api";
import { castPort } from "./list";

export const ShowPort = (user: userType, id: number) => {
    return new Promise<{ port: PortType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/port/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    port: castPort(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
