import { Api } from "../../api";
import {
    PaginationType,
    TransactionType,
    WalletType,
    userType,
} from "../../../DTO";
import { convertToPaginationType } from "../../help";
import { convertToUser } from "../../driver";

export const WalletAllList = (user: userType, currentPage: number) => {
    return new Promise<{
        wallet: WalletType[];
        // pagination: PaginationType;
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: "admin/wallets",
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
            params: {
                page: currentPage,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    wallet: convertToWalletTypeList(data.data.data),
                    // pagination: convertToPaginationType(data.meta),
                });
            })
            .catch((error: any) => {
                reject(error.data.message);
            });
    });
};

export const convertToWalletTypeList = (data: any[]): WalletType[] => {
    return data.map((element) => convertToWalletType(element));
};

export const convertToWalletType = (data: any): WalletType => {
    let out: WalletType = {
        amount: data?.amount,
        balance: data?.balance,
        currency: data?.currency,
        description: data?.description,
        id: data?.id,
        status: data?.status,
        type: data?.type,
        user: data?.user ? convertToUser(data?.user) : undefined,
    };

    return out;
};

export const castTransactionList = (data: any[]): TransactionType[] => {
    return data.map((element) => castTransaction(element));
};

export const castTransaction = (data: any): TransactionType => {
    let out: TransactionType = {
        id: data?.id,
        userId: data?.user_id,
        balance: data?.balance,
        status: data?.status,
        amount: data?.amount,
        type: data?.type,
        refId: data?.ref_id,
        description: data?.description,
        transactionId: data?.transaction_id,
        currency: data?.currency,
        deletedAt: data?.deleted_at,
        createdAt: data?.created_at,
        updatedAt: data?.updated_at,
    };

    return out;
};
