import React, { useState } from "react";
import { useAppSelector } from "../../../redux-config/hooks";
import { selectUser } from "../../../redux-config/entities/user/selector";
import { CustomBreadCrumb } from "../../../components/customBreadCrumb";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./style.scss";
import { Link } from "react-router-dom";
import { useAppNavigate } from "../../../Router/hook";

export const ProfileInfo = () => {
    const user = useAppSelector(selectUser);

    const { id } = useParams();

    const crumbs = [
        { name: "", href: "" },
        { name: "Dashboard", href: "/" },
        { name: "Profile", href: "#" },
    ];

    const seriesLine = [
        {
            name: "Sales",
            data: [
                4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5,
            ],
        },
    ];

    const optionsLine: ApexOptions = {
        chart: {
            height: 350,
            type: "line",
        },
        stroke: {
            width: 5,
            curve: "smooth",
        },
        xaxis: {
            type: "datetime",
            categories: [
                "1/11/2000",
                "2/11/2000",
                "3/11/2000",
                "4/11/2000",
                "5/11/2000",
                "6/11/2000",
                "7/11/2000",
                "8/11/2000",
                "9/11/2000",
                "10/11/2000",
                "11/11/2000",
                "12/11/2000",
                "1/11/2001",
                "2/11/2001",
                "3/11/2001",
                "4/11/2001",
                "5/11/2001",
                "6/11/2001",
            ],
            tickAmount: 10,
            labels: {
                formatter: function (value, timestamp: number, opts) {
                    return opts.dateFormatter(new Date(timestamp), "dd MMM");
                },
            },
        },
        title: {
            text: "Site Activity",
            align: "left",
            style: {
                fontSize: "16px",
                color: "#666",
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                gradientToColors: ["#FDD835"],
                shadeIntensity: 1,
                type: "horizontal",
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100],
            },
        },
        yaxis: {
            min: -10,
            max: 40,
        },
    };

    const navigate = useAppNavigate();

    return (
        <>
            <Helmet>
                <title>SafarTic | User info</title>
                <meta name="description" content="Basic example" />
            </Helmet>
            <div className="d-flex flex-column ">
                <CustomBreadCrumb breadCrumbList={crumbs} />
                <div className="d-flex flex-row flex-wrap rounded px-3">
                    <div className="col-12  col-md-6 col-lg-4 p-2">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column position-relative">
                            <FontAwesomeIcon
                                className="mx-2 userImageSiteLayout"
                                size="5x"
                                icon={faUserCircle}
                            />
                            <span className="text-center mt-2">
                                {user.first_name + " " + user.last_name}
                            </span>
                            <span className="text-center mt-2">
                                {user.email}
                            </span>
                            <hr />
                            <div>
                                <span>company name :</span>
                                <span> sample</span>
                            </div>
                            <div>
                                <span>registery date :</span>
                                <span> 2022/02/02</span>
                            </div>
                            <div
                                className="position-absolute info-dot-icon"
                                style={{ top: "2px", right: "12px" }}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </div>
                            <div
                                className="position-absolute border bg-custom-white rounded info-edit-part"
                                style={{ right: 10 }}
                            >
                                <Link to={`/admin/edit/${id}`} className="p-2">
                                    <span>
                                        <small>Edit profile </small>
                                    </span>
                                </Link>
                                <div className="w-100 border"></div>
                                <div className="p-2">
                                    <span>
                                        <small>Change Password</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4 p-2 ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                            <span>space</span>
                        </div>
                    </div>

                    <div className="col-12   col-lg-4 p-2">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column">
                            <div>
                                <span>message Count : </span>
                                <span>50</span>
                            </div>
                            <hr />
                            <div>
                                <span>message peer group Count :</span>
                                <span> 5</span>
                            </div>
                            <hr />
                            <div>
                                <span>Replay peer group Count :</span>
                                <span> 22</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-2 ">
                        <div className="rounded p-3 shadow bg-custom-white h-100 d-flex flex-column flex-sm-row">
                            <div className=" w-100">
                                <ApexCharts
                                    options={optionsLine}
                                    series={seriesLine}
                                    type="line"
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
