import {
    AccommodationConfigType,
    AccommodationConfigValueType,
    PaginationType,
    userType,
} from "../../../DTO";
import { Api } from "../../api";

export const ListConfigAccommodation = (user: userType, id: number) => {
    return new Promise<{
        accommodationConfig: AccommodationConfigType[];
    }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `admin/accommodation/${id}/config`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    accommodationConfig: convertToAccommodationConfigTypeList(
                        data.data.data,
                    ),
                });
            })
            .catch((error: any) => {
                console.log(error);
                return reject(error.data.message);
            });
    });
};

export const convertToAccommodationConfigTypeList = (
    data: any[],
): AccommodationConfigType[] => {
    return data.map((element) => convertToAccommodationConfigType(element));
};

export const convertToAccommodationConfigType = (
    data: any,
): AccommodationConfigType => {
    let out: AccommodationConfigType = {
        id: data?.id,
        key: data?.key,
        value:
            data?.key == "cancellation"
                ? convertToAccommodationConfigValueTypeList(
                      Array.isArray(JSON.parse(data?.value))
                          ? JSON.parse(data?.value)
                          : JSON.parse(JSON.parse(data?.value)),
                  )
                : data?.value,
    };

    return out;
};

export const convertToAccommodationConfigValueTypeList = (
    data: any[],
): AccommodationConfigValueType[] => {
    return data.map((element) =>
        convertToAccommodationConfigValueType(element),
    );
};

export const convertToAccommodationConfigValueType = (
    data: any,
): AccommodationConfigValueType => {
    let out: AccommodationConfigValueType = {
        hour: data?.hour,
        percent: data?.percent,
    };

    return out;
};
