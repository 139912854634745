import { userType } from "../../DTO";
import { Api } from "../api";

export const ChangeStatusDriver = (
    user: userType,
    id: string,
    status: "Active" | "InActive",
) => {
    return new Promise<{ driver: number }>((resolve, reject) => {
        const api = new Api({
            method: "post",
            path: "admin/driver/change/status",
            header: {
                Authorization: "Bearer " + user?.access_token,
            },
            params: {
                id,
                status,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    driver: data.data,
                });
            })
            .catch((error) => reject(error));
    });
};
