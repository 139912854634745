import { UserAppType, userType } from "../../DTO";
import { Api } from "../api";
import { convertToUser } from "../driver";

export const ShowUser = (user: userType, id: number) => {
    return new Promise<{ user: UserAppType }>((resolve, reject) => {
        const api = new Api({
            method: "get",
            path: `users/admin/show/${id}`,
            header: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        return api
            .call()
            .then((data: any) => {
                return resolve({
                    user: convertToUser(data.data),
                });
            })
            .catch((error) => reject(error));
    });
};
